import { STORE_LOCATIONS } from 'Components/Locations/location';
import { SKUSummaryData } from 'Domain/Backoffice/types';

export const skuSummarySortFn2 = (a: [string, SKUSummaryData], b: [string, SKUSummaryData]) => {
  const aMode = a[1].sku.mode!;
  const bMode = b[1].sku.mode!;
  const aCost = a[1].sku.cost;
  const bCost = b[1].sku.cost;
  if (aMode !== bMode) return aMode.localeCompare(bMode);
  return aCost - bCost;
};

export const orderSortFn = (orderA, orderB) => {
  const aSKUCode = orderA.skuCode;
  const bSKUCode = orderB.skuCode;
  const aMode = aSKUCode.substr(3, 2);
  const bMode = bSKUCode.substr(3, 2);

  if (aMode !== bMode) return aMode.localeCompare(bMode);

  const aType = aSKUCode[aSKUCode.length - 1];
  const bType = bSKUCode[bSKUCode.length - 1];

  if (aType !== bType) return aType.localeCompare(bType);

  return orderA.orderId.localeCompare(orderB.orderId);
};

// TODO: Make it not dependent on LINE HORO
export const skuSummarySortFn = (a: [string, SKUSummaryData], b: [string, SKUSummaryData]) => {
  const aSKUCode = a[0];
  const bSKUCode = b[0];
  const aMode = aSKUCode.substr(3, 2);
  const bMode = bSKUCode.substr(3, 2);
  if (aMode !== bMode) return aMode.localeCompare(bMode);

  const aType = aSKUCode[aSKUCode.length - 1];
  const bType = bSKUCode[bSKUCode.length - 1];
  return aType.localeCompare(bType);
};

export enum FIX_BADLUCK_MODE {
  ZODIAC_REQUIRED = 'ZODIAC_REQUIRED',
  NAME_REQUIRED = 'NAME_REQUIRED',
}

export const getFixBadLuckMode = (locationId: string) => {
  if (locationId === STORE_LOCATIONS.LSJ) return FIX_BADLUCK_MODE.NAME_REQUIRED;
  return FIX_BADLUCK_MODE.ZODIAC_REQUIRED;
};
