import { getDisplayValue } from './utils';
import { getMonthDisplay, WALLPAPER_GENDER } from './Wallpaper';

// This is wallpaperRequestType / topic in API
export enum MTW_REQUEST_TYPES {
  FIRST_COLLECTION = 'FIRST_COLLECTION',
  STAR_TAROT = 'STAR_TAROT',
  DEVAS_TAROT = 'DEVAS_TAROT',
  ARCANA_TAROT = 'ARCANA_TAROT',
  ARCANA_TAROT_DESKTOP = 'ARCANA_TAROT_DESKTOP',
  ASURA_RAHU = 'ASURA_RAHU',
  LUNA_LAPIN = 'LUNA_LAPIN',
  LUCK_CARD = 'LUCK_CARD',
}

export enum MTW_SHIPMENT_FORM_TYPES {
  MTW_LUCK_CARD = 'MTW_LUCK_CARD',
}

// This is subtopic
export enum MTW_SUBTOPICS {
  LOVE = 'LOVE',
  WORK_1ST = 'WORK_1ST',
  MONEY = 'MONEY',
  FANS = 'FANS',
  WORK = 'WORK',
  INVESTMENT = 'INVESTMENT',
  STUDY = 'STUDY',
  LUCK = 'LUCK',
}

export const MTW_SUBTOPIC_DISPLAY = {
  [MTW_SUBTOPICS.WORK]: 'การงาน',
  [MTW_SUBTOPICS.WORK_1ST]: 'การงาน',
  [MTW_SUBTOPICS.LOVE]: 'ความรัก',
  [MTW_SUBTOPICS.MONEY]: 'การเงิน',
  [MTW_SUBTOPICS.FANS]: 'ติ่ง',
  [MTW_SUBTOPICS.INVESTMENT]: 'การลงทุน',
  [MTW_SUBTOPICS.STUDY]: 'การเรียน',
  [MTW_SUBTOPICS.LUCK]: 'โชคลาภ',
};

export enum MTW_DEVICE_TYPES {
  PHONE = 'PHONE',
  DESKTOP = 'DESKTOP',
  ALL = 'ALL',
}

const MTW_DEVICE_DISPLAY = {
  [MTW_DEVICE_TYPES.PHONE]: 'มือถือ',
  [MTW_DEVICE_TYPES.DESKTOP]: 'คอมพิวเตอร์',
  [MTW_DEVICE_TYPES.ALL]: 'ทั้งหมด',
};

interface WallpaperTemplate {
  display: string;
  availableDeviceType: MTW_DEVICE_TYPES[];
  selectTopicLabel: string;
  availableTopics: {
    value: string;
    display: string;
    descriptions?: string;
    forceSelection?: MTW_SUBTOPICS;
  }[];
  requestTypeLabel: string;
  requestTypes: MTW_SUBTOPICS[];
}

const MTW_TEMPLATE: { [key in MTW_REQUEST_TYPES]: WallpaperTemplate } = {
  [MTW_REQUEST_TYPES.FIRST_COLLECTION]: {
    display: '1st Collection',
    availableDeviceType: [MTW_DEVICE_TYPES.PHONE],
    selectTopicLabel: 'ตัวเลือก',
    availableTopics: [
      {
        value: '1st Collection',
        display: '1st Collection',
      },
    ],
    requestTypeLabel: 'เรื่องที่ต้องการเสริม',
    requestTypes: [
      MTW_SUBTOPICS.LOVE,
      MTW_SUBTOPICS.WORK_1ST,
      MTW_SUBTOPICS.MONEY,
      MTW_SUBTOPICS.FANS,
    ],
  },
  [MTW_REQUEST_TYPES.STAR_TAROT]: {
    display: 'Star Tarot',
    availableDeviceType: [MTW_DEVICE_TYPES.PHONE],
    selectTopicLabel: 'เรื่องที่ต้องการเสริม',
    availableTopics: [
      {
        value: 'SUN/MERCURY 14',
        display: 'SUN/MERCURY 14',
        // markdown text
        descriptions: `เซ็ตวิชาการ เติมวิตามินดวงให้สมองเสริมปัญญา คิด วิเคราะห์ แยกแยะ ไม่เคยพลาด  
        **เหมาะกับ: คนที่กำลังอ่านหนังสือสอบ คนที่กำลังทำวิจัย งานเขียนและงานเชิงวิชาการ สำหรับนักเรียนนักศึกษาที่อยากเสริมสติและความรอบคอบในการทำข้อสอบข้อเขียน**`,
      },
      {
        value: 'SUN/JUPITER 15',
        display: 'SUN/JUPITER 15',
        descriptions: `เซ็ตเข้าหาผู้ใหญ่ ถูกสนับสนุนและให้โอกาส มีโชคลาภจากผู้ชาย มีโชคลาภเจริญรุ่งเรือง  
        **เหมาะกับ: สายราชการ คนที่ต้องเข้าหาและต้องติดต่อกับผู้ใหญ่ (โดยเฉพาะผู้ใหญ่เพศชาย) ดีกับคนอยากสอบเข้ามหาวิทยาลัยที่มีชื่อเสียงของประเทศ**`,
      },
      {
        value: 'SUN/VENUS 16',
        display: 'SUN/VENUS 16',
        descriptions: `เซ็ตดาวค้างฟ้า เด่นและปังเพราะแสงมันหิวเรา  
        **เหมาะกับ: คนที่อยากพัฒนาตนเองในเรื่องบุคลิกภาพ ภาพลักษณ์ภายนอก อาชีพที่สัมพันธ์กับความสวยความงาม Influencer และดารา**`,
      },
      {
        value: 'MOON/MERCURY 24',
        display: 'MOON/MERCURY 24',
        descriptions: `เซ็ตเป่าหูให้คนรัก เจรจาปิดดีลเสน่หาแชทหนักซ้ายไม่หนักขวา  
        **เหมาะกับ: เซลล์ คนที่ทำงานเกี่ยวกับการเจรจาหรือต้องพบปะกับผู้คน อยากให้ลูกค้าพูดจาน่ารัก คล้อยตามทุกอย่างที่เราพูดเหมือนมีเวทมนต์ คนที่มีคนคุยและอยากให้บทสนทนาไหลลื่น  วิดิโอคอลกันทุกคืนไม่ได้หลับไม่ได้นอน**`,
      },
      {
        value: 'MARS/JUPITER 35',
        display: 'MARS/JUPITER 35',
        descriptions: `งานไฝว้พลิกแพลงแตลงตลบ แก้ปัญหาเฉพาะหน้าเก่ง มีความคิดสร้างสรรคจนคู่แข่งตามไม่ทัน มีลาภจากการกระทำและความเหนื่อย  
        **เหมาะกับ: สายไฝว้! ช่วงที่ชีวิตมีคู่แข่ง อยากได้ไอเดียประหลาดๆ ที่ใช้งานได้ ใครก็ตามคุณไม่ทัน เป็นผู้นำเทรนด์แห่งวงการ ได้ทั้งคิด พูดและทำ จบครบในคนเดียว**`,
      },
      {
        value: 'MARS/VENUS 36',
        display: 'MARS/VENUS 36',
        descriptions: `เซ็ตคนกี Busy Don't Distrub My กี  
        **เหมาะกับ: คนที่อยากดึงดูดคู่ค้า คู่สัญญาให้ติดหนึบ ติดกับเราด้วยเสน่หา คนที่มีคนคุยอยากให้แชตเด้งไม่หยุด นัดไปกินรามยอน ดูเน็ตฟลิกซ์ ส่งเสริมแชท 18+ คนมีแฟนแล้วได้แซ่บกับคุณแฟนทุกวัน**`,
      },
      {
        value: 'MERCURY/JUPITER 45',
        display: 'MERCURY/JUPITER 45',
        descriptions: `เซ็ตผู้สืบทอดสำนักบู๊ลิ้ม เก่งทั้งบุ๋นและบู๊ไม่มีใครเทียบในยุทธภพ! ผู้ใหญ่ คนมีความรู้มีความสามารถรายล้อม อยากจะเข้าหาและพูดคุย  
        **เหมาะกับ: คนที่อยากจะเรียนต่อ ถูกเอ็นดูโดยอาจารย์และผู้หลักผู้ใหญ่ ช่วยให้เป็นคนอารมณ์แจ่มใส ไม่หงุดหงิดง่าย สมองไวไหลลื่น มีวาทะศิลป์ มีลูกล่อลูกชน สำหรับใครที่ตกหลุมรักคนที่สมอง ต้องเซ็ตนี้!**`,
      },
      {
        value: 'VENUS/JUPITER 65',
        display: 'VENUS/JUPITER 65',
        descriptions: `ผู้มีโชคในการเงินและความรัก ดึงดูดทั้งการเงินและรักดีๆ ให้การสนับสนุน สายเปย์จะเข้ามา  
        **เหมาะกับ: อยากมีเสน่ห์มากขึ้น ขยายเสน่ห์จากระดับหนึ่งเป็นระดับจักรวาล มีผู้ใหญ่เข้ามาสนับสนุน เจอกับสายเปย์ที่พร้อมจะทุ่มไม่อั้น คนที่ชอบเสี่ยงโชคและทำงานในวงการการเงิน กองทุน ขายประกันสะสมทรัพย์ คนที่อยากมีเงินและยกระดับฐานะทางสังคม**`,
      },
      {
        value: 'SATURN/RAHU 87',
        display: 'SATURN/RAHU 87',
        descriptions: `เจ้าแห่งศาสตร์มืด งานสีเทาต้องเราเท่านั้น เสริมความลุ่มหลง ความรวย ความปัง เสี่ยงโชค หุ้นหวยรวยจังเลย  
        **เหมาะกับ: คนทำงานสีเทา ของมึนเมา เซ็กส์เวิร์คเกอร์ คนที่ต้องการให้คนมาลุ่มหลงเหมือนโดนของจนโงหัวไม่ขึ้น อยากเป็นผู้มีอิทธิพล หาคอนเน็กชันสายเทาถึงดำมืด**`,
      },
    ],
    requestTypeLabel: 'สิ่งที่อยากเน้นเป็นพิเศษ',
    requestTypes: [MTW_SUBTOPICS.LOVE, MTW_SUBTOPICS.WORK, MTW_SUBTOPICS.MONEY],
  },
  [MTW_REQUEST_TYPES.DEVAS_TAROT]: {
    display: 'Devas Tarot',
    availableDeviceType: [MTW_DEVICE_TYPES.PHONE],
    selectTopicLabel: 'องค์เทพที่ต้องการเลือก',
    availableTopics: [
      {
        value: 'พระพิฆเนศ',
        display: 'พระพิฆเนศ',
        descriptions: `การงาน: เสริมในเรื่องศิลปะ ความครีเอทีฟ อินฟลู การเรียน งานอินทีเรีย การแสดง การเรียน สอบได้คะแนนดี  
        การเงิน: เน้นเรื่องโชคลาภ ธุรกิจออนไลน์ ธุรกิจทั่วไป ร้านค้าต่างๆ  
        ทั่วไป: เสริมในเรื่องของความสำเร็จ การมีชัยชนะ ฝ่าฟันอุปสรรคไปได้`,
      },
      {
        value: 'พระแม่ลักษมี',
        display: 'พระแม่ลักษมี',
        descriptions: `การงาน: เสริมงานธนาคาร งานที่เกี่ยวกับการเงิน บัญชี งานที่เน้นการทำยอด เซลล์ ธุรกิจของสวยงาม อาหาร ขนม  
        การเงิน: ช่วยในเรื่องของโชคลาภ ได้เงินเข้ามาเยอะขึ้น  
        ความรัก: ช่วยดึงดูดให้เจอคนที่ดี ให้รักมั่นคง เสริมสเน่ห์ให้มีคนเมตตาเอ็นดู อยากเข้าหา`,
      },
      {
        value: 'พระศิวะ',
        display: 'พระศิวะ',
        descriptions: `การงาน: ช่วยเสริมในเรื่องของการเรียน การทำสมาธิ เน้นการขจัดอุปสรรค งานการเกษตร หมอดู Sixsense สาย Spiritual โปรแกรมเมอร์  
        ทั่วไป: เสริมให้มีความสำเร็จเหนือดวง สามารถเอาชนะความลำบาก ขจัดอุปสรรคออกไปให้หมด เน้นการสู้ค ดี(ในกรณีที่เป็นฝ่ายถูก)`,
      },
      {
        value: 'พระตรีมูรติ',
        display: 'พระตรีมูรติ',
        descriptions: `การงาน: เสริมในงานราชการ คอนเน็คชั่นที่ดี ได้โอกาสใหม่ๆ ดีลใหญ่ เหมาะสำหรับเซลล์ เออี ในการไปคุยงาน  Business development  
        ความรัก: เสริมให้ได้เจอคู่บุญบารมี สามารถซัพพอร์ตช่วยเหลือเราได้`,
      },
      {
        value: 'พระราหู',
        display: 'พระราหู',
        descriptions: `การงาน: เสริมในเรื่องของการทำงานสีเทา งานเบื้องหลัง โปรดักส์ชั่นเฮ้าส์ Sex worker หมอดู  
        การเงิน: เสริมในการเทรดต่างๆ คริปโต ช่วยโชคลาภ ก่ีเสี่ยงโชค การพนัน ธุรกิจสีเทา`,
      },
    ],
    requestTypeLabel: 'สิ่งที่อยากเน้นเป็นพิเศษ',
    requestTypes: [MTW_SUBTOPICS.LOVE, MTW_SUBTOPICS.WORK, MTW_SUBTOPICS.MONEY],
  },
  [MTW_REQUEST_TYPES.ARCANA_TAROT]: {
    display: 'Arcana Tarot',
    availableDeviceType: [MTW_DEVICE_TYPES.PHONE],
    selectTopicLabel: 'เรื่องที่ต้องการเสริม',
    availableTopics: [
      {
        value: 'Work',
        display: 'Work',
        descriptions: `งานปังด้วยการตีความจากธาตุทั้ง 4 ดิน น้ำ ลม ไฟ และใช้องค์ประกอบไพ่ The Magician ตัวแทนของผู้มีความสามารถ  
        **เหมาะสำหรับ: คนที่ต้องการความก้าวหน้า ความมั่นคง เป็นที่ยอมรับ มีชื่อเสียงที่ดีในเรื่องการงาน**`,
        forceSelection: MTW_SUBTOPICS.WORK,
      },
      {
        value: 'Love',
        display: 'Love',
        descriptions: `เสริมเรื่องความรักดั่งเทพนิยายด้วยพลังองค์ประกอบของไพ่ Lover ทำให้พัฒนาความสัมพันธ์ได้มากขึ้น  
        **เหมาะสำหรับ: คนที่ต้องการมีความรัก อยากให้ดวงเรื่องรักเปิด หากใครที่มีคนรักอยู่แล้วก็ช่วยให้ความรักผลิบาน**`,
        forceSelection: MTW_SUBTOPICS.LOVE,
      },
      {
        value: 'New Job',
        display: 'New Job',
        descriptions: `เสริมเรื่องของการหางานใหม่ การขายงาน ร่วมงานกับพาร์ทเนอร์ต่างๆ โดยใช้ไพ่ Temperance ไพ่แห่งการโยกย้าย หาความสมดุล งานจะมาแนวไหน เราก็เอาอยู่  
        **เหมาะสำหรับ: คนที่กำลังหางานใหม่ หรือคนที่ต้องการลูกค้า การจับมือร่วมกับกับองค์กร พาร์ทเนอร์ต่างๆ ให้ได้งานหรือข้อตกลงที่ดีที่สุดเป็นที่พอใจ**`,
        forceSelection: MTW_SUBTOPICS.WORK,
      },
      {
        value: 'Money',
        display: 'Money',
        descriptions: `เสริมเรื่องของการเงินโดยการใช้ไพ่ The Empress และ Wheel of fortune เพื่อเป็นตัวแทนแห่งความมั่งคั่งและการขยับขยายทั้งในเรื่องการเงินและธุรกิจ  
        **เหมาะสำหรับ: คนที่ต้องการเพิ่มความมั่งคั่ง รายรับ ขยับขยายแหล่งการเงินในธุรกิจ**`,
        forceSelection: MTW_SUBTOPICS.MONEY,
      },
      {
        value: 'Lucky',
        display: 'Lucky',
        descriptions: `เสริมในเรื่องของโชคและการเงินด้วยไพ่ Ten of Pentacles ไพ่แห่งความอุดมสมบูรณ์ด้านทรัพย์สิน โชคลาภด้านการเงินลอยมาจากฟ้า  
        **เหมาะสำหรับ: คนที่ต้องการเสริมเรื่องโชค อะไรที่ได้มาอย่างฟลุ๊กๆ หรือช่วงที่ต้องการสิ่งของที่ต้องใช้การสุ่ม**`,
        forceSelection: MTW_SUBTOPICS.LUCK,
      },
      {
        value: 'Study',
        display: 'Study',
        descriptions: `เสริมในเรื่องการโฟกัสและมีสมาธิ อ่านอะไรก็จำได้ดี มีความรู้และนำสิ่งนั้นออกมาใช้ได้ดี ด้วยองค์ประกอบของไพ่ The Hierophant  
        **เหมาะสำหรับ: คนที่ต้องการเสริมความรู้ เสริมสมาธิในการสอบหรือการอ่านหนังสือ ช่วงเวลาที่ต้องการโฟกัสกับบางสิ่งบางอย่าง**`,
        forceSelection: MTW_SUBTOPICS.STUDY,
      },
    ],
    requestTypeLabel: 'สิ่งที่อยากเน้นเป็นพิเศษ',
    requestTypes: [
      MTW_SUBTOPICS.LOVE,
      MTW_SUBTOPICS.WORK,
      MTW_SUBTOPICS.MONEY,
      MTW_SUBTOPICS.INVESTMENT,
      MTW_SUBTOPICS.STUDY,
      MTW_SUBTOPICS.LUCK,
    ],
  },
  [MTW_REQUEST_TYPES.ARCANA_TAROT_DESKTOP]: {
    display: 'Arcana Tarot (Desktop)',
    availableDeviceType: [MTW_DEVICE_TYPES.DESKTOP],
    selectTopicLabel: 'เรื่องที่ต้องการเสริม',
    availableTopics: [
      {
        value: 'Work',
        display: 'Work',
        descriptions: `งานปังด้วยการตีความจากธาตุทั้ง 4 ดิน น้ำ ลม ไฟ และใช้องค์ประกอบไพ่ The Magician ตัวแทนของผู้มีความสามารถ  
        **เหมาะสำหรับ: คนที่ต้องการความก้าวหน้า ความมั่นคง เป็นที่ยอมรับ มีชื่อเสียงที่ดีในเรื่องการงาน**`,
        forceSelection: MTW_SUBTOPICS.WORK,
      },
      {
        value: 'Love',
        display: 'Love',
        descriptions: `เสริมเรื่องความรักดั่งเทพนิยายด้วยพลังองค์ประกอบของไพ่ Lover ทำให้พัฒนาความสัมพันธ์ได้มากขึ้น  
        **เหมาะสำหรับ: คนที่ต้องการมีความรัก อยากให้ดวงเรื่องรักเปิด หากใครที่มีคนรักอยู่แล้วก็ช่วยให้ความรักผลิบาน**`,
        forceSelection: MTW_SUBTOPICS.LOVE,
      },
      {
        value: 'New Job',
        display: 'New Job',
        descriptions: `เสริมเรื่องของการหางานใหม่ การขายงาน ร่วมงานกับพาร์ทเนอร์ต่างๆ โดยใช้ไพ่ Temperance ไพ่แห่งการโยกย้าย หาความสมดุล งานจะมาแนวไหน เราก็เอาอยู่  
        **เหมาะสำหรับ: คนที่กำลังหางานใหม่ หรือคนที่ต้องการลูกค้า การจับมือร่วมกับกับองค์กร พาร์ทเนอร์ต่างๆ ให้ได้งานหรือข้อตกลงที่ดีที่สุดเป็นที่พอใจ**`,
        forceSelection: MTW_SUBTOPICS.WORK,
      },
      {
        value: 'Money',
        display: 'Money',
        descriptions: `เสริมเรื่องของการเงินโดยการใช้ไพ่ The Empress และ Wheel of fortune เพื่อเป็นตัวแทนแห่งความมั่งคั่งและการขยับขยายทั้งในเรื่องการเงินและธุรกิจ  
        **เหมาะสำหรับ: คนที่ต้องการเพิ่มความมั่งคั่ง รายรับ ขยับขยายแหล่งการเงินในธุรกิจ**`,
        forceSelection: MTW_SUBTOPICS.MONEY,
      },
      {
        value: 'Lucky',
        display: 'Lucky',
        descriptions: `เสริมในเรื่องของโชคและการเงินด้วยไพ่ Ten of Pentacles ไพ่แห่งความอุดมสมบูรณ์ด้านทรัพย์สิน โชคลาภด้านการเงินลอยมาจากฟ้า  
        **เหมาะสำหรับ: คนที่ต้องการเสริมเรื่องโชค อะไรที่ได้มาอย่างฟลุ๊กๆ หรือช่วงที่ต้องการสิ่งของที่ต้องใช้การสุ่ม**`,
        forceSelection: MTW_SUBTOPICS.LUCK,
      },
      {
        value: 'Study',
        display: 'Study',
        descriptions: `เสริมในเรื่องการโฟกัสและมีสมาธิ อ่านอะไรก็จำได้ดี มีความรู้และนำสิ่งนั้นออกมาใช้ได้ดี ด้วยองค์ประกอบของไพ่ The Hierophant  
        **เหมาะสำหรับ: คนที่ต้องการเสริมความรู้ เสริมสมาธิในการสอบหรือการอ่านหนังสือ ช่วงเวลาที่ต้องการโฟกัสกับบางสิ่งบางอย่าง**`,
        forceSelection: MTW_SUBTOPICS.STUDY,
      },
    ],
    requestTypeLabel: 'สิ่งที่อยากเน้นเป็นพิเศษ',
    requestTypes: [
      MTW_SUBTOPICS.LOVE,
      MTW_SUBTOPICS.WORK,
      MTW_SUBTOPICS.MONEY,
      MTW_SUBTOPICS.INVESTMENT,
      MTW_SUBTOPICS.STUDY,
      MTW_SUBTOPICS.LUCK,
    ],
  },
  [MTW_REQUEST_TYPES.LUNA_LAPIN]: {
    display: 'Luna Lapin',
    availableDeviceType: [MTW_DEVICE_TYPES.PHONE],
    selectTopicLabel: 'ตัวเลือก',
    availableTopics: [
      {
        value: 'Luna Lapin',
        display: 'Luna Lapin',
      },
    ],
    requestTypeLabel: 'เรื่องที่ต้องการเสริม',
    requestTypes: [
      MTW_SUBTOPICS.LOVE,
      MTW_SUBTOPICS.WORK,
      MTW_SUBTOPICS.MONEY,
      MTW_SUBTOPICS.INVESTMENT,
      MTW_SUBTOPICS.STUDY,
      MTW_SUBTOPICS.LUCK,
    ],
  },
  [MTW_REQUEST_TYPES.ASURA_RAHU]: {
    display: 'Asura Rahu',
    availableDeviceType: [MTW_DEVICE_TYPES.PHONE],
    selectTopicLabel: 'แบบของวอลเปเปอร์',
    availableTopics: [
      {
        value: 'Goodbye Rahu',
        display: 'Goodbye Rahu',
        descriptions: `ส่งท่านออกจากราศีเดิมสู่ราศีใหม่ ให้เรื่องร้ายออกจากเราไป เหลือแต่ความปังและความดัง!  
        ซอฟต์เรื่องร้ายให้เบาบาง ปัดรังควาญมารผจญ เสริมศิริมงคลให้กับชะตาของรา ให้มีลาภ ยศ สรรญเสริมและความสุข เงินทองไหลมาเทมา`,
      },
      {
        value: 'Luna Rahu',
        display: 'Luna Rahu',
        descriptions: `ปังรับราหูย้าย ดีไซน์แบบใหม่แบบสับ! ออกแบบให้พระราหูและพระจันทร์อยู่ใน Wallpaper ดีไซน์มาเพื่อเน้นด้านการเงินและเสน่ห์ ให้มีโชคลาภและมีกระแสเงินสด ได้รับเมตตามหานิยม หาเงินเองก็ปัง แถมมีคนมาเปย์`,
      },
    ],
    requestTypeLabel: 'สิ่งที่อยากเน้นเป็นพิเศษ',
    requestTypes: [
      MTW_SUBTOPICS.LOVE,
      MTW_SUBTOPICS.WORK,
      MTW_SUBTOPICS.MONEY,
      MTW_SUBTOPICS.INVESTMENT,
      MTW_SUBTOPICS.STUDY,
      MTW_SUBTOPICS.LUCK,
    ],
  },
  [MTW_REQUEST_TYPES.LUCK_CARD]: {
    display: 'การ์ดเสริมดวง',
    availableDeviceType: [MTW_DEVICE_TYPES.PHONE],
    selectTopicLabel: 'รูปแบบ',
    availableTopics: [
      {
        value: 'การ์ดเสริมดวง',
        display: 'การ์ดเสริมดวง',
      },
    ],
    requestTypeLabel: 'สิ่งที่อยากเน้นเป็นพิเศษ',
    requestTypes: [MTW_SUBTOPICS.LOVE, MTW_SUBTOPICS.WORK, MTW_SUBTOPICS.MONEY],
  },
};

// This is sub-subtopic
const MTW_REQUEST_CHOICES = {
  [MTW_SUBTOPICS.LOVE]: [
    { value: 'LOVE_1', display: 'คนโสด - เพิ่มเสน่ห์ให้คนสนใจ' },
    {
      value: 'LOVE_2',
      display: 'คนโสด - ดึงดูดชาวต่างชาติ',
    },
    {
      value: 'LOVE_3',
      display: 'คนโสด - ตามหารักแท้',
    },
    {
      value: 'LOVE_4',
      display: 'คนมีคู่ - แฟนคลั่งรัก',
    },
    {
      value: 'LOVE_5',
      display: 'คนมีคู่ - แฟนเปย์ จ่ายเงินให้',
    },
    {
      value: 'LOVE_6',
      display: 'คนมีคู่ - ส่งเสริมซึ่งกันและกัน',
    },
    {
      value: 'LOVE_7',
      display: 'คนคุย - ได้คุยกันมากขึ้น',
    },
    {
      value: 'LOVE_8',
      display: 'คนคุย - ความสัมพันธ์พัฒนามากขึ้น',
    },
    {
      value: 'LOVE_9',
      display: 'คนคุย - อีกฝ่ายคลั่งรัก',
    },
  ],
  [MTW_SUBTOPICS.WORK_1ST]: [
    {
      value: 'WORK_1ST_1',
      display: 'งานราชการ',
    },
    {
      value: 'WORK_1ST_2',
      display:
        'การสื่อสาร การตลาด ใช้คำพูด ขายออนไลน์ งานสาย IT งานเอกสาร สายวิเคราะห์ ใช้สัมภาษณ์งาน',
    },
    {
      value: 'WORK_1ST_3',
      display: 'ด้านอาหารขนม HR ศิลปะ ดารา อินฟลู งานสายการเงิน ลูกเรือ แม่บ้าน',
    },
    {
      value: 'WORK_1ST_4',
      display: 'ครู ให้ความรู้ สายสุขภาพ การแพทย์ อสังหา ต้องการเลื่อนตำแหน่ง',
    },
    {
      value: 'WORK_1ST_5',
      display: 'เบื้องหลัง Production House ภาพยนตร์ หมอดู งานสีเทา งานกลางคืน ขายลอตเตอรี่',
    },
    {
      value: 'WORK_1ST_6',
      display: 'งานที่ต้องแข่งขัน เทรนเนอร์ งานที่ใช้กำลัง ขนส่ง อุปกรณ์ก่อสร้าง สายวิศวะ',
    },
  ],
  [MTW_SUBTOPICS.MONEY]: [
    {
      value: 'MONEY_1',
      display: 'มีเงินเข้ามาไม่ขาดมือ',
    },
    {
      value: 'MONEY_2',
      display: 'มีช่องทางการหาเงินใหม่ๆ',
    },
    {
      value: 'MONEY_3',
      display: 'มีคนมาอุปถัมภ์เรื่องการเงิน',
    },
    {
      value: 'MONEY_4',
      display: 'เก็บเงินอยู่',
    },
  ],
  [MTW_SUBTOPICS.FANS]: [
    {
      value: 'FANS_1',
      display: 'เสริมดวงติ่ง',
    },
  ],
  [MTW_SUBTOPICS.WORK]: [
    {
      value: 'WORK_1',
      display: 'หางาน - ได้งานใหม่ที่ถูกใจ',
    },
    {
      value: 'WORK_2',
      display: 'หางาน - งานอะไรก็ได้ที่เข้ามาไวๆ',
    },
    {
      value: 'WORK_3',
      display: 'มีงาน - งานราบรื่น ไม่มีอุปสรรค',
    },
    {
      value: 'WORK_4',
      display: 'มีงาน - งานประสบความสำเร็จ',
    },
    {
      value: 'WORK_5',
      display: 'มีงาน - เจ้านายเอ็นดู คนช่วยเหลืออุปถัมภ์',
    },
    {
      value: 'WORK_6',
      display: 'มีงาน - สามารถปิดดีล เจรจาได้ลงตัว',
    },
    {
      value: 'WORK_7',
      display: 'มีงาน - ได้เลื่อนขั้น เลื่อนตำแหน่ง',
    },
    {
      value: 'WORK_8',
      display: 'มีงาน - มีเสน่ห์ คนอยากติดตาม',
    },
    {
      value: 'WORK_9',
      display: 'ฟรีแลนซ์ - มีงานเข้ามาเยอะๆ',
    },
    {
      value: 'WORK_10',
      display: 'ฟรีแลนซ์ - ฝีมือดีขึ้น ไอเดียบรรเจิด',
    },
    {
      value: 'WORK_11',
      display: 'ฟรีแลนซ์ - ปิดดีล เจรจาลงตัว',
    },
  ],
  [MTW_SUBTOPICS.INVESTMENT]: [
    {
      value: 'INVESTMENT_1',
      display: 'เข้าออกถูกจังหวะ ตัดสินใจได้ดี',
    },
    {
      value: 'INVESTMENT_2',
      display: 'ได้กำไรในระยะยาว',
    },
  ],
  [MTW_SUBTOPICS.STUDY]: [
    {
      value: 'STUDY_1',
      display: 'ได้คะแนนดี',
    },
    {
      value: 'STUDY_2',
      display: 'มีสมาธิ ตั้งใจเรียน',
    },
    {
      value: 'STUDY_3',
      display: 'สอบชิงทุน สอบเพื่อไปต่างประเทศ',
    },
    {
      value: 'STUDY_4',
      display: 'สอบเข้ามหาลัยในประเทศ	',
    },
    {
      value: 'STUDY_5',
      display: 'ทำโปรเจกต์จบ',
    },
    {
      value: 'STUDY_6',
      display: 'อาจารย์เอ็นดู คอยช่วยเหลือ',
    },
  ],
  [MTW_SUBTOPICS.LUCK]: [
    {
      value: 'LUCK_1',
      display: 'มีโชคเรื่องการเงิน',
    },
    {
      value: 'LUCK_2',
      display: 'การเสี่ยงโชค',
    },
    {
      value: 'LUCK_3',
      display: 'สุ่มของที่ต้องใช้โชค',
    },
  ],
};

export const mtwAvailableGender = [
  { display: 'หญิง', value: WALLPAPER_GENDER.FEMALE },
  { display: 'ชาย', value: WALLPAPER_GENDER.MALE },
  { display: 'LGBTQ+ แนวแมน', value: WALLPAPER_GENDER.Q_MALE },
  { display: 'LGBTQ+ แนวหวาน', value: WALLPAPER_GENDER.Q_FEMALE },
];

export enum MTW_MEDICAL_PROBLEMS {
  NONE = 'NONE',
  MED_1 = 'MED_1',
  MED_2 = 'MED_2',
  MED_3 = 'MED_3',
  MED_4 = 'MED_4',
  MED_5 = 'MED_5',
}

export const mtwAvailableMedicalProblems = [
  { display: 'ไม่มี', value: MTW_MEDICAL_PROBLEMS.NONE },
  { display: 'ต่อมน้ำเหลือง ฮอร์โมน อ้วน', value: MTW_MEDICAL_PROBLEMS.MED_1 },
  { display: 'มะเร็ง ก้อนเนื้อ', value: MTW_MEDICAL_PROBLEMS.MED_2 },
  { display: 'กระเพาะ', value: MTW_MEDICAL_PROBLEMS.MED_3 },
  { display: 'ไมเกรน ปวดหัวบ่อย', value: MTW_MEDICAL_PROBLEMS.MED_4 },
  { display: 'ซีมเศร้า ไบโพลาร์', value: MTW_MEDICAL_PROBLEMS.MED_5 },
];

export const getMTWRequestTypeChoices = (requestType: MTW_SUBTOPICS) => {
  const choices = MTW_REQUEST_CHOICES[requestType] ?? [];
  return choices;
};
export const getMTWGenderDisplay = getDisplayValue(mtwAvailableGender);
export const getMTWTopicDisplay = (topic: string) => {
  const template = getMTWTemplate(topic as MTW_REQUEST_TYPES);
  return template?.display ?? 'ข้อมูลไม่ถูกต้อง';
};
export const getMTWSubTopicDisplay = (template: WallpaperTemplate, subTopic: string) => {
  // TODO: Display error if template does not have this subtopic
  return MTW_SUBTOPIC_DISPLAY[subTopic as MTW_SUBTOPICS] ?? 'ข้อมูลไม่ถูกต้อง';
};
export const getMTWSubSubTopicDisplay = (
  template: WallpaperTemplate,
  subTopic: string,
  subSubTopic: string
) => {
  // TODO: Display error if template does not have this subtopic
  return getDisplayValue(MTW_REQUEST_CHOICES[subTopic as MTW_SUBTOPICS])(subSubTopic);
};
const mtwMedicalProblemDisplay = getDisplayValue(mtwAvailableMedicalProblems);
export const getMTWMedicalProblemsDisplay = (medicalProblems?: string[]) => {
  if (!medicalProblems || medicalProblems.length === 0) return '-';
  return medicalProblems.map((problem) => mtwMedicalProblemDisplay(problem)).join(', ');
};

export const getMTWTemplate = (wallpaperRequestType: MTW_REQUEST_TYPES): WallpaperTemplate => {
  return MTW_TEMPLATE[wallpaperRequestType];
};

export const getAvailableMTWOptions = (template: WallpaperTemplate) => {
  const options = template.availableTopics ?? [];
  return options;
};

export const getMTWOptionDisplay = (template: WallpaperTemplate, option: string) => {
  return getDisplayValue(template?.availableTopics)(option);
};

export const getAvailableMTWSubTopics = (template: WallpaperTemplate) => {
  const requestTypes = template?.requestTypes ?? [];
  return requestTypes.map((requestType) => ({
    display: MTW_SUBTOPIC_DISPLAY[requestType],
    value: requestType,
  }));
};

export const getMTWDeviceDisplay = (device?: string) => {
  if (!device) return MTW_DEVICE_DISPLAY[MTW_DEVICE_TYPES.PHONE];
  return MTW_DEVICE_DISPLAY[device as MTW_DEVICE_TYPES];
};

export const getAvailableMTWDeviceType = (template: WallpaperTemplate) => {
  const requestTypes = template.availableDeviceType ?? [];
  return requestTypes.map((requestType) => ({
    display: MTW_DEVICE_DISPLAY[requestType],
    value: requestType,
  }));
};

export const getAvailableMTWSubSubTopics = (requestType?: MTW_SUBTOPICS) => {
  if (!requestType) return [];
  const choices = MTW_REQUEST_CHOICES[requestType] ?? [];
  return choices;
};

export const getMTWIncludeServiceDisplay = (includeService?: boolean) => {
  if (includeService) return 'ใส่รูปพื้นดวง + ฤกษ์การใช้วอลเปเปอร์';
  return '-';
};

export const getMTWFansBirthDayDisplay = (fansBirthDay?: {
  day: number;
  month: number;
  year: number;
}) => {
  if (!fansBirthDay) return '-';
  return fansBirthDay.day + ' ' + getMonthDisplay(fansBirthDay.month) + ' ' + fansBirthDay.year;
};
