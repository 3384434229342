import { CHANT_MODE } from 'Domain/Sattha/Chant';

export enum STORE_LOCATIONS {
  AI_KHAI = 'AI-KHAI',
  THEP_THAN_JAI = 'THEP-THAN-JAI',
  SOTHON = 'SOTHON',
  HK_CHE_KUNG = 'HK-CHE-KUNG',
  HK_HUNG_HOM = 'HK-HUNG-HOM',
  DEMO = 'DEMO-LOCATION',
  LCN = 'LCN',
  HK_WONG_TAI_SIN = 'HK-WONG-TAI-SIN',
  LLM = 'LLM',
  LPP = 'LPP',
  LSC = 'LSC',
  LSR = 'LSR',
  LBC = 'LBC',
  LCL = 'LCL',
  LTT = 'LTT',
  HK_LONG_SAN = 'HK-LONG-SAN',
  LSR04 = 'LSR04',
  LTM04 = 'LTM04',
  LPN = 'LPN',
  LNK = 'LNK',
  LSJ = 'LSJ',
  LTW = 'LTW',
  LCR = 'LCR',
  LPH = 'LPH',
  LTJ = 'LTJ',
  LMK = 'LMK',
  LHJ = 'LHJ',
  LYW = 'LYW',
  LHP = 'LHP',
  LTS = 'LTS',
  LTH = 'LTH',
  // Sattha OA
  SLM = 'SLM',
  // ตักบาตร 9 วัด,
  S9W = 'S9W',
  S9W_1 = 'S9W-1',
  S9W_2 = 'S9W-2',
  S9W_3 = 'S9W-3',
  LLH = 'LLH',
  PDT = 'PDT',
  PDP = 'PDP',
  RMS = 'RMS',
  'LYR-1' = 'LYR-1',
  'LYR-2' = 'LYR-2',
  'LYR-3' = 'LYR-3',
  'LYR-4' = 'LYR-4',
  'LYR-5' = 'LYR-5',
  'LYR-6' = 'LYR-6',
  'LYR-7' = 'LYR-7',
  'LYR-8' = 'LYR-8',
  PSV = 'PSV',
}

const S9Wdata = {
  performInstructions: {
    [CHANT_MODE.WISH]: `## คำแนะนำการตักบาตรจากบ้าน
**อุปกรณ์ที่ต้องใช้**
* ที่กรวดน้ำ (หากไม่มีสามารถใช้ภาชนะอื่นๆแทนได้) 
* บทคำอธิษฐานอุทิศส่วนบุญส่วนกุศลข้างบน (กดบันทึกเก็บไว้อ่านได้เลย!)
    
**ก่อนถึงเวลาที่ร้านค้าจะดำเนินการ**
* ตั้งจิตอธิษฐานให้มั่น แล้วกล่าวบทคำอธิษฐานอุทิศส่วนบุญส่วนกุศล, ชื่อ-สกุลของและข้อมูลของผู้อธิษฐานให้ชัดเจน เป็นอันเสร็จพิธี
* หลังจากนั้นรอรับภาพหลักฐานการดำเนินการจากเราได้เลย! (ใช้เวลา 1-3 วันในการดำเนินการ)
    
**หมายเหตุ**
* การตั้งจิตอธิษฐานและกล่าวบทสวดสามารถเริ่มทำได้ตั้งแต่ได้รับการยืนยันการจ่ายเงินสำเร็จ
* หากไม่มีเวลา สามารถทำได้ตามฤกษ์สะดวก`,
    [CHANT_MODE.VOW]: ``,
  },
  messageInputInstructions: {
    [CHANT_MODE.WISH]: ``,
    [CHANT_MODE.VOW]: ``,
  },
};

const LYRdata = {
  performInstructions: {
    [CHANT_MODE.WISH]: `## คำแนะนำการใส่บาตรจากบ้าน

**อุปกรณ์ที่ต้องใช้**
* ที่กรวดน้ำ (หากไม่มีสามารถใช้ภาชนะอื่นๆแทนได้)
* บทคำอธิษฐานอุทิศส่วนบุญส่วนกุศลข้างบน (กดบันทึกเก็บไว้อ่านได้เลย!)

**ก่อนถึงเวลาที่ร้านค้าจะดำเนินการ**
* ตั้งจิตอธิษฐานให้มั่น แล้วกล่าวบทคำอธิษฐานอุทิศส่วนบุญส่วนกุศล, ชื่อ-สกุลของและข้อมูลของผู้อธิษฐานให้ชัดเจน เป็นอันเสร็จพิธี
* หลังจากนั้นรอรับภาพหลักฐานการดำเนินการจากเราได้เลย! (ใช้เวลา 1-3 วันในการดำเนินการ)

**หมายเหตุ**
* การตั้งจิตอธิษฐานและกล่าวบทสวดสามารถเริ่มทำได้ตั้งแต่ได้รับการยืนยันการจ่ายเงินสำเร็จ
* หากไม่มีเวลา สามารถทำได้ตามฤกษ์สะดวก`,
    [CHANT_MODE.VOW]: '',
  },
  messageInputInstructions: {
    [CHANT_MODE.WISH]: '',
    [CHANT_MODE.VOW]: '',
  },
};

const message = {
  [STORE_LOCATIONS.LPN]: {
    performInstructions: {
      [CHANT_MODE.WISH]: `## วิธีขอพรกับพระพิฆเนศจากบ้าน

**อุปกรณ์ที่ต้องใช้**
* ธูป (สามารถใช้กี่ดอกก็ได้ตามเลขมงคลที่ชื่นชอบ) หรือเทียน 
* บทสวดข้างบน (กดบันทึกเก็บไว้อ่านได้เลย!)

**ก่อนถึงเวลาที่ร้านค้าจะดำเนินการขอพร**
* จุดธูปหรือเทียนให้พร้อม
* ตั้งจิตอธิษฐานให้มั่นต่อรูปพระพิฆเนศที่เราเตรียมไว้ให้ แล้วกล่าวบทสวด, ชื่อสกุลของผู้ขอพรให้ชัดเจน 
* ปักธูปหรือเทียนลงกระถาง เป็นอันเสร็จพิธี`,
      [CHANT_MODE.VOW]: `## วิธีแก้บนกับพระพิฆเนศจากบ้าน

**อุปกรณ์ที่ต้องใช้**
* ธูป (สามารถใช้กี่ดอกก็ได้ตามเลขมงคลที่ชื่นชอบ) หรือเทียน 
* บทสวดข้างบน (กดบันทึกเก็บไว้อ่านได้เลย!)

**ก่อนถึงเวลาที่ร้านค้าจะดำเนินการแก้บน**
* จุดธูปหรือเทียนให้พร้อม
* ตั้งจิตอธิษฐานให้มั่นต่อรูปพระพิฆเนศที่เราเตรียมไว้ให้ แล้วกล่าวบทสวด, ชื่อสกุลของผู้แก้บนให้ชัดเจน 
* ปักธูปหรือเทียนลงกระถาง เป็นอันเสร็จพิธี`,
    },
    messageInputInstructions: {
      [CHANT_MODE.WISH]: `### เคล็ดลับการกรอกคำขอพรพระพิฆเนศให้สำเร็จ 

ระบุชื่อและนามสกุลให้ชัดเจน ให้ขอพรด้วยใจแน่วแน่ แนะนำว่าให้ขอทีละเรื่อง และบอกกล่าวให้ชัดเจนไม่คลุมเครือ เช่น ถ้าอยากขอให้รวย ก็บอก เจาะจงไปเลยว่าอยากมีรายได้ เดือนละเท่าไหร่ ขายของให้ได้เดือนละเท่าไหร่ ระบุให้ละเอียดและเจาะจง สิ่งที่สำคัญคือ คำขอพรหรือเรื่องที่ต้องการแก้บนจะต้องเป็นเรื่องที่ถูกต้องตามศีลธรรมเท่านั้น จึงจะมีโอกาสสมหวัง`,
      [CHANT_MODE.VOW]: `### เคล็ดลับการกรอกคำแก้บนพระพิฆเนศให้สำเร็จ 

ระบุชื่อและนามสกุลให้ชัดเจน ให้ขอพรด้วยใจแน่วแน่ แนะนำว่าให้ขอทีละเรื่อง และบอกกล่าวให้ชัดเจนไม่คลุมเครือ เช่น ถ้าอยากขอให้รวย ก็บอก เจาะจงไปเลยว่าอยากมีรายได้ เดือนละเท่าไหร่ ขายของให้ได้เดือนละเท่าไหร่ ระบุให้ละเอียดและเจาะจง สิ่งที่สำคัญคือ คำขอพรหรือเรื่องที่ต้องการแก้บนจะต้องเป็นเรื่องที่ถูกต้องตามศีลธรรมเท่านั้น จึงจะมีโอกาสสมหวัง`,
    },
  },

  [STORE_LOCATIONS.LNK]: {
    performInstructions: {
      [CHANT_MODE.WISH]: `## วิธีขอพรกับปู่อือลือจากบ้าน

**อุปกรณ์ที่ต้องใช้**
* ธูป 9 ดอก (หากไม่มีไม่เป็นไร)
* บทสวดข้างบน (กดบันทึกเก็บไว้อ่านได้เลย!)

**ก่อนถึงเวลาที่ร้านค้าจะดำเนินการขอพร/แก้บน**
* จุดธูปให้พร้อม
* ตั้งจิตอธิษฐานให้มั่นต่อรูปปู่อือลือที่เราเตรียมไว้ให้ แล้วกล่าวบทสวด, ชื่อ-สกุลของและข้อมูลของผู้ขอพร และคำขอพรให้ชัดเจน 
* ปักธูปหรือเทียนลงกระถาง เป็นอันเสร็จพิธี`,
      [CHANT_MODE.VOW]: `## วิธีแก้บนกับปู่อือลือจากบ้าน

**อุปกรณ์ที่ต้องใช้**
* ธูป 9 ดอก (หากไม่มีไม่เป็นไร)
* บทสวดข้างบน (กดบันทึกเก็บไว้อ่านได้เลย!)

**ก่อนถึงเวลาที่ร้านค้าจะดำเนินการขอพร/แก้บน**
* จุดธูปให้พร้อม
* ตั้งจิตอธิษฐานให้มั่นต่อรูปปู่อือลือที่เราเตรียมไว้ให้ แล้วกล่าวบทสวด, ชื่อ-สกุลของและข้อมูลของผู้แก้บน และคำแก้บนให้ชัดเจน 
* ปักธูปหรือเทียนลงกระถาง เป็นอันเสร็จพิธี`,
    },
    messageInputInstructions: {
      [CHANT_MODE.WISH]: `### เคล็ดลับการกรอกคำขอพร/แก้บนกับปู่อือลือให้สำเร็จ 

* ระบุชื่อ-นามสกุล, วันเกิดให้ชัดเจน ให้ขอพรด้วยใจแน่วแน่ แนะนำว่าให้ขอทีละเรื่อง และบอกกล่าวให้ชัดเจนไม่คลุมเครือ เช่น ถ้าอยากขอให้รวย ก็เจาะจงไปเลยว่าอยากมีรายได้เดือนละเท่าไหร่ หรือขายของให้ได้เดือนละเท่าไหร่ ระบุให้ละเอียดและเจาะจง 
* ไม่ควรใช้คำว่าบนบาน แต่ระบุว่าหากสำเร็จจะทำความดีอุทิศถวายแทน เช่น ถวายสังฆทาน, ใส่บาตร, ถือศีล เป็นต้น
* สิ่งที่สำคัญคือ คำขอพรหรือเรื่องที่ต้องการแก้บนจะต้องเป็นเรื่องที่ถูกต้องตามศีลธรรมเท่านั้น จึงจะมีโอกาสสมหวัง`,
      [CHANT_MODE.VOW]: `### เคล็ดลับการกรอกคำขอพร/แก้บนกับปู่อือลือให้สำเร็จ 

* ระบุชื่อ-นามสกุล, วันเกิดให้ชัดเจน ให้ขอพรด้วยใจแน่วแน่ แนะนำว่าให้ขอทีละเรื่อง และบอกกล่าวให้ชัดเจนไม่คลุมเครือ เช่น ถ้าอยากขอให้รวย ก็เจาะจงไปเลยว่าอยากมีรายได้เดือนละเท่าไหร่ หรือขายของให้ได้เดือนละเท่าไหร่ ระบุให้ละเอียดและเจาะจง 
* ไม่ควรใช้คำว่าบนบาน แต่ระบุว่าหากสำเร็จจะทำความดีอุทิศถวายแทน เช่น ถวายสังฆทาน, ใส่บาตร, ถือศีล เป็นต้น
* สิ่งที่สำคัญคือ คำขอพรหรือเรื่องที่ต้องการแก้บนจะต้องเป็นเรื่องที่ถูกต้องตามศีลธรรมเท่านั้น จึงจะมีโอกาสสมหวัง`,
    },
  },
  [STORE_LOCATIONS.LSJ]: {
    performInstructions: {
      [CHANT_MODE.FIX_BADLUCK]: `### วิธีสะเดาะเคราะห์กับซำปอฮุกโจ้วจากบ้าน

**อุปกรณ์ที่ต้องใช้**
* ธูป 3 ดอก (แนะนำว่าควรมี)
* บทสวดข้างบน (กดบันทึกเก็บไว้อ่านได้เลย!)
      
**ก่อนถึงเวลาที่ร้านค้าจะดำเนินการ**
* จุดธูปให้พร้อม
* ตั้งจิตอธิษฐานให้มั่นต่อรูปซำปอฮุกโจ้วที่เราเตรียมไว้ให้ แล้วกล่าวบทสวด, ชื่อ-สกุลของและข้อมูลของสะเดาะเคราะห์ให้ชัดเจน 
* ปักธูปหรือเทียนลงกระถาง เป็นอันเสร็จพิธี`,
    },
    messageInputInstructions: {
      [CHANT_MODE.FIX_BADLUCK]: `### เคล็ดลับในการสะเดาะเคราะห์กับซำปอฮุกโจ้วให้สำเร็จ

* ระบุชื่อ-นามสกุล, วันเกิดให้ชัดเจน ให้ตั้งจิตอธิษฐานรด้วยใจแน่วแน่ ขอให้อานุภาพบารมีของซำปอฮุกโจ้วช่วยคุ้มครองดวงชะตาให้ปราศจากภัยอันตรายทั้งปวง เรื่องใดที่ไม่ดีให้ให้ท่านชาวยปัดเป่าให้หมดสิ้น
* สิ่งที่สำคัญคือ การตั้งจิตให้แน่วแน่บริสุทธิ์ และเรื่องที่มุ่งหวังจะต้องถูกต้องตามศีลธรรมเท่านั้น จึงจะมีโอกาสสมหวัง`,
    },
  },

  [STORE_LOCATIONS.LTW]: {
    performInstructions: {
      [CHANT_MODE.WISH]: `## คำแนะนำการขอพร-แก้บนกับท้าวเวสสุวรรณจากบ้าน

**อุปกรณ์ที่ต้องใช้**
* ธูป 9 ดอกสำหรับขอพร หรือ 16 ดอกสำหรับแก้บน (แนะนำว่าควรมี)
* ที่กรวดน้ำ (หากไม่มี ไม่เป็นไร)
* บทสวดข้างบน (กดบันทึกเก็บไว้อ่านได้เลย!)

**ก่อนถึงเวลาที่ร้านค้าจะดำเนินการ**
* จุดธูปให้พร้อม
* ตั้งจิตอธิษฐานให้มั่นต่อรูปท้าวเวสสุวรรณที่เราเตรียมไว้ให้ แล้วกล่าวบทสวด, ชื่อ-สกุลของและข้อมูลของผู้ขอพร-แก้บนให้ชัดเจน 
* ปักธูปหรือเทียนลงกระถาง เป็นอันเสร็จพิธี
* หากมีเวลา ให้กรวดน้ำ เพื่อเป็นการอุทิศส่วนกุศลให้แก่ผู้ล่วงลับ
* หลังจากนั้นรอรับภาพหลักฐานการดำเนินการจากเราได้เลย! (ใช้เวลา 1-3 วันในการดำเนินการ)

**หมายเหตุ**
* การตั้งจิตอธิษฐานและกล่าวบทสวดสามารถเริ่มทำได้ตั้งแต่ได้รับการยืนยันการจ่ายเงินสำเร็จ
* หากไม่มีเวลา สามารถทำได้ตามฤกษ์สะดวก`,
      [CHANT_MODE.VOW]: `## คำแนะนำการขอพร-แก้บนกับท้าวเวสสุวรรณจากบ้าน

**อุปกรณ์ที่ต้องใช้**
* ธูป 9 ดอกสำหรับขอพร หรือ 16 ดอกสำหรับแก้บน (แนะนำว่าควรมี)
* ที่กรวดน้ำ (หากไม่มี ไม่เป็นไร)
* บทสวดข้างบน (กดบันทึกเก็บไว้อ่านได้เลย!)

**ก่อนถึงเวลาที่ร้านค้าจะดำเนินการ**
* จุดธูปให้พร้อม
* ตั้งจิตอธิษฐานให้มั่นต่อรูปท้าวเวสสุวรรณที่เราเตรียมไว้ให้ แล้วกล่าวบทสวด, ชื่อ-สกุลของและข้อมูลของผู้ขอพร-แก้บนให้ชัดเจน 
* ปักธูปหรือเทียนลงกระถาง เป็นอันเสร็จพิธี
* หากมีเวลา ให้กรวดน้ำ เพื่อเป็นการอุทิศส่วนกุศลให้แก่ผู้ล่วงลับ
* หลังจากนั้นรอรับภาพหลักฐานการดำเนินการจากเราได้เลย! (ใช้เวลา 1-3 วันในการดำเนินการ)

**หมายเหตุ**
* การตั้งจิตอธิษฐานและกล่าวบทสวดสามารถเริ่มทำได้ตั้งแต่ได้รับการยืนยันการจ่ายเงินสำเร็จ
* หากไม่มีเวลา สามารถทำได้ตามฤกษ์สะดวก`,
    },
    messageInputInstructions: {
      [CHANT_MODE.WISH]: `### เคล็ดลับการกรอกคำขอพร-แก้บนกับท้าวเวสสุวรรณ

* ระบุชื่อ-นามสกุล ให้ชัดเจน ให้ตั้งจิตอธิษฐานรด้วยใจแน่วแน่ แนะนำว่าให้ขอทีละเรื่อง และบอกกล่าวให้ชัดเจนไม่คลุมเครือ เช่น ถ้าอยากขอให้รวย ก็เจาะจงไปเลยว่าอยากมีรายได้เดือนละเท่าไหร่ หรือขายของให้ได้เดือนละเท่าไหร่ ระบุให้ละเอียดและเจาะจง 
* สิ่งที่สำคัญคือ การตั้งจิตให้แน่วแน่บริสุทธิ์ และเรื่องที่มุ่งหวังจะต้องถูกต้องตามศีลธรรมเท่านั้น จึงจะมีโอกาสสมหวัง`,
      [CHANT_MODE.VOW]: `### เคล็ดลับการกรอกคำขอพร-แก้บนกับท้าวเวสสุวรรณ

      * ระบุชื่อ-นามสกุล ให้ชัดเจน ให้ตั้งจิตอธิษฐานรด้วยใจแน่วแน่ แนะนำว่าให้ขอทีละเรื่อง และบอกกล่าวให้ชัดเจนไม่คลุมเครือ เช่น ถ้าอยากขอให้รวย ก็เจาะจงไปเลยว่าอยากมีรายได้เดือนละเท่าไหร่ หรือขายของให้ได้เดือนละเท่าไหร่ ระบุให้ละเอียดและเจาะจง 
      * สิ่งที่สำคัญคือ การตั้งจิตให้แน่วแน่บริสุทธิ์ และเรื่องที่มุ่งหวังจะต้องถูกต้องตามศีลธรรมเท่านั้น จึงจะมีโอกาสสมหวัง`,
    },
  },

  [STORE_LOCATIONS.LCR]: {
    performInstructions: {
      [CHANT_MODE.WISH]: `## คำแนะนำการขอพร-แก้บนกับพระพุทธชินราชจากบ้าน

**อุปกรณ์ที่ต้องใช้**
* ธูป 5 ดอกสำหรับขอพร-แก้บน (แนะนำว่าควรมี)
* บทสวดข้างบน (กดบันทึกเก็บไว้อ่านได้เลย!)

**ก่อนถึงเวลาที่ร้านค้าจะดำเนินการ**
* จุดธูปให้พร้อม
* ตั้งจิตอธิษฐานให้มั่นต่อรูปพระพุทธชินราชที่เราเตรียมไว้ให้ แล้วกล่าวบทสวด, ชื่อ-สกุลของและข้อมูลของผู้ขอพร-แก้บนให้ชัดเจน 
* ปักธูปหรือเทียนลงกระถาง เป็นอันเสร็จพิธี
* หลังจากนั้นรอรับภาพหลักฐานการดำเนินการจากเราได้เลย! (ใช้เวลา 1-3 วันในการดำเนินการ)

**หมายเหตุ**
* การตั้งจิตอธิษฐานและกล่าวบทสวดสามารถเริ่มทำได้ตั้งแต่ได้รับการยืนยันการจ่ายเงินสำเร็จ
* หากไม่มีเวลา สามารถทำได้ตามฤกษ์สะดวก`,
      [CHANT_MODE.VOW]: `## คำแนะนำการขอพร-แก้บนกับพระพุทธชินราชจากบ้าน

**อุปกรณ์ที่ต้องใช้**
* ธูป 5 ดอกสำหรับขอพร-แก้บน (แนะนำว่าควรมี)
* บทสวดข้างบน (กดบันทึกเก็บไว้อ่านได้เลย!)

**ก่อนถึงเวลาที่ร้านค้าจะดำเนินการ**
* จุดธูปให้พร้อม
* ตั้งจิตอธิษฐานให้มั่นต่อรูปพระพุทธชินราชที่เราเตรียมไว้ให้ แล้วกล่าวบทสวด, ชื่อ-สกุลของและข้อมูลของผู้ขอพร-แก้บนให้ชัดเจน 
* ปักธูปหรือเทียนลงกระถาง เป็นอันเสร็จพิธี
* หลังจากนั้นรอรับภาพหลักฐานการดำเนินการจากเราได้เลย! (ใช้เวลา 1-3 วันในการดำเนินการ)

**หมายเหตุ**
* การตั้งจิตอธิษฐานและกล่าวบทสวดสามารถเริ่มทำได้ตั้งแต่ได้รับการยืนยันการจ่ายเงินสำเร็จ
* หากไม่มีเวลา สามารถทำได้ตามฤกษ์สะดวก`,
    },
    messageInputInstructions: {
      [CHANT_MODE.WISH]: `### เคล็ดลับการกรอกคำขอพร-แก้บนกับพระพุทธชินราช

* ระบุชื่อ-นามสกุล ให้ชัดเจน ให้ตั้งจิตอธิษฐานด้วยใจแน่วแน่ แนะนำว่าให้ขอทีละเรื่อง และบอกกล่าวให้ชัดเจนไม่คลุมเครือ เช่น ถ้าอยากขอให้รวย ก็เจาะจงไปเลยว่าอยากมีรายได้เดือนละเท่าไหร่ หรือขายของให้ได้เดือนละเท่าไหร่ ระบุให้ละเอียดและเจาะจง 
* สิ่งที่สำคัญคือ การตั้งจิตให้แน่วแน่บริสุทธิ์ และเรื่องที่มุ่งหวังจะต้องถูกต้องตามศีลธรรมเท่านั้น จึงจะมีโอกาสสมหวัง`,
      [CHANT_MODE.VOW]: `### เคล็ดลับการกรอกคำขอพร-แก้บนกับพระพุทธชินราช

* ระบุชื่อ-นามสกุล ให้ชัดเจน ให้ตั้งจิตอธิษฐานด้วยใจแน่วแน่ แนะนำว่าให้ขอทีละเรื่อง และบอกกล่าวให้ชัดเจนไม่คลุมเครือ เช่น ถ้าอยากขอให้รวย ก็เจาะจงไปเลยว่าอยากมีรายได้เดือนละเท่าไหร่ หรือขายของให้ได้เดือนละเท่าไหร่ ระบุให้ละเอียดและเจาะจง 
* สิ่งที่สำคัญคือ การตั้งจิตให้แน่วแน่บริสุทธิ์ และเรื่องที่มุ่งหวังจะต้องถูกต้องตามศีลธรรมเท่านั้น จึงจะมีโอกาสสมหวัง`,
    },
  },

  [STORE_LOCATIONS.LPH]: {
    performInstructions: {
      [CHANT_MODE.WISH]: `## คำแนะนำการขอพร-แก้บนกับพระธาตุพนมวรมหาวิหารจากบ้าน

**อุปกรณ์ที่ต้องใช้**
* ธูป 16 ดอกสำหรับขอพร-แก้บน (สำหรับการขอพรหากไม่มีไม่เป็นไร แต่ถ้าเป็นการแก้บนแนะนำว่าควรมี)
* บทสวดข้างบน (กดบันทึกเก็บไว้อ่านได้เลย!)

**ก่อนถึงเวลาที่ร้านค้าจะดำเนินการ**
* จุดธูปให้พร้อม
* ตั้งจิตอธิษฐานให้มั่นต่อรูปพระธาตุพนมวรมหาวิหารที่เราเตรียมไว้ให้ แล้วกล่าวบทสวด, ชื่อ-สกุลของและข้อมูลของผู้ขอพร-แก้บนให้ชัดเจน 
* ปักธูปหรือเทียนลงกระถาง เป็นอันเสร็จพิธี
* หลังจากนั้นรอรับภาพหลักฐานการดำเนินการจากเราได้เลย! (ใช้เวลา 1-3 วันในการดำเนินการ)

**หมายเหตุ**
* การตั้งจิตอธิษฐานและกล่าวบทสวดสามารถเริ่มทำได้ตั้งแต่ได้รับการยืนยันการจ่ายเงินสำเร็จ
* หากไม่มีเวลา สามารถทำได้ตามฤกษ์สะดวก`,
      [CHANT_MODE.VOW]: `## คำแนะนำการขอพร-แก้บนกับพระธาตุพนมวรมหาวิหารจากบ้าน

**อุปกรณ์ที่ต้องใช้**
* ธูป 16 ดอกสำหรับขอพร-แก้บน (สำหรับการขอพรหากไม่มีไม่เป็นไร แต่ถ้าเป็นการแก้บนแนะนำว่าควรมี)
* บทสวดข้างบน (กดบันทึกเก็บไว้อ่านได้เลย!)

**ก่อนถึงเวลาที่ร้านค้าจะดำเนินการ**
* จุดธูปให้พร้อม
* ตั้งจิตอธิษฐานให้มั่นต่อรูปพระธาตุพนมวรมหาวิหารที่เราเตรียมไว้ให้ แล้วกล่าวบทสวด, ชื่อ-สกุลของและข้อมูลของผู้ขอพร-แก้บนให้ชัดเจน 
* ปักธูปหรือเทียนลงกระถาง เป็นอันเสร็จพิธี
* หลังจากนั้นรอรับภาพหลักฐานการดำเนินการจากเราได้เลย! (ใช้เวลา 1-3 วันในการดำเนินการ)

**หมายเหตุ**
* การตั้งจิตอธิษฐานและกล่าวบทสวดสามารถเริ่มทำได้ตั้งแต่ได้รับการยืนยันการจ่ายเงินสำเร็จ
* หากไม่มีเวลา สามารถทำได้ตามฤกษ์สะดวก`,
    },
    messageInputInstructions: {
      [CHANT_MODE.WISH]: `### เคล็ดลับการกรอกคำขอพร-แก้บนกับพระธาตุพนมวรมหาวิหาร

* ระบุชื่อ-นามสกุล ให้ชัดเจน ให้ตั้งจิตอธิษฐานด้วยใจแน่วแน่ แนะนำว่าให้ขอทีละเรื่อง และบอกกล่าวให้ชัดเจนไม่คลุมเครือ เช่น ถ้าอยากขอให้รวย ก็เจาะจงไปเลยว่าอยากมีรายได้เดือนละเท่าไหร่ หรือขายของให้ได้เดือนละเท่าไหร่ ระบุให้ละเอียดและเจาะจง 
* สิ่งที่สำคัญคือ การตั้งจิตให้แน่วแน่บริสุทธิ์ และเรื่องที่มุ่งหวังจะต้องถูกต้องตามศีลธรรมเท่านั้น จึงจะมีโอกาสสมหวัง`,
      [CHANT_MODE.VOW]: `### เคล็ดลับการกรอกคำขอพร-แก้บนกับพระธาตุพนมวรมหาวิหาร

* ระบุชื่อ-นามสกุล ให้ชัดเจน ให้ตั้งจิตอธิษฐานด้วยใจแน่วแน่ แนะนำว่าให้ขอทีละเรื่อง และบอกกล่าวให้ชัดเจนไม่คลุมเครือ เช่น ถ้าอยากขอให้รวย ก็เจาะจงไปเลยว่าอยากมีรายได้เดือนละเท่าไหร่ หรือขายของให้ได้เดือนละเท่าไหร่ ระบุให้ละเอียดและเจาะจง 
* สิ่งที่สำคัญคือ การตั้งจิตให้แน่วแน่บริสุทธิ์ และเรื่องที่มุ่งหวังจะต้องถูกต้องตามศีลธรรมเท่านั้น จึงจะมีโอกาสสมหวัง`,
    },
  },

  [STORE_LOCATIONS.LMK]: {
    performInstructions: {
      [CHANT_MODE.WISH]: `## คำแนะนำการขอพร-แก้บนกับมหาเจดีย์ชเวดากองจากบ้าน
      
**อุปกรณ์ที่ต้องใช้**
* บทสวดข้างบน (กดบันทึกเก็บไว้อ่านได้เลย!)

**ก่อนถึงเวลาที่ร้านค้าจะดำเนินการ**
* ตั้งจิตอธิษฐานให้มั่นต่อรูปมหาเจดีย์ชเวดากองที่เราเตรียมไว้ให้ แล้วกล่าวบทสวด, ชื่อ-สกุลของและข้อมูลของผู้ขอพร-แก้บนให้ชัดเจน เป็นอันเสร็จพิธี
* หลังจากนั้นรอรับภาพหลักฐานการดำเนินการจากเราได้เลย! (ใช้เวลา 1-3 วันในการดำเนินการ)

**หมายเหตุ**
* การตั้งจิตอธิษฐานและกล่าวบทสวดสามารถเริ่มทำได้ตั้งแต่ได้รับการยืนยันการจ่ายเงินสำเร็จ
* หากไม่มีเวลา สามารถทำได้ตามฤกษ์สะดวก`,
      [CHANT_MODE.VOW]: `## คำแนะนำการขอพร-แก้บนกับมหาเจดีย์ชเวดากองจากบ้าน
      
**อุปกรณ์ที่ต้องใช้**
* บทสวดข้างบน (กดบันทึกเก็บไว้อ่านได้เลย!)

**ก่อนถึงเวลาที่ร้านค้าจะดำเนินการ**
* ตั้งจิตอธิษฐานให้มั่นต่อรูปมหาเจดีย์ชเวดากองที่เราเตรียมไว้ให้ แล้วกล่าวบทสวด, ชื่อ-สกุลของและข้อมูลของผู้ขอพร-แก้บนให้ชัดเจน เป็นอันเสร็จพิธี
* หลังจากนั้นรอรับภาพหลักฐานการดำเนินการจากเราได้เลย! (ใช้เวลา 1-3 วันในการดำเนินการ)

**หมายเหตุ**
* การตั้งจิตอธิษฐานและกล่าวบทสวดสามารถเริ่มทำได้ตั้งแต่ได้รับการยืนยันการจ่ายเงินสำเร็จ
* หากไม่มีเวลา สามารถทำได้ตามฤกษ์สะดวก`,
    },
    messageInputInstructions: {
      [CHANT_MODE.WISH]: `### เคล็ดลับการกรอกคำขอพร-แก้บนกับมหาเจดีย์ชเวดากอง
  
* ระบุชื่อ-นามสกุล วันเดือนปีเกิดให้ชัดเจน ให้ตั้งจิตอธิษฐานด้วยใจแน่วแน่ แนะนำว่าให้ขอทีละเรื่อง และบอกกล่าวให้ชัดเจนไม่คลุมเครือ เช่น ถ้าอยากขอให้รวย ก็เจาะจงไปเลยว่าอยากมีรายได้เดือนละเท่าไหร่ หรือขายของให้ได้เดือนละเท่าไหร่ ระบุให้ละเอียดและเจาะจง 
* สิ่งที่สำคัญคือ การตั้งจิตให้แน่วแน่บริสุทธิ์ และเรื่องที่มุ่งหวังจะต้องถูกต้องตามศีลธรรมเท่านั้น จึงจะมีโอกาสสมหวัง`,
      [CHANT_MODE.VOW]: `### เคล็ดลับการกรอกคำขอพร-แก้บนกับมหาเจดีย์ชเวดากอง
  
* ระบุชื่อ-นามสกุล วันเดือนปีเกิดให้ชัดเจน ให้ตั้งจิตอธิษฐานด้วยใจแน่วแน่ แนะนำว่าให้ขอทีละเรื่อง และบอกกล่าวให้ชัดเจนไม่คลุมเครือ เช่น ถ้าอยากขอให้รวย ก็เจาะจงไปเลยว่าอยากมีรายได้เดือนละเท่าไหร่ หรือขายของให้ได้เดือนละเท่าไหร่ ระบุให้ละเอียดและเจาะจง 
* สิ่งที่สำคัญคือ การตั้งจิตให้แน่วแน่บริสุทธิ์ และเรื่องที่มุ่งหวังจะต้องถูกต้องตามศีลธรรมเท่านั้น จึงจะมีโอกาสสมหวัง`,
    },
  },

  [STORE_LOCATIONS.LTJ]: {
    performInstructions: {
      [CHANT_MODE.WISH]: `## คำแนะนำการขอพร-แก้บนกับเทพทันใจ (นัตโบโบยี) จากบ้าน
        
**อุปกรณ์ที่ต้องใช้**
* บทสวดข้างบน (กดบันทึกเก็บไว้อ่านได้เลย!)

**ก่อนถึงเวลาที่ร้านค้าจะดำเนินการ**
* ตั้งจิตอธิษฐานให้มั่นต่อรูปเทพทันใจ(นัตโบโบยี) ที่เราเตรียมไว้ให้ แล้วกล่าวบทสวด, ชื่อ-สกุลของและข้อมูลของผู้ขอพร-แก้บนให้ชัดเจน เป็นอันเสร็จพิธี
* หลังจากนั้นรอรับภาพหลักฐานการดำเนินการจากเราได้เลย! (ใช้เวลา 1-3 วันในการดำเนินการ)

**หมายเหตุ**
* การตั้งจิตอธิษฐานและกล่าวบทสวดสามารถเริ่มทำได้ตั้งแต่ได้รับการยืนยันการจ่ายเงินสำเร็จ
* หากไม่มีเวลา สามารถทำได้ตามฤกษ์สะดวก`,
      [CHANT_MODE.VOW]: `## คำแนะนำการขอพร-แก้บนกับเทพทันใจ (นัตโบโบยี) จากบ้าน
        
**อุปกรณ์ที่ต้องใช้**
* บทสวดข้างบน (กดบันทึกเก็บไว้อ่านได้เลย!)

**ก่อนถึงเวลาที่ร้านค้าจะดำเนินการ**
* ตั้งจิตอธิษฐานให้มั่นต่อรูปเทพทันใจ(นัตโบโบยี) ที่เราเตรียมไว้ให้ แล้วกล่าวบทสวด, ชื่อ-สกุลของและข้อมูลของผู้ขอพร-แก้บนให้ชัดเจน เป็นอันเสร็จพิธี
* หลังจากนั้นรอรับภาพหลักฐานการดำเนินการจากเราได้เลย! (ใช้เวลา 1-3 วันในการดำเนินการ)

**หมายเหตุ**
* การตั้งจิตอธิษฐานและกล่าวบทสวดสามารถเริ่มทำได้ตั้งแต่ได้รับการยืนยันการจ่ายเงินสำเร็จ
* หากไม่มีเวลา สามารถทำได้ตามฤกษ์สะดวก`,
    },
    messageInputInstructions: {
      [CHANT_MODE.WISH]: `### เคล็ดลับการกรอกคำขอพร-แก้บนกับเทพทันใจ (นัตโบโบยี)
    
* ระบุชื่อ-นามสกุล วันเดือนปีเกิดให้ชัดเจน ให้ตั้งจิตอธิษฐานด้วยใจแน่วแน่ แนะนำว่าให้ขอทีละเรื่อง และบอกกล่าวให้ชัดเจนไม่คลุมเครือ เช่น ถ้าอยากขอให้รวย ก็เจาะจงไปเลยว่าอยากมีรายได้เดือนละเท่าไหร่ หรือขายของให้ได้เดือนละเท่าไหร่ ระบุให้ละเอียดและเจาะจง 
* สิ่งที่สำคัญคือ การตั้งจิตให้แน่วแน่บริสุทธิ์ และเรื่องที่มุ่งหวังจะต้องถูกต้องตามศีลธรรมเท่านั้น จึงจะมีโอกาสสมหวัง`,
      [CHANT_MODE.VOW]: `### เคล็ดลับการกรอกคำขอพร-แก้บนกับเทพทันใจ (นัตโบโบยี)
    
* ระบุชื่อ-นามสกุล วันเดือนปีเกิดให้ชัดเจน ให้ตั้งจิตอธิษฐานด้วยใจแน่วแน่ แนะนำว่าให้ขอทีละเรื่อง และบอกกล่าวให้ชัดเจนไม่คลุมเครือ เช่น ถ้าอยากขอให้รวย ก็เจาะจงไปเลยว่าอยากมีรายได้เดือนละเท่าไหร่ หรือขายของให้ได้เดือนละเท่าไหร่ ระบุให้ละเอียดและเจาะจง 
* สิ่งที่สำคัญคือ การตั้งจิตให้แน่วแน่บริสุทธิ์ และเรื่องที่มุ่งหวังจะต้องถูกต้องตามศีลธรรมเท่านั้น จึงจะมีโอกาสสมหวัง`,
    },
  },

  [STORE_LOCATIONS.LHJ]: {
    performInstructions: {
      [CHANT_MODE.WISH]: `## คำแนะนำการขอพร-แก้บนกับเจ้าพ่อเห้งเจี่ย สวนผัก จากบ้าน
  
**อุปกรณ์ที่ต้องใช้**
* บทสวดข้างบน (กดบันทึกเก็บไว้อ่านได้เลย!)

**ก่อนถึงเวลาที่ร้านค้าจะดำเนินการ**
* ตั้งจิตอธิษฐานให้มั่นต่อรูปเจ้าพ่อเห้งเจี่ย ที่เราเตรียมไว้ให้ แล้วกล่าวบทสวด, ชื่อ-สกุลของและข้อมูลของผู้ขอพร-แก้บนให้ชัดเจน เป็นอันเสร็จพิธี
* หลังจากนั้นรอรับภาพหลักฐานการดำเนินการจากเราได้เลย! (ใช้เวลา 1-3 วันในการดำเนินการ)

**หมายเหตุ**
* การตั้งจิตอธิษฐานและกล่าวบทสวดสามารถเริ่มทำได้ตั้งแต่ได้รับการยืนยันการจ่ายเงินสำเร็จ
* หากไม่มีเวลา สามารถทำได้ตามฤกษ์สะดวก`,
      [CHANT_MODE.VOW]: `## คำแนะนำการขอพร-แก้บนกับเจ้าพ่อเห้งเจี่ย สวนผัก จากบ้าน
  
**อุปกรณ์ที่ต้องใช้**
* บทสวดข้างบน (กดบันทึกเก็บไว้อ่านได้เลย!)

**ก่อนถึงเวลาที่ร้านค้าจะดำเนินการ**
* ตั้งจิตอธิษฐานให้มั่นต่อรูปเจ้าพ่อเห้งเจี่ย ที่เราเตรียมไว้ให้ แล้วกล่าวบทสวด, ชื่อ-สกุลของและข้อมูลของผู้ขอพร-แก้บนให้ชัดเจน เป็นอันเสร็จพิธี
* หลังจากนั้นรอรับภาพหลักฐานการดำเนินการจากเราได้เลย! (ใช้เวลา 1-3 วันในการดำเนินการ)

**หมายเหตุ**
* การตั้งจิตอธิษฐานและกล่าวบทสวดสามารถเริ่มทำได้ตั้งแต่ได้รับการยืนยันการจ่ายเงินสำเร็จ
* หากไม่มีเวลา สามารถทำได้ตามฤกษ์สะดวก`,
    },
    messageInputInstructions: {
      [CHANT_MODE.WISH]: `### เคล็ดลับการกรอกคำขอพร-แก้บนกับเจ้าพ่อเห้งเจี่ย สวนผัก
  
* ระบุชื่อ-นามสกุล วันเดือนปีเกิดให้ชัดเจน ให้ตั้งจิตอธิษฐานด้วยใจแน่วแน่ แนะนำว่าให้ขอทีละเรื่อง และบอกกล่าวให้ชัดเจนไม่คลุมเครือ เช่น ถ้าอยากขอให้รวย ก็เจาะจงไปเลยว่าอยากมีรายได้เดือนละเท่าไหร่ หรือขายของให้ได้เดือนละเท่าไหร่ ระบุให้ละเอียดและเจาะจง 
* สิ่งที่สำคัญคือ การตั้งจิตให้แน่วแน่บริสุทธิ์ และเรื่องที่มุ่งหวังจะต้องถูกต้องตามศีลธรรมเท่านั้น จึงจะมีโอกาสสมหวัง`,
      [CHANT_MODE.VOW]: `### เคล็ดลับการกรอกคำขอพร-แก้บนกับเจ้าพ่อเห้งเจี่ย สวนผัก
  
      * ระบุชื่อ-นามสกุล วันเดือนปีเกิดให้ชัดเจน ให้ตั้งจิตอธิษฐานด้วยใจแน่วแน่ แนะนำว่าให้ขอทีละเรื่อง และบอกกล่าวให้ชัดเจนไม่คลุมเครือ เช่น ถ้าอยากขอให้รวย ก็เจาะจงไปเลยว่าอยากมีรายได้เดือนละเท่าไหร่ หรือขายของให้ได้เดือนละเท่าไหร่ ระบุให้ละเอียดและเจาะจง 
      * สิ่งที่สำคัญคือ การตั้งจิตให้แน่วแน่บริสุทธิ์ และเรื่องที่มุ่งหวังจะต้องถูกต้องตามศีลธรรมเท่านั้น จึงจะมีโอกาสสมหวัง`,
    },
  },
  [STORE_LOCATIONS.LYW]: {
    performInstructions: {
      [CHANT_MODE.WISH]: `### คำแนะนำการตักบาตรจากบ้าน
  
**อุปกรณ์ที่ต้องใช้**
* ที่กรวดน้ำ (หากไม่มีสามารถใช้ภาชนะอื่นๆแทนได้) 
* บทคำอธิษฐานอุทิศส่วนบุญส่วนกุศลข้างบน (กดบันทึกเก็บไว้อ่านได้เลย!)
      
**ก่อนถึงเวลาที่ร้านค้าจะดำเนินการ**
* ตั้งจิตอธิษฐานให้มั่น แล้วกล่าวบทคำอธิษฐานอุทิศส่วนบุญส่วนกุศล, ชื่อ-สกุลของและข้อมูลของผู้อธิษฐานให้ชัดเจน เป็นอันเสร็จพิธี
* หลังจากนั้นรอรับภาพหลักฐานการดำเนินการจากเราได้เลย! (ใช้เวลา 1-3 วันในการดำเนินการ)
      
**หมายเหตุ**
* การตั้งจิตอธิษฐานและกล่าวบทสวดสามารถเริ่มทำได้ตั้งแต่ได้รับการยืนยันการจ่ายเงินสำเร็จ
* หากไม่มีเวลา สามารถทำได้ตามฤกษ์สะดวก`,
      [CHANT_MODE.VOW]: ``,
    },
    messageInputInstructions: {
      [CHANT_MODE.WISH]: ``,
      [CHANT_MODE.VOW]: ``,
    },
  },
  [STORE_LOCATIONS.LHP]: {
    performInstructions: {
      [CHANT_MODE.WISH]: `### คำแนะนำการตักบาตรจากบ้าน
  
**อุปกรณ์ที่ต้องใช้**
* ที่กรวดน้ำ (หากไม่มีสามารถใช้ภาชนะอื่นๆแทนได้) 
* บทคำอธิษฐานอุทิศส่วนบุญส่วนกุศลข้างบน (กดบันทึกเก็บไว้อ่านได้เลย!)
      
**ก่อนถึงเวลาที่ร้านค้าจะดำเนินการ**
* ตั้งจิตอธิษฐานให้มั่น แล้วกล่าวบทคำอธิษฐานอุทิศส่วนบุญส่วนกุศล, ชื่อ-สกุลของและข้อมูลของผู้อธิษฐานให้ชัดเจน เป็นอันเสร็จพิธี
* หลังจากนั้นรอรับภาพหลักฐานการดำเนินการจากเราได้เลย! (ใช้เวลา 1-3 วันในการดำเนินการ)
      
**หมายเหตุ**
* การตั้งจิตอธิษฐานและกล่าวบทสวดสามารถเริ่มทำได้ตั้งแต่ได้รับการยืนยันการจ่ายเงินสำเร็จ
* หากไม่มีเวลา สามารถทำได้ตามฤกษ์สะดวก`,
      [CHANT_MODE.VOW]: ``,
    },
    messageInputInstructions: {
      [CHANT_MODE.WISH]: ``,
      [CHANT_MODE.VOW]: ``,
    },
  },
  [STORE_LOCATIONS.LTS]: {
    performInstructions: {
      [CHANT_MODE.WISH]: `### คำแนะนำการขอพรและร่วมบริจาคโลงศพจากบ้าน

**อุปกรณ์ที่ต้องใช้**
* ธูป 3 ดอก (แนะนำว่าควรมี)
* บทคำอธิษฐานอุทิศส่วนบุญส่วนกุศลข้างบน (กดบันทึกเก็บไว้อ่านได้เลย!)

**ก่อนถึงเวลาที่ร้านค้าจะดำเนินการ**
*  จุดธูปที่เตรียมไว้
*  ตั้งจิตอธิษฐานให้มั่น แล้วกล่าวบทคำอธิษฐานอุทิศส่วนบุญส่วนกุศล, ชื่อ-สกุลของและข้อมูลของผู้อธิษฐานให้ชัดเจน เป็นอันเสร็จพิธี
*  หลังจากนั้นรอรับภาพหลักฐานการดำเนินการจากเราได้เลย! (ใช้เวลา 1-3 วันในการดำเนินการ)

**หมายเหตุ**
*  การตั้งจิตอธิษฐานและกล่าวบทสวดสามารถเริ่มทำได้ตั้งแต่ได้รับการยืนยันการจ่ายเงินสำเร็จ
*  หากไม่มีเวลา สามารถทำได้ตามฤกษ์สะดวก`,
      [CHANT_MODE.VOW]: ``,
    },
    messageInputInstructions: {
      [CHANT_MODE.WISH]: ``,
      [CHANT_MODE.VOW]: ``,
    },
  },
  [STORE_LOCATIONS.LTH]: {
    performInstructions: {
      [CHANT_MODE.WISH]: `### คำแนะนำการถวายภัตตาหารจากบ้าน

**อุปกรณ์ที่ต้องใช้**
* บทสวดข้างบน (กดบันทึกเก็บไว้อ่านได้เลย!)

**ก่อนถึงเวลาที่ร้านค้าจะดำเนินการ**
* ตั้งจิตอธิษฐานให้มั่น แล้วกล่าวบทสวด, ชื่อ-สกุลของและข้อมูลของผู้อธิษฐานให้ชัดเจน เป็นอันเสร็จพิธี
* หลังจากนั้นรอรับภาพหลักฐานการดำเนินการจากเราได้เลย! (ใช้เวลา 1-3 วันในการดำเนินการ)

**หมายเหตุ**
* การตั้งจิตอธิษฐานและกล่าวบทสวดสามารถเริ่มทำได้ตั้งแต่ได้รับการยืนยันการจ่ายเงินสำเร็จ
* หากไม่มีเวลา สามารถทำได้ตามฤกษ์สะดวก`,
      [CHANT_MODE.VOW]: ``,
    },
    messageInputInstructions: {
      [CHANT_MODE.WISH]: ``,
      [CHANT_MODE.VOW]: ``,
    },
  },
  [STORE_LOCATIONS.S9W]: S9Wdata,
  [STORE_LOCATIONS.S9W_1]: S9Wdata,
  [STORE_LOCATIONS.S9W_2]: S9Wdata,
  [STORE_LOCATIONS.S9W_3]: S9Wdata,
  [STORE_LOCATIONS.LLH]: {
    performInstructions: {
      [CHANT_MODE.WISH]: ``,
      [CHANT_MODE.VOW]: ``,
    },
    messageInputInstructions: {
      [CHANT_MODE.WISH]: `## คำแนะนำในการขอพร
* ขอพรครั้งละ 1 ข้อใหญ่เท่านั้น
* แจ้งชื่อสกุลและวันเดือนปีเกิดของผู้ฝากไหว้ให้ชัดเจน
* หากขอเรื่องโชคลาภหรือทรัพย์สินเงินทอง ควรระบุจำนวน และช่วงเวลาที่ต้องการ เช่น วันที่หรือภายในระยะเวลา... ให้ชัดเจน
* เคล็ดลับเพิ่มเติม : ผู้ฝากไหว้ควรทำกุศลบ่อยๆ พร้อมอุทิศกุศลเหล่านั้นแด่พระราหูด้วย ท่านจะได้มีพลังแรงมาส่งผลให้พรที่เราขอเป็นจริงฉับพลัน, สวดบทบูชาพระราหูเป็นประจำ`,
      [CHANT_MODE.VOW]: ``,
    },
  },
  [STORE_LOCATIONS.PDP]: {
    performInstructions: {
      [CHANT_MODE.WISH]: ``,
      [CHANT_MODE.VOW]: ``,
    },
    messageInputInstructions: {
      [CHANT_MODE.WISH]: `## คำแนะนำในการขอพร
หากมาขอพรองค์พระพิฆเนศ/ท้าวเวสสุวรรณ จะต้องขอพรเพียงข้อเดียวเท่านั้น เมื่อสำเร็จแล้ว จึงค่อยมาขอใหม่  
      
หากใครได้กราบทั้งสองพระองค์  ชีวิตนี้จะไม่ต่ำกว่าคน จะไม่จนกว่าใคร"`,
      [CHANT_MODE.VOW]: ``,
    },
  },
  [STORE_LOCATIONS.PDT]: {
    performInstructions: {
      [CHANT_MODE.WISH]: ``,
      [CHANT_MODE.VOW]: ``,
    },
    messageInputInstructions: {
      [CHANT_MODE.WISH]: `## คำแนะนำในการขอพร
หากมาขอพรองค์พระพิฆเนศ/ท้าวเวสสุวรรณ จะต้องขอพรเพียงข้อเดียวเท่านั้น เมื่อสำเร็จแล้ว จึงค่อยมาขอใหม่  
      
หากใครได้กราบทั้งสองพระองค์  ชีวิตนี้จะไม่ต่ำกว่าคน จะไม่จนกว่าใคร"`,
      [CHANT_MODE.VOW]: ``,
    },
  },
  [STORE_LOCATIONS['LYR-1']]: LYRdata,
  [STORE_LOCATIONS['LYR-2']]: LYRdata,
  [STORE_LOCATIONS['LYR-3']]: LYRdata,
  [STORE_LOCATIONS['LYR-4']]: LYRdata,
  [STORE_LOCATIONS['LYR-5']]: LYRdata,
  [STORE_LOCATIONS['LYR-6']]: LYRdata,
  [STORE_LOCATIONS['LYR-7']]: LYRdata,
  [STORE_LOCATIONS['LYR-8']]: LYRdata,
  [STORE_LOCATIONS.PSV]: {
    performInstructions: {
      [CHANT_MODE.WISH]: `## คำแนะนำในการขอพร
1. ถวายดอกดาวเรือง
2. น้ำเปล่า น้ำอ้อย หรือนม แอปเปิ้ลแดง 5-8 ลูก หรือผลไม้มงคล 5 อย่าง
3. จุดกำยาน หรือเครื่องหอม
4. ทำจิตใจให้สงบแล้วสวดและเริ่มกล่าวบทสวดพระพิฆเนศ จากนั้น สวดพระศิวะ
5. พอท่องบทสวดมนต์เสร็จแล้ว ให้อธิษฐานขอพรเป็นภาษาไทย
6. กล่าวคำว่า "โอม ศานติ" (3 ครั้ง) ขอความสันติและสงบสุข เป็นอันเสร็จพิธี

*ห้ามถวายสิ่งที่ทำมาจากเนื้อสัตว์และขนมที่มีไข่เป็นส่วนประกอบเด็ดขาด*`,
      [CHANT_MODE.VOW]: ``,
    },
    messageInputInstructions: {
      [CHANT_MODE.WISH]: ``,
      [CHANT_MODE.VOW]: ``,
    },
  },
};

export default message;
