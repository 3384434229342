import React, { Fragment } from 'react';
import { FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { Box, Typography } from 'Components/Base';
import {
  HOROWALL_REQUEST_TYPE,
  HOROWALL_REQUEST_DISPLAY,
  getRequestTypeChoices,
  RMS_REQUEST_DISPLAY,
} from 'Domain/Sku/Wallpaper';

export function RMSRequestTypeInput({
  label,
  value,
  onBlur,
  onChange,
  placeholder,
  ...restProps
}: {
  label?: string;
  value: any;
  onBlur?: any;
  onChange?: any;
  placeholder?: any;
  [key: string]: any;
}) {
  return <Typography>{RMS_REQUEST_DISPLAY[value]}</Typography>;
}
