import React from 'react';
import { Box, Typography } from 'Components/Base';
import { List } from '@mui/material';
import { LocationProps } from '../utils';
import { CHANT_MODE, getChantModeText } from 'Domain/Sattha/Chant';

export default function Sothon({ mode }: LocationProps) {
  if (mode === CHANT_MODE.WISH) {
    return (
      <Box>
        <Typography variant="h4">{`วิธีขอพรหลวงพ่อโสธรจากบ้าน`}</Typography>
        <Box mt={1}></Box>
        <b>อุปกรณ์ที่ต้องใช้</b>
        <ul>
          <li>ธูป 3 ดอก (หากไม่มีก็ไม่เป็นไร)</li>
          <li>บทสวดข้างบน (กดบันทึกเก็บไว้อ่านได้เลย!)</li>
        </ul>
        <b>ก่อนถึงเวลาที่ร้านค้าจะดำเนินการขอพร</b>
        <ul>
          <li>จุดธูปให้พร้อม</li>
          <li>
            ตั้งจิตอธิษฐานให้มั่น แล้วกล่าวบทสวดมนต์, ชื่อ, เรื่องที่อยากขอพร/บนบาน
            พร้อมทั้งบอกของที่ต้องการนำมาแก้บน (ถ้ามี) หากพรสมปรารถนา
          </li>
        </ul>
        เช่น เมื่อข้าพเจ้าสำเร็จแล้วจะนำไข่ต้มจำนวน 300 ฟองมาถวาย (บอกจำนวนที่จะนำมาถวาย
        แต่แนะนำว่าอย่างต่ำคือ 30 ฟอง)
      </Box>
    );
  }
  return (
    <Box>
      <Typography variant="h4">{`วิธีแก้บนหลวงพ่อโสธรจากบ้าน`}</Typography>
      <Box mt={1}></Box>
      {mode === CHANT_MODE.VOW && (
        <>
          <b>อุปกรณ์ที่ต้องใช้</b>
          <ul>
            <li>ธูป 3 ดอก (แนะนำว่าควรมี)</li>
          </ul>
          <b>ก่อนถึงเวลาที่ร้านค้าจะดำเนินการแก้บน</b>
          <ul>
            <li>จุดธูปให้พร้อม</li>
            <li>
              ตั้งจิตอธิษฐานให้มั่น แล้วกล่าวบทแก้บน, ชื่อ, เรื่องที่ได้บนบานไว้
              พร้อมบอกกล่าวว่าบัดนี้ด้วยบารมีของหลวงพ่อโสธรที่ได้ทำให้สมปรารถนาแล้วทุกประการ
              จึงได้นำของมาถวายเพื่อแก้บนด้วยถือสัจจะเป็นที่ตั้ง
              ขอให้การบนบานครั้งนี้ขาดจากกันนับแต่บัดนี้
            </li>
          </ul>
          เช่น "ข้าพเจ้า นายมานะ ขยันยิ่ง ได้บนบานเรื่องยอดขายของเดือนที่ผ่านมาได้ 100,000
          ตามที่ได้บนบานเอาไว้ ด้วยบารมีของหลวงพ่อโสธรที่ทำให้สมปรารถนา
          ข้าพเจ้าจึงได้นำของมาถวายเพื่อแก้บนด้วยถือสัจจะเป็นที่ตั้ง
          ขอให้การบนบานครั้งนี้ขาดจากกันนับแต่บัดนี้ด้วยเทอญ"
        </>
      )}
    </Box>
  );
}
