import React from 'react';

import Loading from 'Components/Loading';

import AuthContext from './AuthContext';
import { AUTH_STATE, AuthProvider as AuthAPIProvider, UserData, AUTH_ERROR_CODE } from './types';

import FirebaseAuthProvider from './providers/FirebaseAuthProvider';
import ClientAuthenAPI from 'API/fe-functions/authen/AuthenAPI';

const AuthProvider = (props: any) => {
  const [authState, setAuthState] = React.useState(AUTH_STATE.INITIALIZING);
  const [userInfo, setUserInfo] = React.useState<UserData | null>(null);
  const [loading, setLoading] = React.useState(false); // Set to true when running actions e.g. login, logout.
  const [authError, setAuthError] = React.useState<AUTH_ERROR_CODE | null>(null);
  const authProviderRef = React.useRef<AuthAPIProvider | null>(null);

  function updateAuthState(authState: AUTH_STATE, user?: UserData) {
    setAuthState(authState);
    setAuthError(null);
    if (authState === AUTH_STATE.LOGGED_IN && user) {
      setUserInfo(user);
    } else if (authState === AUTH_STATE.NOT_LOGGED_IN) {
      setUserInfo(null);
    }
  }

  /**
   * Init Auth Provider and Get auth state
   */
  React.useEffect(() => {
    authProviderRef.current = new FirebaseAuthProvider();
    authProviderRef.current.subscribeAuthState(updateAuthState);

    //TODO: Think about GET /users/me
    authProviderRef.current.initGetUserData();
  }, []);

  const loginWithEmailAndPassword = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    setLoading(true);
    try {
      await authProviderRef.current?.loginWithEmailAndPassword({ email, password });
    } catch (error) {
      setAuthState(AUTH_STATE.NOT_LOGGED_IN);
      console.error(error);
      setAuthError(error.message);
    }
    setLoading(false);
  };

  const loginWithLINEAccessToken = async ({ lineAccessToken }) => {
    setLoading(true);
    try {
      const AuthAPI = new ClientAuthenAPI();
      const { token } = await AuthAPI.getCustomTokenFromLINE(lineAccessToken);
      await authProviderRef.current?.loginWithCustomToken({ token });
    } catch (error) {
      setAuthState(AUTH_STATE.NOT_LOGGED_IN);
      console.error(error);
      setAuthError(error.message);
    }
    setLoading(false);
  };

  const logout = async () => {
    setLoading(true);
    await authProviderRef.current?.logout();
    setLoading(false);
  };

  if (authState === AUTH_STATE.INITIALIZING) {
    return <Loading />;
  }

  const isLogin = authState === AUTH_STATE.LOGGED_IN;

  return (
    <AuthContext.Provider
      value={{
        authState,
        AUTH_STATE,
        loading,
        loginWithEmailAndPassword,
        logout,
        loginWithLINEAccessToken,
        userInfo,
        authError,
        isLogin,
      }}
      {...props}
    />
  );
};

export default AuthProvider;
