import {
  Backdrop,
  BackdropProps,
  ButtonProps,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Typography } from 'Components/Base';
import { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import MarkdownBox from 'Components/MarkdownBox';

enum CONFIRMATION_STATE {
  NONE = 'NONE',
  OPEN = 'OPEN',
}

interface ConfirmationButtonProps extends ButtonProps {
  stType?: string;
  showConfirmation?: boolean;
  loading?: boolean;
}

export function PrivateDataDialog({
  open,
  onConfirm,
  onCancel = () => {
    //
  },
}: {
  open: boolean;
  onCancel?: (e: any) => void;
  onConfirm: (e: any) => void;
}) {
  const [acceptSensitivePolicy, setAcceptSensitivePolicy] = useState(false);
  const handleCheck = () => {
    setAcceptSensitivePolicy(!acceptSensitivePolicy);
  };
  useEffect(() => {
    if (open) {
      setAcceptSensitivePolicy(false);
    }
    return () => {
      //
    };
  }, [open]);

  return (
    <>
      <Dialog maxWidth="xs" open={open}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <IconButton
            aria-label="close"
            onClick={onCancel}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box>
            <MarkdownBox>
              {`กรุณาคลิกปุ่ม “ยินยอม” ด้านล่างเพื่อดำเนินการต่อ หากท่านประสงค์ให้**บริษัท ไลน์ คอมพานี (ประเทศไทย) จำกัด** (“**ไลน์**”) ในฐานะผู้ให้บริการทำการเก็บรวบรวม ใช้หรือเปิดเผยข้อมูลส่วนบุคคลอ่อนไหว อันได้แก่ ข้อมูลพฤติกรรมทางเพศ ข้อมูลเกี่ยวกับสุขภาพและ/หรือข้อมูลทางการแพทย์ เพื่อวัตถุประสงค์ในการให้บริการสินค้าเสริมดวงชะตาของไลน์ เท่านั้น ทั้งนี้  ไลน์จะดำเนินการลบหรือทำลายข้อมูลส่วนบุคคลอ่อนไหวออกจากระบบภายใน 3 วันทำการหลังจากส่งมอบสินค้าเสริมดวงชะตาให้กับท่าน 

ท่านสามารถตรวจสอบสิทธิของท่านในฐานะเจ้าของข้อมูลส่วนบุคคลได้ที่ [นโยบายความเป็นส่วนตัว](https://terms.line.me/line_genlctpp?lang=th)

หากท่านไม่ประสงค์จะให้ยินยอม กรุณากดเครื่องหมาย “X” เพื่อปิดหน้าต่างนี้

ทั้งนี้ ไลน์ขอแจ้งให้ทราบว่า หากท่านไม่ประสงค์ให้ความยินยอมดังกล่าว ไลน์จะเก็บข้อมูลทางเพศเฉพาะเพศชายและเพศหญิง เท่านั้น โดยท่านจะต้องเลือกตามเพศสภาพตามความเป็นจริง สำหรับรายละเอียดข้อมูลส่วนบุคคลอ่อนไหวเรื่องอื่นๆ ไลน์จะไม่เก็บข้อมูลของท่าน`}
            </MarkdownBox>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button
            onClick={onConfirm}
            variant="contained"
            color="primary"
            type="submit"
            size="large"
            stType="gradient"
            fullWidth
          >
            ยินยอม
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default function ConfirmationButton({
  onClick,
  showConfirmation = false,
  loading,
  ...restProps
}: ConfirmationButtonProps) {
  const [confirmationState, setConfirmationState] = useState(CONFIRMATION_STATE.NONE);
  const handleClick = (e) => {
    if (!showConfirmation) {
      if (onClick) onClick(e);
      return;
    }
    e.preventDefault();
    if (confirmationState === CONFIRMATION_STATE.NONE) {
      setConfirmationState(CONFIRMATION_STATE.OPEN);
    }
  };
  const handleCancel = () => {
    setConfirmationState(CONFIRMATION_STATE.NONE);
  };
  const handleOk = (e) => {
    setConfirmationState(CONFIRMATION_STATE.NONE);
    if (onClick) onClick(e);
  };

  return (
    <>
      <PrivateDataDialog
        open={confirmationState === CONFIRMATION_STATE.OPEN}
        onConfirm={handleOk}
        onCancel={handleCancel}
      />
      {loading && (
        <Button {...restProps} disabled={loading}>
          <CircularProgress />
        </Button>
      )}
      {!loading && <Button {...restProps} onClick={handleClick} />}
    </>
  );
}
