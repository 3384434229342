import { Divider } from '@mui/material';
import { Box, Typography } from 'Components/Base';
import React from 'react';

type HeadingProps = {
  title: string;
  compact?: boolean;
  toolbar?: any;
};

export default function Heading({ title, compact, toolbar }: HeadingProps) {
  return (
    <>
      <Box my={1} />
      <Box display="flex" justifyContent={'space-between'}>
        <Typography variant="h3" component="h1">
          {title}
        </Typography>
        {toolbar && toolbar}
      </Box>
      <Box my={1} />
      <Divider />
      {!compact && <Box my={3} />}
      {compact && <Box my={1} />}
    </>
  );
}
