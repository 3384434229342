export interface OrderInfo {
  packageTitle: string;
  fullPrice: number;
}

export enum ATTACHMENT_TYPE {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  TEXT = 'TEXT',
  OBJECT = 'OBJECT',
}
export interface StorageObject {
  url?: string;
  name: string;
  ref: string;
  bucket: string;
  type: ATTACHMENT_TYPE;
}

export interface UploadStorageObject extends StorageObject {
  contents?: string;
  data?: any;
}

export interface StorageObjectInput {
  url?: string;
  name: string;
  ref: string;
  bucket: string;
  type: ATTACHMENT_TYPE;
}

interface PDPAInfoInput {
  acceptTermsAndConditions: boolean;
  acceptPrivacyPolicy: boolean;
}

// graphql types
export enum ZODIAC_YEAR {
  RAT = 'RAT',
  OX = 'OX',
  TIGER = 'TIGER',
  RABBIT = 'RABBIT',
  DRAGON = 'DRAGON',
  SNAKE = 'SNAKE',
  HORSE = 'HORSE',
  SHEEP = 'SHEEP',
  MONKEY = 'MONKEY',
  ROOSTER = 'ROOSTER',
  DOG = 'DOG',
  PIG = 'PIG',
}
interface OrderInfoStandardInput {
  chantName: string;
}

interface ShippingInfoInput {
  shippingName: string;
  shippingAddress: string;
  shippingPhoneNo: string;
  shippingPostcode: string;
  shippingAdditionalData?: string;
}

interface DateUnitPartialInput {
  day: number;
  month: number;
  year?: number;
}

interface DateUnitInput {
  day: number;
  month: number;
  year: number;
}

interface TimeUnitInput {
  hour: number;
  minute: number;
}

interface PackageSelectionInput {
  skuCode: string;
}

interface ZodiacSignInput {
  birthDate: DateUnitPartialInput;
  birthTime?: TimeUnitInput;
  zodiacYear: ZODIAC_YEAR;
  age?: number;
}

interface WishVowInfoInput {
  chantMessage: string;
  birthday?: {
    day: number;
    month: number;
    year: number;
  };
  birthTime?: TimeUnitInput;
  faceImage?: StorageObjectInput;
}

export interface UpdateWishVowOrderInput {
  orderInfo: OrderInfoStandardInput;
  shippingInfo?: ShippingInfoInput;
  wishVowInfo: WishVowInfoInput;
  packageInfo?: PackageSelectionInput;
  pdpaInfo: PDPAInfoInput;
}

export interface UpdateFixBadLuckOrderInput {
  orderInfo: OrderInfoStandardInput;
  shippingInfo?: ShippingInfoInput;
  zodiacInfo: ZodiacSignInput;
  packageInfo?: PackageSelectionInput;
  pdpaInfo: PDPAInfoInput;
}

export interface OrderWallpaperInput {
  email?: string;
  phoneNo: string;
  gender: string;
  birthDay: number;
  birthDate: string;
  birthMonth: number;
  birthYear: number;
  occupation: string;
  topic: string;
  subTopic: string;
}

export interface OrderRMSWallpaperInput {
  email?: string;
  phoneNo: string;
  gender: string;
  birthDay: number;
  birthMonth: number;
  birthYear: number;
  birthTime?: TimeUnitInput;
  lineId: string;
  wallpaperColor: string;
  phoneModel: string;
  topic: string;
}

export interface OrderMTWWallpaperInput {
  email?: string;
  phoneNo: string;
  gender: string;
  birthDay: number;
  birthMonth: number;
  birthYear: number;
  birthTime?: TimeUnitInput;
  phoneModel?: string;
  medicalProblems?: string[];
  fansBirthDay?: {
    day: number;
    month: number;
    year: number;
  };
  option: string;
  topic: string;
  subTopic: string;
  subSubTopic: string;
}

export interface OrderHRSWallpaperInput {
  phoneNo: string;
  gender: string;
  birthDate: string;
  birthDay: number;
  birthMonth: number;
  birthYear: number;
  occupation: string;
  secondaryOccupation: string;
  topic: string;
}

export interface UpdateWallpaperOrderInput {
  orderInfo: OrderInfoStandardInput;
  shippingInfo?: ShippingInfoInput;
  wallpaperInfo: OrderWallpaperInput;
  packageInfo?: PackageSelectionInput;
  pdpaInfo: PDPAInfoInput;
}

export interface UpdateRMSWallpaperOrderInput {
  orderInfo: OrderInfoStandardInput;
  shippingInfo?: ShippingInfoInput;
  wallpaperInfo: OrderRMSWallpaperInput;
  packageInfo?: PackageSelectionInput;
  pdpaInfo: PDPAInfoInput;
}

export interface UpdateMTWWallpaperOrderInput {
  orderInfo: OrderInfoStandardInput;
  shippingInfo?: ShippingInfoInput;
  wallpaperInfo: OrderMTWWallpaperInput;
  packageInfo?: PackageSelectionInput;
  pdpaInfo: PDPAInfoInput;
}

export interface UpdateHRSWallpaperOrderInput {
  orderInfo: OrderInfoStandardInput;
  shippingInfo?: ShippingInfoInput;
  wallpaperInfo: OrderHRSWallpaperInput;
  packageInfo?: PackageSelectionInput;
  pdpaInfo: PDPAInfoInput;
}

export interface FortuneTellingInfoInput {
  phoneNo?: string;
  gender: string;
  birthday: DateUnitInput; // Buddist Year
  birthTime: TimeUnitInput;
  birthPlace?: string;
  faceImage?: StorageObjectInput;
  palmImage?: StorageObjectInput[];
  questions: string[];
  occupation?: string;
  additionalInfo?: any;
}

export interface UpdateFortuneTellingOrderInput {
  orderInfo: OrderInfoStandardInput;
  shippingInfo?: ShippingInfoInput;
  fortuneTellingInfo: FortuneTellingInfoInput;
  packageInfo?: PackageSelectionInput;
  pdpaInfo: PDPAInfoInput;
}

export interface OrderWreathInput {
  phoneNo: string;
  locationPlace: string;
  locationPlaceSpecific: string;
  locationProvince: string;
  deceasedName?: string;
  displayMessage?: String;
}

export interface UpdateWreathOrderInput {
  orderInfo: OrderInfoStandardInput;
  wreathInfo: OrderWreathInput;
  packageInfo?: PackageSelectionInput;
  pdpaInfo: PDPAInfoInput;
}

export interface UpdateShipmentOrderInput {
  shippingInfo: ShippingInfoInput;
  packageInfo?: PackageSelectionInput;
  pdpaInfo: PDPAInfoInput;
}

export interface MeritInfoInput {
  chantMessage?: string;
  chantNames?: string[];
  chantAnonymous?: boolean;
}

export interface UpdateMeritOrderInput {
  orderInfo: OrderInfoStandardInput;
  shippingInfo?: ShippingInfoInput;
  meritInfo: MeritInfoInput;
  packageInfo?: PackageSelectionInput;
  pdpaInfo: PDPAInfoInput;
}
