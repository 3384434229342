import { Box, Button, Typography } from 'Components/Base';
import React, { useState } from 'react';
import PlayIcon from '@mui/icons-material/PlayCircleFilledWhite';
import HelpIcon from '@mui/icons-material/HelpOutline';
import { saveAs } from 'file-saver';
import makeStyles from '@mui/styles/makeStyles';
import { DownloadIcon } from './ImageBox';
import ReactPlayer from 'react-player';

const useStyles = makeStyles((theme) => ({
  videoBox: {
    background: `linear-gradient(314.47deg, #01C1A8 0%, #1DCE59 90.84%)`,
  },
  videoBoxText: {
    color: '#fff',
  },
  videoBoxIcon: {
    color: '#fff',
    fontSize: '3rem',
  },
  videoBoxDownload: {
    background: '#e6e6e6',
  },
}));

export enum VIDEOBOX_MODE {
  CLIENT,
  STORE,
}

const getDownloadUrl = (url) => {
  return `https://download.xn--12cb4eds4a9dsixc.com/download?url=${encodeURIComponent(url)}`;
  // return url;
};

const getNameTitle = (mode: VIDEOBOX_MODE, name?: string) => {
  if (mode === VIDEOBOX_MODE.STORE) return name;

  if (name) {
    if (
      name.startsWith('นาย') ||
      name.startsWith('นาง') ||
      name.startsWith('นางสาว') ||
      name.startsWith('เด็กชาย') ||
      name.startsWith('เด็กหญิง')
    ) {
      return `วิดิโอของ ${name}`;
    }
    return `วิดิโอของคุณ ${name}`;
  }
  return 'วิดิโอของคุณ';
};

const getPackageDate = (packageDate?: string) => {
  if (packageDate) return `วันที่ ${packageDate}`;
  return null;
};

export enum VIDEO_STATE {
  BOX,
  PLAYER,
}

export default function VideoDownloadBox({
  videoUrl,
  name,
  packageDate,
  mode = VIDEOBOX_MODE.CLIENT,
  downloadable = true,
  initialState = VIDEO_STATE.BOX,
}: {
  videoUrl: string;
  name?: string;
  packageDate?: string;
  mode?: VIDEOBOX_MODE;
  downloadable?: boolean;
  initialState?: VIDEO_STATE;
}) {
  const classes = useStyles();
  const [videoBoxState, setVideoBoxState] = useState(initialState);
  const handlePlayButton = () => {
    setVideoBoxState(VIDEO_STATE.PLAYER);
  };
  return (
    <Box className={classes.videoBox} display="flex" flexDirection="column" sx={{ width: '100%' }}>
      {videoBoxState === VIDEO_STATE.BOX && (
        <Box p={2} display="flex" flexDirection="column">
          <Typography className={classes.videoBoxText} variant={'title'}>
            {getNameTitle(mode, name)}
          </Typography>
          <Typography className={classes.videoBoxText} variant={'subtitle1'}>
            {getPackageDate(packageDate)}
          </Typography>
          <Box pt={8}></Box>
          <PlayIcon className={classes.videoBoxIcon} onClick={handlePlayButton} />
        </Box>
      )}
      {videoBoxState === VIDEO_STATE.PLAYER && (
        <Box>
          <ReactPlayer url={videoUrl} controls width={'100%'} />
        </Box>
      )}
      {downloadable && (
        <Box className={classes.videoBoxDownload} display="flex" flexDirection="row-reverse">
          <Button
            startIcon={<DownloadIcon />}
            // onClick={(e) => {
            //   e.preventDefault();
            //   saveAs(videoUrl);
            // }}
            component="a"
            href={getDownloadUrl(videoUrl)}
          >
            บันทึกวิดิโอนี้
          </Button>
          <Button
            startIcon={<HelpIcon color="action" />}
            component="a"
            href={
              'https://sattha-online.freshdesk.com/support/solutions/articles/73000613807-%E0%B8%94%E0%B8%B2%E0%B8%A7%E0%B8%99%E0%B9%8C%E0%B9%82%E0%B8%AB%E0%B8%A5%E0%B8%94%E0%B8%AB%E0%B8%A5%E0%B8%B1%E0%B8%81%E0%B8%90%E0%B8%B2%E0%B8%99%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%97%E0%B8%B3%E0%B8%9E%E0%B8%B4%E0%B8%98%E0%B8%B5%E0%B8%9A%E0%B8%99%E0%B8%A3%E0%B8%B0%E0%B8%9A%E0%B8%9A%E0%B8%9B%E0%B8%8F%E0%B8%B4%E0%B8%9A%E0%B8%B1%E0%B8%95%E0%B8%B4%E0%B8%81%E0%B8%B2%E0%B8%A3-ios'
            }
          >
            วิธีการดาวน์โหลด
          </Button>
        </Box>
      )}
    </Box>
  );
}
