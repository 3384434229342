import React from 'react';
import { Box, Typography } from 'Components/Base';
import { List } from '@mui/material';
import { getTitle, LocationProps } from '../utils';
import { CHANT_MODE, getChantModeText } from 'Domain/Sattha/Chant';

export default function Default({ mode, locationTitle }: LocationProps) {
  return (
    <Box>
      <Typography variant="title">{getTitle(mode, locationTitle)}</Typography>
      {mode === CHANT_MODE.VOW && (
        <Typography>{`ระบุชื่อและนามสกุลให้ชัดเจน แล้วระบุเรื่องที่ได้เคยบนบานไว้ ให้ชัดเจน พร้อมทั้งบอกว่าบัดนี้สมปรารถนาแล้ว จึงนำของ (บอกชื่อสิ่งของที่นำมาถวาย) เพื่อแก้บนด้วยสัจจะอันเป็นที่ตั้ง และขอให้การบนบานครั้งนี้ขาดจากกันนับแต่นี้`}</Typography>
      )}
      {mode === CHANT_MODE.WISH && (
        <Typography>{`ระบุชื่อและนามสกุลให้ชัดเจน ให้ขอพรด้วยใจแน่วแน่ แนะนำว่าให้ขอทีละเรื่อง และบอกกล่าวให้ชัดเจนไม่คลุมเครือ เช่น ถ้าอยากขอให้รวย ก็บอก เจาะจงไปเลยว่าอยากมีรายได้ เดือนละเท่าไหร่ ขายของให้ได้เดือนละเท่าไหร่ ระบุให้ละเอียดและเจาะจง `}</Typography>
      )}
      {mode === CHANT_MODE.FIX_BADLUCK && (
        <Typography>{`ระบุชื่อและนามสกุลให้ชัดเจน ให้ตั้งจิตอธิษฐานให้แน่วแน่ หากไม่ทราบ วัน, เดือน หรือเวลาเกิดให้เลือกหรือกรอกคำว่า ‘ดี’ ลงไปแทน`}</Typography>
      )}
    </Box>
  );
}
