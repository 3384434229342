import { getChantImageDocument, ChantImageDocumentData } from 'Libs/Firebase/firestore/Order';
import { useEffect, useState } from 'react';

export const useGetChantImageSubscriber = (orderDocumentId: string) => {
  const [isExisted, setIsExisted] = useState(false);
  const [data, setData] = useState<ChantImageDocumentData | null>(null);
  useEffect(() => {
    const unsubscribe = getChantImageDocument(orderDocumentId).onSnapshot((update) => {
      if (update.exists) {
        const chantImageData = update.data() as ChantImageDocumentData;
        setData(chantImageData);
        setIsExisted(true);
      }
    });
    return () => {
      unsubscribe();
    };
  }, [orderDocumentId]);

  return { isExisted, data };
};
