import React from 'react';
import { Box, Typography } from 'Components/Base';
import { List } from '@mui/material';
import { LocationProps } from '../utils';
import { CHANT_MODE, getChantModeText } from 'Domain/Sattha/Chant';

export default function HKHungHom({ mode }: LocationProps) {
  if (mode === CHANT_MODE.WISH) {
    return (
      <Box>
        <Typography variant="h4">{`วิธีขอพรเจ้าแม่กวนอิมจากบ้าน`}</Typography>
        <Box mt={1}></Box>
        <b>อุปกรณ์ที่ต้องใช้</b>
        <ul>
          <li>ธูป 3 ดอก (แนะนำว่าควรมี)</li>
          <li>บทสวดข้างบน (กดบันทึกเก็บไว้อ่านได้เลย!)</li>
        </ul>
        <b>ก่อนถึงเวลาที่ร้านค้าจะดำเนินการขอพร</b>
        <ul>
          <li>จุดธูปให้พร้อม</li>
          <li>
            ตั้งจิตอธิษฐานให้มั่น แล้วกล่าวบทสวด, ชื่อ, เรื่องที่อยากขอพร/บนบาน
            พร้อมทั้งบอกของที่ต้องการนำมาแก้บน (ถ้ามี) หากพรสมปรารถนา
          </li>
        </ul>
        เช่น เมื่อข้าพเจ้าสำเร็จแล้วจะกลับนำชุดดอกบัวใหญ่กลับมาถวาย
      </Box>
    );
  }
  if (mode === CHANT_MODE.VOW) {
    return (
      <Box>
        <Typography variant="h4">{`วิธีแก้บนเจ้าแม่กวนอิมจากบ้าน`}</Typography>
        <Box mt={1}></Box>
        {mode === CHANT_MODE.VOW && (
          <>
            <b>อุปกรณ์ที่ต้องใช้</b>
            <ul>
              <li>ธูป 3 ดอก (แนะนำว่าควรมี)</li>
              <li>บทสวดข้างบน (กดบันทึกเก็บไว้อ่านได้เลย!)</li>
            </ul>
            <b>ก่อนถึงเวลาที่ร้านค้าจะดำเนินการแก้บน</b>
            <ul>
              <li>จุดธูปให้พร้อม</li>
              <li>
                ตั้งจิตอธิษฐานให้มั่น แล้วกล่าวบทแก้บน, ชื่อ, เรื่องที่ได้บนบานไว้
                พร้อมบอกกล่าวว่าบัดนี้ด้วยบารมีของเจ้าแม่กวนอิมที่ได้ทำให้สมปรารถนาแล้วทุกประการ
                จึงได้นำของมาถวายเพื่อแก้บนด้วยถือสัจจะเป็นที่ตั้ง
                ขอให้การบนบานครั้งนี้ขาดจากกันนับแต่บัดนี้
              </li>
            </ul>
            เช่น "ข้าพเจ้า นายมานะ ขยันยิ่ง ได้บนบานเรื่องยอดขายของเดือนที่ผ่านมาได้ 100,000
            ตามที่ได้บนบานเอาไว้ ด้วยบารมีของเจ้าแม่กวนอิมที่ทำให้สมปรารถนา
            ข้าพเจ้าจึงได้นำของมาถวายเพื่อแก้บนด้วยถือสัจจะเป็นที่ตั้ง
            ขอให้การบนบานครั้งนี้ขาดจากกันนับแต่บัดนี้ด้วยเทอญ"
          </>
        )}
      </Box>
    );
  }
  return null;
}
