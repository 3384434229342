import { useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import StoreOrderSummaryContainer, { SUMMARY_MODE } from './StoreOrderSummaryContainer';
interface StoreOrderSummaryRouterParams {
  orderSummaryId: string;
  mode?: SUMMARY_MODE;
  storeId: string;
}

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function StoreOrderSummaryRoute() {
  const {
    storeId,
    orderSummaryId,
    mode = SUMMARY_MODE.default,
  } = useParams<keyof StoreOrderSummaryRouterParams>() as StoreOrderSummaryRouterParams;
  const query = useQuery();
  const agentId = query.get('agentId');

  return (
    <>
      <StoreOrderSummaryContainer
        storeId={storeId}
        orderSummaryId={orderSummaryId}
        agentId={agentId}
        mode={mode}
      />
    </>
  );
}
