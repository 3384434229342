import { Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import { Field, useForm } from 'react-final-form';
import { FieldArrayRenderProps } from 'react-final-form-arrays';

interface CheckBoxGroupProps {
  fieldArrayProps: FieldArrayRenderProps<any, HTMLElement>;
  options: { display: string; value: string }[];
  disabled?: boolean;
}

export default function CheckBoxGroup(props: CheckBoxGroupProps) {
  const { fieldArrayProps, options = [], disabled } = props;
  const { fields } = fieldArrayProps;
  const toggle = (event, option) => {
    if (disabled) return;
    if (event.target.checked) fields.push(option);
    else fields.remove(option);
  };
  return (
    <>
      <FormGroup>
        {options.map(({ display, value }) => (
          <FormControlLabel
            key={value}
            control={
              <Checkbox name={value} value={value} onClick={(event) => toggle(event, value)} />
            }
            label={display}
          />
        ))}
      </FormGroup>
    </>
  );
}

interface CheckBoxGroupProps2 {
  options: { display: string; value: string }[];
  disabled?: boolean;
  maxLimit?: number;
  name: string;
}

interface CheckBoxEventTarget extends EventTarget {
  checked: boolean;
}

export function CheckBoxGroup2(props: CheckBoxGroupProps2) {
  const { options = [], disabled, name, maxLimit } = props;
  const form = useForm();
  return (
    <>
      <FormGroup>
        {options.map(({ display, value }) => (
          <FormControlLabel
            key={value}
            control={
              <Field
                key={value}
                name={name}
                type="checkbox"
                value={value}
                render={(props) => (
                  <Checkbox
                    {...props.input}
                    name={value}
                    value={value}
                    size={'small'}
                    onChange={(event) => {
                      const currentState = form.getFieldState(name)?.value ?? [];
                      // console.log('onchange', currentState, maxLimit);
                      if (event.target.checked) {
                        if (maxLimit !== undefined && currentState.length < maxLimit)
                          props.input.onChange(event);
                      } else {
                        props.input.onChange(event);
                      }
                    }}
                  />
                )}
              />
            }
            label={display}
          />
        ))}
      </FormGroup>
    </>
  );
}
