import React from 'react';
import { Button, Container } from 'Components/Base';
import Box from 'Components/Base/Box';
import Loading from 'Components/Loading';
import { useClientGetOrderData } from 'Domain/Orders/hooks';
import { SKU_CHANT_MODE } from 'Libs/Firebase/firestore/Order';
import WallpaperSummaryCard from 'Components/WallpaperSummaryCard';
import WreathSummaryCard from 'Components/WreathSummaryCard';
import { isSingleShipmentOrder } from '../ClientOrderContainer';
import ShippingDetailsBox, {
  OrderShippingDataProps,
} from '../Components/OrderDetails/Components/ShippingDetailsBox';
import ShipmentSummaryCard from 'Components/ShipmentSummaryCard';
import FortuneTellingSummaryCard from 'Components/FortuneTellingSummaryCard';

interface OrderDataConfirmProps {
  orderId: string;
  onEditChantMessage?: () => void;
  onConfirmChantMessage?: () => void;
}

export default function OrderDataConfirmation(props: OrderDataConfirmProps) {
  const {
    orderId,
    onEditChantMessage = () => {
      //
    },
    onConfirmChantMessage = () => {
      //
    },
  } = props;

  const { loading, error, data, refetch } = useClientGetOrderData(orderId);

  return (
    <Box my={2} sx={{ position: 'relative' }}>
      {loading && <Loading />}
      {!loading && data && [SKU_CHANT_MODE.WALLPAPER].includes(data.skuMode) && (
        <WallpaperSummaryCard orderInfo={data} />
      )}
      {!loading && data && [SKU_CHANT_MODE.WREATH].includes(data.skuMode) && (
        <WreathSummaryCard orderInfo={data} />
      )}
      {!loading &&
        data &&
        [SKU_CHANT_MODE.FORTUNE_TELLING, SKU_CHANT_MODE.FORTUNE_TELLING_LIVE].includes(
          data.skuMode
        ) && <FortuneTellingSummaryCard orderInfo={data} />}
      {!loading && data && isSingleShipmentOrder(data) && <ShipmentSummaryCard orderInfo={data} />}
      <Box display="grid" gridTemplateColumns="1fr 1fr" gap="0px 10%" mt={2}>
        <Button variant="outlined" color="primary" size="large" onClick={onEditChantMessage}>
          แก้ไขข้อมูล
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          stType="gradient"
          onClick={onConfirmChantMessage}
          disabled={loading || !data}
        >
          ยืนยันข้อมูล
        </Button>
      </Box>
    </Box>
  );
}
