import React from 'react';
import { Field } from 'react-final-form';
import { FormControl, Link, Checkbox, FormControlLabel } from '@mui/material';

import { Box, Typography } from 'Components/Base';
import { UIFORM_TEXT_FIELDS } from 'Domain/Sattha/Chant';

import getConfig from 'Libs/Config/utils';
import { useConfig } from 'Libs/Config';
const configObj = getConfig();

export function validatePDPA(value: any) {
  const errors: any = {};
  if (!configObj.enablePdpa) return errors;

  if (!value.acceptTerms) {
    errors.acceptTerms = 'กรุณายอมรับเงื่อนไขการให้บริการ เพื่อดำเนินการในขั้นตอนต่อไป';
  }
  if (!value.acceptPrivacy) {
    errors.acceptPrivacy = 'กรุณายอมรับนโยบายความเป็นส่วนตัว เพื่อดำเนินการในขั้นตอนต่อไป';
  }
  return errors;
}

const getPdpaConfig = (pdpaConfig) => {
  const provider = pdpaConfig.provider;
  switch (provider) {
    case 'LINE':
      return {
        title: 'การใช้บริการในร้าน LINE ดูดวง ผู้ใช้บริการ',
        tcUrl: 'https://today.line.me/th/v2/article/M97z1y',
        privacyUrl: 'https://terms.line.me/line_genlctpp?lang=th',
      };

    default:
      return {
        title: 'การใช้บริการในศรัทธาออนไลน์ผู้ใช้บริการ',
        tcUrl: 'https://orders.sattha.online/terms',
        privacyUrl: 'https://orders.sattha.online/privacy',
      };
  }
};

export default function AcceptPDPAForm({ FORM_TEXT }: { FORM_TEXT: any }) {
  const config = useConfig();
  if (!config.enablePdpa) return null;
  const pdpaConfig = getPdpaConfig(config.pdpa);
  return (
    <>
      <Box mt={3} />
      <Typography variant="title">{pdpaConfig.title}</Typography>
      <FormControl fullWidth>
        <Field
          name="acceptTerms"
          type="checkbox"
          render={(props) => (
            <FormControlLabel
              control={<Checkbox {...props.input} />}
              label={
                <div>
                  ยอมรับ
                  <Link href={pdpaConfig.tcUrl} target="_blank" rel="noopener">
                    ข้อกำหนดและเงื่อนไขการใช้บริการ
                  </Link>
                </div>
              }
            />
          )}
        />
      </FormControl>
      <FormControl fullWidth>
        <Field
          name="acceptPrivacy"
          type="checkbox"
          render={(props) => (
            <FormControlLabel
              control={<Checkbox {...props.input} />}
              label={
                <div>
                  รับทราบ
                  <Link href={pdpaConfig.privacyUrl} target="_blank" rel="noopener">
                    นโยบายความเป็นส่วนตัว
                  </Link>
                </div>
              }
            />
          )}
        />
      </FormControl>
      <Box mt={1} />
    </>
  );
}
