import {
  FormControl,
  TextField,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Button,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { useState } from 'react';
import { Field, useForm } from 'react-final-form';
import { FormGeneratorInputTypeChantNameMultiple } from '../types';
import { Box, Typography } from 'Components/Base';
import { Clear, RemoveOutlined } from '@mui/icons-material';

const canAddName = (data: string[] = []) => {
  const filteredData = data.filter(
    (d) => d !== null || d !== undefined || (d as string).trim() !== ''
  );
};

export interface ChantNameMultipleInputData {
  names: string[];
  isAnonymous: boolean;
}

export default function ChantNameMultipleInput({
  name,
  label = 'ชื่อ-นามสกุล',
  placeholder = 'กรอกชื่อและนามสกุล',
  tips = 'Tips: กรุณากรอกชื่อจริงเพื่อใช้ในการทำพิธี',
  allowAnonymous = false,
  allowMultiple = false,
  anonymousLabel = 'ไม่ประสงค์ออกนาม',
  multipleMax = 5,
}: FormGeneratorInputTypeChantNameMultiple) {
  const form = useForm();
  const chantNames = form.getState().values[name] ?? { isAnonymous: false, names: [] };
  const chantIsAnonymous = chantNames?.isAnonymous ?? false;
  const chantNameLength = chantNames?.names?.length;
  const fieldsIndexLength = chantNameLength > 0 ? chantNameLength : 1;

  const handleAnonymousChanged = () => {
    // TODO: If is anonymous, then change name should be reset!
    if (!chantIsAnonymous) {
      form.change(`${name}.names`, []);
    } else {
      form.change(`${name}.names`, []);
    }
  };
  const handleChangeNameChanged = (input) => {
    form.change(input.target.name, input.target.value);
  };
  const handleAddNameButtonClicked = () => {
    const chantNames = form.getState().values[name]?.names ?? [];
    const chantNameLength = form.getState().values[name]?.names?.length;
    // TODO: Check if some data is empthy or not?
    form.change(`${name}.names[${chantNames.length}]`, null);
  };
  const handleRemoveName = (idx) => {
    const nameData = form.getState().values[name]?.names ?? [];
    const nameDataTmp = [...nameData];
    nameDataTmp.splice(idx, 1);
    form.change(`${name}.names`, nameDataTmp);
  };

  // console.log(chantNames?.names, fieldsIndexLength);
  return (
    <>
      {chantIsAnonymous && (
        <>
          <TextField
            label={label}
            variant="outlined"
            placeholder={placeholder}
            fullWidth
            InputProps={{
              startAdornment: <div />,
            }}
            inputProps={{ maxLength: 60 }}
            disabled
            value={anonymousLabel}
          />
          <Box mt={1} />
        </>
      )}
      {!chantIsAnonymous &&
        Array.from(Array(fieldsIndexLength)).map((_, idx) => (
          <FormControl fullWidth key={`${name}.names[${idx}]`}>
            <Field
              name={`${name}.names[${idx}]`}
              render={(props) => (
                <>
                  <TextField
                    {...props.input}
                    label={idx === 0 ? label : undefined}
                    variant="outlined"
                    placeholder={placeholder}
                    fullWidth
                    InputProps={{
                      startAdornment: <div />,
                      endAdornment:
                        idx !== 0 ? (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => {
                                handleRemoveName(idx);
                              }}
                              edge="end"
                            >
                              {<Clear />}
                            </IconButton>
                          </InputAdornment>
                        ) : null,
                    }}
                    inputProps={{ maxLength: 60 }}
                    helperText={
                      props.meta.error && props.meta.touched ? (
                        <FormHelperText error>{props.meta.error}</FormHelperText>
                      ) : fieldsIndexLength === 1 ? (
                        tips
                      ) : undefined
                    }
                    onChange={handleChangeNameChanged}
                  />
                  <Box mt={1} />
                </>
              )}
            />
          </FormControl>
        ))}
      {(allowAnonymous || allowMultiple) && (
        <Box display="flex" flexDirection="row" justifyContent={'space-between'}>
          {allowAnonymous && (
            <FormControl>
              <Field
                name={`${name}.isAnonymous`}
                type="checkbox"
                render={(props) => (
                  <FormControlLabel
                    control={
                      <Checkbox {...props.input} onClick={handleAnonymousChanged} size={'small'} />
                    }
                    label={anonymousLabel}
                  />
                )}
              />
            </FormControl>
          )}
          {allowMultiple && !chantIsAnonymous && fieldsIndexLength < multipleMax && (
            <Button onClick={handleAddNameButtonClicked}>เพิ่มชื่อ</Button>
          )}
        </Box>
      )}
    </>
  );
}
