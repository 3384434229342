import React from 'react';
import { Form } from 'react-final-form';
import { Paper, FormHelperText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import arrayMutators from 'final-form-arrays';

import { Box, Button, Typography } from 'Components/Base';
import { CHANT_MODE, getUserInfoForm, UIFORM_TEXT_FIELDS } from 'Domain/Sattha/Chant';
import {
  AvailableSkuItem,
  OrderShippingData,
  SkuFortuneTellingData,
} from 'Libs/Firebase/firestore/Order';
import ShippingForm from '../Components/ShippingForm';
import { HiddenFieldForm, HiddenSkuCodeFieldForm } from '../Components/HiddenFieldForm';
import { shippingValidations } from '../utils/validations';
import AcceptPDPAForm, { validatePDPA } from '../Components/AcceptPDPAForm';
import ErrorBox from '../Components/ErrorBox';
import ConfirmationButton from '../Components/ConfirmationButton';
import FortuneTellingHoroForm, { validations } from '../Components/FortuneTellingHoroForm';
import { StorageObject } from 'Domain/Orders/types';
import { convertToStorageObject, UploadedFileData } from 'Containers/ImageUploadBox/ImageUploadBox';

import {
  INPUT_MODE as MANKARIN_INPUT_MODE,
  validations as mankarinValidations,
} from '../Input/FTMankarinBox';

const useStyles = makeStyles((theme) => ({
  hiddenField: {
    display: 'none',
  },
  gradientButton: {
    background: `linear-gradient(314.47deg, #01C1A8 0%, #1DCE59 90.84%)`,
    borderRadius: 2,
    color: 'white',
    width: '100%',
    '&:disabled': {
      color: `#969696`,
      background: '#E6E6E6',
    },
  },
}));

export interface UserInfoFormDataFortuneTelling {
  orderId: string;
  mode: CHANT_MODE;
  chantName: string;
  shippingName?: string;
  shippingAddress?: string;
  shippingPhoneNo?: string;
  shippingPostcode?: string;
  skuCode?: string;
  phoneNo?: string;
  gender: string;
  birthday: {
    day: number;
    month: number;
    year: number;
  };
  birthTime?: string;
  birthPlace?: string;
  facePicture?: StorageObject;
  palmPicture?: StorageObject[];
  questions: string[];
  occupation?: string;
  additionalFtInfo?: any;

  acceptTerms: boolean;
  acceptPrivacy: boolean;
}

export interface UserInfoFormInitialData {
  chantName?: string;
  chantMessage?: string;
}

interface CreateFortuneTellingOrderFormProps extends SkuFortuneTellingData {
  onSubmit?: (formValues: UserInfoFormDataFortuneTelling) => void;
  orderId: string;
  orderRealId: string;
  locationInstructions?: string;
  locationId: string;
  isLoading: boolean;
  mode: CHANT_MODE;
  initialData?: UserInfoFormInitialData;
  haveShipping?: boolean;
  shippingData?: OrderShippingData;
  showPackageSelection: boolean;
  enablePackageSelection: boolean;
  skuMasterCode?: string;
  availableSkus?: AvailableSkuItem[];
  skuCode?: string;
}

enum ORDER_MODE {
  FULFILLMENT = 'FULFILLMENT',
  SHIPPING = 'SHIPPING',
}

export default function FortuneTellingForm(props: CreateFortuneTellingOrderFormProps) {
  const classes = useStyles();

  const {
    orderId,
    orderRealId,
    onSubmit = () => {
      /** */
    },
    locationId,
    isLoading,
    mode,
    initialData,
    haveShipping,
    shippingData,
    showPackageSelection,
    enablePackageSelection,
    skuMasterCode,
    availableSkus,
    questions,
    requiredPalmPicture,
    requiredBirthPlace,
    hideFacePicture,
    additionalInputMode,
    skuCode,
  } = props;

  const FORM_TEXT = getUserInfoForm(mode);
  const { chantName, chantMessage } = initialData ?? {};

  const orderMode = haveShipping ? ORDER_MODE.SHIPPING : ORDER_MODE.FULFILLMENT;

  let newInitialData: any = { orderId, mode, chantMessage, chantName };

  if (shippingData) {
    newInitialData = { ...newInitialData, ...shippingData };
  }

  if (showPackageSelection && skuMasterCode && availableSkus && availableSkus?.length > 0) {
    const skuCode = availableSkus[0].skuCode;
    const newData = { skuCode };
    newInitialData = { ...newInitialData, ...newData };
  } else if (skuCode) {
    const newData = { skuCode };
    newInitialData = { ...newInitialData, ...newData };
  }

  const handleSubmit = (values: UserInfoFormDataFortuneTelling, ...restArgs) => {
    // console.log('handleSubmit', values);
    const { facePicture, palmPicture, ...restValue } = values;
    const newValues: UserInfoFormDataFortuneTelling = { ...restValue };
    if (facePicture) {
      const facePictureParsed = JSON.parse(facePicture as unknown as string);
      if (facePictureParsed.length > 0) {
        newValues.facePicture = convertToStorageObject(facePictureParsed[0]);
      }
    }
    if (palmPicture) {
      const palmPictureParsed = JSON.parse(palmPicture as unknown as string);
      if (palmPictureParsed.length > 0) {
        newValues.palmPicture = palmPictureParsed.map((p) =>
          convertToStorageObject(p as unknown as UploadedFileData)
        );
      }
    }
    if (newValues.questions) {
      newValues.questions = newValues.questions.filter(
        (q) => q !== null && q !== undefined && q.trim().length > 0
      );
    } else {
      newValues.questions = [];
    }

    onSubmit(newValues);
  };

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={newInitialData}
        mutators={{
          // potentially other mutators could be merged here
          ...arrayMutators,
        }}
        validate={(values) => {
          const errors: { [k: string]: any } = {};
          if (!values.skuCode) {
            errors.skuCode = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_SELECT_PACAKGE];
          }
          if (!values.chantName) {
            errors.chantName = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_NAME_REQUIRED];
          }

          const formValidations = validations(values, FORM_TEXT, questions, {
            requiredPalmPicture,
            requiredBirthPlace,
          });
          Object.assign(errors, formValidations);

          // If order has shipping bundle
          if (orderMode === ORDER_MODE.SHIPPING) {
            const shippingErrors = shippingValidations(values, FORM_TEXT);
            Object.assign(errors, shippingErrors);
          }

          if (additionalInputMode) {
            if (additionalInputMode === MANKARIN_INPUT_MODE) {
              const mankarinErrors = mankarinValidations(values);
              Object.assign(errors, mankarinErrors);
            }
          }

          const pdpaErrors = validatePDPA(values);
          Object.assign(errors, pdpaErrors);

          console.log('validations', errors);
          return errors;
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => {
          console.log(values);
          return (
            <form onSubmit={handleSubmit}>
              <HiddenFieldForm />
              <Paper elevation={0} style={{ padding: 16 }}>
                <HiddenSkuCodeFieldForm />
                <Typography variant="h3">{FORM_TEXT[UIFORM_TEXT_FIELDS.HEADER_NAME]}</Typography>
                <FortuneTellingHoroForm
                  mode={mode}
                  locationId={locationId}
                  FORM_TEXT={FORM_TEXT}
                  totalQuestions={questions}
                  requiredPalmPicture={requiredPalmPicture}
                  requiredBirthPlace={requiredBirthPlace}
                  hideFacePicture={hideFacePicture}
                  orderRealId={orderRealId}
                  additionalInputMode={additionalInputMode}
                />
                <Box my={5}></Box>
                {orderMode === ORDER_MODE.SHIPPING && (
                  <>
                    <Typography variant="h3">
                      {FORM_TEXT[UIFORM_TEXT_FIELDS.HEADER_SHIPPING]}
                    </Typography>
                    <Box my={3}></Box>
                    <ShippingForm FORM_TEXT={FORM_TEXT} />
                    <Box my={5}></Box>
                  </>
                )}
                <AcceptPDPAForm FORM_TEXT={FORM_TEXT} />
                {form.getState().hasValidationErrors && (
                  <>
                    <ErrorBox errors={form.getState().errors} />
                    <Box my={1}></Box>
                  </>
                )}
                <ConfirmationButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="large"
                  stType="gradient"
                  fullWidth
                  onClick={handleSubmit}
                  disabled={form.getState().hasValidationErrors || isLoading}
                  loading={isLoading}
                >
                  {orderMode === ORDER_MODE.FULFILLMENT
                    ? FORM_TEXT[UIFORM_TEXT_FIELDS.CONFIRM_BUTTON]
                    : FORM_TEXT[UIFORM_TEXT_FIELDS.CONFIRM_BUTTON_SHIPPING]}
                </ConfirmationButton>
              </Paper>
            </form>
          );
        }}
      ></Form>
    </>
  );
}
