import { gql } from '@apollo/client';

export const getCampaignOrder = gql`
  query getCampaignOrder($orderId: ID!) {
    orderCampaigns(orderId: $orderId) {
      id
      campaignCode
      status
    }
  }
`;

export const getWallpaperCampaignOrder = gql`
  query getWallpaperCampaign2021Order($orderId: ID!) {
    orderCampaignWallpaper(orderId: $orderId) {
      id
      campaignCode
      status
      availableWallpapers {
        id
        name
        previewImageUrl
      }
      wallpaperImageUrl
    }
  }
`;

export const selectWallpaperCampaignOrder = gql`
  mutation selectWallpaperCampaignOrder($orderId: ID!, $wallpaperId: ID!) {
    selectOrderCampaignWallpaper2021(input: { orderId: $orderId, wallpaperId: $wallpaperId }) {
      ... on SelectOrderCampaignWallPaperSuccessResponse {
        __typename
        orderCampaignWallpaper {
          id
          campaignCode
          status
          availableWallpapers {
            id
            name
            previewImageUrl
          }
          wallpaperImageUrl
        }
      }
      ... on MutationErrorResponse {
        message
        code
      }
    }
  }
`;
