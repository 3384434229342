import React from 'react';
import { Field } from 'react-final-form';
import { FormControl, TextField, FormHelperText } from '@mui/material';

import { Box, Typography } from 'Components/Base';
import { UIFORM_TEXT_FIELDS } from 'Domain/Sattha/Chant';

export default function ShippingForm({ FORM_TEXT }: { FORM_TEXT: any }) {
  return (
    <>
      <FormControl fullWidth>
        <Field
          name="shippingName"
          render={(props) => (
            <TextField
              {...props.input}
              label={FORM_TEXT[UIFORM_TEXT_FIELDS.SHIPPING_NAME]}
              variant="outlined"
              placeholder="กรอกชื่อ - นามสกุล สำหรับการจัดส่ง"
              fullWidth
              InputProps={{
                startAdornment: <div />,
              }}
              inputProps={{ maxLength: 60 }}
              helperText={
                props.meta.error && props.meta.touched ? (
                  <FormHelperText error>{props.meta.error}</FormHelperText>
                ) : null
              }
            />
          )}
        />
      </FormControl>
      <Box my={3}></Box>
      <FormControl fullWidth>
        <Field
          name="shippingAddress"
          render={(props) => (
            <TextField
              {...props.input}
              label={FORM_TEXT[UIFORM_TEXT_FIELDS.SHIPPING_ADDRESS]}
              variant="outlined"
              fullWidth
              multiline
              placeholder={'กรุณากรอกที่อยู่สำหรับการจัดส่ง'}
              rows={4}
              InputProps={{
                startAdornment: <div />,
              }}
              inputProps={{ maxLength: 300 }}
              helperText={
                props.meta.error && props.meta.touched ? (
                  <FormHelperText error>{props.meta.error}</FormHelperText>
                ) : null
              }
            />
          )}
        />
      </FormControl>
      <Typography variant="subtitle2" color="grey" mt={1}>
        {'Tips: เพื่อความถูกต้องในการจัดส่ง กรุณาระบุตำบล/อำเภอ หรือ แขวง/เขต'}
      </Typography>
      <Box my={3}></Box>
      <FormControl fullWidth>
        <Field
          name="shippingPostcode"
          render={(props) => (
            <TextField
              {...props.input}
              label={FORM_TEXT[UIFORM_TEXT_FIELDS.SHIPPING_POSTCODE]}
              variant="outlined"
              placeholder="รหัสไปรษณีย์"
              fullWidth
              InputProps={{
                startAdornment: <div />,
              }}
              inputProps={{ maxLength: 5, minLength: 5 }}
              helperText={
                props.meta.error && props.meta.touched ? (
                  <FormHelperText error>{props.meta.error}</FormHelperText>
                ) : null
              }
            />
          )}
        />
      </FormControl>
      <Box my={3}></Box>
      <FormControl fullWidth>
        <Field
          name="shippingPhoneNo"
          render={(props) => (
            <TextField
              {...props.input}
              label={FORM_TEXT[UIFORM_TEXT_FIELDS.SHIPPING_PHONENO]}
              variant="outlined"
              placeholder={'เบอร์โทรศัพท์สำหรับการติดต่อเพื่อการจัดส่ง'}
              fullWidth
              InputProps={{
                startAdornment: <div />,
              }}
              inputProps={{ maxLength: 20 }}
              helperText={
                props.meta.error && props.meta.touched ? (
                  <FormHelperText error>{props.meta.error}</FormHelperText>
                ) : null
              }
            />
          )}
        />
      </FormControl>
      <Box my={1}></Box>
      <Typography variant="info">
        {'* กรุณาตรวจสอบข้อมูลการจัดส่งให้ถูกต้อง ทางเราจะไม่รับผิดชอบหากกรอกที่อยู่ผิด'}
      </Typography>
      <Box my={1}></Box>
    </>
  );
}
