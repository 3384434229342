export const TRACKING_PROVIDERS = [
  { name: 'Kerry', value: 'KERRY' },
  { name: 'Thailand Post - EMS', value: 'TP-EMS' },
  { name: 'Thailand Post - Registered mail', value: 'TP-REG' },
  { name: 'Best Express', value: 'BEST' },
  { name: 'DHL', value: 'DHL' },
  { name: 'Alpha Fast', value: 'ALP' },
  { name: 'Ninja Van', value: 'NINJA' },
  { name: 'J&T', value: 'JT' },
  { name: 'SCG Express', value: 'SCG' },
  { name: 'Flash', value: 'FLASH' },
  { name: 'NIM Express', value: 'NIM' },
];

const TRACKING_PROVIDERS_MAPPING: { [key: string]: string } = {};
TRACKING_PROVIDERS.forEach((p) => {
  TRACKING_PROVIDERS_MAPPING[p.name] = p.value;
});

const TRACKING_PROVIDERS_REVERSE_MAPPING: { [key: string]: string } = {};
TRACKING_PROVIDERS.forEach((p) => {
  TRACKING_PROVIDERS_REVERSE_MAPPING[p.value] = p.name;
});

export { TRACKING_PROVIDERS_MAPPING, TRACKING_PROVIDERS_REVERSE_MAPPING };
