import React from 'react';
import { Box, Typography } from 'Components/Base';
import { List } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { LocationProps } from '../utils';
import { CHANT_MODE, getChantModeText } from 'Domain/Sattha/Chant';

const WISH_TEXT = `### วิธีตักบาตรออนไลน์จากบ้าน  
  
**อุปกรณ์ที่ต้องใช้**  
* ที่กรวดน้ำ (หากไม่มีสามารถใช้ภาชนะอื่นๆแทนได้)
* บทคำอธิษฐานอุทิศส่วนบุญส่วนกุศลให้ (กดบันทึกเก็บไว้อ่านได้เลย!)
* เตรียมอุปกรณ์ให้พร้อม
* เมื่อทีมงานนำใส่บาตรให้วัดเรียบร้อย จะส่งภาพและวิดิโอคลิปมายังผู้ตักบาตร ตั้งจิตอธิษฐานให้มั่น แล้วกล่าวบทคำอธิษฐานอุทิศส่วนบุญส่วนกุศลให้, ชื่อ, เรื่องที่อยากอธิษฐาน
* กดดูคลิปหรือรูปการตักบาตร ผู้ถวายอธิษฐานจิตกรวดน้ำ อุทิศบุญที่บ้านของคุณ พร้อมกล่าวบทกรวดน้ำดังนี้

"อิทัง เม ญาตีนัง โหตุ สุขิตา โหนตุ ญาตะโย ขอบุญนี้ จงสำเร็จแก่ญาติทั้งหลายของข้าพเจ้าเถิด ขอญาติทั้งหลายของข้าพเจ้าจงมีความสุข สุขใจเถิดฯ"`;

const VOW_TEXT = ``;

export default function LBC({ mode }: LocationProps) {
  if (mode === CHANT_MODE.WISH) {
    return (
      <Box>
        <ReactMarkdown>{WISH_TEXT}</ReactMarkdown>
      </Box>
    );
  }
  return (
    <Box>
      <ReactMarkdown>{VOW_TEXT}</ReactMarkdown>
    </Box>
  );
}
