import axios, { AxiosInstance } from 'axios';

import { getHeaders as getAuthHeader } from '../api';

const API_URL =
  process.env.REACT_APP_BACKOFFICE_API_URL ||
  'https://asia-southeast2-sattha-online-orders.cloudfunctions.net/feOrders/backoffice';

export enum APPROVAL_TYPE {
  APPROVE = 'approve',
  REJECT = 'reject',
}
class BackofficeAPI {
  private instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      baseURL: API_URL,
    });
  }

  async getHeaders() {
    return getAuthHeader();
  }

  /**
   *
   * @param storeId - Store's id
   * @param orderId - OrderId field, not order's id field
   * @returns
   */
  async getOrderSummary(orderSummaryId: string) {
    const headers = await this.getHeaders();
    const result = await this.instance.get(`/orders-summary/${orderSummaryId}`, {
      headers,
    });
    return result.data;
  }

  async getOrderSummaries() {
    const headers = await this.getHeaders();
    const result = await this.instance.get(`/orders-summary`, {
      headers,
    });
    return result.data;
  }

  async getOrder(orderId: string) {
    const headers = await this.getHeaders();
    const result = await this.instance.get(`/orders/${orderId}`, {
      headers,
    });
    return result.data;
  }

  async approveOrder(orderId: string, approvalType: APPROVAL_TYPE) {
    const headers = await this.getHeaders();
    const result = await this.instance.post(
      `/orders/${orderId}/approval`,
      { approvalType },
      {
        headers,
      }
    );
    return result.data;
  }

  async createOrderSummary(packageDate: string) {
    const headers = await this.getHeaders();
    await this.instance.post(
      `/orders-summary`,
      {
        packageDate,
      },
      {
        headers,
      }
    );
  }

  async shipmentUploadfile(file: File) {
    const headers = await this.getHeaders();
    let formData = new FormData();

    formData.append('file', file);

    return this.instance.post('/shipments/upload', formData, {
      headers: {
        ...headers,
        'Content-Type': 'multipart/form-data',
      },
      // onUploadProgress,
    });
  }

  async getAllPendingWreathOrders() {
    const headers = await this.getHeaders();
    const result = await this.instance.get(`/wreath-orders`, {
      headers,
    });
    return result.data;
  }
}

export default BackofficeAPI;
