import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import {
  FormControl,
  Typography,
  Radio,
  RadioGroup,
  TextField,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { Box } from 'Components/Base';
import ChineseYearLib from 'chinese-year';
import { ANIMAL_MAPPING } from './ChineseYear';
import { getDefaultNormalizer } from '@testing-library/dom';

enum INPUT_STATE {
  INITIAL,
  SET_YEAR,
  SET_YEAR_GOOD,
}

const now = dayjs();
const currentYear = now.locale('en').year() + 543;

const getChineseYear = (thaiYear: number) => {
  return ANIMAL_MAPPING[ChineseYearLib.getAnimal(thaiYear - 543)];
};

const availableYears = Array.from(Array(120)).map((_, i) => ({
  display: `${currentYear - i} (${getChineseYear(currentYear - i)})`,
  value: currentYear - i,
}));

type ZodiacYearData = {
  zodiacYear: string;
  birthYear: number;
  zodiacAge: number;
};

const convertThaiYearToZodiacYearData = (thaiYear): ZodiacYearData => {
  const year = thaiYear - 543;
  const zodiacYear = ChineseYearLib.getAnimal(year);
  const zodiacAge = currentYear - thaiYear + 1;
  return {
    zodiacYear,
    zodiacAge,
    birthYear: year,
  };
};

export default function ChineseZodiacYearInput({
  label,
  value,
  onBlur,
  onChange,
  placeholder,
  ...restProps
}: {
  label?: string;
  value: any;
  onBlur?: any;
  onChange?: any;
  placeholder?: any;
  [key: string]: any;
}) {
  const [inputState, setInputState] = useState(INPUT_STATE.INITIAL);
  const [inputYearValue, setInputYearValue] = useState<ZodiacYearData | null>(null);

  useEffect(() => {
    // parse value to internal data
    if (value) {
      setInputYearValue(JSON.parse(value));
    } else {
      // setInputYearValue(convertThaiYearToZodiacYearData(currentYear));
    }
    return () => {
      // cleanup
    };
  }, [value]);

  const handleYearChange = (event) => {
    const thaiYear = event.target.value;
    const zodiacData = convertThaiYearToZodiacYearData(thaiYear);
    console.log(zodiacData);
    onChange(JSON.stringify(zodiacData));
  };
  return (
    <Box>
      {label && (
        <Box mt={2}>
          <InputLabel disableAnimation style={{ marginTop: -28 }}>
            {label}
          </InputLabel>
          <Box mt={2}></Box>
        </Box>
      )}
      <Box>
        <FormControl fullWidth>
          {/* <InputLabel id="year-select-label">เลือกปีเกิด</InputLabel>
          <Box mt={2}></Box> */}
          <Select
            id="year-select"
            labelId="year-select-label"
            variant="outlined"
            value={inputYearValue ? (inputYearValue.birthYear + 543).toString() : ''}
            onChange={handleYearChange}
            fullWidth
          >
            {placeholder && (
              <MenuItem value="" disabled>
                {placeholder}
              </MenuItem>
            )}
            {availableYears.map((year) => (
              <MenuItem key={year.value} value={year.value}>
                {year.display}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
}
