import React from 'react';

import { Navigate, Route, RouteProps, useLocation } from 'react-router-dom';

import { useAuthContext } from './hooks';
import { AUTH_STATE } from './types';

export default function RequireAuth({
  children,
  ...props
}: {
  children: JSX.Element;
  redirectTo: string;
  useUrlParams?: boolean;
  replace?: boolean;
}) {
  const { redirectTo = '/login', useUrlParams = false, replace = false, ...restProps } = props;
  const authContext = useAuthContext();
  let location = useLocation();
  const searchParams = new URLSearchParams();
  searchParams.set('liffRedirectTo', location.pathname);
  searchParams.set('liffSearch', location.search);

  if (authContext.authState !== AUTH_STATE.LOGGED_IN) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    if (!useUrlParams) {
      return <Navigate to={redirectTo} state={{ from: location }} replace={replace} />;
    } else {
      return <Navigate to={`${redirectTo}?${searchParams.toString()}`} replace={replace} />;
    }
  }

  return children;
}
