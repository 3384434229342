import React from 'react';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ApolloProvider } from '@apollo/client';

import AppBar from 'Components/Layout/AppBar';
import theme from 'Components/Base/Theme';
import { Box } from 'Components/Base';

import AuthProvider from 'Domain/auth/AuthProvider';

import apolloClient from './Libs/GraphQL';

import RoutesApp from './routes';
import ConfigProvider from 'Libs/Config';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const queryClient = new QueryClient();

function App(): JSX.Element {
  return (
    <ConfigProvider>
      <ApolloProvider client={apolloClient}>
        <QueryClientProvider client={queryClient}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <AuthProvider>
                <>
                  <AppBar />
                  <RoutesApp />
                  <Box mt={5} />
                </>
              </AuthProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </QueryClientProvider>
      </ApolloProvider>
    </ConfigProvider>
  );
}

export default App;
