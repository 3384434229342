import React from 'react';
import { Form, Field } from 'react-final-form';
import { Paper, FormControl, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VideoLibIcon from '@mui/icons-material/VideoLibrary';

import { Box, Button, Typography } from 'Components/Base';
import MarkdownBox from 'Components/MarkdownBox';

import { ImageBox } from 'Components/Base/Box';
import {
  ATTACHMENT_TYPE,
  ORDER_TYPE,
  SatthaOrder,
  SKU_CHANT_MODE,
} from 'Libs/Firebase/firestore/Order';
import { CompletedBox } from './Components';
import { StoreUploadOrderSuccessProps } from './types';
import OrderDetailsGeneral from './OrderDetailsGeneral';
import OrderDetailsFortuneTelling from './OrderDetailsFortuneTelling';

const useStyles = makeStyles((theme) => ({
  hiddenField: {
    display: 'none',
  },
  gradientButton: {
    background: `linear-gradient(314.47deg, #01C1A8 0%, #1DCE59 90.84%)`,
    borderRadius: 2,
    color: 'white',
    width: '100%',
    '&:disabled': {
      color: `#969696`,
      background: '#E6E6E6',
    },
  },
}));

export interface StoreOrderFormData {
  orderId: string;
  uploadedImagesData: string;
}

type StoreOrderFormProps = {
  orderRefId?: string;
  orderId: string;
  storeId: string;
  customerName?: string;
  packageId?: string;
  packageName?: string;
  attachmentList?: {
    url: string;
    type: ATTACHMENT_TYPE;
  }[];
};

enum FORM_MODE {
  FORTUNE_TELLING = 'FORTUNE_TELLING',
  GENERAL = 'GENERAL',
  SHIPMENT = 'SHIPMENT',
}

const getOrderDetailsMode = (order: SatthaOrder) => {
  if (order.skuMode === SKU_CHANT_MODE.FORTUNE_TELLING) return FORM_MODE.FORTUNE_TELLING;
  if (order.type === ORDER_TYPE.SHIPMENT) return FORM_MODE.SHIPMENT;
  return FORM_MODE.GENERAL;
};

export default function StoreOrderForm(props: StoreUploadOrderSuccessProps) {
  const classes = useStyles();
  const formMode = getOrderDetailsMode(props.orderData);
  return (
    <>
      <Form
        onSubmit={() => {
          /**/
        }}
        initialValues={{
          ...props.orderData,
        }}
        validate={(values) => {
          const errors: { [k: string]: any } = {};
          return errors;
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Paper elevation={0} style={{ padding: 16 }}></Paper>
              {formMode === FORM_MODE.GENERAL && <OrderDetailsGeneral {...props} />}
              {formMode === FORM_MODE.FORTUNE_TELLING && <OrderDetailsFortuneTelling {...props} />}
            </form>
          );
        }}
      ></Form>
    </>
  );
}
