import React from 'react';
import { CircularProgress } from '@mui/material';

import { Box } from 'Components/Base';

export default function Loading() {
  return (
    <Box display="flex" alignContent="center" alignItems="center" flexDirection="column" my={3}>
      <CircularProgress />
    </Box>
  );
}
