import React from 'react';
import { Form, Field } from 'react-final-form';
import { Paper, FormHelperText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { Box, Button, Typography } from 'Components/Base';
import { CHANT_MODE, getUserInfoForm, UIFORM_TEXT_FIELDS } from 'Domain/Sattha/Chant';
import {
  AvailableSkuItem,
  OrderMeritData,
  OrderShippingData,
  SkuMeritData,
  SkuWishVowData,
  SKU_CHANT_MODE,
} from 'Libs/Firebase/firestore/Order';
import SelectPackageForm from '../../Components/SelectPackageForm';
import ShippingForm from '../../Components/ShippingForm';
import { HiddenFieldForm, HiddenSkuCodeFieldForm } from '../../Components/HiddenFieldForm';
import AcceptPDPAForm, { validatePDPA } from '../../Components/AcceptPDPAForm';
import ErrorBox from '../../Components/ErrorBox';
import ConfirmationButton from '../../Components/ConfirmationButton';
import { FieldsGenerator } from '../../FormGenerator';
import { getInitialData, getTemplate, getValidations } from './MeritFormData';
import { ChantNameMultipleInputData } from 'Components/UserInfoForm/FormGenerator/Input/ChantNameMultipleInput';

const useStyles = makeStyles((theme) => ({
  hiddenField: {
    display: 'none',
  },
  gradientButton: {
    background: `linear-gradient(314.47deg, #01C1A8 0%, #1DCE59 90.84%)`,
    borderRadius: 2,
    color: 'white',
    width: '100%',
    '&:disabled': {
      color: `#969696`,
      background: '#E6E6E6',
    },
  },
}));

export interface UserInfoFormData {
  orderId: string;
  mode: CHANT_MODE;
  chantName: string;
  chantMessage: string;
  shippingName?: string;
  shippingAddress?: string;
  shippingPhoneNo?: string;
  shippingPostcode?: string;
  skuCode?: string;

  birthday?: {
    day: number;
    month: number;
    year: number;
  };

  acceptTerms: boolean;
  acceptPrivacy: boolean;
}

interface UserInfoFormDataMeritBase {
  orderId: string;
  mode: CHANT_MODE;
  chantName: string;
  chantMessage: string;
  shippingName?: string;
  shippingAddress?: string;
  shippingPhoneNo?: string;
  shippingPostcode?: string;
  skuCode?: string;

  acceptTerms: boolean;
  acceptPrivacy: boolean;
}

export interface MeritFormSuriya extends UserInfoFormDataMeritBase {
  chantNames: ChantNameMultipleInputData;
}

export type UserInfoFormDataMerit = MeritFormSuriya | UserInfoFormDataMeritBase;

export interface UserInfoFormInitialData {
  chantName?: string;
  chantMessage?: string;
  meritData?: OrderMeritData;
}

export function prepareMeritData(skuMode: SKU_CHANT_MODE, data: UserInfoFormDataMerit) {
  if (skuMode === SKU_CHANT_MODE.MERIT) {
    const chantNames = (data as MeritFormSuriya).chantNames;
    let chantName = 'ไม่ประสงค์ออกนาม';
    if (chantNames.isAnonymous) {
      chantName = 'ไม่ประสงค์ออกนาม';
    } else {
      chantName = chantNames.names
        .filter((n) => n !== null && n !== undefined && n.trim() !== '')
        .join(', ');
    }
    return {
      ...data,
      chantName: chantName,
      chantNames: chantNames.names,
      chantAnonymous: chantNames.isAnonymous,
    };
  } else {
    // FIXME
    return { ...data, chantNames: [], chantAnonymous: undefined };
  }
}

interface CreateOrderFormProps {
  onSubmit?: (formValues: UserInfoFormDataMerit) => void;
  orderId: string;
  locationInstructions?: string;
  locationId: string;
  isLoading: boolean;
  mode: CHANT_MODE;
  initialData?: UserInfoFormInitialData;
  haveShipping?: boolean;
  shippingData?: OrderShippingData;
  showPackageSelection: boolean;
  enablePackageSelection: boolean;
  skuMasterCode?: string;
  availableSkus?: AvailableSkuItem[];
  skuData?: SkuMeritData;
  skuCode?: string;
}

enum ORDER_MODE {
  FULFILLMENT = 'FULFILLMENT',
  SHIPPING = 'SHIPPING',
}

export default function UserMeritForm(props: CreateOrderFormProps) {
  const classes = useStyles();

  const {
    orderId,
    onSubmit = () => {
      /** */
    },
    locationId,
    isLoading,
    mode,
    initialData,
    haveShipping,
    shippingData,
    showPackageSelection,
    enablePackageSelection,
    skuMasterCode,
    availableSkus,
    skuData,
    skuCode,
  } = props;

  const FORM_TEXT = getUserInfoForm(mode);

  const orderMode = haveShipping ? ORDER_MODE.SHIPPING : ORDER_MODE.FULFILLMENT;
  let newInitialData = { orderId, mode, ...getInitialData(mode, skuData, initialData) };
  if (shippingData) {
    newInitialData = { ...newInitialData, ...shippingData };
  }
  if (showPackageSelection && skuMasterCode && availableSkus && availableSkus?.length > 0) {
  } else if (skuCode) {
    const newData = { skuCode };
    newInitialData = { ...newInitialData, ...newData };
  }

  const handleSubmit = (values: UserInfoFormData, ...restArgs) => {
    // console.log('handleSubmit', values);
    const { ...restValue } = values;
    const newValues: UserInfoFormDataMerit = { ...restValue };
    onSubmit(newValues);
  };

  const showPackageSelectionUI = showPackageSelection && skuMasterCode;

  const template = getTemplate(mode, skuData);

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={newInitialData}
        validate={(values) => {
          const errors: { [k: string]: any } = {};
          console.log(values);
          if (!values.skuCode) {
            errors.skuCode = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_SELECT_PACAKGE];
          }
          const formValidations = getValidations(mode, skuData, values);
          Object.assign(errors, formValidations);

          const pdpaErrors = validatePDPA(values);
          Object.assign(errors, pdpaErrors);

          console.log('validations', errors);
          return errors;
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => {
          // console.log(values);
          return (
            <form onSubmit={handleSubmit}>
              <HiddenFieldForm />
              <Paper elevation={0} style={{ padding: 16 }}>
                {showPackageSelectionUI && (
                  <>
                    <SelectPackageForm
                      FORM_TEXT={FORM_TEXT}
                      skuMasterCode={skuMasterCode!}
                      enable={enablePackageSelection}
                      availableSkus={availableSkus}
                    />
                    <Box my={3}></Box>
                  </>
                )}
                {!showPackageSelectionUI && <HiddenSkuCodeFieldForm />}
                <Typography variant="h3">{FORM_TEXT[UIFORM_TEXT_FIELDS.HEADER_NAME]}</Typography>
                <FieldsGenerator fields={template} />
                <Box my={5}></Box>
                {orderMode === ORDER_MODE.SHIPPING && (
                  <>
                    <Typography variant="h3">
                      {FORM_TEXT[UIFORM_TEXT_FIELDS.HEADER_SHIPPING]}
                    </Typography>
                    <Box my={3}></Box>
                    <ShippingForm FORM_TEXT={FORM_TEXT} />
                    <Box my={5}></Box>
                  </>
                )}
                <AcceptPDPAForm FORM_TEXT={FORM_TEXT} />
                {form.getState().hasValidationErrors && (
                  <>
                    <ErrorBox errors={form.getState().errors} />
                    <Box my={1}></Box>
                  </>
                )}
                <ConfirmationButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="large"
                  stType="gradient"
                  fullWidth
                  disabled={form.getState().hasValidationErrors || isLoading}
                  onClick={handleSubmit}
                  loading={isLoading}
                >
                  {orderMode === ORDER_MODE.FULFILLMENT
                    ? FORM_TEXT[UIFORM_TEXT_FIELDS.CONFIRM_BUTTON]
                    : FORM_TEXT[UIFORM_TEXT_FIELDS.CONFIRM_BUTTON_SHIPPING]}
                </ConfirmationButton>
              </Paper>
            </form>
          );
        }}
      ></Form>
    </>
  );
}
