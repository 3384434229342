import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function FormDialog({ open, setOpen, onSubmit, isLoading }) {
  const [packageDate, setPackageDate] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    onSubmit(packageDate);
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">สร้าง Order Summary</DialogTitle>
      <DialogContent>
        <DialogContentText>ใส่ Package Date ที่ต้องการสร้าง orderSummary</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Package Date"
          fullWidth
          onChange={(e) => setPackageDate(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" disabled={isLoading}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" disabled={isLoading}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
