import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import StoreOrderContainer from './StoreOrderContainer';
interface StoreOrderRouterParams {
  storeId: string;
  orderId: string;
}

export default function StoreOrderRoute() {
  const { orderId, storeId } = useParams<keyof StoreOrderRouterParams>() as StoreOrderRouterParams;
  return <StoreOrderContainer orderId={orderId} storeId={storeId} />;
}
