import ShipmentSummaryCard from 'Components/ShipmentSummaryCard';
import WallpaperSummaryCard from 'Components/WallpaperSummaryCard';
import WreathSummaryCard from 'Components/WreathSummaryCard';
import { SatthaOrder, SKU_CHANT_MODE } from 'Libs/Firebase/firestore/Order';
import React from 'react';
import { isSingleShipmentOrder } from 'Routes/ClientOrder';
import FortuneTellingOrderInfo from './OrderInfo/FortuneTellingOrderInfo';

interface StoreOrderInfoProps {
  orderInfo: SatthaOrder;
  skuMode: SKU_CHANT_MODE;
}

export default function StoreOrderInfo(props: StoreOrderInfoProps) {
  const { orderInfo, skuMode } = props;

  if (skuMode === SKU_CHANT_MODE.WREATH) return <WreathSummaryCard orderInfo={orderInfo} />;
  if (skuMode === SKU_CHANT_MODE.WALLPAPER) return <WallpaperSummaryCard orderInfo={orderInfo} />;
  if ([SKU_CHANT_MODE.FORTUNE_TELLING, SKU_CHANT_MODE.FORTUNE_TELLING_LIVE].includes(skuMode))
    return <FortuneTellingOrderInfo orderInfo={orderInfo} />;
  if (isSingleShipmentOrder(orderInfo)) return <ShipmentSummaryCard orderInfo={orderInfo} />;
  return null;
}
