import defaults from 'lodash/defaults';
import log from 'loglevel';

const getBool = (str, defaultValue) => {
  if (str !== null && str !== undefined && str !== '') {
    return str.toLowerCase() === 'true' ? true : false;
  }
  return defaultValue;
};

const getString = (str, defaultValue) => {
  if (str !== null && str !== undefined && str !== '') {
    return str;
  }
  return defaultValue;
};

const getInt = (str, defaultValue) => {
  if (str !== null && str !== undefined && str !== '') {
    return parseInt(str, 10);
  }
  return defaultValue;
};

let config = {
  log: process.env.REACT_APP_DEBUG_LEVEL || 'info',
  enablePdpa: getBool(process.env.REACT_APP_ENABLE_PDPA, true),
  pdpa: {
    provider: process.env.REACT_APP_PDPA_PROVIDER || 'LINE', // LINE, SATTHA
  },
  liffId: process.env.REACT_APP_LIFF_ID || '1657537296-E5rNrZ7R',
  enableClientAuth: getBool(process.env.REACT_APP_ENABLE_CLIENT_AUTH, false),
  gift: {
    enableGift: getBool(process.env.REACT_APP_ENABLE_GIFT, false),
    allowGiftState: getString(process.env.REACT_APP_ALLOW_GIFT_STATE, 'PAID,WAIT_FOLLOW_UP').split(
      ','
    ),
  },
};

function getConfig() {
  if (config === null) {
    /**
     * This will merge only 1 level deep, it will not recursivly merge values.
     * Previously it used defaultsDeep, which is ok except for array value, it will append array elements
     */
    log.setLevel(config.log);
    log.debug(config);
  }
  console.log('config is', config);
  return config;
}

export const getConfigContext = (props) => {
  const { configContext } = props;
  return configContext;
};

export default getConfig;
