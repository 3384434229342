import { FormControl, FormHelperText, MenuItem, Select, TextField } from '@mui/material';
import { CHANT_MODE, UIFORM_TEXT_FIELDS } from 'Domain/Sattha/Chant';
import React from 'react';
import { Field } from 'react-final-form';
import { Box, Typography } from 'Components/Base';

export default function WreathForm({
  FORM_TEXT,
  locationId,
  mode,
}: {
  FORM_TEXT: any;
  locationId: string;
  mode: CHANT_MODE;
}) {
  return (
    <>
      <Box my={1}></Box>
      <Typography variant="info">
        {'* ข้อมูลของท่านจะถูกใช้เพื่อการทำวอลเปเปอร์เท่านั้น'}
      </Typography>
      <Box my={3}></Box>
      <FormControl fullWidth required>
        <Field
          name="chantName"
          render={(props) => (
            <>
              <Typography variant="h5">{FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_NAME]}</Typography>
              <TextField
                {...props.input}
                label=""
                variant="outlined"
                placeholder="กรอกชื่อ - นามสกุล"
                fullWidth
                InputProps={{
                  startAdornment: <div />,
                }}
                inputProps={{ maxLength: 60 }}
                helperText={
                  props.meta.error && props.meta.touched ? (
                    <FormHelperText error>{props.meta.error}</FormHelperText>
                  ) : null
                }
              />
            </>
          )}
        />
      </FormControl>
      <Box my={2}></Box>
      <FormControl fullWidth required>
        <Field
          name="phoneNo"
          render={(props) => (
            <>
              <Typography variant="h5">{FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_PHONENO]}</Typography>
              <TextField
                {...props.input}
                label=""
                variant="outlined"
                placeholder={FORM_TEXT[UIFORM_TEXT_FIELDS.PLACEHOLDER_PHONENO]}
                fullWidth
                InputProps={{
                  startAdornment: <div />,
                }}
                inputProps={{ maxLength: 15 }}
                helperText={
                  props.meta.error && props.meta.touched ? (
                    <FormHelperText error>{props.meta.error}</FormHelperText>
                  ) : null
                }
              />
            </>
          )}
        />
      </FormControl>
      <Box my={2}></Box>
      <FormControl fullWidth required>
        <Field
          name="wreathLocationPlace"
          render={(props) => (
            <>
              <Typography variant="h5">{'สถานที่จัดส่ง (วัด)'}</Typography>
              <TextField
                {...props.input}
                label=""
                variant="outlined"
                placeholder="กรอกชื่อวัด ถ้าระบุเขตหรืออำเภอได้ให้ระบุด้วย"
                fullWidth
                InputProps={{
                  startAdornment: <div />,
                }}
                inputProps={{ maxLength: 60 }}
                helperText={
                  props.meta.error && props.meta.touched ? (
                    <FormHelperText error>{props.meta.error}</FormHelperText>
                  ) : null
                }
              />
            </>
          )}
        />
      </FormControl>
      <Box my={2}></Box>
      <FormControl fullWidth required>
        <Field
          name="wreathLocationPlaceSpecific"
          render={(props) => (
            <>
              <Typography variant="h5">{'ศาลา'}</Typography>
              <TextField
                {...props.input}
                label=""
                variant="outlined"
                placeholder="กรอกศาลา"
                fullWidth
                InputProps={{
                  startAdornment: <div />,
                }}
                inputProps={{ maxLength: 60 }}
                helperText={
                  props.meta.error && props.meta.touched ? (
                    <FormHelperText error>{props.meta.error}</FormHelperText>
                  ) : null
                }
              />
            </>
          )}
        />
      </FormControl>
      <Box my={2}></Box>
      <FormControl fullWidth required>
        <Field
          name="wreathLocationProvince"
          render={(props) => (
            <>
              <Typography variant="h5">{'จังหวัด'}</Typography>
              <TextField
                {...props.input}
                disabled
                label=""
                variant="outlined"
                placeholder="จังหวัด"
                fullWidth
                InputProps={{
                  startAdornment: <div />,
                }}
                inputProps={{ maxLength: 60 }}
                helperText={
                  props.meta.error && props.meta.touched ? (
                    <FormHelperText error>{props.meta.error}</FormHelperText>
                  ) : null
                }
              />
            </>
          )}
        />
      </FormControl>
      <Box my={2}></Box>
      <FormControl fullWidth>
        <Field
          name="wreathDeceasedName"
          render={(props) => (
            <>
              <Typography variant="h5">{'ชื่อผู้วายชนม์ (หากไม่ทราบให้เว้นว่าง)'}</Typography>
              <TextField
                {...props.input}
                label=""
                variant="outlined"
                placeholder="กรอกชื่อผู้วายชนม์"
                fullWidth
                InputProps={{
                  startAdornment: <div />,
                }}
                inputProps={{ maxLength: 60 }}
                helperText={
                  props.meta.error && props.meta.touched ? (
                    <FormHelperText error>{props.meta.error}</FormHelperText>
                  ) : null
                }
              />
            </>
          )}
        />
      </FormControl>
      <Box my={2}></Box>
      <FormControl fullWidth required>
        <Field
          name="wreathDisplay"
          render={(props) => (
            <>
              <Typography variant="h5">{'ข้อความในป้าย (หากไม่กรอกจะเป็นป้ายโลโก้)'}</Typography>
              <TextField
                {...props.input}
                label=""
                variant="outlined"
                placeholder="พิมพ์ข้อความไว้อาลัยที่จะปรากฎบนป้าย"
                fullWidth
                InputProps={{
                  startAdornment: <div />,
                }}
                inputProps={{ maxLength: 60 }}
                helperText={
                  props.meta.error && props.meta.touched ? (
                    <FormHelperText error>{props.meta.error}</FormHelperText>
                  ) : null
                }
              />
            </>
          )}
        />
      </FormControl>
      <Box my={1}></Box>
    </>
  );
}
