import { SKU_CHANT_MODE } from 'Libs/Firebase/firestore/Order';
import {
  getInitialData as getSuriyaInitialData,
  suriyaValidations,
  TEMPLATE_SURIYA,
} from './templates/suriya';

export function getInitialData(mode: SKU_CHANT_MODE, skuData: any, initialData: any) {
  return getSuriyaInitialData(skuData, initialData);
}

export function getTemplate(mode: SKU_CHANT_MODE, skuData: any) {
  return TEMPLATE_SURIYA;
}

export function getValidations(mode: SKU_CHANT_MODE, skuData: any, formValues: any) {
  return suriyaValidations(formValues);
}
