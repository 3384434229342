import React, { useCallback, useState } from 'react';
import { Divider } from '@mui/material';

import PackageInfoForm from 'Components/PackageInfoForm';
import {
  UserInfoFormData,
  UserInfoFormDataOnSubmit,
  FixBadluckForm,
  WallpaperForm,
  WishVowForm,
  WreathForm,
  SingleShipmentForm,
  FortuneTellingForm,
} from 'Components/UserInfoForm';
import Box from 'Components/Base/Box';

import {
  ORDER_SKU_MASTER_TYPE,
  ORDER_TYPE,
  SatthaOrder,
  SkuFortuneTellingData,
  SkuMeritData,
  SkuShipmentData,
  SkuWallpaperData,
  SkuWishVowData,
  SkuWreathData,
  SKU_CHANT_MODE,
} from 'Libs/Firebase/firestore/Order';
import { Typography } from 'Components/Base';
import LateAlertBox, { LateAlertBoxUtils } from '../LateAlertBox';
import {
  convertSatthaOrderToWallpaperRequestType,
  WALLPAPER_INPUT_TYPE,
} from 'Domain/Sku/Wallpaper';
import BookingInfoBox from 'Components/BookingInfoBox';
import { CHANT_MODE } from 'Domain/Sattha/Chant';
import { UserInfoFormDataChantFix } from 'Components/UserInfoForm/UserInfoForm';
import { getTimeInput } from 'Domain/Sku/FixBadLuck';
import { UpdateWishVowOrderInput, ZODIAC_YEAR } from 'Domain/Orders/types';
import { UserInfoFormDataWallpaper } from 'Components/UserInfoForm/Forms/WallpaperForm';
import { UserInfoFormDataFortuneTelling } from 'Components/UserInfoForm/Forms/FortuneTellingForm';
import { UserInfoFormDataChant } from 'Components/UserInfoForm/Forms/WishVowForm';
import { isSingleShipmentOrder } from 'Routes/ClientOrder';
import { UserInfoFormDataShipment } from 'Components/UserInfoForm/Forms/SingleShipmentForm';
import { UserInfoFormDataWreath } from 'Components/UserInfoForm/Forms/WreathForm';
import {
  useUpdateFixBadLuckOrder,
  useUpdateFortuneTellingOrder,
  useUpdateMTWWallpaperOrder,
  useUpdateRMSWallpaperOrder,
  useUpdateHRSWallpaperOrder,
  useUpdateSingleShipmentOrder,
  useUpdateWallpaperOrder,
  useUpdateWishVowOrder,
  useUpdateWreathOrder,
  useUpdateMeritOrder,
} from 'Domain/Orders/hooks';
import MarkdownBox from 'Components/MarkdownBox';
import { updateHRSWallpaperOrderMutation } from 'Domain/Orders/query';
import MeritForm from 'Components/UserInfoForm/FormV2/MeritForm';
import {
  prepareMeritData,
  UserInfoFormDataMerit,
} from 'Components/UserInfoForm/FormV2/MeritForm/MeritForm';

type NeedUserInfoOrderProps = {
  orderInfo: SatthaOrder;
  onUserInfoUpdate?: (userInfo: UserInfoFormDataOnSubmit) => void;
  fillInitialData?: boolean;
};

export type { UserInfoFormData, UserInfoFormDataOnSubmit };

export default function NeedUserInfoOrder(props: NeedUserInfoOrderProps) {
  const [isLoading, setIsLoading] = useState(false);
  const {
    onUserInfoUpdate = () => {
      /** */
    },
  } = props;
  const { orderInfo, fillInitialData } = props;
  const {
    id: orderRealId,
    orderId,
    locationInstructions,
    skuCode,
    skuMasterCode,
    skuMode,
    locationId,
    locationDisplayInfo,
    chantName,
    chantMessage,
    bundleInfo,
    availableSkus,
    skuData,
    type,
    shippingData,
    booking,
    skuMasterType,
    meritData,
    birthday,
    faceImage,
  } = orderInfo;

  const [updateWishVowOrder] = useUpdateWishVowOrder();
  const [updateFixBadLuckOrder] = useUpdateFixBadLuckOrder();
  const [updateSingleShipmentOrder] = useUpdateSingleShipmentOrder();
  const [updateWallpaperOrder] = useUpdateWallpaperOrder();
  const [updateRMSWallpaperOrder] = useUpdateRMSWallpaperOrder();
  const [updateMTWWallpaperOrder] = useUpdateMTWWallpaperOrder();
  const [updateHRSWallpaperOrder] = useUpdateHRSWallpaperOrder();
  const [updateFortuneTellingOrder] = useUpdateFortuneTellingOrder();
  const [updateMeritOrder] = useUpdateMeritOrder();
  const [updateWreathOrder] = useUpdateWreathOrder();

  const handleUserInfoUpdate = useCallback(
    async (userInfo: UserInfoFormDataOnSubmit) => {
      console.log('handleUserInfoUpdate', userInfo);
      const { chantName, acceptPrivacy, acceptTerms } = userInfo;
      const { skuCode } = userInfo;
      const pdpaInfo = {
        acceptPrivacyPolicy: acceptPrivacy ?? false,
        acceptTermsAndConditions: acceptTerms ?? false,
      };

      if (userInfo.mode === CHANT_MODE.FIX_BADLUCK) {
        const { birthDate, birthMonth, birthTime, birthYear } =
          userInfo as UserInfoFormDataChantFix;

        const { shippingName, shippingAddress, shippingPhoneNo, shippingPostcode } =
          userInfo as UserInfoFormDataChantFix;

        const shippingInfo =
          shippingName && shippingAddress && shippingPhoneNo && shippingPostcode
            ? { shippingName, shippingAddress, shippingPhoneNo, shippingPostcode }
            : undefined;

        if (birthTime && birthYear) {
          const { hour, minute, notKnow } = getTimeInput(birthTime);
          let birthTimeInput;
          if (!notKnow) {
            birthTimeInput = {
              hour: hour!,
              minute: minute!,
            };
          }
          await updateFixBadLuckOrder({
            variables: {
              orderId: orderId,
              input: {
                orderInfo: {
                  chantName,
                },
                zodiacInfo: {
                  age: birthYear!.zodiacAge >= 13 ? birthYear!.zodiacAge : undefined,
                  birthDate: {
                    day: birthDate!,
                    month: birthMonth!,
                    year: birthYear!.zodiacAge >= 13 ? birthYear!.birthYear : undefined,
                  },
                  birthTime: birthTimeInput,
                  zodiacYear: birthYear!.zodiacYear.toUpperCase() as ZODIAC_YEAR,
                },
                shippingInfo,
                packageInfo: skuCode ? { skuCode } : undefined,
                pdpaInfo,
              },
            },
          });
        } else {
          await updateFixBadLuckOrder({
            variables: {
              orderId: orderId,
              input: {
                orderInfo: {
                  chantName,
                },
                zodiacInfo: {
                  age: undefined,
                  birthTime: undefined,
                  birthDate: {
                    day: 1,
                    month: 1,
                    year: undefined,
                  },
                  zodiacYear: ZODIAC_YEAR.DOG,
                },
                shippingInfo,
                packageInfo: skuCode ? { skuCode } : undefined,
                pdpaInfo,
              },
            },
          });
        }
      } else if (userInfo.mode === CHANT_MODE.WALLPAPER) {
        const input = userInfo as UserInfoFormDataWallpaper;
        const { shippingName, shippingAddress, shippingPhoneNo, shippingPostcode } =
          userInfo as UserInfoFormDataWallpaper;
        const shippingInfo =
          shippingName && shippingAddress && shippingPhoneNo && shippingPostcode
            ? { shippingName, shippingAddress, shippingPhoneNo, shippingPostcode }
            : undefined;
        const iSkuData = skuData as SkuWallpaperData;
        if (iSkuData.inputTemplate === WALLPAPER_INPUT_TYPE.HOROWALL) {
          await updateWallpaperOrder({
            variables: {
              orderId: orderId,
              input: {
                orderInfo: {
                  chantName,
                },
                wallpaperInfo: {
                  email: input.email,
                  phoneNo: input.phoneNo,
                  gender: input.gender,
                  birthDay: input.birthDay,
                  birthDate: input.birthDate,
                  birthMonth: input.birthMonth,
                  birthYear: input.birthYear,
                  occupation: input.occupation,
                  topic: input.wallpaperRequestType,
                  subTopic: input.wallpaperRequestSubType,
                },
                shippingInfo,
                packageInfo: skuCode ? { skuCode } : undefined,
                pdpaInfo,
              },
            },
          });
        } else if (iSkuData.inputTemplate === WALLPAPER_INPUT_TYPE.RMS) {
          const { hour, minute, notKnow } = input.birthTime
            ? getTimeInput(input.birthTime)
            : { hour: 0, minute: 0, notKnow: true };
          let birthTimeInput: any | undefined = {
            hour: hour!,
            minute: minute!,
          };
          if (notKnow) birthTimeInput = undefined;
          await updateRMSWallpaperOrder({
            variables: {
              orderId: orderId,
              input: {
                orderInfo: {
                  chantName,
                },
                wallpaperInfo: {
                  email: input.email,
                  phoneNo: input.phoneNo,
                  gender: input.gender,
                  birthDay: input.birthDay,
                  birthMonth: input.birthMonth,
                  birthYear: input.birthYear,
                  birthTime: birthTimeInput,
                  lineId: input.lineId!,
                  phoneModel: input.phoneModel!,
                  wallpaperColor: input.wallpaperColor!,
                  topic: input.wallpaperRequestType,
                },
                shippingInfo,
                packageInfo: skuCode ? { skuCode } : undefined,
                pdpaInfo,
              },
            },
          });
        } else if (iSkuData.inputTemplate === WALLPAPER_INPUT_TYPE.MTW) {
          const { hour, minute, notKnow } = input.birthTime
            ? getTimeInput(input.birthTime)
            : { hour: 0, minute: 0, notKnow: true };
          let birthTimeInput: any | undefined = {
            hour: hour!,
            minute: minute!,
          };
          if (notKnow) birthTimeInput = undefined;
          await updateMTWWallpaperOrder({
            variables: {
              orderId: orderId,
              input: {
                orderInfo: {
                  chantName,
                },
                wallpaperInfo: {
                  email: input.email,
                  phoneNo: input.phoneNo,
                  gender: input.gender,
                  birthDay: input.birthDay,
                  birthMonth: input.birthMonth,
                  birthYear: input.birthYear,
                  birthTime: birthTimeInput,
                  phoneModel: input.phoneModel,
                  option: input.wallpaperRequestOption!,
                  topic: input.wallpaperRequestType,
                  subTopic: input.wallpaperRequestSubType,
                  subSubTopic: input.wallpaperRequestSubSubType!,
                  medicalProblems: input.medicalProblems,
                  fansBirthDay: input.fansBirthDay,
                },
                shippingInfo,
                packageInfo: skuCode ? { skuCode } : undefined,
                pdpaInfo,
              },
            },
          });
        } else if (iSkuData.inputTemplate === WALLPAPER_INPUT_TYPE.HRS) {
          await updateHRSWallpaperOrder({
            variables: {
              orderId: orderId,
              input: {
                orderInfo: {
                  chantName,
                },
                wallpaperInfo: {
                  phoneNo: input.phoneNo,
                  gender: input.gender,
                  birthDay: input.birthDay,
                  birthDate: input.birthDate,
                  birthMonth: input.birthMonth,
                  birthYear: input.birthYear,
                  occupation: input.occupation,
                  secondaryOccupation: input.secondaryOccupation!,
                  topic: input.wallpaperRequestType,
                },
                shippingInfo,
                packageInfo: skuCode ? { skuCode } : undefined,
                pdpaInfo,
              },
            },
          });
        }
      } else if (
        [CHANT_MODE.FORTUNE_TELLING, CHANT_MODE.FORTUNE_TELLING_LIVE].includes(userInfo.mode) &&
        type === ORDER_TYPE.FULFILLMENT
      ) {
        const iSkuData = skuData as SkuFortuneTellingData;
        const {
          gender,
          birthday,
          birthTime,
          birthPlace,
          facePicture,
          questions,
          phoneNo,
          palmPicture,
          occupation,
          additionalFtInfo,
        } = userInfo as UserInfoFormDataFortuneTelling;
        const { shippingName, shippingAddress, shippingPhoneNo, shippingPostcode } =
          userInfo as UserInfoFormDataChantFix;
        const shippingInfo =
          shippingName && shippingAddress && shippingPhoneNo && shippingPostcode
            ? { shippingName, shippingAddress, shippingPhoneNo, shippingPostcode }
            : undefined;
        const { hour, minute, notKnow } = birthTime
          ? getTimeInput(birthTime)
          : { hour: 0, minute: 0, notKnow: true };
        let birthTimeInput: any | undefined = {
          hour: hour!,
          minute: minute!,
        };
        if (notKnow) birthTimeInput = undefined;
        await updateFortuneTellingOrder({
          variables: {
            orderId: orderId,
            input: {
              orderInfo: {
                chantName,
              },
              fortuneTellingInfo: {
                phoneNo,
                gender,
                birthday,
                birthTime: birthTimeInput,
                birthPlace,
                faceImage: facePicture,
                questions,
                palmImage: palmPicture,
                occupation,
                additionalInfo: { ...additionalFtInfo, inputMode: iSkuData.additionalInputMode },
              },
              shippingInfo,
              packageInfo: skuCode ? { skuCode } : undefined,
              pdpaInfo,
            },
          },
        });
      } else if (
        [CHANT_MODE.VOW, CHANT_MODE.WISH].includes(userInfo.mode) &&
        type === ORDER_TYPE.FULFILLMENT
      ) {
        const { chantMessage, birthday, facePicture, birthTime } =
          userInfo as UserInfoFormDataChant;
        const { shippingName, shippingAddress, shippingPhoneNo, shippingPostcode } =
          userInfo as UserInfoFormDataChantFix;
        const shippingInfo =
          shippingName && shippingAddress && shippingPhoneNo && shippingPostcode
            ? { shippingName, shippingAddress, shippingPhoneNo, shippingPostcode }
            : undefined;
        const wishVowInfo: UpdateWishVowOrderInput['wishVowInfo'] = {
          chantMessage,
          birthday,
          faceImage: facePicture,
        };
        if (birthTime) {
          const { hour, minute, notKnow } = getTimeInput(birthTime);
          let birthTimeInput: any | undefined = {
            hour: hour!,
            minute: minute!,
          };
          if (notKnow) birthTimeInput = undefined;
          wishVowInfo.birthTime = birthTimeInput;
        }
        await updateWishVowOrder({
          variables: {
            orderId: orderId,
            input: {
              orderInfo: {
                chantName,
              },
              wishVowInfo: wishVowInfo,
              shippingInfo,
              packageInfo: skuCode ? { skuCode } : undefined,
              pdpaInfo,
            },
          },
        });
      } else if (isSingleShipmentOrder(orderInfo)) {
        const { shippingName, shippingAddress, shippingPhoneNo, shippingPostcode, additionalData } =
          userInfo as UserInfoFormDataShipment;
        const shippingInfo = {
          shippingName,
          shippingAddress,
          shippingPhoneNo,
          shippingPostcode,
          shippingAdditionalData: additionalData,
        };
        await updateSingleShipmentOrder({
          variables: {
            orderId: orderId,
            input: {
              shippingInfo,
              packageInfo: skuCode ? { skuCode } : undefined,
              pdpaInfo,
            },
          },
        });
      } else if (userInfo.mode === CHANT_MODE.WREATH) {
        const input = userInfo as UserInfoFormDataWreath;
        await updateWreathOrder({
          variables: {
            orderId: orderId,
            input: {
              orderInfo: {
                chantName,
              },
              wreathInfo: {
                locationPlace: input.wreathLocationPlace,
                locationPlaceSpecific: input.wreathLocationPlaceSpecific,
                locationProvince: input.wreathLocationProvince,
                deceasedName: input.wreathDeceasedName,
                displayMessage: input.wreathDisplay,
                phoneNo: input.phoneNo,
              },
              packageInfo: skuCode ? { skuCode } : undefined,
              pdpaInfo,
            },
          },
        });
      } else if ([SKU_CHANT_MODE.MERIT].includes(userInfo.mode)) {
        const { chantMessage, shippingName, shippingAddress, shippingPhoneNo, shippingPostcode } =
          userInfo as UserInfoFormDataMerit;
        const shippingInfo =
          shippingName && shippingAddress && shippingPhoneNo && shippingPostcode
            ? { shippingName, shippingAddress, shippingPhoneNo, shippingPostcode }
            : undefined;
        const inputData = prepareMeritData(userInfo.mode, userInfo as UserInfoFormDataMerit);
        await updateMeritOrder({
          variables: {
            orderId: orderId,
            input: {
              orderInfo: {
                chantName: inputData.chantName,
              },
              meritInfo: {
                chantMessage: chantMessage,
                chantNames: inputData.chantNames!,
                chantAnonymous: inputData.chantAnonymous!,
              },
              shippingInfo,
              packageInfo: skuCode ? { skuCode } : undefined,
              pdpaInfo,
            },
          },
        });
      }

      onUserInfoUpdate(userInfo);
    },
    [
      type,
      orderInfo,
      onUserInfoUpdate,
      updateFixBadLuckOrder,
      orderId,
      skuData,
      updateWallpaperOrder,
      updateRMSWallpaperOrder,
      updateMTWWallpaperOrder,
      updateHRSWallpaperOrder,
      updateFortuneTellingOrder,
      updateWishVowOrder,
      updateSingleShipmentOrder,
      updateWreathOrder,
      updateMeritOrder,
    ]
  );

  const handleSubmit = useCallback(
    async (values: UserInfoFormDataOnSubmit) => {
      setIsLoading(true);
      await handleUserInfoUpdate(values);
      setIsLoading(false);
    },
    [handleUserInfoUpdate]
  );

  const haveShipping = bundleInfo?.haveShippingOrder ?? false;
  const bundleShippingData = bundleInfo?.shippingOrder?.shippingData ?? undefined;

  // show package selection when SKUMaster enabled
  const showPackageSelection = skuMasterType === ORDER_SKU_MASTER_TYPE.SKUMASTER;
  // enable selection only when SKUCode is null/undefined
  const enablePackageSelection = showPackageSelection;

  const showLateAlert = LateAlertBoxUtils.shouldShowNotifyOrderLateAlert(orderInfo);

  return (
    <Box>
      <Box my={2}></Box>
      {booking && (
        <Box p={2}>
          <Typography variant="h3">ลิงก์การดูดวงแบบออนไลน์</Typography>
          <Box my={2}></Box>
          <BookingInfoBox
            meetingUrl={booking.meetingUrl}
            startTime={booking.startTime}
            endTime={booking.endTime}
            showBookingTime
          />
        </Box>
      )}
      {showLateAlert && <LateAlertBox order={orderInfo} />}
      {locationDisplayInfo && locationDisplayInfo.trim().length > 0 && (
        <Box sx={{ fontSize: '0.8em' }} px={2}>
          <MarkdownBox>{locationDisplayInfo}</MarkdownBox>
        </Box>
      )}
      {[SKU_CHANT_MODE.WISH, SKU_CHANT_MODE.VOW].includes(skuMode) &&
        type !== ORDER_TYPE.SHIPMENT && (
          <WishVowForm
            isLoading={isLoading}
            orderId={orderId}
            orderRealId={orderRealId}
            locationInstructions={locationInstructions}
            locationId={locationId}
            onSubmit={handleSubmit}
            mode={skuMode}
            initialData={
              fillInitialData ? { chantName, chantMessage, birthday, faceImage } : undefined
            }
            haveShipping={haveShipping}
            shippingData={bundleShippingData}
            showPackageSelection={showPackageSelection}
            enablePackageSelection={enablePackageSelection}
            skuMasterCode={skuMasterCode}
            availableSkus={availableSkus}
            skuCode={skuCode}
            skuData={skuData as SkuWishVowData}
          />
        )}
      {[SKU_CHANT_MODE.FIX_BADLUCK].includes(skuMode) && (
        <FixBadluckForm
          isLoading={isLoading}
          orderId={orderId}
          locationInstructions={locationInstructions}
          locationId={locationId}
          onSubmit={handleSubmit}
          mode={skuMode}
          initialData={fillInitialData ? { chantName, chantMessage } : undefined}
          haveShipping={haveShipping}
          shippingData={bundleShippingData}
          showPackageSelection={showPackageSelection}
          enablePackageSelection={enablePackageSelection}
          skuMasterCode={skuMasterCode}
          availableSkus={availableSkus}
        />
      )}
      {[SKU_CHANT_MODE.WALLPAPER].includes(skuMode) && (
        <WallpaperForm
          isLoading={isLoading}
          orderId={orderId}
          locationInstructions={locationInstructions}
          locationId={locationId}
          onSubmit={handleSubmit}
          mode={skuMode}
          initialData={fillInitialData ? { chantName, chantMessage } : undefined} // TODO: WALLPAPER fill initial data
          haveShipping={haveShipping}
          shippingData={bundleShippingData}
          showPackageSelection={showPackageSelection}
          enablePackageSelection={enablePackageSelection}
          skuMasterCode={skuMasterCode}
          availableSkus={availableSkus}
          wallpaperRequestType={convertSatthaOrderToWallpaperRequestType(
            (skuData! as SkuWallpaperData).wallpaperRequestType
          )}
          horowallShowIncludedServices={(skuData! as SkuWallpaperData).horowallIncludeServices}
          wallpaperInputTemplate={(skuData! as SkuWallpaperData).inputTemplate}
          skuData={skuData! as SkuWallpaperData}
        />
      )}
      {[SKU_CHANT_MODE.WREATH].includes(skuMode) && (
        <WreathForm
          isLoading={isLoading}
          orderId={orderId}
          locationInstructions={locationInstructions}
          locationId={locationId}
          onSubmit={handleSubmit}
          mode={skuMode}
          initialData={fillInitialData ? { chantName, chantMessage } : undefined} // TODO: WALLPAPER fill initial data
          showPackageSelection={showPackageSelection}
          enablePackageSelection={enablePackageSelection}
          skuMasterCode={skuMasterCode}
          availableSkus={availableSkus}
          wreathProvince={(skuData! as SkuWreathData).wreathLocation}
        />
      )}
      {[SKU_CHANT_MODE.FORTUNE_TELLING, SKU_CHANT_MODE.FORTUNE_TELLING_LIVE].includes(skuMode) && (
        <FortuneTellingForm
          isLoading={isLoading}
          orderId={orderId}
          orderRealId={orderRealId}
          locationInstructions={locationInstructions}
          locationId={locationId}
          onSubmit={handleSubmit}
          mode={skuMode}
          initialData={fillInitialData ? { chantName, chantMessage } : undefined} // TODO: WALLPAPER fill initial data
          showPackageSelection={showPackageSelection}
          enablePackageSelection={enablePackageSelection}
          skuMasterCode={skuMasterCode}
          availableSkus={availableSkus}
          skuCode={skuCode}
          {...(skuData! as SkuFortuneTellingData)}
        />
      )}
      {[SKU_CHANT_MODE.MERIT].includes(skuMode) && (
        <MeritForm
          isLoading={isLoading}
          orderId={orderId}
          locationInstructions={locationInstructions}
          locationId={locationId}
          onSubmit={handleSubmit}
          mode={skuMode}
          initialData={fillInitialData ? { chantName, chantMessage, meritData } : undefined}
          haveShipping={haveShipping}
          shippingData={bundleShippingData}
          showPackageSelection={showPackageSelection}
          enablePackageSelection={enablePackageSelection}
          skuMasterCode={skuMasterCode}
          availableSkus={availableSkus}
          skuCode={skuCode}
          skuData={skuData as SkuMeritData}
        />
      )}
      {type === ORDER_TYPE.SHIPMENT && (
        <SingleShipmentForm
          isLoading={isLoading}
          orderId={orderId}
          locationId={locationId}
          onSubmit={handleSubmit}
          mode={skuMode}
          initialData={fillInitialData ? { chantName } : undefined}
          shippingData={shippingData}
          showPackageSelection={showPackageSelection}
          enablePackageSelection={enablePackageSelection}
          skuMasterCode={skuMasterCode}
          availableSkus={availableSkus}
          skuData={skuData as SkuShipmentData}
        />
      )}
      <Box my={2}></Box>
      <Typography variant="body2" color="textSecondary" align="center">
        หากมีข้อสงสัย หรือต้องการสอบถามเพิ่มเติม กรุณาติดต่อที่อีเมล์{' '}
        <a href="mailto:hello@sattha.online">hello@sattha.online</a>
      </Typography>
    </Box>
  );
}
