import { useState, useEffect } from 'react';

import BackofficeAPI, { APPROVAL_TYPE } from 'API/fe-functions/backoffice/BackofficeAPI';
import { BackofficeOrderSummary } from './types';
import { SatthaOrder } from 'Libs/Firebase/firestore/Order';
import { useQuery, UseQueryResult } from 'react-query';

type UseBackofficeSummaryOrderData = {
  loading: boolean;
  data: BackofficeOrderSummary | null;
  error?: any | null;
  refetch: any;
};

export const useGetOrderSummaryFromSummaryId = (
  orderSummaryId: string
): UseBackofficeSummaryOrderData => {
  const { isLoading, error, data, refetch } = useQuery<BackofficeOrderSummary>(
    'backofficeOrderSummaryId' + orderSummaryId,
    () => {
      const api = new BackofficeAPI();
      return api.getOrderSummary(orderSummaryId);
    },
    { refetchOnWindowFocus: false }
  );
  return { loading: isLoading, data: data ?? null, error, refetch };
};

type UseBackofficeOrderSummariesData = {
  loading: boolean;
  data: BackofficeOrderSummary[];
  error?: any | null;
  refetch: () => Promise<void>;
};

export const useGetOrderSummaries = (): UseBackofficeOrderSummariesData => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<BackofficeOrderSummary[]>([]);
  const [error, setError] = useState<any | null>(null);

  const loadData = async () => {
    try {
      setLoading(true);
      const api = new BackofficeAPI();
      const data = await api.getOrderSummaries();
      setLoading(false);
      setData(data);
      return data;
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  const refetch = async () => {
    return loadData();
  };

  useEffect(() => {
    loadData();
    return () => {};
  }, []);
  return { loading, data, error, refetch };
};

export const useBackofficeCreateOrderSummary = () => {
  const [error, setError] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const createOrderSummary = async (packageDate: string) => {
    const api = new BackofficeAPI();
    try {
      setError(null);
      setIsLoading(true);
      await api.createOrderSummary(packageDate);
      setIsLoading(false);
      return true;
    } catch (error) {
      setError(error);
      setIsLoading(false);
      return null;
    }
  };
  return { isLoading, createOrderSummary, error };
};

export const useBackofficeApprovalOrder = () => {
  const [error, setError] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const approveOrder = async (orderId: string, approvalType: APPROVAL_TYPE) => {
    const api = new BackofficeAPI();
    try {
      setError(null);
      setIsLoading(true);
      const result = await api.approveOrder(orderId, approvalType);
      setIsLoading(false);
      return result;
    } catch (error) {
      setError(error);
      setIsLoading(false);
      return null;
    }
  };
  return { isLoading, approveOrder, error };
};

interface GetWreathOrdersData {
  loading: boolean;
  data: SatthaOrder[];
  error?: any | null;
  refetch: (options?: { throwOnError: boolean; cancelRefetch: boolean }) => Promise<UseQueryResult>;
}

export const useBackofficeGetAllPendingWreathData = (): GetWreathOrdersData => {
  const { isLoading, error, data, refetch } = useQuery<SatthaOrder[]>(
    'allPendingWreathOrders',
    () => {
      const api = new BackofficeAPI();
      return api.getAllPendingWreathOrders();
    }
  );
  return { loading: isLoading, data: data ?? [], error, refetch };
};
