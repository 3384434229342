import React from 'react';
import { useParams } from 'react-router';
import ClientOrderContainer from 'Routes/ClientOrder/ClientOrderContainer';
import liff from '@line/liff';
import getLineLiff from 'Libs/Liff';

interface ClientOrderRouterParams {
  orderId: string;
}

export default function ClientOrder2() {
  const { orderId: clientOrderId } = useParams<
    keyof ClientOrderRouterParams
  >() as ClientOrderRouterParams;
  return (
    <>
      <button
        onClick={async () => {
          const liff = await getLineLiff();
          liff.shareTargetPicker([
            {
              type: 'text',
              text: 'Hello, World!',
            },
          ]);
        }}
      >
        click me
      </button>
      <ClientOrderContainer clientOrderId={clientOrderId} />
    </>
  );
}
