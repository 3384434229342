import { Box, Button, Typography } from 'Components/Base';
import MarkdownBox from 'Components/MarkdownBox';
import React from 'react';
import liff from '@line/liff';
import getLineLiff from 'Libs/Liff';
import { Form } from 'react-final-form';
import { HiddenFieldForm } from 'Components/UserInfoForm/UserInfoForm';
import { SatthaOrder } from 'Libs/Firebase/firestore/Order';

interface OrderGiftDetailsProps {
  orderInfo: SatthaOrder;
}

export default function OrderGiftDetails(props: OrderGiftDetailsProps) {
  const handleSubmit = async () => {
    // TODO: Call fe-functions to enable gift state for order

    // Send Gif via LINE share sheet
    await lineSendGift({
      name: 'Jane',
      message: `Jane ส่งชุดเสริมโชคลาภ จากไอ้ไข่ วัดเจดีย์ ให้เป็นของขวัญให้คุณผ่าน LINE ดูดวง

    ขอให้คุณมีความสุขสมหวังทุกประการ`,
    });
  };
  const lineSendGift = async ({ name, message }: { name: string; message: string }) => {
    // TODO: Create function to get gifting url
    const giftUrl = `https://www.google.com`;
    const liff = await getLineLiff();
    const newMessage = `${message}

กรุณากรอกคำขอพรผ่านลิงค์นี้ ${giftUrl}
    `;
    liff.shareTargetPicker(
      [
        {
          type: 'text',
          text: newMessage,
        },
      ],
      { isMultiple: false }
    );
  };

  return (
    <>
      <MarkdownBox>
        {`
### ขั้นตอนการส่งชุดขอพรให้เพื่อนออนไลน์

1. ปรับแก้ไขคำอวยพรของคุณด้านล่าง
2. กดปุ่มส่งของขวัญให้เพื่อน
3. เลือกเพื่อนที่คุณต้องการเพื่อส่งชุดขอพรให้เป็นของขวัญ
4. รอผู้รับกรอกข้อมูลส่วนตัวและคำขอพร
5. ระบบจะส่งข้อความยืนยันให้คุณเมื่อผู้รับเปิดลิงก์และทำการกรอกข้อมูล
6. ระบบจะส่งลิงก์ขั้นตอนและคำแนะนำในการขอพรออนไลน์ให้ผู้รับ 
7. ทางทีมงานจะส่งหลักฐานการขอพรผ่านทางไลน์
                  `}
      </MarkdownBox>
      <Button
        variant="contained"
        color="primary"
        size="large"
        stType="gradient"
        fullWidth
        onClick={lineSendGift}
      >
        🎁 ส่งของขวัญให้เพื่อน
      </Button>
      <Form
        onSubmit={handleSubmit}
        initialValues={{}}
        validate={(values) => {
          const errors: { [k: string]: any } = {};
          console.log(values);
          console.log('validations', errors);
          return errors;
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => {
          // console.log(values);
          return (
            <form onSubmit={handleSubmit}>
              <HiddenFieldForm />
            </form>
          );
        }}
      ></Form>
      <Box my={5}></Box>
      <Typography variant="body2" color="textSecondary" align="center">
        หากมีข้อสงสัย หรือต้องการสอบถามเพิ่มเติม กรุณาติดต่อที่อีเมล์{' '}
        <a href="mailto:hello@sattha.online">hello@sattha.online</a>
      </Typography>
    </>
  );
}
