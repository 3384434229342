import React, { Fragment } from 'react';
import ReactPlayer from 'react-player';
import { useGetStoreOrderUploadSubscriber } from 'Domain/StoreOrderUpload/hooks';
import Loading from 'Components/Loading';
// import StoreUploadedImagesBox from 'Routes/ProviderOrder/Components/OrderDetails/StoreUploadedImagesBox';
import { Box, Button } from 'Components/Base';
import { ButtonGroup, Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ImageBox, {
  IMAGE_BOX_MODE,
} from 'Routes/ClientOrder/Components/OrderDetails/Components/ImageBox';
import ReactMarkdown from 'Components/MarkdownBox';
import { QA_TYPE } from 'Routes/StoreOrder/Containers/types';
import FortuneTellingAnswerSummaryCard from 'Components/FortuneTellingAnswerSummaryCard';

type UploadImagesBoxProps = {
  imageList?: { url: string; type: string; contents?: string; name?: string; data?: any }[];
  asSideBar?: boolean;
};

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'transparent',
    color: 'rgba(0, 0, 0, 0.87)',
    maxHeight: '80vh',
    width: 'auto',
    fontSize: theme.typography.pxToRem(12),
    border: '0px solid transparent',
  },
}))(Tooltip);

function BackofficeStoreUploadedImagesBox({ imageList = [], asSideBar }: UploadImagesBoxProps) {
  return (
    <Box display="flex" flexDirection="row" overflow="auto" width={'100%'}>
      {imageList.length > 0 &&
        imageList.map((image) => (
          <Box key={image.url} p={1}>
            {image.type === 'IMAGE' && (
              <>
                <HtmlTooltip
                  title={
                    <img
                      src={image.url}
                      style={{
                        width: 'auto',
                        maxHeight: '80vh',
                      }}
                      alt={''}
                    />
                  }
                  placement={asSideBar ? 'left-start' : 'bottom'}
                >
                  <img
                    src={image.url}
                    style={{
                      width: 'auto',
                      maxHeight: 300,
                    }}
                    alt={''}
                  />
                </HtmlTooltip>
              </>
            )}
            {image.type === 'VIDEO' && (
              <>
                <ReactPlayer url={image.url} controls />
              </>
            )}
            {image.type === 'TEXT' && (
              <Box
                sx={{
                  // maxHeight: 200,
                  minWidth: 400,
                  overflow: 'auto',
                  maxWidth: asSideBar ? '100%' : 400,
                  border: '1px solid lightgrey',
                }}
                p={1}
              >
                <ReactMarkdown>{image.contents!}</ReactMarkdown>
              </Box>
            )}
            {image.type === 'OBJECT' && image.name === QA_TYPE && (
              <Box
                sx={{
                  overflow: 'auto',
                  minWidth: 400,
                  maxWidth: 400,
                }}
                p={1}
              >
                <FortuneTellingAnswerSummaryCard data={image.data!} />
              </Box>
            )}
          </Box>
        ))}
    </Box>
  );
}

interface ApprovalCardProps {
  id: string;
  orderId: string;
  separateByType?: boolean;
  asSideBar?: boolean;
}

export default function BackofficeStoreOrderUploadImages(props: ApprovalCardProps) {
  const { id, orderId, separateByType, asSideBar } = props;
  const { isExisted, data } = useGetStoreOrderUploadSubscriber(id);
  if (!isExisted) return <Loading />;
  const storeUploadImageList =
    data?.attachmentList.map((at) => ({
      url: at.url,
      type: at.type,
      contents: at.contents,
      name: at.name,
      data: at.data,
    })) || [];
  console.log(data?.attachmentList);
  if (!separateByType)
    return (
      <Box>
        <BackofficeStoreUploadedImagesBox
          imageList={[...storeUploadImageList]}
          asSideBar={asSideBar}
        />
      </Box>
    );

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <BackofficeStoreUploadedImagesBox
        imageList={storeUploadImageList.filter((list) => list.type === 'OBJECT')}
        asSideBar={asSideBar}
      />
      <BackofficeStoreUploadedImagesBox
        imageList={storeUploadImageList.filter((list) => list.type === 'TEXT')}
        asSideBar={asSideBar}
      />
      <BackofficeStoreUploadedImagesBox
        imageList={storeUploadImageList.filter((list) => list.type === 'IMAGE')}
        asSideBar={asSideBar}
      />
      <BackofficeStoreUploadedImagesBox
        imageList={storeUploadImageList.filter((list) => list.type === 'VIDEO')}
        asSideBar={asSideBar}
      />
    </Box>
  );
}
