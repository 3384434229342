export enum CAMPAIGN_ORDER_STATUS {
  NEW = 'NEW',
  COMPLETED = 'COMPLETED',
}

export interface CampaignOrderWallpaper {
  id: string;
  campaignCode: string;
  status: CAMPAIGN_ORDER_STATUS;
}
