import { Typography as MuiTypography, Theme } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';

const style = (theme: Theme) =>
  createStyles({
    number: {
      fontSize: theme.typography.pxToRem(20),
      fontWeight: 400,
    },
    footer: {
      fontSize: theme.typography.pxToRem(11.5),
      letterSpacing: '0.05em',
    },
    label: {
      fontSize: theme.typography.pxToRem(13),
      letterSpacing: '0.05em',
      lineHeight: 1.4,
      color: '#585757',
    },
    title: {
      fontWeight: 'bold',
      fontSize: theme.typography.h5.fontSize,
    },
    navigationTitle: {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: 600,
    },
    subtitle: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 600,
    },
    info: {
      fontSize: theme.typography.body1.fontSize,
      color: theme.palette.info.main,
    },
  });

/**
 * Overrides default material UI typography with some additional variants
 */
const Typography = ({ variant, classes, className, ...props }) => {
  const isCustom = Object.keys(classes).indexOf(variant) > -1;
  return (
    <MuiTypography
      className={isCustom ? clsx(classes[variant], className) : className}
      variant={isCustom ? undefined : variant}
      {...props}
    />
  );
};

export default withStyles(style)(Typography);
