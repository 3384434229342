import { Box, Button, Typography } from 'Components/Base';
import { ImageBox } from 'Components/Base/Box';
import React from 'react';
import { saveAs } from 'file-saver';

export default function DownloadWallpaperStep({ wallpaperUrl }: { wallpaperUrl: string }) {
  return (
    <Box>
      <Typography variant="h4">คุณได้รับ ดิจิตอลวอลเปเปอร์ เสริมดวงชะตาฟรี!</Typography>
      <Box mt={4} />
      <ImageBox src={wallpaperUrl} style={{ width: '100%' }} />
      <Box mt={4}></Box>
      <Button
        variant="contained"
        color="primary"
        size="large"
        stType="gradient"
        fullWidth
        onClick={() => {
          saveAs(wallpaperUrl);
        }}
      >
        ดาวน์โหลดวอลเปเปอร์
      </Button>
      <Box mt={2}></Box>
    </Box>
  );
}
