import React, { Fragment } from 'react';
import { FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { Box, Typography } from 'Components/Base';
import {
  HOROWALL_REQUEST_TYPE,
  HOROWALL_REQUEST_DISPLAY,
  getRequestTypeChoices,
} from 'Domain/Sku/Wallpaper';

export function HorowallRequestTypeInput({
  label,
  value,
  onBlur,
  onChange,
  placeholder,
  ...restProps
}: {
  label?: string;
  value: any;
  onBlur?: any;
  onChange?: any;
  placeholder?: any;
  [key: string]: any;
}) {
  return <Typography>{HOROWALL_REQUEST_DISPLAY[value]}</Typography>;
}

export function HorowallRequestSubTypeInput({
  label,
  value,
  onBlur,
  onChange,
  placeholder,
  horowallRequestType,
  ...restProps
}: {
  label?: string;
  value: any;
  onBlur?: any;
  onChange?: any;
  placeholder?: any;
  horowallRequestType: HOROWALL_REQUEST_TYPE;
  [key: string]: any;
}) {
  const availableChoices = getRequestTypeChoices(horowallRequestType) ?? [];
  return (
    <>
      <FormControl fullWidth>
        <RadioGroup id={restProps.id} value={value} onChange={onChange}>
          {availableChoices.map((choice) => (
            <Fragment key={choice.value}>
              <Box mt={2}></Box>
              <FormControlLabel
                value={choice.value}
                control={<Radio />}
                label={choice.display}
                sx={
                  {
                    // alignItems: 'flex-start',
                  }
                }
              />
            </Fragment>
          ))}
        </RadioGroup>
      </FormControl>
    </>
  );
}
