import { getDisplayValue } from './utils';

export const mankarinDesignNeeds = [
  {
    display: 'งานราบรื่น',
    value: 'NEED_1',
  },
  {
    display: 'เงินคล่องตัว',
    value: 'NEED_2',
  },
  {
    display: 'โชคลาภ',
    value: 'NEED_3',
  },
  {
    display: 'ความรักสมหวัง',
    value: 'NEED_4',
  },
  {
    display: 'มีเสน่ห์น่าสนใจ',
    value: 'NEED_5',
  },
  {
    display: 'สุขภาพดีและมีพระคุ้มครอง',
    value: 'NEED_6',
  },
  {
    display: 'ชื่อเสียง',
    value: 'NEED_7',
  },
  {
    display: 'อำนาจบารมี',
    value: 'NEED_8',
  },
  {
    display: 'คนเมตตาอุปถัมภ์',
    value: 'NEED_9',
  },
  {
    display: 'เลื่อนขั้นเลื่อนตำแหน่ง',
    value: 'NEED_10',
  },
  {
    display: 'เจรจาค้าขายเก่ง',
    value: 'NEED_11',
  },
  {
    display: 'มีปัญญาและไหวพริบ',
    value: 'NEED_12',
  },
  {
    display: 'อื่นๆ (กรอกข้อมูลด้านล่าง)',
    value: 'NEED_OTHERS',
  },
];

export const mankarinDesignOptions = [
  { display: 'เบอร์โทรศัพท์', value: 'PHONE_NO' },
  { display: 'เลขทะเบียนรถ', value: 'CAR_PLATE_NO' },
  { display: 'เลขที่บ้าน (กรอกเลขที่บ้านด้านล่าง)', value: 'HOME_NO' },
  { display: 'เลขต่อท้ายชื่อไลน์/อีเมล์/Profile Name', value: 'PROFILE_NO' },
  { display: 'เลขพาสเวิร์ดเข้าบัญชีต่างๆ', value: 'PASSWORD_NO' },
];

export const getMankarinDesignTopicDisplay = getDisplayValue(mankarinDesignOptions);

export const getMankarinNeedsDisplaySingle = getDisplayValue(mankarinDesignNeeds);
export const getMankarinNeedsDisplay = (needs: string[]) => {
  if (!needs) return;
  return needs.map((need) => getMankarinNeedsDisplaySingle(need)).join(',\n');
};
