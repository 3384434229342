import { ChantNameMultipleInputData } from '../../../FormGenerator/Input/ChantNameMultipleInput';
import {
  FormGeneratorBlockHeading,
  FormGeneratorInput,
  FormGeneratorInputTypeChantNameMultiple,
  FormGeneratorInputTypeTextArea,
  FORM_GENERATOR_INPUT_TYPE,
} from '../../../FormGenerator/types';
import { FORM_FIELDS } from '../fields';

export const SURIYA_INITIAL_DATA = {
  chantNames: {
    names: [],
    isAnonymous: false,
  } as ChantNameMultipleInputData,
  chantMessage: undefined,
};

export function getInitialData(skuData: any, initialData: any) {
  const { chantName, chantMessage, meritData } = initialData ?? {};
  const { chantNames, chantAnonymous } = meritData ?? {};
  if (chantNames === null || chantNames === undefined) return SURIYA_INITIAL_DATA;
  return { chantNames: { names: chantNames, isAnonymous: chantAnonymous }, chantMessage };
}

export const TEMPLATE_SURIYA: FormGeneratorInput[] = [
  { title: 'รายละเอียด', type: FORM_GENERATOR_INPUT_TYPE.HEADING } as FormGeneratorBlockHeading,
  {
    name: FORM_FIELDS.CHANT_NAMES,
    label: 'ชื่อนามสกุล',
    type: FORM_GENERATOR_INPUT_TYPE.CHANT_NAME_MULTIPLE,
  } as FormGeneratorInputTypeChantNameMultiple,
  {
    name: FORM_FIELDS.CHANT_MESSAGE,
    label: 'อุทิศบุญให้ (ถ้าไม่มีให้กรอก - )',
    type: FORM_GENERATOR_INPUT_TYPE.TEXT_AREA,
    maxLength: 100,
    rows: 3,
  } as FormGeneratorInputTypeTextArea,
];

export function suriyaValidations(formValues: any) {
  const errors = {};
  const chantNames = formValues[FORM_FIELDS.CHANT_NAMES] as ChantNameMultipleInputData;
  if (!chantNames) {
    errors[FORM_FIELDS.CHANT_NAMES] = { names: ['กรุณากรอกชื่อ-นามสกุล'] };
  } else {
    if (!chantNames.isAnonymous) {
      if (chantNames.names.length === 0) {
        errors[FORM_FIELDS.CHANT_NAMES] = { names: ['กรุณากรอกชื่อ-นามสกุล'] };
      } else {
        const nameErrors = chantNames.names.map((name) => {
          if (name === null || name === undefined) return 'กรุณากรอกชื่อ-นามสกุล';
          if (name.trim() === '') return 'กรุณากรอกชื่อ-นามสกุล';
          return undefined;
        });
        errors[FORM_FIELDS.CHANT_NAMES] = { names: nameErrors };
      }
    }
  }
  const chantMessage = formValues[FORM_FIELDS.CHANT_MESSAGE];
  if (!chantMessage) {
    errors[FORM_FIELDS.CHANT_MESSAGE] = 'กรุณากรอกช่องอุทิศบุญ';
  }
  return errors;
}
