import React from 'react';
import { Form, Field } from 'react-final-form';
import {
  Paper,
  FormControl,
  TextField,
  FormHelperText,
  Select,
  InputLabel,
  MenuItem,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { Box, Button, Typography } from 'Components/Base';
import { CHANT_MODE, getUserInfoForm, UIFORM_TEXT_FIELDS } from 'Domain/Sattha/Chant';
import { AvailableSkuItem, OrderShippingData } from 'Libs/Firebase/firestore/Order';
import FixBadluckForm, { availableMonths } from './Components/FixBadLuckForm';
import { getTimeInput } from 'Domain/Sku/FixBadLuck';
import { FIX_BADLUCK_MODE, getFixBadLuckMode } from 'Domain/Orders/utils';
import SelectPackageForm from './Components/SelectPackageForm';
import ChantForm from './Components/ChantForm';
import ShippingForm from './Components/ShippingForm';
import HorowallWallpaperForm from './Components/HorowallWallpaperForm';
import { HOROWALL_REQUEST_TYPE } from 'Domain/Sku/Wallpaper';
import { UserInfoFormDataWallpaper } from './Forms/WallpaperForm';
import { UserInfoFormDataWreath } from './Forms/WreathForm';

const useStyles = makeStyles((theme) => ({
  hiddenField: {
    display: 'none',
  },
  gradientButton: {
    background: `linear-gradient(314.47deg, #01C1A8 0%, #1DCE59 90.84%)`,
    borderRadius: 2,
    color: 'white',
    width: '100%',
    '&:disabled': {
      color: `#969696`,
      background: '#E6E6E6',
    },
  },
}));

export function HiddenFieldForm() {
  const classes = useStyles();
  return (
    <>
      <FormControl fullWidth className={classes.hiddenField}>
        <Field
          name="orderId"
          render={(props) => (
            <TextField {...props.input} hidden className={classes.hiddenField} fullWidth />
          )}
        />
      </FormControl>
      <FormControl fullWidth className={classes.hiddenField}>
        <Field
          name="mode"
          render={(props) => (
            <TextField {...props.input} hidden className={classes.hiddenField} fullWidth />
          )}
        />
      </FormControl>
    </>
  );
}

export interface UserInfoFormData {
  orderId: string;
  mode: CHANT_MODE;
  chantName: string;
  chantMessage: string;
  shippingName?: string;
  shippingAddress?: string;
  shippingPhoneNo?: string;
  shippingPostcode?: string;
  skuCode?: string;
  birthDate?: number;
  birthMonth?: number;
  birthTime?: string;
  birthYear?: string;
}

export interface UserInfoFormDataChantFix {
  orderId: string;
  mode: CHANT_MODE;
  chantName: string;
  chantMessage: string;
  shippingName?: string;
  shippingAddress?: string;
  shippingPhoneNo?: string;
  shippingPostcode?: string;
  skuCode?: string;
  birthDate?: number;
  birthMonth?: number;
  birthTime?: string;
  birthYear?: { zodiacAge: number; zodiacYear: string; birthYear: number };
}

export type UserInfoFormDataOnSubmit =
  | UserInfoFormDataChantFix
  | UserInfoFormDataWallpaper
  | UserInfoFormDataWreath;

export interface UserInfoFormInitialData {
  chantName?: string;
  chantMessage?: string;
}

interface CreateOrderFormProps {
  onSubmit?: (formValues: UserInfoFormDataOnSubmit) => void;
  orderId: string;
  orderRealId: string;
  locationInstructions?: string;
  locationId: string;
  isLoading: boolean;
  mode: CHANT_MODE;
  initialData?: UserInfoFormInitialData;
  haveShipping?: boolean;
  shippingData?: OrderShippingData;
  showPackageSelection: boolean;
  enablePackageSelection: boolean;
  skuMasterCode?: string;
  availableSkus?: AvailableSkuItem[];
  wallpaperRequestType?: HOROWALL_REQUEST_TYPE;
}

enum ORDER_MODE {
  FULFILLMENT = 'FULFILLMENT',
  SHIPPING = 'SHIPPING',
}

export default function CreateOrderForm(props: CreateOrderFormProps) {
  const classes = useStyles();

  const {
    orderId,
    orderRealId,
    onSubmit = () => {
      /** */
    },
    locationId,
    isLoading,
    mode,
    initialData,
    haveShipping,
    shippingData,
    showPackageSelection,
    enablePackageSelection,
    skuMasterCode,
    availableSkus,
    wallpaperRequestType,
  } = props;

  const FORM_TEXT = getUserInfoForm(mode);
  const { chantName, chantMessage } = initialData ?? {};

  const orderMode = haveShipping ? ORDER_MODE.SHIPPING : ORDER_MODE.FULFILLMENT;
  let newInitialData = { orderId, mode, chantMessage, chantName, wallpaperRequestType };
  if (shippingData) {
    newInitialData = { ...newInitialData, ...shippingData };
  }
  const handleSubmit = (values: UserInfoFormData, ...restArgs) => {
    // console.log('handleSubmit', values);
    const { birthYear, ...restValue } = values;
    const newValues: UserInfoFormDataOnSubmit = { ...restValue };
    if (birthYear) {
      newValues.birthYear = JSON.parse(birthYear) as {
        zodiacAge: number;
        zodiacYear: string;
        birthYear: number;
      };
    }
    onSubmit(newValues);
  };

  const fixBadLuckMode = getFixBadLuckMode(locationId);
  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={newInitialData}
        validate={(values) => {
          const errors: { [k: string]: any } = {};
          if (showPackageSelection && enablePackageSelection) {
            if (!values.skuCode) {
              errors.skuCode = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_SELECT_PACAKGE];
            }
          }
          if (!values.chantName) {
            errors.chantName = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_NAME_REQUIRED];
          }

          // Checking by mode
          if ([CHANT_MODE.VOW, CHANT_MODE.WISH].includes(mode)) {
            if (!values.chantMessage) {
              errors.chantMessage = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_CHANT_REQUIRED];
            }
            if (values.chantMessage) {
              const numberOfLines = values.chantMessage.split('\n').length;
              if (numberOfLines > 3) {
                errors.chantMessage = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_CHANT_MESSAGE_MAX_LINES];
              }
            }
          } else if ([CHANT_MODE.FIX_BADLUCK].includes(mode)) {
            if (fixBadLuckMode === FIX_BADLUCK_MODE.ZODIAC_REQUIRED) {
              if (!values.birthDate && values.birthDate !== 0) {
                errors.birthDate = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHDATE_REQUIRED];
              }
              if (!values.birthMonth && values.birthMonth !== 0) {
                errors.birthMonth = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHMONTH_REQUIRED];
              } else {
                if (values.birthDate && values.birthMonth > 0 && values.birthDate > 0) {
                  const monthObj = availableMonths.find((m) => m.value === values.birthMonth);
                  if (monthObj?.maxDays && values.birthDate > monthObj?.maxDays) {
                    errors.birthDate = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHDATE_NOT_VALID];
                  }
                }
              }
              if (!values.birthTime) {
                errors.birthTime = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_REQUIRED];
              } else {
                try {
                  const { hour, minute, notKnow } = getTimeInput(values.birthTime);
                } catch (error) {
                  if (error.message === 'Not Valid Format') {
                    errors.birthTime = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_NOT_IN_FORMAT];
                  } else if (error.message === 'Time not in range') {
                    errors.birthTime =
                      FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_NOT_IN_TIME_FORMAT];
                  } else {
                    errors.birthTime = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_NOT_IN_FORMAT];
                  }
                }
              }
              if (!values.birthYear) {
                errors.birthYear = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHYEAR_REQUIRED];
              }
            }
          } else if ([CHANT_MODE.WALLPAPER].includes(mode)) {
            // TODO: WALLPAPER Validate Form Input Here
          } else {
            // Fallback case, should not happen
          }

          // If order has shipping bundle
          if (orderMode === ORDER_MODE.SHIPPING) {
            if (!values.shippingName) {
              errors.shippingName = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_SHIPPING_NAME];
            }
            if (!values.shippingAddress) {
              errors.shippingAddress = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_SHIPPING_ADDRESS];
            }
            if (values.shippingPostcode) {
              // const regex = /^\d{5}$/;
              // if (!regex.test(values.shippingPostcode)) {
              //   errors.shippingPostcode = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_SHIPPING_POSTCODE];
              // }
            } else {
              errors.shippingPostcode = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_SHIPPING_POSTCODE];
            }
            if (values.shippingPhoneNo) {
              // const regex =
              //   /^(((\+66|0)(\d{1,2}\-?\d{3}\-?\d{3,4}))|((\+๖๖|๐)([๐-๙]{1,2}\-?[๐-๙]{3}\-?[๐-๙]{3,4})))$/;
              // if (!regex.test(values.shippingPhoneNo)) {
              //   errors.shippingPhoneNo =
              //     FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_SHIPPING_PHONENO_FORMAT];
              // }
            } else {
              errors.shippingPhoneNo = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_SHIPPING_PHONENO];
            }
          }

          console.log('validations', errors);
          return errors;
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => {
          console.log(values);
          return (
            <form onSubmit={handleSubmit}>
              <HiddenFieldForm />
              <Paper elevation={0} style={{ padding: 16 }}>
                {showPackageSelection && skuMasterCode && (
                  <>
                    <SelectPackageForm
                      FORM_TEXT={FORM_TEXT}
                      skuMasterCode={skuMasterCode}
                      enable={enablePackageSelection}
                      availableSkus={availableSkus}
                    />
                    <Box my={3}></Box>
                  </>
                )}
                {[CHANT_MODE.VOW, CHANT_MODE.WISH].includes(mode) && (
                  <>
                    <Typography variant="h3">
                      {FORM_TEXT[UIFORM_TEXT_FIELDS.HEADER_NAME]}
                    </Typography>
                    <ChantForm
                      FORM_TEXT={FORM_TEXT}
                      locationId={locationId}
                      mode={mode}
                      orderRealId={orderRealId}
                    />
                  </>
                )}
                {[CHANT_MODE.FIX_BADLUCK].includes(mode) && (
                  <>
                    {fixBadLuckMode === FIX_BADLUCK_MODE.ZODIAC_REQUIRED && (
                      <Typography variant="h3">
                        {FORM_TEXT[UIFORM_TEXT_FIELDS.HEADER_NAME]}
                      </Typography>
                    )}
                    {fixBadLuckMode === FIX_BADLUCK_MODE.NAME_REQUIRED && (
                      <Typography variant="h3">
                        {FORM_TEXT[UIFORM_TEXT_FIELDS.HEADER_NAME_ALTERNATIVE]}
                      </Typography>
                    )}
                    <FixBadluckForm
                      FORM_TEXT={FORM_TEXT}
                      locationId={locationId}
                      mode={mode}
                      fixBadLuckMode={fixBadLuckMode}
                    />
                  </>
                )}
                {[CHANT_MODE.WALLPAPER].includes(mode) && (
                  <>
                    <Typography variant="h3">
                      {FORM_TEXT[UIFORM_TEXT_FIELDS.HEADER_NAME]}
                    </Typography>
                    <HorowallWallpaperForm
                      FORM_TEXT={FORM_TEXT}
                      locationId={locationId}
                      mode={mode}
                      wallpaperRequestType={wallpaperRequestType!}
                    />
                  </>
                )}
                <Box my={5}></Box>
                {orderMode === ORDER_MODE.SHIPPING && (
                  <>
                    <Typography variant="h3">
                      {FORM_TEXT[UIFORM_TEXT_FIELDS.HEADER_SHIPPING]}
                    </Typography>
                    <Box my={3}></Box>
                    <ShippingForm FORM_TEXT={FORM_TEXT} />
                    <Box my={5}></Box>
                  </>
                )}
                {form.getState().hasValidationErrors && (
                  <>
                    <FormHelperText error>{'มีข้อมูลไม่ถูกต้อง'}</FormHelperText>
                    {Object.values(form.getState().errors || {}).map((errorText) => (
                      <FormHelperText error key={errorText}>{`- ${errorText}`}</FormHelperText>
                    ))}
                    <Box my={1}></Box>
                  </>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="large"
                  stType="gradient"
                  fullWidth
                  disabled={form.getState().hasValidationErrors || isLoading}
                >
                  {orderMode === ORDER_MODE.FULFILLMENT
                    ? FORM_TEXT[UIFORM_TEXT_FIELDS.CONFIRM_BUTTON]
                    : FORM_TEXT[UIFORM_TEXT_FIELDS.CONFIRM_BUTTON_SHIPPING]}
                </Button>
              </Paper>
            </form>
          );
        }}
      ></Form>
    </>
  );
}
