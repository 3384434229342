import liff from '@line/liff';
import getConfig from 'Libs/Config/utils';

const config = getConfig();
async function getLineLiff() {
  if (!liff.id) {
    await liff.init({ liffId: config.liffId });
  }
  return liff;
}

export { liff };

export default getLineLiff;
