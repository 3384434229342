import React, { Fragment, useState } from 'react';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { Box, Typography } from 'Components/Base';
import {
  getAvailableMTWSubTopics,
  getMTWTemplate,
  MTW_REQUEST_TYPES,
  MTW_SUBTOPICS,
  getAvailableMTWSubSubTopics,
  getAvailableMTWOptions,
  getAvailableMTWDeviceType,
  MTW_DEVICE_TYPES,
  mtwAvailableMedicalProblems,
  MTW_MEDICAL_PROBLEMS,
} from 'Domain/Sku/MTWWallpaper';
import MarkdownBox from 'Components/MarkdownBox';
import { PrivateDataDialog } from '../Components/ConfirmationButton/ConfirmationButton';

export function MTWRequestTypeInput({
  label,
  value,
  onBlur,
  onChange,
  placeholder,
  ...restProps
}: {
  label?: string;
  value: any;
  onBlur?: any;
  onChange?: any;
  placeholder?: any;
  [key: string]: any;
}) {
  const template = getMTWTemplate(value);
  return <Typography>{template?.display}</Typography>;
}

export function MTWRequestSubTypeInput({
  name,
  label,
  value,
  onBlur,
  onChange,
  placeholder,
  inputRequestType,
  wallpaperRequestOption,
  ...restProps
}: {
  name: string;
  label?: string;
  value: any;
  onBlur?: any;
  onChange?: any;
  placeholder?: any;
  inputRequestType: MTW_REQUEST_TYPES;
  wallpaperRequestOption?: string;
  [key: string]: any;
}) {
  const template = getMTWTemplate(inputRequestType);
  let availableChoices = getAvailableMTWSubTopics(template) ?? [];
  if (wallpaperRequestOption) {
    const topic = template.availableTopics.find((v) => v.value === wallpaperRequestOption);
    if (topic?.forceSelection) {
      availableChoices = availableChoices.filter((c) => c.value === topic.forceSelection);
    }
  }
  const input = {
    value,
    onBlur,
    onChange,
    ...restProps,
  };
  return (
    <>
      <Typography variant="h5">{template.requestTypeLabel}</Typography>
      <FormControl fullWidth>
        <Select {...input} displayEmpty variant="outlined" fullWidth>
          <MenuItem value="" disabled>
            {template.requestTypeLabel}
          </MenuItem>
          {availableChoices.map((data) => (
            <MenuItem key={data.value} value={data.value}>
              {data.display}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}

export function MTWRequestSubSubTypeInput({
  name,
  label,
  value,
  onBlur,
  onChange,
  placeholder,
  inputRequestType,
  subTopic,
  ...restProps
}: {
  name: string;
  label?: string;
  value: any;
  onBlur?: any;
  onChange?: any;
  placeholder?: any;
  inputRequestType: MTW_REQUEST_TYPES;
  subTopic?: string;
  [key: string]: any;
}) {
  const template = getMTWTemplate(inputRequestType);
  const availableChoices = getAvailableMTWSubSubTopics(subTopic as MTW_SUBTOPICS) ?? [];
  return (
    <>
      <FormControl fullWidth>
        <RadioGroup id={restProps.id} value={value} onChange={onChange}>
          {availableChoices.map((choice) => (
            <Fragment key={choice.value}>
              <Box mt={0}></Box>
              <FormControlLabel
                name={name}
                value={choice.value}
                control={<Radio />}
                label={choice.display}
                checked={choice.value === value}
              />
            </Fragment>
          ))}
        </RadioGroup>
      </FormControl>
    </>
  );
}

export function MTWRequestOptionsInput({
  label,
  value,
  onBlur,
  onChange,
  placeholder,
  inputRequestType,
  ...restProps
}: {
  name: string;
  label?: string;
  value: any;
  onBlur?: any;
  onChange?: any;
  placeholder?: any;
  inputRequestType: MTW_REQUEST_TYPES;
  [key: string]: any;
}) {
  const template = getMTWTemplate(inputRequestType);
  const availableChoices = getAvailableMTWOptions(template) ?? [];
  return (
    <>
      <Typography variant="h5">{template.selectTopicLabel}</Typography>
      <FormControl fullWidth>
        <RadioGroup id={restProps.id} value={value} onChange={onChange}>
          {availableChoices.map((choice) => (
            <Fragment key={choice.value}>
              <Box mt={1}></Box>
              <FormControlLabel
                value={choice.value}
                control={<Radio />}
                label={choice.display}
                checked={choice.value === value}
              />
              {choice.descriptions && (
                <Typography variant="subtitle2" mt={-2}>
                  <MarkdownBox>{choice.descriptions}</MarkdownBox>
                </Typography>
              )}
            </Fragment>
          ))}
        </RadioGroup>
      </FormControl>
    </>
  );
}

export function MTWRequestDeviceInput({
  label,
  value,
  onBlur,
  onChange,
  placeholder,
  inputRequestType,
  subTopic,
  forceDeviceType,
  ...restProps
}: {
  label?: string;
  value: any;
  onBlur?: any;
  onChange?: any;
  placeholder?: any;
  inputRequestType: MTW_REQUEST_TYPES;
  subTopic?: string;
  forceDeviceType?: MTW_DEVICE_TYPES;
  [key: string]: any;
}) {
  const template = getMTWTemplate(inputRequestType);
  let availableChoices = getAvailableMTWDeviceType(template);
  if (forceDeviceType) {
    availableChoices = availableChoices.filter((device) => device.value === forceDeviceType);
  }
  return (
    <>
      <FormControl fullWidth>
        <RadioGroup id={restProps.id} value={value} onChange={onChange}>
          {availableChoices.map((choice) => (
            <Fragment key={choice.value}>
              <Box mt={0}></Box>
              <FormControlLabel
                value={choice.value}
                control={<Radio />}
                label={choice.display}
                checked={choice.value === value}
              />
            </Fragment>
          ))}
        </RadioGroup>
      </FormControl>
    </>
  );
}

enum CONFIRMATION_STATE {
  NONE = 'NONE',
  OPEN = 'OPEN',
}

export function MTWMedicalProbemInput({
  label,
  value,
  onBlur,
  onChange,
  placeholder,
  ...restProps
}: {
  label?: string;
  value: any;
  onBlur?: any;
  onChange?: any;
  placeholder?: any;
  [key: string]: any;
}) {
  const [confirmationState, setConfirmationState] = useState(CONFIRMATION_STATE.NONE);
  const [eventState, setEventState] = useState<SelectChangeEvent<any> | null>(null);
  const handleConfirm = () => {
    setConfirmationState(CONFIRMATION_STATE.NONE);
    onChange(eventState);
  };
  const handleCancel = () => {
    setConfirmationState(CONFIRMATION_STATE.NONE);
    onChange(undefined);
  };
  const handleInputChanged = (e: SelectChangeEvent<any>) => {
    if (![MTW_MEDICAL_PROBLEMS.NONE].includes(e.target.value)) {
      setConfirmationState(CONFIRMATION_STATE.OPEN);
      setEventState(e);
    } else {
      onChange(e);
    }
  };
  return (
    <>
      <PrivateDataDialog
        open={confirmationState === CONFIRMATION_STATE.OPEN}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
      <FormControl fullWidth>
        <RadioGroup id={restProps.id} value={value} onChange={handleInputChanged}>
          {mtwAvailableMedicalProblems.map((choice) => (
            <Fragment key={choice.value}>
              <Box mt={0}></Box>
              <FormControlLabel
                value={choice.value}
                control={<Radio />}
                label={choice.display}
                checked={choice.value === value}
              />
            </Fragment>
          ))}
        </RadioGroup>
      </FormControl>
    </>
  );
}
