import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export const PHONE_NO_REGEX = /^(((\+66|0)(\d{1,2}\-?\d{3}\-?\d{3,4})))$/;
export const EMAIL_REGEX = /^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,}$/;
export const ENGLISH_NAME_REGEX = /^[a-zA-Z][a-zA-Z '-.‘’]+$/;

const now = dayjs();
const currentYear = now.locale('en').year() + 543;
const MIN_AGE = 13;

export const validateDate = ({
  day,
  month,
  year,
}: {
  day: number;
  month: number;
  year: number;
}) => {
  const dayObj = dayjs(
    `${year - 543}-${(month + '').padStart(2, '0')}-${(day + '').padStart(2, '0')}`,
    'YYYY-MM-DD',
    true
  );
  return dayObj.isValid();
};

export const validateAge = ({ day, month, year }: { day: number; month: number; year: number }) => {
  const now = dayjs();
  const birthDay = dayjs(
    `${year - 543}-${(month + '').padStart(2, '0')}-${(day + '').padStart(2, '0')}`,
    'YYYY-MM-DD'
  );
  const age = now.diff(birthDay, 'years');
  console.log(birthDay.format('YYYY-MM-DD'), `age is`, age);
  if (age >= MIN_AGE) return true;
  return false;
};

export const availableYears = Array.from(Array(120)).map((_, i) => ({
  display: `${currentYear - MIN_AGE - i} / ${currentYear - MIN_AGE - i - 543}`,
  value: currentYear - MIN_AGE - i,
}));

export const availableDays = Array.from(Array(31)).map((_, i) => ({
  display: `${i + 1}`,
  value: i + 1,
}));

export const availableMonths = [
  { display: 'มกราคม', value: 1, maxDays: 31 },
  { display: 'กุมภาพันธ์', value: 2, maxDays: 29 },
  { display: 'มีนาคม', value: 3, maxDays: 31 },
  { display: 'เมษายน', value: 4, maxDays: 30 },
  { display: 'พฤษภาคม', value: 5, maxDays: 31 },
  { display: 'มิถุนายน', value: 6, maxDays: 30 },
  { display: 'กรกฎาคม', value: 7, maxDays: 31 },
  { display: 'สิงหาคม', value: 8, maxDays: 31 },
  { display: 'กันยายน', value: 9, maxDays: 30 },
  { display: 'ตุลาคม', value: 10, maxDays: 31 },
  { display: 'พฤศจิกายน', value: 11, maxDays: 30 },
  { display: 'ธันวาคม', value: 12, maxDays: 31 },
];
