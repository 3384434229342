import { useQuery, useMutation } from '@apollo/client';
import { getCampaignOrder, getWallpaperCampaignOrder, selectWallpaperCampaignOrder } from './query';

export function useGetCampaignOrder(orderId: string) {
  const { loading, error, data } = useQuery(getCampaignOrder, {
    variables: { orderId },
  });
  return { loading, error, data };
}

export function useGetWallpaperCampaign2021Order(orderId: string) {
  const { loading, error, data, refetch } = useQuery(getWallpaperCampaignOrder, {
    variables: { orderId },
  });
  const refetchData = () => refetch({ orderId });
  return { loading, error, data, refetch: refetchData };
}

export function useSelectWallpaperCampaign2021Order() {
  return useMutation(selectWallpaperCampaignOrder);
}
