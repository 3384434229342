import { Divider } from '@mui/material';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Box, Container, Typography } from 'Components/Base';
import Heading from 'Components/Layout/Heading';
import React, { useState, useEffect } from 'react';
import StoreLoginForm, { UserLoginFormData } from './Components/StoreLoginForm';
import Loading from 'Components/Loading';

import { useAuthContext } from 'Domain/auth/hooks';
import { AUTH_ERROR_CODE, AUTH_STATE } from 'Domain/auth/types';
import { useGetStoreData } from 'Domain/Stores/hooks';

interface StoreLoginRouterParams {
  storeId?: string;
}

enum STORE_LOGIN_STATE {
  INIT,
  ENTERING,
  ERROR_NO_USER,
  ERROR_LOGIN,
}

const mapErrorMessage = (authError?: AUTH_ERROR_CODE) => {
  if (!authError) return undefined;
  switch (authError) {
    case AUTH_ERROR_CODE.INVALID_PASSWORD:
      return 'รหัสไม่ถูกต้อง';

    default:
      return 'เกิดข้อผิดพลาด';
  }
};

interface LocationState {
  from: { pathname: string };
}

const STORE_REGEX = /^\/store\/([^\/]+).*$/;

export default function StoreLogin() {
  const routerParams = useParams<keyof StoreLoginRouterParams>() as StoreLoginRouterParams;
  const navigate = useNavigate();
  const location = useLocation();
  const [userLoginInitialData, setUserLoginInitialData] = useState<any>(null);
  const [userLoginState, setUserLoginState] = useState<STORE_LOGIN_STATE>(STORE_LOGIN_STATE.INIT);
  const [isLoading, setIsLoading] = useState(true);
  const { loading, authState, loginWithEmailAndPassword, authError } = useAuthContext();

  console.log(loading, authState, authError);
  console.log(location.state);

  let storeId = routerParams.storeId;
  if (!storeId) {
    const pathParseResult = STORE_REGEX.exec((location.state as LocationState)?.from?.pathname);
    if (pathParseResult && pathParseResult[1]) {
      storeId = pathParseResult[1];
    }
  }
  const { loading: storeLoading, data: storeData } = useGetStoreData(storeId);

  useEffect(() => {
    if (!storeLoading && storeData) {
      setIsLoading(false);
      const { id, name, email } = storeData;
      setUserLoginInitialData({ email, name });
    } else if (!storeLoading && !storeData) {
      setIsLoading(false);
    }

    return;
  }, [storeLoading, storeData]);

  const handleStoreLogin = (formValues: UserLoginFormData) => {
    const { email, password } = formValues;
    if (loginWithEmailAndPassword) loginWithEmailAndPassword({ email, password });
  };

  const errorMessage = mapErrorMessage(authError);

  useEffect(() => {
    if (authState === AUTH_STATE.LOGGED_IN) {
      navigate((location.state as LocationState)?.from || `/store/${storeId}`);
    }
    return () => {
      //
    };
  }, [authState, location, navigate, storeId]);

  return (
    <Container>
      <Heading title="เข้าสู่ระบบร้านค้า" />
      <Typography>สำหรับร้านค้าของ ศรัทธา.online เท่านั้น</Typography>
      {isLoading && <Loading />}
      {!isLoading && (
        <StoreLoginForm
          {...userLoginInitialData}
          onSubmit={handleStoreLogin}
          errorMessage={errorMessage}
        />
      )}
      <Typography variant="footer">
        หากพบปัญหาการใช้งานสามารถแจ้ง LINE{' '}
        <a href="https://line.me/R/ti/p/@sattha.online">@sattha.online</a>
      </Typography>
    </Container>
  );
}
