import React from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';

import Store from './Routes/Store';
import StoreOrderSummary from './Routes/StoreOrderSummary';
import StoreOrder from './Routes/StoreOrder';
import StoreLogin from './Routes/StoreLogin';
import Logout from './Routes/Logout';

import PrivateRoute from 'Domain/auth/PrivateRoute';
import BackofficeLogin from 'Routes/BackofficeLogin';
import BackofficeOrderSummary from 'Routes/BackofficeOrderSummary';
import BackofficeOrderSummaries from 'Routes/BackofficeOrderSummaries';
import ClientOrder from 'Routes/ClientOrder';
import BackofficeShipmentUpload from 'Routes/BackofficeShipmentUpload';
import BackofficeWreathOrders from 'Routes/BackofficeWreathOrders';
import StoreWreathOrders from 'Routes/StoreWreathOrders';
import StoreSummaryList from 'Routes/StoreSummaryList';
import StoreFortuneTellingLiveOrders from 'Routes/StoreFortuneTellingLiveOrders';
import ClientOrder2 from 'Routes/ClientOrder2';
import ClientLogin from 'Routes/ClientLogin';

import getConfig from 'Libs/Config/utils';
import Terms from 'Routes/PDPA/Terms';
import Privacy from 'Routes/PDPA/Privacy';
import ClientRedeem from 'Routes/ClientRedeem/ClientRedeem';

const config = getConfig();
const enableClientAuth = config.enableClientAuth;

const isClientMode = process.env.REACT_APP_SHOW_CLIENT_ROUTES === 'true';
const isStoreMode = process.env.REACT_APP_SHOW_STORE_ROUTES === 'true';
const isBackofficeMode = process.env.REACT_APP_SHOW_BACKOFFICE_ROUTES === 'true';
const isMaintenance = process.env.REACT_APP_SHOW_MAINTENANCE === 'true';

function RoutesApp(): JSX.Element {
  return (
    <Router>
      <Routes>
        <Route path="/terms" element={<Terms />}></Route>
        <Route path="/privacy" element={<Privacy />}></Route>
        {!isMaintenance && isClientMode && (
          <Route path="/client/login" element={<ClientLogin />}></Route>
        )}
        {!isMaintenance && isClientMode && !enableClientAuth && (
          <Route path="/myorder/:orderId" element={<ClientOrder />}></Route>
        )}
        {!isMaintenance && isClientMode && (
          <Route path="/redeem" element={<ClientRedeem />}></Route>
        )}
        {!isMaintenance && isClientMode && enableClientAuth && (
          <Route
            path="/myorder/:orderId"
            element={
              <PrivateRoute redirectTo="/client/login" useUrlParams>
                <ClientOrder />
              </PrivateRoute>
            }
          ></Route>
        )}
        {!isMaintenance && isClientMode && (
          <Route
            path="/myorder2/:orderId"
            element={
              <PrivateRoute redirectTo="/client/login" useUrlParams>
                <ClientOrder2 />
              </PrivateRoute>
            }
          ></Route>
        )}
        {!isMaintenance && isStoreMode && (
          <Route path="/store/login" element={<StoreLogin />}></Route>
        )}
        {!isMaintenance && isStoreMode && (
          <Route
            element={
              <PrivateRoute redirectTo="/store/login">
                <Outlet />
              </PrivateRoute>
            }
          >
            <Route path="/store/:storeId/wreath/:mode" element={<StoreWreathOrders />}></Route>
            <Route path="/store/:storeId/wreath" element={<StoreWreathOrders />}></Route>
            <Route
              path="/store/:storeId/fortune-telling/live"
              element={<StoreFortuneTellingLiveOrders />}
            ></Route>
            <Route
              path="/store/:storeId/fortune-telling/live/:agentId"
              element={<StoreFortuneTellingLiveOrders />}
            ></Route>
            <Route path="/store/:storeId/summary" element={<StoreSummaryList />}></Route>
            <Route
              path="/store/:storeId/summary/:orderSummaryId/:mode"
              element={<StoreOrderSummary />}
            ></Route>
            <Route
              path="/store/:storeId/summary/:orderSummaryId"
              element={<StoreOrderSummary />}
            ></Route>
            <Route path="/store/:storeId/order/:orderId" element={<StoreOrder />}></Route>
            <Route path="/store/:storeId" element={<Store />}></Route>
          </Route>
        )}
        {!isMaintenance && isBackofficeMode && (
          <Route path="/backoffice/login" element={<BackofficeLogin />}></Route>
        )}
        {!isMaintenance && isBackofficeMode && (
          <Route
            element={
              <PrivateRoute redirectTo="/backoffice/login">
                <Outlet />
              </PrivateRoute>
            }
          >
            <Route
              path="/backoffice/summary/:ordersummaryId/:mode"
              element={<BackofficeOrderSummary />}
            ></Route>
            <Route
              path="/backoffice/summary/:ordersummaryId"
              element={<BackofficeOrderSummary />}
            ></Route>
            <Route path="/backoffice/summary" element={<BackofficeOrderSummaries />}></Route>
            <Route path="/backoffice/wreath" element={<BackofficeWreathOrders />}></Route>
            <Route path="/backoffice/wreath/:mode" element={<BackofficeWreathOrders />}></Route>
            <Route
              path="/backoffice/shipment/upload"
              element={<BackofficeShipmentUpload />}
            ></Route>
            <Route path="/backoffice" element={<div>Hello Backoffice</div>}></Route>
          </Route>
        )}
        {!isMaintenance && <Route path="/logout" element={<Logout />}></Route>}
        {!isMaintenance && <Route>404</Route>}
        {isMaintenance && <Route>ระบบกำลังปรับปรุงอยู่ กรุณารอสักครู่</Route>}
      </Routes>
    </Router>
  );
}

export default RoutesApp;
