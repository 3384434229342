import React from 'react';
import { Paper, Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box, Typography } from 'Components/Base';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { SatthaOrder, SkuWallpaperData } from 'Libs/Firebase/firestore/Order';
import {
  getDateOfBirthDisplay,
  getGenderDisplay,
  getMonthDisplay,
  getOccupationDisplay,
  getSubTopicDisplay,
  getTopicDisplay,
  getYearDisplay,
  getHorowallIncludeServiceDisplay,
  WALLPAPER_INPUT_TYPE,
  getRMSTopicDisplay,
  getRMSGenderDisplay,
  getRMSWallpaperColorDisplay,
  getBirthTimeDisplay,
} from 'Domain/Sku/Wallpaper';
import {
  getMTWDeviceDisplay,
  getMTWSubTopicDisplay,
  getMTWMedicalProblemsDisplay,
  getMTWGenderDisplay,
  MTW_SUBTOPICS,
  getMTWTemplate,
  MTW_REQUEST_TYPES,
  getMTWOptionDisplay,
  getMTWSubSubTopicDisplay,
  getMTWIncludeServiceDisplay,
  getMTWFansBirthDayDisplay,
} from 'Domain/Sku/MTWWallpaper';
import {
  getHRSDateOfBirthDisplay,
  getHRSOccupationDisplay,
  getHRSTopicDisplay,
  getHRSIncludeServiceDisplay,
} from 'Domain/Sku/HRSWallpaper';

function createData(name: string, value?: any) {
  return { name, value: value ?? 'ไม่ระบุ' };
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
    border: 0,
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.selected,
    border: 0,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: '#E6E6E6',
  borderRadius: 20,
}));

const StyledCell = styled(TableCell)(({ theme }) => ({
  verticalAlign: 'baseline',
}));

function BasicTable({ rows = [] }: { rows: { name: string; value: string }[] }) {
  return (
    <TableContainer component={Card} elevation={0}>
      <Table sx={{ minWidth: 240 }} size={'small'} aria-label="simple table">
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledCell
                component="th"
                scope="row"
                align="right"
                sx={{ fontWeight: 'bold', maxWidth: 150, minWidth: 70, width: 110 }}
              >
                {row.name}
              </StyledCell>
              <StyledCell>{row.value}</StyledCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const HeaderBox = styled(Box)(({ theme }) => ({
  background: `linear-gradient(314.47deg, #01C1A8 0%, #1DCE59 90.84%)`,
}));

const getDataRows = (orderInfo: Partial<SatthaOrder>) => {
  const wallpaperData = orderInfo.wallpaperData;
  if ((orderInfo.skuData as SkuWallpaperData).inputTemplate === WALLPAPER_INPUT_TYPE.HOROWALL) {
    const rows1 = [
      createData('เลขที่ออเดอร์', orderInfo.refId),
      createData('ชื่อ - นามสกุล', orderInfo.chantName),
      createData('เพศ', getGenderDisplay(wallpaperData?.gender)),
      createData('เบอร์โทรศัพท์', wallpaperData?.phoneNo),
      createData('อีเมล', wallpaperData?.email),
      createData('วันที่เกิด', wallpaperData?.birthDay),
      createData('วันและช่วงเวลาเกิด', getDateOfBirthDisplay(wallpaperData?.birthDate)),
      createData('เดือนที่เกิด', getMonthDisplay(wallpaperData?.birthMonth)),
      createData('ปีที่เกิด', getYearDisplay(wallpaperData?.birthYear!)),
      createData('อาชีพ', getOccupationDisplay(wallpaperData?.occupation)),
    ];
    const rows2 = [
      createData(
        'ด้านที่ต้องการเสริมดวง',
        `${getTopicDisplay(wallpaperData?.topic!)} - ${getSubTopicDisplay(
          wallpaperData?.topic!,
          wallpaperData?.subTopic!
        )}`
      ),
      createData(
        'บริการเสริม',
        `${getHorowallIncludeServiceDisplay(
          (orderInfo.skuData as SkuWallpaperData).horowallIncludeServices
        )}`
      ),
    ];
    return [rows1, rows2];
  } else if ((orderInfo.skuData as SkuWallpaperData).inputTemplate === WALLPAPER_INPUT_TYPE.RMS) {
    return [
      [
        createData('เลขที่ออเดอร์', orderInfo.refId),
        createData('ชื่อ - นามสกุล', orderInfo.chantName),
        createData('เพศ', getRMSGenderDisplay(wallpaperData?.gender)),
        createData('เบอร์โทรศัพท์', wallpaperData?.phoneNo),
        createData('อีเมล', wallpaperData?.email),
        createData('LINE ID', wallpaperData?.lineId),
        createData('วันที่เกิด', wallpaperData?.birthDay),
        createData('เดือนที่เกิด', getMonthDisplay(wallpaperData?.birthMonth)),
        createData('ปีที่เกิด', getYearDisplay(wallpaperData?.birthYear!)),
        createData('เวลาที่เกิด', getBirthTimeDisplay(wallpaperData?.birthTime!)),
        createData('สีพื้นหลัง', getRMSWallpaperColorDisplay(wallpaperData?.wallpaperColor!)),
        createData('รุ่นอุปกรณ์', wallpaperData?.phoneModel),
      ],
      [
        createData('แพคเกจ', `${orderInfo.skuName!}`),
        createData('ด้านที่ต้องการเสริมดวง', `${getRMSTopicDisplay(wallpaperData?.topic!)}`),
      ],
    ];
  } else if ((orderInfo.skuData as SkuWallpaperData).inputTemplate === WALLPAPER_INPUT_TYPE.MTW) {
    const template = getMTWTemplate(orderInfo.wallpaperData?.topic! as MTW_REQUEST_TYPES);
    const secondSection = [
      createData('ชุดวอลเปเปอร์', `${template?.display}`),
      createData(template?.selectTopicLabel, getMTWOptionDisplay(template, wallpaperData?.option!)),
      createData(
        template?.requestTypeLabel,
        `${getMTWSubTopicDisplay(template, wallpaperData?.subTopic!)} - ${getMTWSubSubTopicDisplay(
          template,
          wallpaperData?.subTopic!,
          wallpaperData?.subSubTopic!
        )}`
      ),
    ];
    if ((wallpaperData?.subTopic as unknown as MTW_SUBTOPICS) === MTW_SUBTOPICS.FANS) {
      secondSection.push(
        createData('วันเกิดติ่ง', getMTWFansBirthDayDisplay(wallpaperData?.fansBirthDay!))
      );
    }
    secondSection.push(
      createData(
        'บริการเสริม',
        `${getMTWIncludeServiceDisplay((orderInfo.skuData as SkuWallpaperData).mtwIncludeServices)}`
      )
    );
    return [
      [
        createData('เลขที่ออเดอร์', orderInfo.refId),
        createData('ชื่อ - นามสกุล', orderInfo.chantName),
        createData('เพศ', getMTWGenderDisplay(wallpaperData?.gender)),
        createData('เบอร์โทรศัพท์', wallpaperData?.phoneNo),
        createData('อีเมล', wallpaperData?.email),
        createData('วันที่เกิด', wallpaperData?.birthDay),
        createData('เดือนที่เกิด', getMonthDisplay(wallpaperData?.birthMonth)),
        createData('ปีที่เกิด', getYearDisplay(wallpaperData?.birthYear!)),
        createData('เวลาที่เกิด', getBirthTimeDisplay(wallpaperData?.birthTime!)),
        createData('อุปกรณ์', getMTWDeviceDisplay(wallpaperData?.phoneModel)),
        createData('โรคประจำตัว', getMTWMedicalProblemsDisplay(wallpaperData?.medicalProblems)),
      ],
      secondSection,
    ];
  } else if ((orderInfo.skuData as SkuWallpaperData).inputTemplate === WALLPAPER_INPUT_TYPE.HRS) {
    const rows1 = [
      createData('เลขที่ออเดอร์', orderInfo.refId),
      createData('ชื่อ - นามสกุล', orderInfo.chantName),
      createData('เพศ', getMTWGenderDisplay(wallpaperData?.gender)),
      createData('เบอร์โทรศัพท์', wallpaperData?.phoneNo),
      createData('วันเกิด', getHRSDateOfBirthDisplay(wallpaperData?.birthDate)),
      createData('วันที่เกิด', wallpaperData?.birthDay),
      createData('เดือนที่เกิด', getMonthDisplay(wallpaperData?.birthMonth)),
      createData('ปีที่เกิด', getYearDisplay(wallpaperData?.birthYear!)),
      createData('อาชีพหลัก', getHRSOccupationDisplay(wallpaperData?.occupation)),
      createData('อาชีพเสริม', getHRSOccupationDisplay(wallpaperData?.secondaryOccupation)),
    ];
    const rows2 = [
      createData('ด้านที่ต้องการเสริมดวง', `${getHRSTopicDisplay(wallpaperData?.topic!)}`),
      createData(
        'บริการเสริม',
        `${getHRSIncludeServiceDisplay((orderInfo.skuData as SkuWallpaperData).hrsIncludeServices)}`
      ),
    ];
    return [rows1, rows2];
  }
  return [
    [
      createData('ชื่อ - นามสกุล', orderInfo.chantName),
      createData('เพศ', getGenderDisplay(orderInfo.wallpaperData?.gender)),
    ],
    [createData('ด้านที่ต้องการเสริมดวง', `${getTopicDisplay(orderInfo.wallpaperData?.topic!)}`)],
  ];
};

export default function WallpaperSummaryCard({ orderInfo }: { orderInfo: Partial<SatthaOrder> }) {
  const [rows1, rows2] = getDataRows(orderInfo);

  return (
    <StyledCard>
      <HeaderBox py={2} px={2}>
        <Typography variant="h3" color="white">
          สรุปข้อมูล
        </Typography>
      </HeaderBox>
      <Box py={2} px={2}>
        <Typography variant="h4" color="primary">
          ข้อมูลทั่วไป
        </Typography>
      </Box>
      <BasicTable rows={rows1} />
      <Box pt={4} pb={2} px={2}>
        <Typography variant="h4" color="primary">
          ข้อมูลการเสริมดวง
        </Typography>
      </Box>
      <BasicTable rows={rows2} />
    </StyledCard>
  );
}
