import { FORM_MODE } from 'Components/StoreOrderForm';
import { SatthaOrder } from 'Libs/Firebase/firestore/Order';

export type StoreUploadOrderProps = {
  storeId: string;
  orderId: string;
  onUploadSuccess: () => void;
  orderData: SatthaOrder;
  mode: FORM_MODE;
};

export const QA_TYPE = 'QA';
