import React from 'react';
import { Box, Typography } from 'Components/Base';
import { List } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { LocationProps } from '../utils';
import { CHANT_MODE, getChantModeText } from 'Domain/Sattha/Chant';

const WISH_TEXT = `### วิธีขอพรพระพรหมจากบ้าน
**อุปกรณ์ที่ต้องใช้**
* ธูป 16-39 ดอกขึ้นอยู่กับเรื่องที่ต้องการขอพร (หากไม่มีก็ไม่เป็นไร)
1) พักตร์ที่ 1 ใช้ธูป 16 ดอก - เรื่องการเรียน การสอบแข่งขัน ความก้าวหน้าในชีวิต 
2) พักตร์ที่ 2 ใช้ธูป 36 ดอก - เรื่องทรัพย์สิน อสังหาริมทรัพย์
3) พักตร์ที่ 3 ใช้ธูป 39 ดอก - เรื่องสุขภาพ ครอบครัว คู่ชีวิต
4) พักตร์ที่ 4 ใช้ธูป 19 ดอก - เรื่องโชคลาภ เงินทอง
* บทสวดข้างบน (กดบันทึกเก็บไว้อ่านได้เลย!) 

**ก่อนถึงเวลาที่ร้านค้าจะดำเนินการขอพร**
* จุดธูปให้พร้อม 
* ตั้งจิตอธิษฐานให้มั่น แล้วกล่าวบทสวด, ชื่อ, เรื่องที่อยากขอพร/บนบาน พร้อมทั้งบอกของที่ต้องการนำมาแก้บน (ถ้ามี) หากพรสมปรารถนา เช่น เมื่อข้าพเจ้าสำเร็จแล้วจะมาถวายชุดพรสำเร็จ เป็นต้น

**ทริคแนะนำ**
* เนื่องจากพระพรหมมี 4 พักตร์ สามารถขอเฉพาะพักตร์ใดพักตร์หนึ่ง หรือขอให้ครบทุกพักตร์ก็ได้`;

const VOW_TEXT = `### วิธีแก้บนพระพรหมจากบ้าน
**อุปกรณ์ที่ต้องใช้**
* ธูป 16-39 ดอกขึ้นอยู่กับเรื่องที่ต้องการแก้บน (แนะนำว่าควรมี)
1) พักตร์ที่ 1 ใช้ธูป 16 ดอก - เรื่องการเรียน การสอบแข่งขัน ความก้าวหน้าในชีวิต 
2) พักตร์ที่ 2 ใช้ธูป 36 ดอก - เรื่องทรัพย์สิน อสังหาริมทรัพย์
3) พักตร์ที่ 3 ใช้ธูป 39 ดอก - เรื่องสุขภาพ ครอบครัว คู่ชีวิต
4) พักตร์ที่ 4 ใช้ธูป 19 ดอก - เรื่องโชคลาภ เงินทอง
* บทสวดข้างบน (กดบันทึกเก็บไว้อ่านได้เลย!) 

**ก่อนถึงเวลาที่ร้านค้าจะดำเนินการขอพร**
* จุดธูปให้พร้อม 
* ตั้งจิตอธิษฐานให้มั่น แล้วกล่าวบทแก้บน, ชื่อ, เรื่องที่ได้บนบานไว้ พร้อมบอกกล่าวว่าบัดนี้ด้วยบารมีของพระพรหมที่ได้ทำให้สมปรารถนาแล้วทุกประการ จึงได้นำของมาถวายเพื่อแก้บนด้วยถือสัจจะเป็นที่ตั้ง ขอให้การบนบานครั้งนี้ขาดจากกันนับแต่บัดนี้

เช่น "ข้าพเจ้า นายมานะ ขยันยิ่ง ได้บนบานเรื่องยอดขายของเดือนที่ผ่านมาได้ 100,000 บาท ตามที่ได้บนบานเอาไว้ ด้วยบารมีของพระพรหมที่ทำให้สมปรารถนา ข้าพเจ้าจึงได้นำของมาถวายเพื่อแก้บนด้วยถือสัจจะเป็นที่ตั้ง ขอให้การบนบานครั้งนี้ขาดจากกันนับแต่บัดนี้ด้วยเทอญ"`;

export default function LPP({ mode }: LocationProps) {
  if (mode === CHANT_MODE.WISH) {
    return (
      <Box>
        <ReactMarkdown>{WISH_TEXT}</ReactMarkdown>
      </Box>
    );
  }
  return (
    <Box>
      <ReactMarkdown>{VOW_TEXT}</ReactMarkdown>
    </Box>
  );
}
