import { FormControl, Select, MenuItem, TextField, FormHelperText } from '@mui/material';
import { Field } from 'react-final-form';
import { FormGeneratorInputTypeTextArea } from '../types';

export default function TextAreaInput({
  name,
  label,
  placeholder,
  maxLength = 300,
  rows = 4,
  tips,
}: FormGeneratorInputTypeTextArea) {
  return (
    <FormControl fullWidth>
      <Field
        name={name}
        render={(props) => (
          <TextField
            {...props.input}
            label={label}
            variant="outlined"
            fullWidth
            multiline
            placeholder={placeholder}
            rows={rows}
            InputProps={{
              startAdornment: <div />,
            }}
            inputProps={{ maxLength: maxLength }}
            helperText={
              props.meta.error && props.meta.touched ? (
                <FormHelperText error>{props.meta.error}</FormHelperText>
              ) : (
                tips
              )
            }
          />
        )}
      />
    </FormControl>
  );
}
