import { gql } from '@apollo/client';

export const checkRedemptionCodeQuery = gql`
  query CheckRedemptionCode($redemptionCode: String!) {
    checkRedemptionCode(redemptionCode: $redemptionCode) {
      code
      status
      data {
        orderIds
      }
    }
  }
`;

export const redeemRedeemCodeMutation = gql`
  mutation RedeemRedeemCode($input: RedeemRedeemCodeInput!) {
    redeemRedeemCode(input: $input) {
      ... on RedeemRedeemCodeSuccessResponse {
        data {
          orderIds
        }
        status
      }
      ... on RedeemRedeemCodeInvalidInputResponse {
        message
        code
        additionalData {
          status
        }
      }
    }
  }
`;
