import React from 'react';
import { Form, Field } from 'react-final-form';
import { Paper, FormHelperText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import arrayMutators from 'final-form-arrays';

import { Box, Button, Typography } from 'Components/Base';
import { CHANT_MODE, getUserInfoForm, UIFORM_TEXT_FIELDS } from 'Domain/Sattha/Chant';
import {
  AvailableSkuItem,
  OrderShippingData,
  SkuData,
  SkuShipmentData,
} from 'Libs/Firebase/firestore/Order';
import ShippingForm from '../Components/ShippingForm';
import MTWShipmentForm, {
  generateShipmentAdditionalData,
  validations as mtwShipmentValidations,
} from '../Components/MTWShipmentForm';
import { HiddenFieldForm, HiddenSkuCodeFieldForm } from '../Components/HiddenFieldForm';
import { MTW_REQUEST_TYPES, MTW_SHIPMENT_FORM_TYPES } from 'Domain/Sku/MTWWallpaper';
import AcceptPDPAForm, { validatePDPA } from '../Components/AcceptPDPAForm';
import ErrorBox from '../Components/ErrorBox';
import ConfirmationButton from '../Components/ConfirmationButton';

const useStyles = makeStyles((theme) => ({
  hiddenField: {
    display: 'none',
  },
  gradientButton: {
    background: `linear-gradient(314.47deg, #01C1A8 0%, #1DCE59 90.84%)`,
    borderRadius: 2,
    color: 'white',
    width: '100%',
    '&:disabled': {
      color: `#969696`,
      background: '#E6E6E6',
    },
  },
}));

export interface UserInfoFormDataShipment {
  orderId: string;
  mode: CHANT_MODE;
  chantName: string;
  shippingName: string;
  shippingAddress: string;
  shippingPhoneNo: string;
  shippingPostcode: string;
  skuCode?: string;

  additionalData?: string;

  acceptTerms: boolean;
  acceptPrivacy: boolean;

  // MTW Shipment
  orderName?: string;
  email?: string;
  gender?: string;
  phoneNo?: string;
  birthDay?: number;
  birthMonth?: number;
  birthYear?: number;
  birthTime?: string;
  medicalProblems?: string[];
  wallpaperRequestType?: string;
  wallpaperRequestOption?: string;
  wallpaperRequestSubType?: string;
  wallpaperRequestSubSubType?: string;
}

export interface UserInfoFormInitialData {
  chantName?: string;
  chantMessage?: string;
}

interface CreateShippingOrderFormProps {
  onSubmit?: (formValues: UserInfoFormDataShipment) => void;
  orderId: string;
  locationId: string;
  isLoading: boolean;
  mode: CHANT_MODE;
  initialData?: UserInfoFormInitialData;
  shippingData?: OrderShippingData;
  showPackageSelection: boolean;
  enablePackageSelection: boolean;
  skuMasterCode?: string;
  availableSkus?: AvailableSkuItem[];
  skuData: SkuShipmentData;
}

enum ORDER_MODE {
  FULFILLMENT = 'FULFILLMENT',
  SHIPPING = 'SHIPPING',
}

export default function SingleShipmentForm(props: CreateShippingOrderFormProps) {
  const classes = useStyles();

  const {
    orderId,
    onSubmit = () => {
      /** */
    },
    locationId,
    isLoading,
    mode,
    initialData,
    shippingData,
    showPackageSelection,
    enablePackageSelection,
    skuMasterCode,
    availableSkus,
    skuData,
  } = props;

  const FORM_TEXT = getUserInfoForm(mode);
  const { chantName, chantMessage } = initialData ?? {};

  const inputTemplate = skuData?.inputTemplate as unknown as MTW_SHIPMENT_FORM_TYPES;

  let newInitialData = { orderId, mode, chantMessage, chantName };
  if (shippingData) {
    newInitialData = { ...newInitialData, ...shippingData };
  }
  if (inputTemplate === MTW_SHIPMENT_FORM_TYPES.MTW_LUCK_CARD) {
    newInitialData = {
      ...newInitialData,
      ...{ wallpaperRequestType: MTW_REQUEST_TYPES.LUCK_CARD },
    };
  }
  if (showPackageSelection && skuMasterCode && availableSkus && availableSkus?.length > 0) {
    const skuCode = availableSkus[0].skuCode;
    const newData = { skuCode };
    newInitialData = { ...newInitialData, ...newData };
  }
  const handleSubmit = (values: UserInfoFormDataShipment, ...restArgs) => {
    // console.log('handleSubmit', values);
    const {
      orderId,
      mode,
      chantName,
      shippingName,
      shippingAddress,
      shippingPhoneNo,
      shippingPostcode,
      skuCode,
      acceptTerms,
      acceptPrivacy,
      ...restValue
    } = values;
    const shippingData = {
      orderId,
      mode,
      chantName,
      shippingName,
      shippingAddress,
      shippingPhoneNo,
      shippingPostcode,
      skuCode,
      acceptTerms,
      acceptPrivacy,
    };
    if (inputTemplate === MTW_SHIPMENT_FORM_TYPES.MTW_LUCK_CARD) {
      const additionalData = generateShipmentAdditionalData(values);
      onSubmit({ ...shippingData, additionalData });
    } else {
      const newValues: UserInfoFormDataShipment = { ...shippingData };
      onSubmit(newValues);
    }
  };

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={newInitialData}
        mutators={{
          // potentially other mutators could be merged here
          ...arrayMutators,
        }}
        validate={(values) => {
          const errors: { [k: string]: any } = {};
          if (!values.skuCode) {
            errors.skuCode = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_SELECT_PACAKGE];
          }

          if (!values.shippingName) {
            errors.shippingName = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_SHIPPING_NAME];
          }
          if (!values.shippingAddress) {
            errors.shippingAddress = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_SHIPPING_ADDRESS];
          }
          if (values.shippingPostcode) {
            // const regex = /^\d{5}$/;
            // if (!regex.test(values.shippingPostcode)) {
            //   errors.shippingPostcode = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_SHIPPING_POSTCODE];
            // }
          } else {
            errors.shippingPostcode = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_SHIPPING_POSTCODE];
          }
          if (values.shippingPhoneNo) {
            // const regex =
            //   /^(((\+66|0)(\d{1,2}\-?\d{3}\-?\d{3,4}))|((\+๖๖|๐)([๐-๙]{1,2}\-?[๐-๙]{3}\-?[๐-๙]{3,4})))$/;
            // if (!regex.test(values.shippingPhoneNo)) {
            //   errors.shippingPhoneNo =
            //     FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_SHIPPING_PHONENO_FORMAT];
            // }
          } else {
            errors.shippingPhoneNo = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_PHONENO_REQUIRED];
          }

          if (inputTemplate === MTW_SHIPMENT_FORM_TYPES.MTW_LUCK_CARD) {
            const mtwErrors = mtwShipmentValidations(values, FORM_TEXT);
            Object.assign(errors, mtwErrors);
          }

          const pdpaErrors = validatePDPA(values);
          Object.assign(errors, pdpaErrors);
          // console.log(values);
          // console.log('validations', errors);
          return errors;
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => {
          // console.log(values);
          return (
            <form onSubmit={handleSubmit}>
              <HiddenFieldForm />
              <Paper elevation={0} style={{ padding: 16 }}>
                <HiddenSkuCodeFieldForm />
                <Typography variant="h3">
                  {FORM_TEXT[UIFORM_TEXT_FIELDS.HEADER_SHIPPING]}
                </Typography>
                <Box my={3}></Box>
                <ShippingForm FORM_TEXT={FORM_TEXT} />
                {inputTemplate === MTW_SHIPMENT_FORM_TYPES.MTW_LUCK_CARD && (
                  <>
                    <Box my={4}></Box>
                    <Typography variant="h3">{'ข้อมูลเพิ่มเติม'}</Typography>
                    <MTWShipmentForm
                      FORM_TEXT={getUserInfoForm(CHANT_MODE.WALLPAPER)}
                      shipmentFormType={inputTemplate}
                      values={values}
                    />
                  </>
                )}
                <AcceptPDPAForm FORM_TEXT={FORM_TEXT} />
                {form.getState().hasValidationErrors && (
                  <>
                    <ErrorBox errors={form.getState().errors} />
                    <Box my={1}></Box>
                  </>
                )}
                <ConfirmationButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="large"
                  stType="gradient"
                  fullWidth
                  onClick={handleSubmit}
                  disabled={form.getState().hasValidationErrors || isLoading}
                  loading={isLoading}
                >
                  {'ยืนยันข้อมูลการจัดส่ง'}
                </ConfirmationButton>
              </Paper>
            </form>
          );
        }}
      ></Form>
    </>
  );
}
