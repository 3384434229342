import React, { useState, useEffect } from 'react';
import FileUploader from 'react-firebase-file-uploader';
import firebase from 'Libs/Firebase/firebase';
import Box, { ImageBox } from 'Components/Base/Box';
import { Button, Typography } from 'Components/Base';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import VideoLibIcon from '@mui/icons-material/VideoLibrary';
import ImageIcon from '@mui/icons-material/Image';

import { CircularProgress, FormHelperText } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import { ATTACHMENT_TYPE, StorageObject } from 'Domain/Orders/types';
import VideoDownloadBox, {
  VIDEOBOX_MODE,
  VIDEO_STATE,
} from 'Routes/ClientOrder/Components/OrderDetails/Components/VideoDownloadBox';

const createUploadRootPath = (prefix, key) => `${prefix}/${key}`;

const useStyles = makeStyles((theme) => ({
  uploadBox: {
    backgroundColor: '#F2F2F2',
    borderRadius: 0,
    cursor: 'pointer',
    textAlign: 'center',
    borderStyle: 'solid',
    borderColor: '#E6E6E6',
    borderWidth: 1,
  },
  uploadBoxError: {
    backgroundColor: '#F2F2F2',
    borderRadius: 4,
    cursor: 'pointer',
    textAlign: 'center',
    borderStyle: 'solid',
    borderColor: theme.palette.error.main,
    borderWidth: 1,
  },
  uploadBoxHidden: {
    display: 'none',
  },
}));

const LoadingBox = () => {
  return (
    <Box display="flex" justifyContent="center" style={{ width: '100%' }} py={4}>
      <CircularProgress />
    </Box>
  );
};

export const UploadBox = ({
  errors,
  uploadActionText = 'Upload',
  uploadComponent,
  isUploading,
  uploadProgress,
  disabled,
  mode = UPLOAD_MODE.IMAGE,
  multiple = false,
  uploadedCount,
}) => {
  const classes = useStyles();
  let classnames;
  if (isUploading || disabled) {
    classnames = classes.uploadBoxHidden;
  } else {
    if (errors) {
      classnames = classes.uploadBoxError;
    } else {
      classnames = classes.uploadBox;
    }
  }
  // const classnames =
  //   isUploading || disabled
  //     ? classes.uploadBoxHidden
  //     : errors
  //       ? classes.uploadBoxError
  //       : classes.uploadBox;

  if (!multiple && uploadedCount > 0) return null;

  return (
    <>
      <Box marginY={1}></Box>
      {isUploading && (
        <Box display="flex" flexDirection="column" alignItems="center">
          <LoadingBox />
          <Typography variant={'subtitle'}>{`${uploadProgress} %`}</Typography>
        </Box>
      )}
      <Box component={'label'} className={classnames}>
        <Box m={4}>
          <AddIcon />
          {mode === UPLOAD_MODE.VIDEO && <VideoLibIcon />}
          {mode === UPLOAD_MODE.IMAGE && <ImageIcon />}
          <Typography>{uploadActionText}</Typography>
        </Box>
        {uploadComponent}
      </Box>
      {errors && <FormHelperText error>{JSON.stringify(errors)}</FormHelperText>}
    </>
  );
};

const useImageListStyles = makeStyles((theme) => ({
  buttonColor: {
    color: theme.palette.error.dark,
  },
}));

interface ImageListProps {
  uploadedFiles?: UploadedFileData[];
  onSoftDeleteImage?: (imageIndex: number, file: UploadedFileData) => void;
  disabled?: boolean;
}

export const ImageList = ({
  disabled,
  uploadedFiles = [],
  onSoftDeleteImage = () => {
    /** */
  },
}: ImageListProps) => {
  const classes = useImageListStyles();
  return (
    <Box display="flex" flexDirection="column" alignItems="center" style={{ width: '100%' }}>
      {uploadedFiles.map((uploadedFile, i) => {
        return (
          <Box key={i} mb={3}>
            <ImageBox
              src={uploadedFile.fileurl}
              alt="uploaded-slip"
              style={{ height: 'auto', width: '100%' }}
            />
            <Box display="flex" flexDirection="row" justifyContent="flex-end">
              <Button
                disabled={disabled}
                className={classes.buttonColor}
                startIcon={<DeleteIcon />}
                onClick={() => onSoftDeleteImage(i, uploadedFile)}
              >
                ลบรูปนี้
              </Button>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export const VideoList = ({
  disabled,
  uploadedFiles = [],
  onSoftDeleteImage = () => {
    /** */
  },
}: ImageListProps) => {
  const classes = useImageListStyles();
  return (
    <Box display="flex" flexDirection="column" alignItems="center" style={{ width: '100%' }}>
      {uploadedFiles.map((uploadedFile, i) => {
        return (
          <Box key={i} mb={3} width="100%">
            <Box display="flex" flexDirection="row">
              <VideoDownloadBox
                videoUrl={uploadedFile.fileurl}
                name={uploadedFile.filename}
                packageDate={''}
                mode={VIDEOBOX_MODE.STORE}
                initialState={VIDEO_STATE.PLAYER}
                downloadable={false}
              />
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="flex-end">
              <Button
                disabled={disabled}
                className={classes.buttonColor}
                startIcon={<DeleteIcon />}
                onClick={() => onSoftDeleteImage(i, uploadedFile)}
              >
                ลบวิดิโอ
              </Button>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export type UploadedFileData = {
  fileurl: any;
  filename: string;
  ref: string;
  bucket: string;
  type: ATTACHMENT_TYPE;
};

export enum UPLOAD_MODE {
  IMAGE = 'image',
  VIDEO = 'video',
}

export interface ImageUploadBoxProps {
  uploadActionText?: string;
  uploadPrefix: string;
  uploadKey: string;
  onUploadFiles: (uploadedFiles?: UploadedFileData[]) => void;
  errors?: string;
  disabled?: boolean;
  mode?: UPLOAD_MODE;
  multiple?: boolean;
  value?: string;
}

function getAcceptFileTypes(mode: UPLOAD_MODE) {
  // ref: http://www.iana.org/assignments/media-types/media-types.xhtml
  switch (mode) {
    case UPLOAD_MODE.IMAGE:
      return 'image/jpeg,image/png'; // does not accept heic
    case UPLOAD_MODE.VIDEO:
      return 'video/mp4,video/quicktime';
    default:
      return 'image/jpeg,image/png';
  }
}

export const convertToStorageObject = (file: UploadedFileData): StorageObject => {
  return {
    url: file.fileurl,
    name: file.filename,
    ref: file.ref,
    bucket: file.bucket,
    type: file.type,
  };
};

export const convertFromStorageObject = (storageObject: StorageObject): UploadedFileData => {
  return {
    fileurl: storageObject.url,
    filename: storageObject.name,
    ref: storageObject.ref,
    bucket: storageObject.bucket,
    type: storageObject.type,
  };
};

const ImageUploadBox = (props: ImageUploadBoxProps) => {
  const [isUploading, setIsUploding] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFileData[]>(
    props.value ? JSON.parse(props.value) : []
  );
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadError, setUploadError] = useState<any | null>(null);

  const storage = firebase.storage();

  const {
    onUploadFiles,
    uploadKey,
    uploadPrefix,
    uploadActionText,
    disabled,
    mode = UPLOAD_MODE.IMAGE,
    multiple,
  } = props;

  const storageRefPath = createUploadRootPath(uploadPrefix, uploadKey);

  const handleUploadStart = () => {
    setIsUploding(true);
    setUploadError(null);
    setUploadProgress(0);
  };

  const handleProgress = (progress) => {
    setUploadProgress(progress);
  };

  const handleUploadError = (error) => {
    setIsUploding(false);
    setUploadError(error);
    console.error(error);
  };

  const handleUploadSuccess = async (filename) => {
    const downloadURL = await firebase
      .storage()
      .ref(storageRefPath)
      .child(filename)
      .getDownloadURL();

    const uploadedFile = {
      fileurl: downloadURL,
      filename: filename,
      ref: storageRefPath,
      bucket: storage.ref().bucket,
      type: mode === UPLOAD_MODE.IMAGE ? ATTACHMENT_TYPE.IMAGE : ATTACHMENT_TYPE.VIDEO,
    };

    console.log('upload success', filename);
    setUploadedFiles((oldUploadedFiles) => [...oldUploadedFiles, uploadedFile]);
    setUploadProgress(100);
    setIsUploding(false);
  };

  const handleSoftDeleteImage = (imageIndex, imageURL) => {
    console.log('delete image at index', imageIndex);
    setUploadedFiles((oldUploadedFiles) => {
      const tmp = [...oldUploadedFiles];
      tmp.splice(imageIndex, 1);
      return tmp;
    });
  };

  useEffect(() => {
    onUploadFiles(uploadedFiles);
  }, [uploadedFiles, onUploadFiles]);

  return (
    <Box display="flex" flexDirection="column" style={{ width: '100%' }}>
      {mode === UPLOAD_MODE.IMAGE && (
        <ImageList
          uploadedFiles={uploadedFiles}
          onSoftDeleteImage={handleSoftDeleteImage}
          disabled={disabled}
        />
      )}
      {mode === UPLOAD_MODE.VIDEO && (
        <VideoList
          uploadedFiles={uploadedFiles}
          onSoftDeleteImage={handleSoftDeleteImage}
          disabled={disabled}
        />
      )}
      <UploadBox
        uploadActionText={uploadActionText}
        errors={uploadError}
        isUploading={isUploading}
        uploadProgress={uploadProgress}
        disabled={disabled}
        mode={mode}
        multiple={multiple}
        uploadedCount={uploadedFiles.length}
        uploadComponent={
          <FileUploader
            hidden
            accept={getAcceptFileTypes(mode)}
            name="image-uploader-multiple"
            randomizeFilename
            storageRef={storage.ref(storageRefPath)}
            onUploadStart={handleUploadStart}
            onUploadError={handleUploadError}
            onUploadSuccess={handleUploadSuccess}
            onProgress={handleProgress}
            multiple={multiple}
          />
        }
      />
    </Box>
  );
};

export default ImageUploadBox;
