import Heading from 'Components/Layout/Heading';
import StoreShipmentOrderForm from 'Components/StoreShipmentOrderForm';
import { useUpdateShipmentOrder } from 'Domain/Stores/hooks';
import React from 'react';
import { StoreUploadOrderProps } from './types';

export default function StoreShipmentOrder(props: StoreUploadOrderProps) {
  const { storeId, orderId, onUploadSuccess, orderData, mode } = props;
  const { mutate: updateOrder, isLoading, error, data } = useUpdateShipmentOrder();

  const handleSubmit = async (formValues) => {
    console.log('hs', formValues);
    const { trackingProvider, trackingCode } = formValues;
    await updateOrder(
      {
        storeId,
        orderId,
        shipmentData: { trackingProvider, trackingCode },
      },
      {
        onSuccess: () => {
          onUploadSuccess();
        },
      }
    );
  };

  return (
    <>
      <Heading title="อัพโหลดหลักฐาน" />
      <StoreShipmentOrderForm
        {...orderData}
        mode={mode}
        customerName={orderData.chantName}
        packageName={orderData.skuName}
        packageId={orderData.skuCode}
        skuMode={orderData.skuMode}
        orderRealId={orderData.id}
        onSubmit={handleSubmit}
        loading={isLoading}
        error={error}
      />
    </>
  );
}
