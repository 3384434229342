import { useParams } from 'react-router-dom';
import BackofficeOrderSummaryContainer, { SUMMARY_MODE } from './BackofficeOrderSummaryContainer';
interface BackofficeOrderSummaryRouterParams {
  ordersummaryId: string;
  mode?: SUMMARY_MODE;
}

export default function BackofficeOrderSummaryRoute() {
  const { ordersummaryId, mode } = useParams<
    keyof BackofficeOrderSummaryRouterParams
  >() as BackofficeOrderSummaryRouterParams;

  return (
    <>
      <BackofficeOrderSummaryContainer orderSummaryId={ordersummaryId} mode={mode} />
    </>
  );
}
