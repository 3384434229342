import React from 'react';
import { Paper, Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box, Typography } from 'Components/Base';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import 'dayjs/locale/th';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { SatthaOrder } from 'Libs/Firebase/firestore/Order';

dayjs.extend(isToday);
dayjs.extend(isTomorrow);

export const getDisplayDateWithRelativeDate = (serviceDateStr: string, offsetDays: number = 0) => {
  const day = dayjs(serviceDateStr, 'YYYY-MM-DD').add(offsetDays, 'day').locale('th');
  let formattedDate = day.format('D MMM');

  if (day.isToday()) {
    formattedDate = `${formattedDate} (วันนี้)`;
  }

  if (day.isTomorrow()) {
    formattedDate = `${formattedDate} (พรุ่งนี้)`;
  }

  return formattedDate;
};

function createData(name: string, value?: any) {
  return { name, value: value ?? 'ไม่ระบุ' };
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
    border: 0,
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.selected,
    border: 0,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: '#E6E6E6',
  borderRadius: 20,
}));

const StyledCell = styled(TableCell)(({ theme }) => ({
  verticalAlign: 'baseline',
}));

function BasicTable({ rows = [] }: { rows: { name: string; value: string }[] }) {
  return (
    <TableContainer component={Card} elevation={0}>
      <Table sx={{ minWidth: 240 }} size={'small'} aria-label="simple table">
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledCell
                component="th"
                scope="row"
                align="right"
                sx={{ fontWeight: 'bold', maxWidth: 150, minWidth: 70, width: 110 }}
              >
                {row.name}
              </StyledCell>
              <StyledCell>{row.value}</StyledCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const HeaderBox = styled(Box)(({ theme }) => ({
  background: `#C7B199`,
}));

export default function WreathSummaryCard({ orderInfo }: { orderInfo: Partial<SatthaOrder> }) {
  const rows1 = [
    createData(
      'วันที่ต้องการส่งพวงหรีด',
      getDisplayDateWithRelativeDate(orderInfo.serviceDate!, -1)
    ),
    createData('ผู้จัดส่ง', orderInfo.chantName),
    createData('เบอร์โทรศัพท์', orderInfo.wreathData?.phoneNo),
    createData('สถานที่จัดส่ง (วัด)', orderInfo.wreathData?.locationPlace),
    createData('จังหวัด', orderInfo.wreathData?.locationProvince),
    createData('ศาลา', orderInfo.wreathData?.locationPlaceSpecific),
    createData('ชื่อผู้วายชนม์', orderInfo.wreathData?.deceasedName),
    createData('ข้อความในป้าย', orderInfo.wreathData?.displayMessage),
  ];

  return (
    <StyledCard>
      <HeaderBox py={2} px={2}>
        <Typography variant="h3" color="white">
          สรุปข้อมูล
        </Typography>
      </HeaderBox>
      <Box py={2} px={2}>
        <Typography variant="h4" color="black">
          ข้อมูลทั่วไป
        </Typography>
      </Box>
      <BasicTable rows={rows1} />
      <Box pt={4} pb={2} px={2}></Box>
    </StyledCard>
  );
}
