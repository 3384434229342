import React from 'react';
import ChineseYearLib from 'chinese-year';
import { Typography } from '@mui/material';

// console.log(ChineseYearLib.years);

export const ANIMAL_MAPPING = {
  Rat: 'ปีชวด',
  Ox: 'ปีฉลู',
  Tiger: 'ปีขาล',
  Rabbit: 'ปีเถาะ',
  Dragon: 'ปีมะโรง',
  Snake: 'ปีมะเส็ง',
  Horse: 'ปีมะเมีย',
  Sheep: 'ปีมะแม',
  Monkey: 'ปีวอก',
  Rooster: 'ปีระกา',
  Dog: 'ปีจอ',
  Pig: 'ปีกุน',
};

export default function ChineseYear({ year }) {
  return <Typography>{ANIMAL_MAPPING[ChineseYearLib.getAnimal(year)]}</Typography>;
}
