export enum AUTH_STATE {
  INITIALIZING = 'INITIALIZING', // Shows that the app is initializing and trying to get current logged-in skooldio user.
  LOGGED_IN = 'LOGGED_IN',
  NOT_LOGGED_IN = 'NOT_LOGGED_IN',
}

export enum AUTH_ERROR_CODE {
  INVALID_PASSWORD = 'INVALID_PASSWORD',
  OTHERS = 'OTHERS',
}

export interface AuthContextData {
  authState: AUTH_STATE;
  loading: boolean;
  loginWithEmailAndPassword: (loginData: LoginWithEmailAndPasswordData) => Promise<any>;
  loginWithLINEAccessToken: (loginData: LoginWithLINEAccessTokenData) => Promise<any>;
  logout: () => Promise<void>;
  userInfo: any;
  authError?: AUTH_ERROR_CODE;
  isLogin: boolean;
}

export type LoginWithEmailAndPasswordData = {
  email: string;
  password: string;
};

export type LoginWithLINEAccessTokenData = {
  lineAccessToken: string;
};

export type LoginWithCustomTokenData = {
  token: string;
};

export type LoginWithUsernameAndPasswordData = {
  username: string;
  password: string;
};

export type UserData = {
  id: string;
  displayName: string | null;
};

export type UserAuthStateData = {
  state: AUTH_STATE;
  user?: UserData;
};

export interface AuthProvider {
  loginWithEmailAndPassword: (loginData: LoginWithEmailAndPasswordData) => Promise<any>;
  loginWithUsername: (loginData: LoginWithUsernameAndPasswordData) => Promise<any>;
  loginWithCustomToken: (loginData: LoginWithCustomTokenData) => Promise<any>;
  logout: () => Promise<void>;
  subscribeAuthState: (callback: (state: AUTH_STATE, user?: UserData) => void) => void;
  getUserInfo: () => Promise<UserData | null>;
  getAuthState: AUTH_STATE;
  initGetUserData: () => Promise<UserAuthStateData>;
}
