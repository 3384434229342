import React from 'react';
import { Box, Typography } from 'Components/Base';
import { List } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { CHANT_MODE, getChantModeText } from 'Domain/Sattha/Chant';
import Chants from '../../../location';
import { ChantLocationProps } from 'Components/Locations/ChantInstructions/utils';
import Default from '../Default';

export default function GenericChantMessageInstructions({ mode, locationId }: ChantLocationProps) {
  const performInstructionData = Chants[locationId]?.messageInputInstructions;
  if (!performInstructionData) return <Default mode={mode} />;
  const markdownText = performInstructionData[mode];

  if (!markdownText) return null;
  return (
    <Box>
      <ReactMarkdown>{markdownText}</ReactMarkdown>
    </Box>
  );
}
