import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Box, Typography } from 'Components/Base';
import {
  useGetWallpaperCampaign2021Order,
  useSelectWallpaperCampaign2021Order,
} from 'Domain/CampaignOrder/hooks';
import { CAMPAIGN_ORDER_STATUS } from 'Domain/CampaignOrder/types';
import React, { useEffect, useState } from 'react';
import DownloadWallpaperStep from './Components/DownloadWallpaperStep';
import SelectWallpaperSteps from './Components/SelectWallpaperSteps';

const getDisplayProgress = (status?: CAMPAIGN_ORDER_STATUS) => {
  if (!status) return '';
  switch (status) {
    case CAMPAIGN_ORDER_STATUS.NEW:
      return '1 / 2';
    case CAMPAIGN_ORDER_STATUS.COMPLETED:
      return '2 / 2';
    default:
      return '';
  }
};

export default function WallpaperCampaign({ orderId }: { orderId: string }) {
  const [showModal, setShowModal] = useState<boolean>(false);
  const { error, loading, data, refetch } = useGetWallpaperCampaign2021Order(orderId);
  const [selectWallpaper, { error: mutateError, loading: mutateLoading, data: mutateData }] =
    useSelectWallpaperCampaign2021Order();
  const wallpaperCampaign = data?.orderCampaignWallpaper;

  useEffect(() => {
    if (wallpaperCampaign) {
      if (wallpaperCampaign.status === CAMPAIGN_ORDER_STATUS.COMPLETED) {
        // Doing Nothing
      } else if (wallpaperCampaign.status === CAMPAIGN_ORDER_STATUS.NEW) {
        setShowModal(true);
      }
    }
    return () => {
      /** Clean up */
    };
  }, [wallpaperCampaign]);

  useEffect(() => {
    const updatedWallpaperCampaign = mutateData?.orderCampaignWallpaper;
    return () => {
      //
    };
  }, [mutateData]);

  const handleOnSelectWallpaper = async (wallpaper: {
    id: string;
    name: string;
    previewImageUrl: string;
  }) => {
    console.log(wallpaper);
    await selectWallpaper({
      variables: { orderId, wallpaperId: wallpaper.id },
    });
    await refetch();
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  if (wallpaperCampaign) {
    const buttonText =
      wallpaperCampaign.status === CAMPAIGN_ORDER_STATUS.NEW
        ? 'เลือกวอลเปเปอร์มงคล'
        : 'ดูวอลเปเปอร์มงคล';
    return (
      <>
        <Dialog open={showModal} maxWidth="md" scroll="paper" onClose={handleCloseModal}>
          <DialogTitle style={{ paddingBottom: 0 }}>
            <Box display="grid" gridTemplateRows="1fr" gridTemplateColumns="repeat(3,1fr)">
              <Box></Box>
              <Box justifySelf="center">
                <Typography>{getDisplayProgress(wallpaperCampaign.status)}</Typography>
              </Box>
              <Box justifySelf="end">
                <CloseIcon onClick={handleCloseModal} />
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            <>
              {wallpaperCampaign.status === CAMPAIGN_ORDER_STATUS.NEW && (
                <SelectWallpaperSteps
                  availableWallpapers={wallpaperCampaign.availableWallpapers}
                  onSelectWallpaper={handleOnSelectWallpaper}
                  loading={mutateLoading}
                />
              )}
              {wallpaperCampaign.status === CAMPAIGN_ORDER_STATUS.COMPLETED && (
                <DownloadWallpaperStep wallpaperUrl={wallpaperCampaign.wallpaperImageUrl} />
              )}
            </>
          </DialogContent>
        </Dialog>
        <Button
          variant="contained"
          color="primary"
          stType="gradient"
          size="large"
          fullWidth
          onClick={handleShowModal}
        >
          {buttonText}
        </Button>
      </>
    );
  }
  return null;
}
