import { Button, Typography } from 'Components/Base';
import { Link } from 'react-router-dom';
import { ORDER_STATUS, SatthaOrder } from 'Libs/Firebase/firestore/Order';
import MUIDataTable from 'mui-datatables';
import React from 'react';
import {
  canStoreUpload,
  canStoreView,
  generateStoreUploadURL,
  STORE_CAN_UPLOAD_STATUS_LIST,
} from 'Domain/Stores/utils';
import { generateClientMyOrderUrl } from 'Domain/Backoffice/utils';
import QRCode from 'react-qr-code';
import { ButtonGroup, TableCell, TableRow } from '@mui/material';
import { Box } from 'Components/Base';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { useBackofficeApprovalOrder } from 'Domain/Backoffice/hooks';
import { APPROVAL_TYPE } from 'API/fe-functions/backoffice/BackofficeAPI';
import { getStoreOrderStatusText } from 'Domain/Sattha/Status';
import { getChantModeText } from 'Domain/Sattha/Chant';
import { getDisplayDateWithRelativeDate } from 'Components/WreathSummaryCard/WreathSummaryCard';

export enum TABLE_VIEW_MODE {
  STORE_PRINT,
  UPLOAD,
  DEFAULT,
}
interface OrderSummaryTableProps {
  data?: SatthaOrder[];
  title?: string;
  viewMode?: TABLE_VIEW_MODE;
}

const getOrderStatusColor = (status: ORDER_STATUS) => {
  switch (status) {
    case ORDER_STATUS.CLIENT_ACCEPT:
    case ORDER_STATUS.COMPLETED:
      return 'green';
    case ORDER_STATUS.MERCHANT_ACCEPT:
    case ORDER_STATUS.MERCHANT_PREPARING:
    case ORDER_STATUS.MERCHANT_DELIVERING:
      return 'blue';
    default:
      return 'grey';
  }
};

export default function StoreOrderSummaryTable(props: OrderSummaryTableProps) {
  const { title = 'รายการออเดอร์', data = [], viewMode = TABLE_VIEW_MODE.DEFAULT } = props;
  const { approveOrder, isLoading, error } = useBackofficeApprovalOrder();
  const enhancedData = data.map((orderData) => ({
    ...orderData,
    clientUrl: generateClientMyOrderUrl(orderData.orderId),
    storeUploadUrl: generateStoreUploadURL(orderData.storeId, orderData.orderId),
  }));

  const columns = [
    {
      name: 'orderId',
      label: 'Order No.',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        // filterList: [TABLE_VIEW_MODE.UPLOAD].includes(viewMode)
        //   ? [...STORE_CAN_UPLOAD_STATUS_LIST]
        //   : [],
        sort: true,
        display: [TABLE_VIEW_MODE.DEFAULT, TABLE_VIEW_MODE.UPLOAD].includes(viewMode),
        customBodyRender: (value) => (
          <Typography style={{ color: getOrderStatusColor(value) }}>
            {getStoreOrderStatusText(value)}
          </Typography>
        ),
      },
    },
    {
      name: 'refId',
      label: 'Ref Order No.',
      options: {
        filter: true,
        sort: true,
        display: [TABLE_VIEW_MODE.DEFAULT, TABLE_VIEW_MODE.STORE_PRINT].includes(viewMode),
      },
    },
    {
      name: 'chantName',
      label: 'ชื่อผู้สั่ง',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'serviceDate',
      label: 'วันดำเนินการ',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => getDisplayDateWithRelativeDate(value, -1),
      },
    },
    // {
    //   name: 'skuCode',
    //   label: 'รหัสแพคเกจ',
    //   options: {
    //     filter: true,
    //     sort: false,
    //     display: [
    //       TABLE_VIEW_MODE.DEFAULT,
    //       TABLE_VIEW_MODE.UPLOAD,
    //       TABLE_VIEW_MODE.STORE_PRINT,
    //     ].includes(viewMode),
    //   },
    // },
    {
      name: 'skuMode',
      label: 'แพคเกจ',
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const order = data[dataIndex];
          const type = getChantModeText(order.skuMode);
          const skuName = order.skuName;
          return `${skuName} (${type})`;
        },
      },
    },
    {
      name: 'Actions',
      label: 'Actions',
      options: {
        download: false,
        display: [TABLE_VIEW_MODE.DEFAULT, TABLE_VIEW_MODE.UPLOAD].includes(viewMode),
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const order = data[dataIndex];
          return (
            <>
              <Button
                component={Link}
                to={`/store/${order.storeId}/order/${order.orderId}`}
                variant="contained"
                color="secondary"
                disabled={!(canStoreUpload(order.status) || canStoreView(order.status))}
              >
                {canStoreUpload(order.status) && 'ส่งหลักฐาน'}
                {!canStoreUpload(order.status) && 'ดูรายละเอียด'}
              </Button>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    pagination: false,
    selectableRows: 'none',
    elevation: 1,
    setTableProps: () => ({ size: 'small' }),
    // responsive: 'standard',
    // print: false,
    download: false,
    viewColumns: false,
  };

  return (
    <MUIDataTable
      title={title}
      data={enhancedData}
      columns={columns}
      options={options}
      size={'small'}
    />
  );
}
