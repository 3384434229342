import { Button, Typography } from 'Components/Base';
import { Link } from 'react-router-dom';
import { ORDER_STATUS, SatthaOrder } from 'Libs/Firebase/firestore/Order';
import MUIDataTable from 'mui-datatables';
import React from 'react';
import { generateStoreUploadURL } from 'Domain/Stores/utils';
import { generateClientMyOrderUrl } from 'Domain/Backoffice/utils';
import QRCode from 'react-qr-code';
import { ButtonGroup, TableCell, TableRow } from '@mui/material';
import BackofficeStoreOrderUploadImages from './BackofficeStoreOrderUploadImages';
import { Box } from 'Components/Base';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { useBackofficeApprovalOrder } from 'Domain/Backoffice/hooks';
import { APPROVAL_TYPE } from 'API/fe-functions/backoffice/BackofficeAPI';
import { getStatusText } from 'Domain/Sattha/Status';
import { getChantModeText } from 'Domain/Sattha/Chant';

export enum TABLE_VIEW_MODE {
  STORE_PRINT,
  APPROVAL,
  DEFAULT,
}

const enableApproveRejectAction = (order: SatthaOrder) => {
  return [
    ORDER_STATUS.MERCHANT_DELIVERED,
    ORDER_STATUS.CLIENT_ACCEPT,
    ORDER_STATUS.COMPLETED,
    ORDER_STATUS.MESSAGE_FAIL_DELIVERING,
  ].includes(order.status);
};

const showApproveButton = (order: SatthaOrder) => {
  return [ORDER_STATUS.MERCHANT_DELIVERED].includes(order.status);
};
interface OrderSummaryTableProps {
  data?: SatthaOrder[];
  title?: string;
  viewMode?: TABLE_VIEW_MODE;
  refetchData?: () => Promise<void>;
  onViewOrder?: (order: SatthaOrder) => void;
}

export default function OrderSummaryTable(props: OrderSummaryTableProps) {
  const {
    title = 'รายการออเดอร์',
    data = [],
    viewMode = TABLE_VIEW_MODE.DEFAULT,
    refetchData = () => {
      /**/
    },
    onViewOrder = (order) => {
      /** */
    },
  } = props;
  const { approveOrder, isLoading, error } = useBackofficeApprovalOrder();
  const enhancedData = data.map((orderData) => ({
    ...orderData,
    clientUrl: generateClientMyOrderUrl(orderData.orderId),
    storeUploadUrl: generateStoreUploadURL(orderData.storeId, orderData.orderId),
  }));

  const handleApproveOrder = async (order: SatthaOrder) => {
    await approveOrder(order.orderId, APPROVAL_TYPE.APPROVE);
    await refetchData();
  };

  const handleRejectOrder = async (order) => {
    await approveOrder(order.orderId, APPROVAL_TYPE.REJECT);
    await refetchData();
  };

  const handleViewOrder = async (order: SatthaOrder) => {
    onViewOrder(order);
  };

  const columns = [
    {
      name: 'orderId',
      label: 'Order No.',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        sort: true,
        display: [TABLE_VIEW_MODE.DEFAULT, TABLE_VIEW_MODE.APPROVAL].includes(viewMode),
        customBodyRender: (value) => getStatusText(value),
      },
    },
    {
      name: 'refId',
      label: 'Ref Order No.',
      options: {
        filter: true,
        sort: true,
        display: [TABLE_VIEW_MODE.DEFAULT, TABLE_VIEW_MODE.STORE_PRINT].includes(viewMode),
      },
    },
    {
      name: 'refSource',
      label: 'Ref Source',
      options: {
        filter: true,
        sort: true,
        display: [TABLE_VIEW_MODE.DEFAULT].includes(viewMode),
      },
    },
    {
      name: 'chantName',
      label: 'ชื่อ',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'packageDate',
      label: 'วันดำเนินการ',
      options: {
        filter: true,
        sort: false,
        display: [
          TABLE_VIEW_MODE.DEFAULT,
          // TABLE_VIEW_MODE.APPROVAL,
          // TABLE_VIEW_MODE.STORE_PRINT,
        ].includes(viewMode),
      },
    },
    {
      name: 'skuCode',
      label: 'รหัสแพคเกจ',
      options: {
        filter: true,
        sort: false,
        display: [
          TABLE_VIEW_MODE.DEFAULT,
          TABLE_VIEW_MODE.APPROVAL,
          TABLE_VIEW_MODE.STORE_PRINT,
        ].includes(viewMode),
      },
    },
    {
      name: 'skuMode',
      label: 'ประเภท',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => getChantModeText(value),
      },
    },
    {
      name: 'skuName',
      label: 'แพคเกจ',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'clientUrl',
      label: 'Client URL',
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
      },
    },
    {
      name: 'storeUploadUrl',
      label: 'Store URL',
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
      },
    },
    {
      name: 'QR',
      label: 'QR',
      options: {
        download: false,
        display: [TABLE_VIEW_MODE.STORE_PRINT].includes(viewMode),
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const order = data[dataIndex];
          const url = generateStoreUploadURL(order.storeId, order.orderId);
          return <QRCode value={url} level="L" size={64} />;
        },
      },
    },
    {
      name: 'Actions',
      label: 'Actions',
      options: {
        download: false,
        display: [TABLE_VIEW_MODE.DEFAULT].includes(viewMode),
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const order = data[dataIndex];
          return (
            <>
              <Button component={Link} to={`/myorder/${order.orderId}`} variant="contained">
                {'หน้าลูกค้า'}
              </Button>
              <Button
                component={Link}
                to={`/store/${order.storeId}/order/${order.orderId}`}
                variant="contained"
              >
                {'ส่งหลักฐาน'}
              </Button>
            </>
          );
        },
      },
    },
    {
      name: 'Approval Actions',
      label: 'Actions',
      options: {
        download: false,
        display: [TABLE_VIEW_MODE.APPROVAL].includes(viewMode),
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const order = data[dataIndex];
          const enableApproveRejectActionButton = enableApproveRejectAction(order);
          const enableApproveActionButton = showApproveButton(order);
          return (
            <>
              <Box display="fex" flexDirection="row" justifyContent="flex-end">
                <ButtonGroup
                  disableElevation
                  variant="text"
                  disabled={!enableApproveRejectActionButton}
                >
                  <Button
                    startIcon={<InfoIcon />}
                    color="info"
                    onClick={(event) => {
                      event.stopPropagation();
                      handleViewOrder(order);
                    }}
                    disabled={!enableApproveActionButton}
                  >
                    View
                  </Button>
                  <Button
                    startIcon={<CheckIcon />}
                    color="primary"
                    onClick={(event) => {
                      event.stopPropagation();

                      handleApproveOrder(order);
                    }}
                    disabled={!enableApproveActionButton}
                  >
                    Approve
                  </Button>
                  <Button
                    startIcon={<ClearIcon />}
                    color="secondary"
                    onClick={(event) => {
                      event.stopPropagation();
                      handleRejectOrder(order);
                    }}
                  >
                    Reject
                  </Button>
                </ButtonGroup>
              </Box>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    pagination: false,
    selectableRows: 'none',
    elevation: 1,
    setTableProps: () => ({ size: 'small' }),
    responsive: 'standard',
    expandableRows: [TABLE_VIEW_MODE.APPROVAL].includes(viewMode),
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    isRowExpandable: (dataIndex, expandedRows) => {
      if (viewMode !== TABLE_VIEW_MODE.APPROVAL) return false;

      const dataRow = data[dataIndex];
      if (![ORDER_STATUS.MERCHANT_DELIVERED, ORDER_STATUS.CLIENT_ACCEPT].includes(dataRow.status))
        return false;
      // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
      if (
        // expanded rows > X AND selected row is not the expanded one
        expandedRows.data.length > 20 &&
        expandedRows.data.filter((d) => d.dataIndex === dataIndex).length === 0
      )
        return false;
      return true;
    },
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      const { dataIndex } = rowMeta;
      const dataRow = data[dataIndex];
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <BackofficeStoreOrderUploadImages id={dataRow.id} orderId={dataRow.orderId} />
          </TableCell>
        </TableRow>
      );
    },
    onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) =>
      console.log(curExpanded, allExpanded, rowsExpanded),
  };

  return (
    <MUIDataTable
      title={title}
      data={enhancedData}
      columns={columns}
      options={options}
      size={'small'}
    />
  );
}
