import React from 'react';

import StoreOrderForm from 'Components/StoreOrderForm';
import { useStoreUpdateUploadImages } from 'Domain/Stores/hooks';
import { StoreUploadOrderProps } from './types';
import Heading from 'Components/Layout/Heading';
import { STORE_UPDATE_ORDER_STATUS } from 'API/fe-functions/store/StoreAPI';

const formatBucketObjectList = (formValues) => {
  const { uploadedImagesData, uploadedVideoData, uploadedTextData } = formValues;
  const imageObjectList = uploadedImagesData.map((data) => ({
    url: data.fileurl,
    name: data.filename,
    ref: data.ref,
    bucket: data.bucket,
    type: 'IMAGE',
  }));
  const videoObjectList = uploadedVideoData.map((data) => ({
    url: data.fileurl,
    name: data.filename,
    ref: data.ref,
    bucket: data.bucket,
    type: 'VIDEO',
  }));
  const bucketObjectList = [...imageObjectList, ...videoObjectList];
  if (uploadedTextData) {
    bucketObjectList.push({
      url: '',
      name: 'Text Content',
      type: 'TEXT',
      ref: '',
      bucket: '',
      contents: uploadedTextData,
    });
  }
  return bucketObjectList;
};

export default function StoreFulfillmentOrder(props: StoreUploadOrderProps) {
  const { storeId, orderId, onUploadSuccess, orderData, mode } = props;
  const { submitOrder, isLoading, error: submitError } = useStoreUpdateUploadImages();

  const handleSubmit = async (formValues) => {
    console.log('hs', formValues);
    const bucketObjectList = formatBucketObjectList(formValues);
    // console.log(bucketObjectList);
    const result = await submitOrder(storeId, orderId, bucketObjectList);
    console.log(result);
    // Have result means success
    if (result) onUploadSuccess();
  };

  const handleSave = async (formValues) => {
    console.log('hs', formValues);
    const bucketObjectList = formatBucketObjectList(formValues);
    // console.log(bucketObjectList);
    const result = await submitOrder(
      storeId,
      orderId,
      bucketObjectList,
      STORE_UPDATE_ORDER_STATUS.MERCHANT_DELIVERING
    );
    console.log(result);
    // Have result means success
    if (result) onUploadSuccess();
  };

  return (
    <>
      <Heading title="อัพโหลดหลักฐาน" />
      <StoreOrderForm
        {...orderData}
        mode={mode}
        customerName={orderData.chantName}
        packageName={orderData.skuName}
        packageId={orderData.skuCode}
        skuMode={orderData.skuMode}
        orderRealId={orderData.id}
        onSubmit={handleSubmit}
        onSave={handleSave}
        loading={isLoading}
        error={submitError?.response?.data}
      />
    </>
  );
}
