export interface RedeemRedeemCodeInput {
  redemptionCode: string;
}

export enum RedemptionCodeStatus {
  AVAILABLE = 'AVAILABLE',
  NOT_FOUND = 'NOT_FOUND',
  EXPIRED = 'EXPIRED',
  USED = 'USED',
  INVALID = 'INVALID',
}

export interface CheckRedemptionCodeResponse {
  code: string;
  status: RedemptionCodeStatus;
  data?: {
    orderIds: string[];
  };
}

export interface RedeemRedeemCodeSuccessResponse {
  redeemRedeemCode: {
    data?: {
      orderIds: string[];
    };
    status: RedemptionCodeStatus;
  };
}

export interface RedeemRedeemCodeInvalidInputResponse {
  redeemRedeemCode: {
    message: string;
    code: string;
    additionalData: {
      status: RedemptionCodeStatus;
    };
  };
}

export type RedeemRedeemCodeResponse =
  | RedeemRedeemCodeSuccessResponse
  | RedeemRedeemCodeInvalidInputResponse;
