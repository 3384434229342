import { Box, Container } from 'Components/Base';
import Loading from 'Components/Loading';
import React, { useEffect, useState } from 'react';
import { useParams, useLocation, Navigate } from 'react-router-dom';
import { useAuthContext } from 'Domain/auth/hooks';
import getConfig from 'Libs/Config/utils';
import { AUTH_STATE } from 'Domain/auth/types';
import getLineLiff, { liff } from 'Libs/Liff';
const config = getConfig();

enum AUTHEN_STATE {
  INIT,
  AUTHENTICATED,
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

interface LiffProfile {
  userId: string;
  displayName: string;
  pictureUrl?: string;
  statusMessage?: string;
}

export default function ClientLogin() {
  const query = useQuery();
  const liffRedirectTo = query.get('liffRedirectTo');
  const liffRedirectToSearch = query.get('liffSearch');
  const [authenState, setAuthenState] = useState<AUTHEN_STATE>(AUTHEN_STATE.INIT);
  const [liffUserProfile, setLiffUserProfile] = useState<LiffProfile | null>(null);
  const { loading, authState, loginWithLINEAccessToken, userInfo } = useAuthContext();

  useEffect(() => {
    console.log('will init liff');
    getLineLiff()
      .then((liff) => {
        console.log('liff init');
        if (!liff.isLoggedIn()) {
          liff.login({ redirectUri: window.location.toString() });
        } else {
          liff.getProfile().then((profile) => {
            setLiffUserProfile(profile);
          });
        }
      })
      .catch((error) => {
        console.error('LIFF Init Error', error);
      });

    return () => {};
  }, []);

  useEffect(() => {
    console.log(authState);
    if (authState === AUTH_STATE.NOT_LOGGED_IN && liffUserProfile) {
      console.log('Logging with LINE');
      const accessToken = liff.getAccessToken();
      loginWithLINEAccessToken!({ lineAccessToken: accessToken! });
    } else if (authState === AUTH_STATE.LOGGED_IN && liffUserProfile) {
      if (liffUserProfile.userId !== userInfo?.id) {
        // TODO: Recheck if we can re-login with this
        console.log('Account Mismatch, relogin with LINE');
        const accessToken = liff.getAccessToken();
        loginWithLINEAccessToken!({ lineAccessToken: accessToken! });
      } else {
        console.log('Logged in with LINE');
        setAuthenState(AUTHEN_STATE.AUTHENTICATED);
      }
    }
  }, [authState, userInfo, liffUserProfile, loginWithLINEAccessToken]);

  if (!liffRedirectTo)
    return (
      <>
        <Container maxWidth={'sm'}>
          <Box>Redirecting...</Box>
          <Box mt={5}>
            <Loading />
          </Box>
        </Container>
      </>
    );

  if (authenState === AUTHEN_STATE.INIT) {
    return (
      <>
        <Container maxWidth={'sm'}>
          <Box mt={5}>
            <Loading />
          </Box>
        </Container>
      </>
    );
  }
  console.log(liffRedirectTo);
  return <Navigate to={`${liffRedirectTo}${liffRedirectToSearch}`} replace />;
}
