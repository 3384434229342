import axios, { AxiosInstance } from 'axios';

import { getHeaders as getAuthHeader } from '../api';

const API_URL =
  process.env.REACT_APP_STORE_API_URL ||
  'https://asia-southeast2-sattha-online-orders.cloudfunctions.net/feOrders/store';

export enum STORE_UPDATE_ORDER_STATUS {
  MERCHANT_DELIVERED = 'MERCHANT_DELIVERED',
  MERCHANT_DELIVERING = 'MERCHANT_DELIVERING',
}

interface StorageObject {
  url: string;
  name: string;
  bucket?: string;
}

type StoreUpdateOrderInput = {
  attachmentUrls?: string[];
  attachmentObjects?: StorageObject[];
  status: STORE_UPDATE_ORDER_STATUS;
};

export type StoreUpdateShipmentOrderInput = {
  trackingProvider: string;
  trackingCode: string;
};
class StoreAPI {
  private instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      baseURL: API_URL,
    });
  }

  async getHeaders() {
    return getAuthHeader();
  }

  /**
   *
   * @param storeId - Store's id
   * @param orderId - OrderId field, not order's id field
   * @returns
   */
  async getOrder(storeId: string, orderId: string) {
    const headers = await this.getHeaders();
    const result = await this.instance.get(`/${storeId}/order/${orderId}`, {
      headers,
    });
    return result.data;
  }

  async submitOrder(storeId: string, orderId: string, data: StoreUpdateOrderInput) {
    const headers = await this.getHeaders();
    const result = await this.instance.patch(`/${storeId}/order/${orderId}`, data, {
      headers,
    });
    return result.data;
  }

  async updateShipmentData(storeId: string, orderId: string, data: StoreUpdateShipmentOrderInput) {
    const headers = await this.getHeaders();
    const result = await this.instance.patch(`/${storeId}/order/${orderId}/shipment`, data, {
      headers,
    });
    return result.data;
  }

  async getOrderSummaryList(storeId: string) {
    const headers = await this.getHeaders();
    const result = await this.instance.get(`/${storeId}/summary`, {
      headers,
    });
    return result.data;
  }

  async getOrderSummary(storeId: string, orderSummaryId: string) {
    const headers = await this.getHeaders();
    const result = await this.instance.get(`/${storeId}/summary/${orderSummaryId}`, {
      headers,
    });
    return result.data;
  }

  async getWreathOrders(storeId: string) {
    const headers = await this.getHeaders();
    const result = await this.instance.get(`/${storeId}/wreath-order`, {
      headers,
    });
    return result.data;
  }

  async getFTLiveBookings(storeId: string) {
    const headers = await this.getHeaders();
    const result = await this.instance.get(`/${storeId}/fortune-telling-order/live`, {
      headers,
    });
    return result.data;
  }

  async getFTLiveBookingsWithAgent(storeId: string, agentId: String) {
    const headers = await this.getHeaders();
    const result = await this.instance.get(`/${storeId}/fortune-telling-order/live/${agentId}`, {
      headers,
    });
    return result.data;
  }
}

export default StoreAPI;
