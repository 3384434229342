import { Button, Container, Typography } from 'Components/Base';
import Heading from 'Components/Layout/Heading';
import OrderSummaryTable, { TABLE_VIEW_MODE } from './Components/OrderSummaryTable';
import { useGetOrderSummaryFromSummaryId } from 'Domain/Backoffice/hooks';
import { Helmet } from 'react-helmet';
import { Drawer, IconButton, List, ListItem, ListItemText, Menu, MenuItem } from '@mui/material';
import { BackofficeOrderSummary } from 'Domain/Backoffice/types';
import Loading from 'Components/Loading';
import { getChantModeText } from 'Domain/Sattha/Chant';
import { Paper } from '@mui/material';
import { Box } from 'Components/Base';
import { skuSummarySortFn } from 'Domain/Orders/utils';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { SatthaOrder } from 'Libs/Firebase/firestore/Order';
import BackofficeApprovalOrderBox from './Containers/BackofficeApprovalOrderBox';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';
import NavigationHeader from 'Components/Layout/NavigationHeader';

export enum SUMMARY_MODE {
  default = 'default',
  print = 'print',
  approval = 'approval',
}

const tableViewModeMapping = {
  [SUMMARY_MODE.default]: TABLE_VIEW_MODE.DEFAULT,
  [SUMMARY_MODE.print]: TABLE_VIEW_MODE.STORE_PRINT,
  [SUMMARY_MODE.approval]: TABLE_VIEW_MODE.APPROVAL,
};

const getUITitle = (ordersummaryId, data) => {
  if (!data) return `รายการออเดอร์`;
  return `รายการออเดอร์ - ${ordersummaryId} - ${data?.store.name}`;
};

const getTotalCost = (data) => {
  const formattedCost = new Intl.NumberFormat('th-TH', {
    style: 'currency',
    currency: 'THB',
  }).format(data?.totalCost || 0);

  return `ยอดที่ต้องโอนให้${data?.store.name} = ${formattedCost}`;
};

function OrderSummaryHeader({ data }: { data: BackofficeOrderSummary }) {
  const formattedCost = new Intl.NumberFormat('th-TH', {
    style: 'currency',
    currency: 'THB',
  }).format(data?.totalCost || 0);
  const skuSummary = data.skuSummary;
  const datesToPerform = data.orders.map((order) => order.packageDate);
  const uniqDates = Array.from(new Set(datesToPerform));
  const skuSummaryList = Object.entries(skuSummary);
  const location = data.locationName;
  skuSummaryList.sort(skuSummarySortFn);
  return (
    <Paper elevation={2}>
      <Box p={1}>
        <List
          dense={true}
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: 0,
          }}
        >
          <ListItem style={{ maxWidth: 200 }}>
            <ListItemText primary={`สถานที่`} secondary={location ?? '-'} />
          </ListItem>
          <ListItem style={{ maxWidth: 200 }}>
            <ListItemText primary={`วันดำเนินการ`} secondary={uniqDates.join(', ')} />
          </ListItem>
          <ListItem style={{ maxWidth: 200 }}>
            <ListItemText primary={`ยอดที่ต้องโอน`} secondary={formattedCost} />
          </ListItem>
        </List>
        <List
          dense={true}
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: 0,
            flexWrap: 'wrap',
          }}
        >
          {skuSummaryList.map(([skuCode, skuSummaryData]) => {
            return (
              <ListItem key={skuCode} style={{ maxWidth: 200 }}>
                <ListItemText
                  primary={`${skuSummaryData.sku.name} (${getChantModeText(
                    skuSummaryData.sku.mode
                  )})`}
                  secondary={skuSummaryData.count}
                />
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Paper>
  );
}

function PositionedMenu({ orderSummaryId }: { orderSummaryId: string }) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (mode: SUMMARY_MODE) => () => {
    handleClose();
    navigate(`/backoffice/summary/${orderSummaryId}/${mode}`);
  };

  return (
    <div>
      <Button
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        variant="outlined"
        color="secondary"
      >
        View
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={handleMenuClick(SUMMARY_MODE.default)}>Default</MenuItem>
        <MenuItem onClick={handleMenuClick(SUMMARY_MODE.print)}>Print</MenuItem>
        <MenuItem onClick={handleMenuClick(SUMMARY_MODE.approval)}>Approval</MenuItem>
      </Menu>
    </div>
  );
}

const drawerWidth = 800;

export default function BackofficeOrderSummaryRoute({
  orderSummaryId,
  mode = SUMMARY_MODE.default,
}: {
  orderSummaryId: string;
  mode?: SUMMARY_MODE;
}) {
  const { loading, error, data, refetch } = useGetOrderSummaryFromSummaryId(orderSummaryId);
  const [selectedOrder, setSelectedOrder] = useState<SatthaOrder | null>(null);
  const tableViewMode = tableViewModeMapping[mode];
  console.log(mode, tableViewMode);

  const handleDrawerClose = () => {
    setSelectedOrder(null);
  };

  const handleViewOrder = (order) => {
    setSelectedOrder(order);
  };

  return (
    <>
      <Helmet>
        <title>{getUITitle(orderSummaryId, data)}</title>
      </Helmet>
      <Container>
        <Box my={3} />
        <NavigationHeader
          title={getUITitle(orderSummaryId, data)}
          breadCrumb={[{ href: `/backoffice/summary`, title: 'สรุปออเดอร์' }]}
          toolbar={<PositionedMenu orderSummaryId={orderSummaryId} />}
        />
        {loading && !data && <Loading />}
        {data && (
          <>
            <OrderSummaryHeader data={data} />
            <Box my={1} />
            <OrderSummaryTable
              data={data?.orders}
              viewMode={tableViewModeMapping[mode]}
              refetchData={refetch}
              onViewOrder={handleViewOrder}
            />
          </>
        )}
        <Drawer
          variant="temporary"
          open={selectedOrder !== null}
          sx={{
            width: drawerWidth,
            maxWidth: '100vw',
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              maxWidth: '100vw',
              boxSizing: 'border-box',
            },
          }}
          anchor="right"
        >
          <Box>
            <IconButton onClick={handleDrawerClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box>
            <BackofficeApprovalOrderBox
              key={selectedOrder?.id!}
              initialOrderId={selectedOrder?.orderId!}
              orders={data?.orders ?? []}
              onOrderApproval={() => {
                refetch();
              }}
            />
          </Box>
        </Drawer>
      </Container>
    </>
  );
}
