import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { Box, Button, Typography } from 'Components/Base';
import { ImageBox } from 'Components/Base/Box';
import React, { useState } from 'react';

enum SELECT_WALLPAPER_STEPS {
  CHOOSE_WALLPAPER,
  CHOOSE_WALLPAPER_SELECTED,
}

export default function SelectWallpaperSteps({
  availableWallpapers,
  onSelectWallpaper,
  loading,
}: {
  availableWallpapers: { id: string; name: string; previewImageUrl: string }[];
  onSelectWallpaper: (wallpaper: { id: string; name: string; previewImageUrl: string }) => void;
  loading: boolean;
}) {
  const [selectWallpaperSteps, setSelectWallpaperSteps] = useState(
    SELECT_WALLPAPER_STEPS.CHOOSE_WALLPAPER
  );
  const [selectedWallpaper, setSelectedWallpaper] = useState<{
    id: string;
    name: string;
    previewImageUrl: string;
  } | null>(null);

  const onClick = () => {
    if (selectedWallpaper) {
      onSelectWallpaper(selectedWallpaper);
    }
  };

  return (
    <Box>
      <Typography variant="h4">คุณได้รับ ดิจิตอลวอลเปเปอร์ เสริมดวงชะตาฟรี!</Typography>
      <Box mt={4} />
      <Typography variant="h5">เลือกด้านที่คุณต้องการเเสริมดวง</Typography>
      <Typography variant="subtitle2">
        (สามารถเลือกได้ 1 ภาพต่อ 1 การสั่งซื้อ และไม่สามารถเปลี่ยนทีหลังได้)
      </Typography>
      <Box mt={1} />
      <FormControl component="fieldset">
        <FormLabel component="legend"></FormLabel>
        <RadioGroup
          aria-label="wallpaper"
          name="radio-buttons-group"
          value={selectedWallpaper?.id}
          onChange={(event) => {
            const wallpaper = availableWallpapers.find(
              (wallpaper) => wallpaper.id === event.target.value
            );
            setSelectedWallpaper(wallpaper ?? null);
          }}
        >
          {availableWallpapers.map((wallpaper) => (
            <FormControlLabel
              key={wallpaper.id}
              value={wallpaper.id}
              control={<Radio />}
              label={wallpaper.name}
            />
          ))}
        </RadioGroup>
      </FormControl>
      {selectedWallpaper && (
        <>
          <Box mt={2} />
          <Typography variant="h5">ตัวอย่างวอลเปเปอร์</Typography>
          <Box display="flex" alignItems="center" flexDirection="column" m={2}>
            <ImageBox
              src={selectedWallpaper.previewImageUrl}
              className="imagebox"
              style={{ maxWidth: 110 }}
            />
          </Box>
        </>
      )}
      <Box mt={4}></Box>
      <Button
        variant="contained"
        color="primary"
        size="large"
        stType="gradient"
        fullWidth
        disabled={selectedWallpaper === null || loading}
        onClick={onClick}
      >
        ยืนยันตัวเลือก
      </Button>
      <Box mt={2}></Box>
    </Box>
  );
}
