import { FormControl, FormHelperText, MenuItem, Select, TextField } from '@mui/material';
import { CHANT_MODE, UIFORM_TEXT_FIELDS } from 'Domain/Sattha/Chant';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { Field } from 'react-final-form';
import { Box, Typography } from 'Components/Base';
import {
  rmsAvailableGender,
  RMS_REQUEST_TYPE,
  rmsAvailableWallpaperColor,
  RMS_WALLPAPER_COLOR,
} from 'Domain/Sku/Wallpaper';
import { RMSRequestTypeInput } from '../Input/RMSRequestInput';
import { getTimeInput } from 'Domain/Sku/FixBadLuck';
import {
  availableDays,
  availableMonths,
  availableYears,
  EMAIL_REGEX,
  PHONE_NO_REGEX,
  validateAge,
  validateDate,
} from '../utils/utils';
import GenderInput from '../Input/GenderInput';

const useStyles = makeStyles((theme) => ({
  hiddenField: {
    display: 'none',
  },
}));

export function validations(values: any, FORM_TEXT) {
  const errors: { [key: string]: string } = {};
  if (!values.gender) errors.gender = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_GENDER_REQUIRED];
  if (!values.phoneNo) {
    errors.phoneNo = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_SHIPPING_PHONENO];
  } else {
    // if (!PHONE_NO_REGEX.test(values.phoneNo)) {
    //   errors.phoneNo = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_SHIPPING_PHONENO_FORMAT];
    // }
  }
  if (values.email) {
    if (!EMAIL_REGEX.test(values.email)) {
      errors.email = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_EMAIL_NOT_VALID];
    }
  }
  if (!values.birthDay) errors.birthDay = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHDAY_REQUIRED];
  if (!values.birthMonth)
    errors.birthMonth = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHMONTH_REQUIRED];
  if (!values.birthYear) errors.birthYear = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHYEAR_REQUIRED];
  if (values.birthDay && values.birthMonth && values.birthYear) {
    const isValidBirthDay = validateAge({
      day: values.birthDay,
      month: values.birthMonth,
      year: values.birthYear,
    });
    if (!isValidBirthDay) {
      errors.birthYear =
        'ระบบไม่สามารถให้บริการบุคคลที่อายุต่ำกว่า 13 ปีได้ กรุณาติดต่อ hello@sattha.online เพื่อทำการคืนเงิน';
    }
    const isValidDate = validateDate({
      day: values.birthDay,
      month: values.birthMonth,
      year: values.birthYear,
    });
    if (!isValidDate) {
      errors.birthDay = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHDATE_NOT_VALID];
    }
  }
  if (!values.birthTime) {
    // pass validation
    // errors.birthTime = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_REQUIRED];
  } else {
    try {
      const { hour, minute, notKnow } = getTimeInput(values.birthTime);
      if (notKnow) {
        errors.birthTime = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_NOT_IN_FORMAT];
      }
    } catch (error) {
      if (error.message === 'Not Valid Format') {
        errors.birthTime = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_NOT_IN_FORMAT];
      } else if (error.message === 'Time not in range') {
        errors.birthTime = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_NOT_IN_TIME_FORMAT];
      } else {
        errors.birthTime = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_NOT_IN_FORMAT];
      }
    }
  }
  if (!values.lineId) {
    errors.lineId = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_LINE_ID_REQUIRED];
  }
  if (!values.phoneModel) {
    errors.phoneModel = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_PHONE_MODEL_REQUIRED];
  }
  if (!values.wallpaperColor) {
    errors.wallpaperColor = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_WALLPAPER_COLOR_REQUIRED];
  }
  return errors;
}

export default function RMSWallpaperForm({
  FORM_TEXT,
  locationId,
  mode,
  forceWallpaperColor,
}: {
  FORM_TEXT: any;
  locationId: string;
  mode: CHANT_MODE;
  wallpaperRequestType: RMS_REQUEST_TYPE;
  forceWallpaperColor?: RMS_WALLPAPER_COLOR;
}) {
  const classes = useStyles();
  const hideColorInput = forceWallpaperColor === RMS_WALLPAPER_COLOR.NONE;
  const disableColorInput = forceWallpaperColor !== null && forceWallpaperColor !== undefined;
  return (
    <>
      <Box my={1}></Box>
      <Typography variant="info">
        {'* ข้อมูลของท่านจะถูกใช้เพื่อการทำวอลเปเปอร์เท่านั้น'}
      </Typography>
      <Box my={3}></Box>
      <FormControl fullWidth required>
        <Field
          name="chantName"
          render={(props) => (
            <>
              <Typography variant="h5">{FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_NAME]}</Typography>
              <TextField
                {...props.input}
                label=""
                variant="outlined"
                placeholder="กรอกชื่อ - นามสกุลจริง"
                fullWidth
                InputProps={{
                  startAdornment: <div />,
                }}
                inputProps={{ maxLength: 60 }}
                helperText={
                  props.meta.error && props.meta.touched ? (
                    <FormHelperText error>{props.meta.error}</FormHelperText>
                  ) : null
                }
              />
            </>
          )}
        />
      </FormControl>
      <Box my={2}></Box>
      <FormControl fullWidth required>
        <Field
          name="gender"
          render={(props) => (
            <>
              <Typography variant="h5">{FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_GENDER]}</Typography>
              <GenderInput
                {...props}
                placeholder={FORM_TEXT[UIFORM_TEXT_FIELDS.PLACEHOLDER_GENDER]}
                choices={rmsAvailableGender}
              />
            </>
          )}
        />
      </FormControl>
      <Box my={2}></Box>
      <FormControl fullWidth required>
        <Field
          name="phoneNo"
          render={(props) => (
            <>
              <Typography variant="h5">{FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_PHONENO]}</Typography>
              <TextField
                {...props.input}
                label=""
                variant="outlined"
                placeholder={FORM_TEXT[UIFORM_TEXT_FIELDS.PLACEHOLDER_PHONENO]}
                fullWidth
                InputProps={{
                  startAdornment: <div />,
                }}
                inputProps={{ maxLength: 15 }}
                helperText={
                  props.meta.error && props.meta.touched ? (
                    <FormHelperText error>{props.meta.error}</FormHelperText>
                  ) : null
                }
              />
            </>
          )}
        />
      </FormControl>
      <Box my={2}></Box>
      <FormControl fullWidth>
        <Field
          name="email"
          render={(props) => (
            <>
              <Typography variant="h5">{FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_EMAIL]}</Typography>
              <TextField
                {...props.input}
                label=""
                variant="outlined"
                placeholder={FORM_TEXT[UIFORM_TEXT_FIELDS.PLACEHOLDER_EMAIL]}
                fullWidth
                InputProps={{
                  startAdornment: <div />,
                }}
                inputProps={{ maxLength: 60 }}
                helperText={
                  props.meta.error && props.meta.touched ? (
                    <FormHelperText error>{props.meta.error}</FormHelperText>
                  ) : null
                }
              />
            </>
          )}
        />
      </FormControl>
      <Box my={3}></Box>
      <FormControl fullWidth required>
        <Field
          name="birthDay"
          render={(props) => (
            <>
              <Typography variant="h5">{FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_BIRTHDATE]}</Typography>
              <Select
                {...props.input}
                displayEmpty
                variant="outlined"
                // placeholder="กรอกชื่อ - นามสกุลจริง เพื่อใช้ในการประกอบพิธี"
                fullWidth
              >
                <MenuItem value="" disabled>
                  {FORM_TEXT[UIFORM_TEXT_FIELDS.PLACEHOLDER_BIRTHDATE]}
                </MenuItem>
                {availableDays?.map((data) => (
                  <MenuItem key={data.value} value={data.value}>
                    {data.display}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        />
      </FormControl>
      <Box my={2}></Box>
      <FormControl fullWidth>
        <Field
          name="birthMonth"
          render={(props) => (
            <>
              <Typography variant="h5">{FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_BIRTHMONTH]}</Typography>
              <Select {...props.input} displayEmpty variant="outlined" fullWidth>
                <MenuItem value="" disabled>
                  {FORM_TEXT[UIFORM_TEXT_FIELDS.PLACEHOLDER_BIRTHMONTH]}
                </MenuItem>
                {availableMonths?.map((data) => (
                  <MenuItem key={data.value} value={data.value}>
                    {data.display}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        />
      </FormControl>
      <Box my={2}></Box>
      <FormControl fullWidth>
        <Field
          name="birthYear"
          render={(props) => (
            <>
              <Typography variant="h5">{FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_BIRTHYEAR]}</Typography>
              <Select {...props.input} displayEmpty variant="outlined" fullWidth>
                <MenuItem value="" disabled>
                  {FORM_TEXT[UIFORM_TEXT_FIELDS.PLACEHOLDER_BIRTHYEAR]}
                </MenuItem>
                {availableYears?.map((data) => (
                  <MenuItem key={data.value} value={data.value}>
                    {data.display}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        />
      </FormControl>
      <Box my={2}></Box>
      <FormControl fullWidth>
        <Field
          name="birthTime"
          render={(props) => (
            <>
              <Typography variant="h5">{FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_BIRTHTIME]}</Typography>
              <TextField
                {...props.input}
                label={''}
                variant="outlined"
                placeholder={FORM_TEXT[UIFORM_TEXT_FIELDS.PLACEHOLDER_BIRTHTIME]}
                fullWidth
                InputProps={{
                  startAdornment: <div />,
                }}
                inputProps={{ maxLength: 60 }}
                helperText={
                  props.meta.error && props.meta.touched ? (
                    <FormHelperText error>{props.meta.error}</FormHelperText>
                  ) : null
                }
              />
            </>
          )}
        />
      </FormControl>
      <Box my={2}></Box>
      <FormControl fullWidth>
        <Field
          name="lineId"
          render={(props) => (
            <>
              <Typography variant="h5">{FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_LINE_ID]}</Typography>
              <TextField
                {...props.input}
                label={''}
                variant="outlined"
                placeholder={FORM_TEXT[UIFORM_TEXT_FIELDS.PLACEHOLDER_LINE_ID]}
                fullWidth
                InputProps={{
                  startAdornment: <div />,
                }}
                inputProps={{ maxLength: 60 }}
                helperText={
                  props.meta.error && props.meta.touched ? (
                    <FormHelperText error>{props.meta.error}</FormHelperText>
                  ) : null
                }
              />
            </>
          )}
        />
      </FormControl>
      <Box my={2}></Box>
      <FormControl fullWidth>
        <Field
          name="phoneModel"
          render={(props) => (
            <>
              <Typography variant="h5">
                {FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_PHONE_MODEL]}
              </Typography>
              <TextField
                {...props.input}
                label={''}
                variant="outlined"
                placeholder={FORM_TEXT[UIFORM_TEXT_FIELDS.PLACEHOLDER_PHONE_MODEL]}
                fullWidth
                InputProps={{
                  startAdornment: <div />,
                }}
                inputProps={{ maxLength: 60 }}
                helperText={
                  props.meta.error && props.meta.touched ? (
                    <FormHelperText error>{props.meta.error}</FormHelperText>
                  ) : (
                    '* เราจะทำขนาดวอลเปเปอร์ให้เหมาะสมกับอุปกรณ์ของคุณ'
                  )
                }
              />
            </>
          )}
        />
      </FormControl>
      <Box my={2}></Box>
      <FormControl
        fullWidth
        className={hideColorInput ? classes.hiddenField : undefined}
        disabled={disableColorInput}
      >
        <Field
          name="wallpaperColor"
          render={(props) => (
            <>
              <Typography variant="h5">
                {FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_WALLPAPER_COLOR]}
              </Typography>
              <Select {...props.input} displayEmpty variant="outlined" fullWidth>
                <MenuItem value="" disabled>
                  {FORM_TEXT[UIFORM_TEXT_FIELDS.PLACEHOLDER_WALLPAPER_COLOR]}
                </MenuItem>
                {rmsAvailableWallpaperColor?.map((data) => (
                  <MenuItem key={data.value} value={data.value}>
                    {data.display}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        />
      </FormControl>
      <Box my={2}></Box>
      <Box my={2}></Box>
      <Typography variant="h4">{'ข้อมูลเรื่องการเสริมดวง'}</Typography>
      <Box my={2}></Box>
      <FormControl fullWidth>
        <Field
          name="wallpaperRequestType"
          render={(props) => (
            <>
              <Typography variant="h5">{'เรื่องที่ต้องการเสริมดวง'}</Typography>
              <RMSRequestTypeInput {...props.input} />
            </>
          )}
        />
      </FormControl>
      <Box my={1}></Box>
    </>
  );
}
