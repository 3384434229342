import React from 'react';
import { Box, Typography } from 'Components/Base';
import { List } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { LocationProps } from '../utils';
import { CHANT_MODE, getChantModeText } from 'Domain/Sattha/Chant';

const WISH_TEXT = `### วิธีขอพรพระแม่ลักษมีจากบ้าน
**อุปกรณ์ที่ต้องใช้**
* ธูป 9 ดอก (หากไม่มีก็ไม่เป็นไร) 
* บทสวดข้างบน (กดบันทึกเก็บไว้อ่านได้เลย!) 

**ก่อนถึงเวลาที่ร้านค้าจะดำเนินการขอพร**
* จุดธูปให้พร้อม 
* ตั้งจิตอธิษฐานให้มั่น แล้วกล่าวบทสวด, ชื่อ, เรื่องที่อยากขอพร/บนบาน พร้อมทั้งบอกของที่ต้องการนำมาแก้บน (ถ้ามี) หากพรสมปรารถนา เช่น เมื่อข้าพเจ้าสำเร็จแล้วจะมาถวายชุดสมหวัง เป็นต้น`;

const VOW_TEXT = `### วิธีแก้บนพระแม่ลักษมีจากบ้าน
**อุปกรณ์ที่ต้องใช้**
* ธูป 9 ดอก (แนะนำว่าควรมี) 
* บทสวดข้างบน (กดบันทึกเก็บไว้อ่านได้เลย!) 

**ก่อนถึงเวลาที่ร้านค้าจะดำเนินการแก้บน**
* จุดธูปให้พร้อม 
* ตั้งจิตอธิษฐานให้มั่น แล้วกล่าวบทสวด, ชื่อ, เรื่องที่อยากจะแก้บน พร้อมทั้งบอกของที่ต้องการนำมาแก้บน (ถ้ามี) หากพรสมปรารถนา เช่น เมื่อข้าพเจ้าสำเร็จแล้วจะมาถวายชุดสมหวัง เป็นต้น`;

export default function LLM({ mode }: LocationProps) {
  if (mode === CHANT_MODE.WISH) {
    return (
      <Box>
        <ReactMarkdown>{WISH_TEXT}</ReactMarkdown>
      </Box>
    );
  }
  return (
    <Box>
      <ReactMarkdown>{VOW_TEXT}</ReactMarkdown>
    </Box>
  );
}
