import React from 'react';
import { Box, Typography } from 'Components/Base';
import { List } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { LocationProps } from '../utils';
import { CHANT_MODE, getChantModeText } from 'Domain/Sattha/Chant';

const WISH_TEXT = `### วิธีถวายสังฆทานจากบ้าน 

**อุปกรณ์ที่ต้องใช้**
* ที่กรวดน้ำ (หากไม่มีสามารถใช้ภาชนะอื่นๆแทนได้) 
* บทถวายสังฆทานข้างบน (กดบันทึกเก็บไว้อ่านได้เลย!) ก่อนถึงเวลาที่ร้านค้าจะดำเนินการถวายสังฆทาน 
* เตรียมอุปกรณ์ให้พร้อม • เมื่อทีมงานนำสังฆทานถวายยังวัดเรียบร้อย ส่งภาพมายังผู้ถวาย ตั้งจิตอธิษฐานให้มั่น แล้วกล่าวบทถวายสังฆทาน, ชื่อ, เรื่องที่อยากขอพร 
* กดดูคลิปหรือรูปการถวาย ผู้ถวายอธิษฐานจิตกรวดน้ำ อุทิศบุญที่บ้านของคุณ พร้อมกล่าวบทกรวดน้ำดังนี้ 

"อิทัง เม ญาตีนัง โหตุ สุขิตา โหนตุ ญาตะโย ขอบุญนี้ จงสำเร็จแก่ญาติทั้งหลายของข้าพเจ้าเถิด ขอญาติทั้งหลายของข้าพเจ้าจงมีความสุข สุขใจเถิดฯ" 

**ทริคแนะนำ**
* การกรวดน้ำได้สามารถทำได้ 2 แบบ 
  * กรวดเปียก ใช้น้ำกรวดเพื่ออธิษฐานอุทิศบุญ 
  * กรวดแห้ง ใช้การนึกอธิษฐานโดยไม่ใช้น้ำ`;

const VOW_TEXT = ``;

export default function LSR({ mode }: LocationProps) {
  if (mode === CHANT_MODE.WISH) {
    return (
      <Box>
        <ReactMarkdown>{WISH_TEXT}</ReactMarkdown>
      </Box>
    );
  }
  return (
    <Box>
      <ReactMarkdown>{VOW_TEXT}</ReactMarkdown>
    </Box>
  );
}
