import React, { Fragment, useState } from 'react';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  SelectChangeEvent,
  Autocomplete,
  TextField,
} from '@mui/material';
import { Box, Typography } from 'Components/Base';

const OTH_VALUE = 'OTH';

const provinces = [
  {
    label: 'กรุงเทพมหานคร',
    value: 'กรุงเทพมหานคร',
  },
  {
    label: 'สมุทรปราการ',
    value: 'สมุทรปราการ',
  },
  {
    label: 'นนทบุรี',
    value: 'นนทบุรี',
  },
  {
    label: 'ปทุมธานี',
    value: 'ปทุมธานี',
  },
  {
    label: 'พระนครศรีอยุธยา',
    value: 'พระนครศรีอยุธยา',
  },
  {
    label: 'อ่างทอง',
    value: 'อ่างทอง',
  },
  {
    label: 'ลพบุรี',
    value: 'ลพบุรี',
  },
  {
    label: 'สิงห์บุรี',
    value: 'สิงห์บุรี',
  },
  {
    label: 'ชัยนาท',
    value: 'ชัยนาท',
  },
  {
    label: 'สระบุรี',
    value: 'สระบุรี',
  },
  {
    label: 'ชลบุรี',
    value: 'ชลบุรี',
  },
  {
    label: 'ระยอง',
    value: 'ระยอง',
  },
  {
    label: 'จันทบุรี',
    value: 'จันทบุรี',
  },
  {
    label: 'ตราด',
    value: 'ตราด',
  },
  {
    label: 'ฉะเชิงเทรา',
    value: 'ฉะเชิงเทรา',
  },
  {
    label: 'ปราจีนบุรี',
    value: 'ปราจีนบุรี',
  },
  {
    label: 'นครนายก',
    value: 'นครนายก',
  },
  {
    label: 'สระแก้ว',
    value: 'สระแก้ว',
  },
  {
    label: 'นครราชสีมา',
    value: 'นครราชสีมา',
  },
  {
    label: 'บุรีรัมย์',
    value: 'บุรีรัมย์',
  },
  {
    label: 'สุรินทร์',
    value: 'สุรินทร์',
  },
  {
    label: 'ศรีสะเกษ',
    value: 'ศรีสะเกษ',
  },
  {
    label: 'อุบลราชธานี',
    value: 'อุบลราชธานี',
  },
  {
    label: 'ยโสธร',
    value: 'ยโสธร',
  },
  {
    label: 'ชัยภูมิ',
    value: 'ชัยภูมิ',
  },
  {
    label: 'อำนาจเจริญ',
    value: 'อำนาจเจริญ',
  },
  {
    label: 'หนองบัวลำภู',
    value: 'หนองบัวลำภู',
  },
  {
    label: 'ขอนแก่น',
    value: 'ขอนแก่น',
  },
  {
    label: 'อุดรธานี',
    value: 'อุดรธานี',
  },
  {
    label: 'เลย',
    value: 'เลย',
  },
  {
    label: 'หนองคาย',
    value: 'หนองคาย',
  },
  {
    label: 'มหาสารคาม',
    value: 'มหาสารคาม',
  },
  {
    label: 'ร้อยเอ็ด',
    value: 'ร้อยเอ็ด',
  },
  {
    label: 'กาฬสินธุ์',
    value: 'กาฬสินธุ์',
  },
  {
    label: 'สกลนคร',
    value: 'สกลนคร',
  },
  {
    label: 'นครพนม',
    value: 'นครพนม',
  },
  {
    label: 'มุกดาหาร',
    value: 'มุกดาหาร',
  },
  {
    label: 'เชียงใหม่',
    value: 'เชียงใหม่',
  },
  {
    label: 'ลำพูน',
    value: 'ลำพูน',
  },
  {
    label: 'ลำปาง',
    value: 'ลำปาง',
  },
  {
    label: 'อุตรดิตถ์',
    value: 'อุตรดิตถ์',
  },
  {
    label: 'แพร่',
    value: 'แพร่',
  },
  {
    label: 'น่าน',
    value: 'น่าน',
  },
  {
    label: 'พะเยา',
    value: 'พะเยา',
  },
  {
    label: 'เชียงราย',
    value: 'เชียงราย',
  },
  {
    label: 'แม่ฮ่องสอน',
    value: 'แม่ฮ่องสอน',
  },
  {
    label: 'นครสวรรค์',
    value: 'นครสวรรค์',
  },
  {
    label: 'อุทัยธานี',
    value: 'อุทัยธานี',
  },
  {
    label: 'กำแพงเพชร',
    value: 'กำแพงเพชร',
  },
  {
    label: 'ตาก',
    value: 'ตาก',
  },
  {
    label: 'สุโขทัย',
    value: 'สุโขทัย',
  },
  {
    label: 'พิษณุโลก',
    value: 'พิษณุโลก',
  },
  {
    label: 'พิจิตร',
    value: 'พิจิตร',
  },
  {
    label: 'เพชรบูรณ์',
    value: 'เพชรบูรณ์',
  },
  {
    label: 'ราชบุรี',
    value: 'ราชบุรี',
  },
  {
    label: 'กาญจนบุรี',
    value: 'กาญจนบุรี',
  },
  {
    label: 'สุพรรณบุรี',
    value: 'สุพรรณบุรี',
  },
  {
    label: 'นครปฐม',
    value: 'นครปฐม',
  },
  {
    label: 'สมุทรสาคร',
    value: 'สมุทรสาคร',
  },
  {
    label: 'สมุทรสงคราม',
    value: 'สมุทรสงคราม',
  },
  {
    label: 'เพชรบุรี',
    value: 'เพชรบุรี',
  },
  {
    label: 'ประจวบคีรีขันธ์',
    value: 'ประจวบคีรีขันธ์',
  },
  {
    label: 'นครศรีธรรมราช',
    value: 'นครศรีธรรมราช',
  },
  {
    label: 'กระบี่',
    value: 'กระบี่',
  },
  {
    label: 'พังงา',
    value: 'พังงา',
  },
  {
    label: 'ภูเก็ต',
    value: 'ภูเก็ต',
  },
  {
    label: 'สุราษฎร์ธานี',
    value: 'สุราษฎร์ธานี',
  },
  {
    label: 'ระนอง',
    value: 'ระนอง',
  },
  {
    label: 'ชุมพร',
    value: 'ชุมพร',
  },
  {
    label: 'สงขลา',
    value: 'สงขลา',
  },
  {
    label: 'สตูล',
    value: 'สตูล',
  },
  {
    label: 'ตรัง',
    value: 'ตรัง',
  },
  {
    label: 'พัทลุง',
    value: 'พัทลุง',
  },
  {
    label: 'ปัตตานี',
    value: 'ปัตตานี',
  },
  {
    label: 'ยะลา',
    value: 'ยะลา',
  },
  {
    label: 'นราธิวาส',
    value: 'นราธิวาส',
  },
  {
    label: 'บึงกาฬ',
    value: 'บึงกาฬ',
  },
  {
    label: 'อื่นๆ (เกิดต่างประเทศ)',
    value: OTH_VALUE,
  },
];

enum INPUT_MODE {
  THAILAND,
  OTHER_COUNTRY,
}

export default function BirthPlaceInput({
  name,
  label,
  value,
  onBlur,
  onChange,
  placeholder,
  ...restProps
}: {
  name: string;
  label?: string;
  value: any;
  onBlur?: any;
  onChange?: any;
  placeholder?: any;
  [key: string]: any;
}) {
  const [inputMode, setInputMode] = useState(INPUT_MODE.THAILAND);
  const input = {
    value,
    onBlur,
    onChange,
    ...restProps,
  };
  const handleChange = (event, value) => {
    // console.log(event, value);
    if (inputMode === INPUT_MODE.THAILAND) {
      if (value?.value === OTH_VALUE) {
        setInputMode(INPUT_MODE.OTHER_COUNTRY);
      } else {
        onChange(value?.value);
      }
    } else {
      setInputMode(INPUT_MODE.THAILAND);
      onChange(value?.value);
    }
  };
  return (
    <>
      <FormControl fullWidth>
        <Autocomplete
          {...input}
          value={inputMode === INPUT_MODE.OTHER_COUNTRY ? 'อื่นๆ (เกิดต่างประเทศ)' : input.value}
          onChange={handleChange}
          disablePortal
          options={provinces}
          renderInput={(params) => <TextField {...params} placeholder={placeholder} />}
        />
        {inputMode === INPUT_MODE.OTHER_COUNTRY && (
          <>
            <Box my={1}></Box>
            <TextField
              label={''}
              variant="outlined"
              placeholder={'ระบุสถานที่เกิด'}
              fullWidth
              InputProps={{
                startAdornment: <div />,
              }}
              {...input}
              inputProps={{ maxLength: 60 }}
            />
          </>
        )}
      </FormControl>
    </>
  );
}
