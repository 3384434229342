import { Button } from 'Components/Base';
import React from 'react';

import { useAuthContext } from 'Domain/auth/hooks';
import { AUTH_STATE } from 'Domain/auth/types';

export default function LogoutButton() {
  const authContext = useAuthContext();
  const isLogin = authContext.authState === AUTH_STATE.LOGGED_IN;
  return (
    <Button
      disabled={!isLogin}
      onClick={() => {
        authContext?.logout?.();
      }}
    >
      Logout
    </Button>
  );
}
