import axios, { AxiosInstance } from 'axios';

import { getHeaders as getAuthHeader } from '../api';
import getConfig from 'Libs/Config/utils';
const config = getConfig();

const API_URL =
  process.env.REACT_APP_CLIENT_API_URL ||
  'https://asia-southeast2-sattha-online-orders.cloudfunctions.net/feOrders/client';

type ShippingInfoInput = {
  shippingName: string;
  shippingAddress: string;
  shippingPhoneNo: string;
  shippingPostcode: string;
};
interface ClientPostOrderBody {
  chantName: string;
  chantMessage: string;
  shippingInfo?: ShippingInfoInput;
  skuCode?: string;
}

class ClientAPI {
  private instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      baseURL: API_URL,
    });
  }

  async getHeaders() {
    if (config.enableClientAuth) {
      return getAuthHeader();
    } else {
      return {};
    }
  }

  async getOrderFromProvider(providerId: string, providerOrderId: string) {
    const headers = await this.getHeaders();
    const result = await this.instance.get(`/providerOrders/${providerId}/${providerOrderId}`, {
      headers,
    });
    return result.data;
  }

  async getOrder(orderId: string) {
    const headers = await this.getHeaders();
    const result = await this.instance.get(`/orders/${orderId}`, {
      headers,
    });
    return result.data;
  }

  async updateOrder(orderId: string, updateOrderData: ClientPostOrderBody) {
    const headers = await this.getHeaders();
    const result = await this.instance.post(`/orders/${orderId}`, updateOrderData, { headers });
    return result.data;
  }

  async confirmChantOrder(orderId: string) {
    const headers = await this.getHeaders();
    const result = await this.instance.post(`/orders/${orderId}/confirmChant`, null, { headers });
    return result.data;
  }
}

export default ClientAPI;
