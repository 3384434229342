import { Box, Typography } from 'Components/Base';
import React, { Fragment } from 'react';
import ChantNameMultipleInput from './Input/ChantNameMultipleInput';
import TextAreaInput from './Input/TextAreaInput';
import {
  FormGeneratorBlockHeading,
  FormGeneratorInput,
  FormGeneratorInputTypeChantNameMultiple,
  FormGeneratorInputTypeTextArea,
  FORM_GENERATOR_INPUT_TYPE,
} from './types';

export default function FieldsGenerator({ fields = [] }: { fields: FormGeneratorInput[] }) {
  if (!fields || fields.length === 0) return null;

  return (
    <>
      {fields.map((field) => {
        switch (field.type) {
          case FORM_GENERATOR_INPUT_TYPE.HEADING: {
            const data = field as FormGeneratorBlockHeading;
            return (
              <Fragment key={data.title}>
                <Typography variant="h3">{data.title}</Typography>
                <Box mb={3} />
              </Fragment>
            );
          }
          case FORM_GENERATOR_INPUT_TYPE.CHANT_NAME_MULTIPLE: {
            const data = field as FormGeneratorInputTypeChantNameMultiple;
            return (
              <Fragment key={data.name}>
                <ChantNameMultipleInput
                  placeholder={data.placeholder}
                  tips={data.tips}
                  allowAnonymous
                  allowMultiple
                  {...data}
                />
                <Box mb={3} />
              </Fragment>
            );
          }
          case FORM_GENERATOR_INPUT_TYPE.TEXT_AREA: {
            const data = field as FormGeneratorInputTypeTextArea;
            return (
              <Fragment key={data.name}>
                <TextAreaInput {...data} />
                <Box mb={3} />
              </Fragment>
            );
          }
          default:
            return null;
        }
      })}
    </>
  );
}
