import { UIFORM_TEXT_FIELDS } from 'Domain/Sattha/Chant';

export function shippingValidations(values: any, FORM_TEXT) {
  const errors: { [key: string]: string } = {};
  if (!values.shippingName) {
    errors.shippingName = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_SHIPPING_NAME];
  }
  if (!values.shippingAddress) {
    errors.shippingAddress = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_SHIPPING_ADDRESS];
  }
  if (values.shippingPostcode) {
    // const regex = /^\d{5}$/;
    // if (!regex.test(values.shippingPostcode)) {
    //   errors.shippingPostcode = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_SHIPPING_POSTCODE];
    // }
  } else {
    errors.shippingPostcode = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_SHIPPING_POSTCODE];
  }
  if (values.shippingPhoneNo) {
    // const regex =
    //   /^(((\+66|0)(\d{1,2}\-?\d{3}\-?\d{3,4}))|((\+๖๖|๐)([๐-๙]{1,2}\-?[๐-๙]{3}\-?[๐-๙]{3,4})))$/;
    // if (!regex.test(values.shippingPhoneNo)) {
    //   errors.shippingPhoneNo =
    //     FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_SHIPPING_PHONENO_FORMAT];
    // }
  } else {
    errors.shippingPhoneNo = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_SHIPPING_PHONENO];
  }
  return errors;
}
