import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { useAuthContext } from 'Domain/auth/hooks';
import { AUTH_STATE } from 'Domain/auth/types';

export default function Logout() {
  const { logout, isLogin, loading } = useAuthContext();
  let navigate = useNavigate();

  useEffect(() => {
    if (isLogin) {
      logout?.();
    }
    return;
  }, [isLogin, logout]);

  useEffect(() => {
    if (!loading && !isLogin) return navigate('/');
    return () => {
      //
    };
  }, [navigate, loading, isLogin]);

  return null;
}
