import { useState, useEffect } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import { CheckRedemptionCodeResponse, RedeemRedeemCodeResponse } from './types';
import { checkRedemptionCodeQuery, redeemRedeemCodeMutation } from './query';

export function useRedeemCode() {
  return useMutation<RedeemRedeemCodeResponse, { input: { redemptionCode: string } }>(
    redeemRedeemCodeMutation
  );
}

export function useGetRedeemCodeStatus() {
  return useLazyQuery<CheckRedemptionCodeResponse, { input: { redemptionCode: string } }>(
    checkRedemptionCodeQuery
  );
}
