import React from 'react';
import { Box, Typography } from 'Components/Base';
import { List } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { LocationProps } from '../utils';
import { CHANT_MODE, getChantModeText } from 'Domain/Sattha/Chant';

const WISH_TEXT = `### วิธีการถวายผ้าไตรจากบ้าน
**อุปกรณ์ที่ต้องใช้**
* ธูป 3 ดอก (หากไม่มีไม่เป็นไร)
* บทสวดข้างบน (กดบันทึกเก็บไว้อ่านได้เลย!)

**ก่อนถึงเวลาที่ร้านค้าจะดำเนินการทำพิธี**
* จุดธูปให้พร้อม
* ตั้งจิตอธิษฐานให้มั่นต่อรูปสิ่งศักดิ์สิทธ์ที่เราเตรียมไว้ให้ แล้วกล่าวบทสวด, ชื่อของผู้ขอพรให้ชัดเจน 
* ปักธูปลงกระถาง เป็นอันเสร็จพิธี`;

const VOW_TEXT = ``;

export default function LTM04({ mode }: LocationProps) {
  if (mode === CHANT_MODE.WISH) {
    return (
      <Box>
        <ReactMarkdown>{WISH_TEXT}</ReactMarkdown>
      </Box>
    );
  }
  return (
    <Box>
      <ReactMarkdown>{VOW_TEXT}</ReactMarkdown>
    </Box>
  );
}
