import { Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/PendingActionsOutlined';
import VideoLibIcon from '@mui/icons-material/VideoLibrary';

import { Box, Button, Typography } from 'Components/Base';
import MarkdownBox from 'Components/MarkdownBox';

import { ImageBox } from 'Components/Base/Box';
import VideoDownloadBox, {
  VIDEOBOX_MODE,
} from 'Routes/ClientOrder/Components/OrderDetails/Components/VideoDownloadBox';

const useImageListStyles = makeStyles((theme) => ({
  buttonColor: {
    color: theme.palette.error.dark,
  },
}));

export const CompletedBox = ({
  canResendOrder,
  onResendOrder,
}: {
  canResendOrder?: boolean;
  onResendOrder?: () => void;
}) => (
  <Paper elevation={3} style={{ padding: 16 }}>
    <Box display="flex" flexDirection="column" alignItems="center">
      <CheckCircleIcon style={{ color: '#00B900' }} fontSize={'large'} />
      <Typography variant="title">ส่งงานเรียบร้อย!</Typography>
      <Typography variant="subtitle">ทางเราจะตรวจสอบข้อมูลหลักฐานการอัพโหลด</Typography>
      <Box my={2} />
      <Typography>คุณสามารถปิดหน้านี้และส่งงานออเดอร์อื่นได้เลย</Typography>
      <Box my={1} />
      {canResendOrder && (
        <Button variant="contained" size="large" stType="gradient" onClick={onResendOrder}>
          แก้ไขการส่งงาน
        </Button>
      )}
      <Box my={1} />
      <Typography>
        หากคุณส่งหลักฐานผิด สามารถแจ้ง Group ร้านค้า หรือ LINE{' '}
        <a href="https://line.me/R/ti/p/@sattha.online">@sattha.online</a>{' '}
        พร้อมแจ้งเลขที่ออเดอร์​ที่ต้องการแก้ไข
      </Typography>
    </Box>
  </Paper>
);

type UploadedFileData = {
  url: string;
  name: string;
  contents?: string;
};

interface AttachmentListProps {
  uploadedFiles?: UploadedFileData[];
}

export const ImageList = ({ uploadedFiles = [] }: AttachmentListProps) => {
  const classes = useImageListStyles();
  return (
    <Box display="flex" flexDirection="column" alignItems="center" style={{ width: '100%' }}>
      {uploadedFiles.map((uploadedFile, i) => {
        return (
          <Box key={i} mb={3}>
            <ImageBox
              src={uploadedFile.url}
              alt="uploaded-slip"
              style={{ height: 'auto', width: '100%' }}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export const VideoList = ({ uploadedFiles = [] }: AttachmentListProps) => {
  const classes = useImageListStyles();
  return (
    <Box display="flex" mt={1} flexDirection="column" alignItems="center" style={{ width: '100%' }}>
      {uploadedFiles.map((uploadedFile, i) => {
        return (
          <Box key={i} mb={3} display="flex" sx={{ width: '100%' }}>
            <VideoDownloadBox
              videoUrl={uploadedFile.url}
              name={uploadedFile.name}
              packageDate={''}
              mode={VIDEOBOX_MODE.STORE}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export const TextList = ({ uploadedFiles = [] }: AttachmentListProps) => {
  const classes = useImageListStyles();
  return (
    <Box display="flex" flexDirection="column" alignItems="center" style={{ width: '100%' }}>
      {uploadedFiles.map((uploadedFile, i) => {
        return (
          <Box key={i} mb={3}>
            <Typography variant="subtitle">ข้อความในออเดอร์</Typography>
            {uploadedFile.contents && <MarkdownBox>{uploadedFile.contents}</MarkdownBox>}
          </Box>
        );
      })}
    </Box>
  );
};
