import React from 'react';
import { Form, Field, useForm } from 'react-final-form';
import { Paper, FormControl, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VideoLibIcon from '@mui/icons-material/VideoLibrary';

import { Box, Button, Typography } from 'Components/Base';
import MarkdownBox from 'Components/MarkdownBox';

import { ImageBox } from 'Components/Base/Box';
import { ATTACHMENT_TYPE } from 'Libs/Firebase/firestore/Order';
import { StoreUploadOrderSuccessProps } from './types';
import { ImageList, VideoList, TextList } from './Components';

export default function OrderDetailsGeneral(props: StoreUploadOrderSuccessProps) {
  const disableEditing = true;
  const form = useForm();
  const attachmentList = props.orderData.attachments
    ? props.orderData.attachments['store-order-upload']?.attachmentList
    : undefined;

  return (
    <>
      <FormControl fullWidth disabled={disableEditing}>
        <Field
          name="orderId"
          render={(props) => (
            <TextField
              {...props.input}
              label="Order NO."
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: <div />,
              }}
              inputProps={{
                disabled: disableEditing,
              }}
            />
          )}
        />
      </FormControl>
      <Box my={3}></Box>
      <FormControl fullWidth disabled={disableEditing}>
        <Field
          name="chantName"
          render={(props) => (
            <TextField
              {...props.input}
              label="ชื่อลูกค้า"
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: <div />,
              }}
              inputProps={{
                disabled: disableEditing,
              }}
            />
          )}
        />
      </FormControl>
      <Box my={3}></Box>
      <FormControl fullWidth disabled={disableEditing}>
        <Field
          name="skuName"
          render={(props) => (
            <TextField
              {...props.input}
              label="แพคเกจ"
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: <div />,
              }}
              inputProps={{
                disabled: disableEditing,
              }}
            />
          )}
        />
      </FormControl>
      <Box my={3}></Box>
      {attachmentList && (
        <>
          <ImageList
            uploadedFiles={attachmentList.filter(
              (attachment) => attachment.type === ATTACHMENT_TYPE.IMAGE
            )}
          />
          <VideoList
            uploadedFiles={attachmentList.filter(
              (attachment) => attachment.type === ATTACHMENT_TYPE.VIDEO
            )}
          />
          <TextList
            uploadedFiles={attachmentList.filter(
              (attachment) => attachment.type === ATTACHMENT_TYPE.TEXT
            )}
          />
        </>
      )}
    </>
  );
}
