import React, { useMemo, useState } from 'react';
import Calendar, { CalendarEvent, CALENDAR_EVENT_STATUS } from './Calendar';
import { Helmet } from 'react-helmet';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Divider,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Select,
} from '@mui/material';
import { Link } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Box, Typography } from 'Components/Base';
import { useGetFTLiveBookingList } from 'Domain/Stores/hooks';
import { FTLiveBookingListItem } from 'Domain/Stores/types';
import Loading from 'Components/Loading';
import NavigationHeader from 'Components/Layout/NavigationHeader';

const getUITitle = () => 'ตารางดูดวง';

const transformDataToEvent = (data: FTLiveBookingListItem[]): CalendarEvent[] => {
  return data.map((item) => ({
    id: item.id,
    title: item.orderId ?? '',
    start: item.startTime,
    end: item.endTime,
    resource: {
      id: item.agent?.id,
      title: item.agent?.name,
    },
    status:
      item.status === 'BOOKED' ? CALENDAR_EVENT_STATUS.BOOKED : CALENDAR_EVENT_STATUS.AVAILABLE,
    url: item.orderId ? `/store/${item.storeId}/order/${item.orderId}` : undefined,
  }));
};

interface AgentData {
  id: string;
  name: string;
}

const consolidateAgents = (data: FTLiveBookingListItem[]): AgentData[] => {
  const agents = new Map<string, AgentData>();
  data.forEach((d) => {
    const agent = d.agent;
    agents.set(agent.id, agent);
  });
  return Array.from(agents.values()).sort((a, b) => a.name.localeCompare(b.name));
};

interface BookingSearchConditionProps {
  agentList: AgentData[];
  onAgentSelected: (agentId: string) => void;
  onStatusSelected: (status: BookingStatus) => void;
}

enum BookingStatus {
  ALL,
  BOOKED,
  AVAILABLE,
}

function BookingSearchConditionBox({
  agentList,
  onAgentSelected,
  onStatusSelected,
}: BookingSearchConditionProps) {
  const [selectedAgent, setSelectedAgent] = useState<string>('all');
  const [selectedStatus, setSelectedStatus] = useState(BookingStatus.BOOKED);
  const handleAgentChange = (event) => {
    setSelectedAgent(event.target.value);
    onAgentSelected(event.target.value);
  };
  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
    onStatusSelected(event.target.value);
  };
  return (
    <Box>
      <Box>
        <FormControl sx={{ m: 1 }}>
          <InputLabel id="select-agent-label">นักพยากรณ์</InputLabel>
          <Select
            labelId="select-agent-label"
            id="select-agent"
            value={selectedAgent}
            label="นักพยากรณ์"
            onChange={handleAgentChange}
          >
            <MenuItem value={'all'}>{`--- ทุกคน ---`}</MenuItem>
            {agentList.map((agent) => (
              <MenuItem key={agent.id} value={agent.id}>
                {`${agent.name} (${agent.id})`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1 }}>
          <InputLabel id="select-status-label">สถานะ</InputLabel>
          <Select
            labelId="select-status-label"
            id="select-status"
            value={selectedStatus}
            label="สถานะ"
            onChange={handleStatusChange}
          >
            <MenuItem value={BookingStatus.ALL}>{`--- ทุกสถานะ ---`}</MenuItem>
            <MenuItem value={BookingStatus.AVAILABLE}>{`ยังไม่มีการจอง`}</MenuItem>
            <MenuItem value={BookingStatus.BOOKED}>{`จองแล้ว`}</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
}

const filterBy = (data: FTLiveBookingListItem[], agentId: string, status: BookingStatus) => {
  return data.filter((d) => {
    let condition = true;
    if (agentId !== 'all') condition = condition && agentId === d.agent?.id;
    if (status !== BookingStatus.ALL) {
      switch (status) {
        case BookingStatus.BOOKED:
          condition = condition && ['BOOKED'].includes(d.status);
          break;
        case BookingStatus.AVAILABLE:
          condition = condition && !['BOOKED'].includes(d.status);
          break;
        default:
          break;
      }
    }
    return condition;
  });
};

function AgentsLinkHeader({ agentList, storeId }: { agentList: AgentData[]; storeId: string }) {
  return (
    <>
      <Box>
        <List
          dense={true}
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: 0,
            flexWrap: 'wrap',
          }}
        >
          {agentList.map((agentData) => (
            <ListItem key={agentData.id} style={{ maxWidth: 200 }}>
              <ListItemText
                primary={
                  <Box display="flex" flexDirection="column">
                    <Typography>{`${agentData.name}`}</Typography>
                  </Box>
                }
                secondary={
                  <Link
                    to={`/store/${storeId}/fortune-telling/live/${agentData.id}`}
                    target="_blank"
                  >
                    Link
                  </Link>
                }
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </>
  );
}

export default function StoreFortuneTellingLiveOrdersContainer({
  storeId,
  agentId,
}: {
  storeId: string;
  agentId?: string;
}) {
  const { loading, error, data, refetch } = useGetFTLiveBookingList(storeId, agentId);
  const [selectedAgent, setSelectedAgent] = useState<string>('all');
  const [selectedStatus, setSelectedStatus] = useState(BookingStatus.BOOKED);
  const handleAgentChange = (agentId) => {
    setSelectedAgent(agentId);
  };
  const handleStatusChange = (status) => {
    setSelectedStatus(status);
  };
  const { events, agents } = useMemo(
    () => ({
      events: transformDataToEvent(filterBy(data ?? [], selectedAgent, selectedStatus)),
      agents: consolidateAgents(data ?? []),
    }),
    [data, selectedAgent, selectedStatus]
  );
  if (loading)
    return (
      <Container>
        <Loading />
      </Container>
    );

  return (
    <>
      <Helmet>
        <title>{getUITitle()}</title>
      </Helmet>
      <Container>
        <NavigationHeader
          title={getUITitle()}
          breadCrumb={[
            {
              href: undefined,
              title: 'ร้านค้า',
            },
          ]}
        />
        <Box my={2}>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5" color="secondary">
                ตัวกรอง
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <BookingSearchConditionBox
                agentList={agents}
                onAgentSelected={handleAgentChange}
                onStatusSelected={handleStatusChange}
              />
            </AccordionDetails>
          </Accordion>
          {!agentId && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5" color="secondary">
                  ลิงค์แยกตามนักพยากรณ์
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <AgentsLinkHeader agentList={agents} storeId={storeId} />
              </AccordionDetails>
            </Accordion>
          )}
        </Box>
        <Divider />
        <Box my={2} />
        <Paper elevation={2}>
          <Box p={1} pt={2}>
            <Calendar events={events} />
          </Box>
        </Paper>
      </Container>
    </>
  );
}
