import React from 'react';

import LogoutButton from 'Containers/LogoutButton';

export default function StoreRoute() {
  return (
    <div>
      Store Page
      <LogoutButton></LogoutButton>
    </div>
  );
}
