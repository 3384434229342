import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from 'Components/Base';
import { CheckBoxGroup2 } from './CheckBoxGroup';
import { FormControl, FormHelperText, MenuItem, Select, TextField } from '@mui/material';
import { Field } from 'react-final-form';
import { mankarinDesignNeeds, mankarinDesignOptions } from 'Domain/Sku/FortuneTelling';

export const INPUT_MODE = 'MANKARIN';

export const validations = (values) => {
  const errors: { [field: string]: any } = {};
  if (!values.occupation) errors.occupation = 'โปรดระบุอาชีพของคุณ';
  if (!values.additionalFtInfo || !values.additionalFtInfo.design) {
    errors[`additionalFtInfo.design[0].topic`] = 'โปรดเลือกการออกแบบ';
  } else {
    if (values.additionalFtInfo.design.length < 2) {
      errors[`additionalFtInfo.design[0].topic`] = 'โปรดเลือกการออกแบบให้ครบทุกเรื่อง';
    }
    values.additionalFtInfo.design.forEach((design, index) => {
      if (!design.topic) {
        errors[`additionalFtInfo.design[${index}].topic`] = `โปรดเลือกการออกแบบเรื่องที่ ${
          index + 1
        }`;
      } else {
        if (
          design.topic === 'HOME_NO' &&
          (!design.comment || design.comment?.trim().length === 0)
        ) {
          errors[
            `additionalFtInfo.design[${index}].others`
          ] = `โปรดระบุบ้านเลขที่ที่คุณกำลังดูอยู่`;
        }
      }

      if (!design.enhancements || design.enhancements.length < 1) {
        errors[
          `additionalFtInfo.design[${index}].enhancements`
        ] = `โปรดเลือกด้านที่ต้องการเสริมของการออกแบบเรื่องที่ ${index + 1}`;
      } else {
        if (
          design.enhancements.includes('NEED_OTHERS') &&
          (!design.comment || design.comment?.trim().length === 0)
        ) {
          errors[
            `additionalFtInfo.design[${index}].others`
          ] = `โปรดระบุด้านที่ต้องการเสริมของการออกแบบเรื่องที่ ${index + 1}`;
        }
      }
    });
  }
  console.log('mkvalidations', errors);
  return errors;
};

function DesignSection({ index }: { index: number }) {
  return (
    <>
      <Typography variant="h4">{`เรื่องที่ต้องการออกแบบ หัวข้อที่ ${index + 1}`}</Typography>
      <Box my={2}></Box>
      <FormControl fullWidth>
        <Field
          name={`additionalFtInfo.design[${index}].topic`}
          render={(props) => (
            <>
              <Typography variant="h5">{'เรื่องที่ต้องการออกแบบ'}</Typography>
              <Select {...props.input} displayEmpty variant="outlined" fullWidth>
                <MenuItem value="" disabled>
                  {'เลือกการออกแบบ'}
                </MenuItem>
                {mankarinDesignOptions.map((data) => (
                  <MenuItem key={data.value} value={data.value} style={{ whiteSpace: 'normal' }}>
                    {data.display}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        />
      </FormControl>
      <Box my={2}></Box>
      <Typography variant="h5">{'ด้านที่ต้องการเสริม (เลือกได้สูงสุด 4 เรื่อง)'}</Typography>
      <Box ml={1}>
        <FormControl>
          <CheckBoxGroup2
            name={`additionalFtInfo.design[${index}].enhancements`}
            options={mankarinDesignNeeds}
            maxLimit={4}
          />
        </FormControl>
      </Box>
      <Box my={2}></Box>
      <Typography variant="h5">{'รายละเอียดเพิ่มเติม'}</Typography>
      <Typography variant="info">
        * หากเลือก “เลขที่บ้าน” กรุณาระบุเลขที่บ้านที่ดูไว้ให้ชัดเจน
      </Typography>
      <Typography variant="info">* หากเลือก “อื่นๆ” กรุณาระบุว่าด้านใด</Typography>
      <Box mt={1}></Box>
      <FormControl fullWidth>
        <Field
          name={`additionalFtInfo.design[${index}].comment`}
          render={(props) => {
            return (
              <TextField
                {...props.input}
                label={''}
                variant="outlined"
                fullWidth
                multiline
                placeholder={`กรอกข้อมูลเพิ่มเติม`}
                InputProps={{
                  startAdornment: <div />,
                }}
                inputProps={{ maxLength: 5000 }}
                helperText={
                  props.meta.error &&
                  props.meta.touched && <FormHelperText error>{props.meta.error}</FormHelperText>
                }
              />
            );
          }}
        />
      </FormControl>
    </>
  );
}

function FTMankarinBox(props) {
  return (
    <>
      <Typography variant="h3">การออกแบบตัวเลขและความเฮง</Typography>
      <Box my={2}></Box>
      <FormControl fullWidth required>
        <Field
          name="occupation"
          render={(props) => (
            <>
              <Typography variant="h5">{'อาชีพ'}</Typography>
              <TextField
                {...props.input}
                label=""
                variant="outlined"
                placeholder="ระบุอาชีพ"
                fullWidth
                InputProps={{
                  startAdornment: <div />,
                }}
                inputProps={{ maxLength: 60 }}
                helperText={
                  props.meta.error && props.meta.touched ? (
                    <FormHelperText error>{props.meta.error}</FormHelperText>
                  ) : null
                }
              />
            </>
          )}
        />
      </FormControl>
      <Box my={4}></Box>
      <DesignSection index={0} />
      <Box my={4}></Box>
      <DesignSection index={1} />
    </>
  );
}

export default FTMankarinBox;
