import { ORDER_STATUS, ORDER_SUMMARY_STATUS } from 'Libs/Firebase/firestore/Order';

const STATE_TEXT = {
  [ORDER_STATUS.CREATED]: 'สร้าง',
  [ORDER_STATUS.PENDING]: 'รอการชำระเงิน',
  [ORDER_STATUS.PAYMENT_UPLOADED]: 'รอการยืนยันการชำระเงิน',
  [ORDER_STATUS.PAID]: 'จ่ายเงินแล้ว',
  [ORDER_STATUS.CLIENT_FILLED_INFO]: 'กรอกข้อมูลแล้ว',
  [ORDER_STATUS.CANCELLED]: 'ยกเลิก',
  [ORDER_STATUS.MERCHANT_ACCEPT]: 'ร้านค้ารับออเดอร์',
  [ORDER_STATUS.MERCHANT_PREPARING]: 'ร้านค้าเตรียมของ',
  [ORDER_STATUS.MERCHANT_DELIVERING]: 'ร้านค้ากำลังดำเนินการ',
  [ORDER_STATUS.MERCHANT_DELIVERED]: 'ร้านค้าส่งหลักฐานแล้ว',
  [ORDER_STATUS.CLIENT_ACCEPT]: 'ตรวจสอบหลักฐานแล้ว',
  [ORDER_STATUS.MESSAGE_FAIL_DELIVERING]: 'ส่งข้อความไม่สำเร็จ',
  [ORDER_STATUS.COMPLETED]: 'สำเร็จ',
};

const STORE_STATE_TEXT = {
  [ORDER_STATUS.CREATED]: 'ยังไม่พร้อม',
  [ORDER_STATUS.PENDING]: 'ยังไม่พร้อม',
  [ORDER_STATUS.PAYMENT_UPLOADED]: 'ยังไม่พร้อม',
  [ORDER_STATUS.PAID]: 'ยังไม่พร้อม',
  [ORDER_STATUS.CLIENT_FILLED_INFO]: 'ข้อมูลครบแล้ว',
  [ORDER_STATUS.CANCELLED]: 'ยกเลิก',
  [ORDER_STATUS.MERCHANT_ACCEPT]: 'รอหลักฐาน',
  [ORDER_STATUS.MERCHANT_PREPARING]: 'รอหลักฐาน',
  [ORDER_STATUS.MERCHANT_DELIVERING]: 'รอหลักฐาน',
  [ORDER_STATUS.MERCHANT_DELIVERED]: 'ส่งหลักฐานแล้ว',
  [ORDER_STATUS.CLIENT_ACCEPT]: 'ตรวจสอบหลักฐานแล้ว',
  [ORDER_STATUS.MESSAGE_FAIL_DELIVERING]: 'ตรวจสอบหลักฐานแล้ว',
  [ORDER_STATUS.COMPLETED]: 'สำเร็จ',
};

const ORDER_SUMMARY_STATUS_TEXT = {
  [ORDER_SUMMARY_STATUS.NEW]: 'กำลังสร้าง',
  [ORDER_SUMMARY_STATUS.ORDER_READY]: 'พร้อมดำเนินการ',
  [ORDER_SUMMARY_STATUS.MERCHANT_DELIVERING]: 'กำลังดำเนินการ',
  [ORDER_SUMMARY_STATUS.MERCHANT_DELIVERED]: 'ส่งงานครบ',
  [ORDER_SUMMARY_STATUS.COMPLETED]: 'สำเร็จ',
  [ORDER_SUMMARY_STATUS.SENT_TO_STORE]: 'ส่งให้ร้านค้า (ยังไม่มีสถานะนี้ในระบบ)',
};

export function getStatusText(status: ORDER_STATUS) {
  return STATE_TEXT[status] || status;
}

export function getStoreOrderStatusText(status: ORDER_STATUS) {
  return STORE_STATE_TEXT[status] || status;
}

export function getOrderSummaryStatusText(status: ORDER_SUMMARY_STATUS) {
  return ORDER_SUMMARY_STATUS_TEXT[status] || status;
}
