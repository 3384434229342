import { ORDER_TYPE, SatthaOrder, SKU_CHANT_MODE } from 'Libs/Firebase/firestore/Order';
import React from 'react';
import StoreFortuneTellingOrder from './StoreFortuneTellingOrder';
import StoreFulfillmentOrder from './StoreFulfillmentOrder';
import StoreShipmentOrder from './StoreShipmentOrder';
import { StoreUploadOrderProps } from './types';

export enum ORDER_UPLOAD_FORM_MODE {
  FULLFILLMENT,
  FORTUNE_TELLING,
  SHIPMENT,
  UNKNOWN,
}

export const getOrderUploadFormMode = (orderData: SatthaOrder) => {
  if (orderData.type === ORDER_TYPE.FULFILLMENT) {
    if (
      [SKU_CHANT_MODE.FORTUNE_TELLING, SKU_CHANT_MODE.FORTUNE_TELLING_LIVE].includes(
        orderData.skuMode
      )
    ) {
      return ORDER_UPLOAD_FORM_MODE.FORTUNE_TELLING;
    }
    return ORDER_UPLOAD_FORM_MODE.FULLFILLMENT;
  }
  if (orderData.type === ORDER_TYPE.SHIPMENT) return ORDER_UPLOAD_FORM_MODE.SHIPMENT;
  return ORDER_UPLOAD_FORM_MODE.UNKNOWN;
};

export default function StoreUploadOrder(props: StoreUploadOrderProps) {
  const { orderData } = props;
  const formMode = getOrderUploadFormMode(orderData);
  switch (formMode) {
    case ORDER_UPLOAD_FORM_MODE.FULLFILLMENT:
      return <StoreFulfillmentOrder {...props} />;

    case ORDER_UPLOAD_FORM_MODE.FORTUNE_TELLING:
      return <StoreFortuneTellingOrder {...props} />;

    case ORDER_UPLOAD_FORM_MODE.SHIPMENT:
      return <StoreShipmentOrder {...props} />;

    default:
      return <div>StoreOrder</div>;
  }
}
