import React from 'react';
import { Box, Typography } from 'Components/Base';
import { List } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { LocationProps } from '../utils';
import { CHANT_MODE, getChantModeText } from 'Domain/Sattha/Chant';

const WISH_TEXT = `### วิธีขอพรกับพระตรีมูรติจากบ้าน
**อุปกรณ์ที่ต้องใช้**
* บทสวดข้างบน (กดบันทึกเก็บไว้อ่านได้เลย!)

**ก่อนถึงเวลาที่ร้านค้าจะดำเนินการขอพร/แก้บน**
* ตั้งจิตอธิษฐานให้มั่นต่อรูปพระตรีมูรติที่เราเตรียมไว้ให้ แล้วกล่าวบทสวด, ชื่อสกุลของผู้ขอพรให้ชัดเจน เป็นอันจบพิธี`;

const VOW_TEXT = `### วิธีแก้บนกับพระตรีมูรติจากบ้าน
**อุปกรณ์ที่ต้องใช้**
* บทสวดข้างบน (กดบันทึกเก็บไว้อ่านได้เลย!)

**ก่อนถึงเวลาที่ร้านค้าจะดำเนินการแก้บน**
* ตั้งจิตอธิษฐานให้มั่นต่อรูปพระตรีมูรติที่เราเตรียมไว้ให้ แล้วกล่าวบทสวด, ชื่อสกุลของผู้แก้บนให้ชัดเจน เป็นอันจบพิธี`;

export default function LTT({ mode }: LocationProps) {
  if (mode === CHANT_MODE.WISH) {
    return (
      <Box>
        <ReactMarkdown>{WISH_TEXT}</ReactMarkdown>
      </Box>
    );
  }
  return (
    <Box>
      <ReactMarkdown>{VOW_TEXT}</ReactMarkdown>
    </Box>
  );
}
