import React from 'react';
// import GetAppIcon from '@mui/icons-material/GetApp';
import { saveAs } from 'file-saver';

import { Box, Button } from 'Components/Base';
import SvgIcon from '@mui/material/SvgIcon';

export enum IMAGE_BOX_MODE {
  CLIENT = 'CLIENT',
  BACKOFFICE = 'BACKOFFICE',
}

type ImageBoxProps = {
  imageUrl: string;
  downloadUrl?: string;
  alt?: string;
  hideDownloadButton?: boolean;
  mode?: IMAGE_BOX_MODE;
};

export const DownloadIcon = (props) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15"
        stroke="#585757"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 9.99999L12 15L17 9.99999"
        stroke="#585757"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12 15V3" stroke="#585757" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  </SvgIcon>
);

export default function ImageBox({
  imageUrl,
  downloadUrl = imageUrl,
  alt = 'image',
  hideDownloadButton = false,
  mode = IMAGE_BOX_MODE.CLIENT,
}: ImageBoxProps) {
  let imageStyle: React.CSSProperties = { width: '100%', height: 'auto' };
  if (mode === IMAGE_BOX_MODE.BACKOFFICE) {
    imageStyle = {
      width: 'auto',
      maxHeight: 300,
    };
  }

  return (
    <Box>
      <img src={imageUrl} style={imageStyle} alt={alt} />
      {!hideDownloadButton && (
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <Button
            startIcon={<DownloadIcon />}
            onClick={() => {
              saveAs(downloadUrl);
            }}
          >
            บันทึกรูปนี้
          </Button>
        </Box>
      )}
    </Box>
  );
}
