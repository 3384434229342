import React from 'react';
import { Form, Field } from 'react-final-form';
import { Paper, FormHelperText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { Box, Button, Typography } from 'Components/Base';
import { CHANT_MODE, getUserInfoForm, UIFORM_TEXT_FIELDS } from 'Domain/Sattha/Chant';
import { AvailableSkuItem, OrderShippingData, SkuWishVowData } from 'Libs/Firebase/firestore/Order';
import FixBadluckForm, { availableMonths } from '../Components/FixBadLuckForm';
import { getTimeInput } from 'Domain/Sku/FixBadLuck';
import { FIX_BADLUCK_MODE, getFixBadLuckMode } from 'Domain/Orders/utils';
import SelectPackageForm from '../Components/SelectPackageForm';
import ChantForm from '../Components/ChantForm';
import ShippingForm from '../Components/ShippingForm';
import HorowallWallpaperForm from '../Components/HorowallWallpaperForm';
import { HOROWALL_REQUEST_TYPE } from 'Domain/Sku/Wallpaper';
import { HiddenFieldForm, HiddenSkuCodeFieldForm } from '../Components/HiddenFieldForm';
import AcceptPDPAForm, { validatePDPA } from '../Components/AcceptPDPAForm';
import ErrorBox from '../Components/ErrorBox';
import ConfirmationButton from '../Components/ConfirmationButton';
import { validateAge, validateDate } from '../utils/utils';
import {
  convertFromStorageObject,
  convertToStorageObject,
} from 'Containers/ImageUploadBox/ImageUploadBox';
import { StorageObject } from 'Domain/Orders/types';

const useStyles = makeStyles((theme) => ({
  hiddenField: {
    display: 'none',
  },
  gradientButton: {
    background: `linear-gradient(314.47deg, #01C1A8 0%, #1DCE59 90.84%)`,
    borderRadius: 2,
    color: 'white',
    width: '100%',
    '&:disabled': {
      color: `#969696`,
      background: '#E6E6E6',
    },
  },
}));

export interface UserInfoFormData {
  orderId: string;
  mode: CHANT_MODE;
  chantName: string;
  chantMessage: string;
  shippingName?: string;
  shippingAddress?: string;
  shippingPhoneNo?: string;
  shippingPostcode?: string;
  skuCode?: string;

  birthday?: {
    day: number;
    month: number;
    year: number;
  };
  birthTime?: {
    hour: number;
    minute: number;
  };

  facePicture?: string;

  acceptTerms: boolean;
  acceptPrivacy: boolean;
}

export interface UserInfoFormDataChant {
  orderId: string;
  mode: CHANT_MODE;
  chantName: string;
  chantMessage: string;
  shippingName?: string;
  shippingAddress?: string;
  shippingPhoneNo?: string;
  shippingPostcode?: string;
  skuCode?: string;

  birthday?: {
    day: number;
    month: number;
    year: number;
  };
  birthTime?: {
    hour: number;
    minute: number;
  };
  facePicture?: StorageObject;

  acceptTerms: boolean;
  acceptPrivacy: boolean;
}

export interface UserInfoFormInitialData {
  chantName?: string;
  chantMessage?: string;
  // TODO: Add more initial data
  birthday?: { day: number; month: number; year: number };
  birthTime?: { hour: number; minute: number };
  faceImage?: StorageObject;
}

interface CreateOrderFormProps {
  onSubmit?: (formValues: UserInfoFormDataChant) => void;
  orderId: string;
  orderRealId: string;
  locationInstructions?: string;
  locationId: string;
  isLoading: boolean;
  mode: CHANT_MODE;
  initialData?: UserInfoFormInitialData;
  haveShipping?: boolean;
  shippingData?: OrderShippingData;
  showPackageSelection: boolean;
  enablePackageSelection: boolean;
  skuMasterCode?: string;
  availableSkus?: AvailableSkuItem[];
  skuData?: SkuWishVowData;
  skuCode?: string;
}

enum ORDER_MODE {
  FULFILLMENT = 'FULFILLMENT',
  SHIPPING = 'SHIPPING',
}

export default function UserInfoWishVowForm(props: CreateOrderFormProps) {
  const classes = useStyles();

  const {
    orderId,
    orderRealId,
    onSubmit = () => {
      /** */
    },
    locationId,
    isLoading,
    mode,
    initialData,
    haveShipping,
    shippingData,
    showPackageSelection,
    enablePackageSelection,
    skuMasterCode,
    availableSkus,
    skuData,
    skuCode,
  } = props;

  const FORM_TEXT = getUserInfoForm(mode);
  const { chantName, chantMessage, birthday, faceImage } = initialData ?? {};

  const orderMode = haveShipping ? ORDER_MODE.SHIPPING : ORDER_MODE.FULFILLMENT;
  let newInitialData = { orderId, mode, chantMessage, chantName, birthday };
  if (faceImage) {
    newInitialData['facePicture'] = JSON.stringify([convertFromStorageObject(faceImage)]);
  }
  if (shippingData) {
    newInitialData = { ...newInitialData, ...shippingData };
  }
  if (showPackageSelection && skuMasterCode && availableSkus && availableSkus?.length > 0) {
  } else if (skuCode) {
    const newData = { skuCode };
    newInitialData = { ...newInitialData, ...newData };
  }

  const handleSubmit = (values: UserInfoFormData, ...restArgs) => {
    // console.log('handleSubmit', values);
    const { facePicture, ...restValue } = values;

    const newValues: UserInfoFormDataChant = { ...restValue };

    if (facePicture) {
      const facePictureParsed = JSON.parse(facePicture as unknown as string);
      if (facePictureParsed.length > 0) {
        newValues.facePicture = convertToStorageObject(facePictureParsed[0]);
      }
    }

    onSubmit(newValues);
  };

  const showBirthdayInput = skuData?.requiredBirthday === true;
  const showFacePictureInput = skuData?.requiredFacePicture === true;
  const showPackageSelectionUI = showPackageSelection && skuMasterCode;

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={newInitialData}
        validate={(values) => {
          const errors: { [k: string]: any } = {};
          console.log(values);
          if (!values.skuCode) {
            errors.skuCode = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_SELECT_PACAKGE];
          }
          if (!values.chantName) {
            errors.chantName = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_NAME_REQUIRED];
          }

          // Checking by mode
          if ([CHANT_MODE.VOW, CHANT_MODE.WISH].includes(mode)) {
            if (!values.chantMessage) {
              errors.chantMessage = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_CHANT_REQUIRED];
            }
            if (values.chantMessage) {
              const numberOfLines = values.chantMessage.split('\n').length;
              if (numberOfLines > 3) {
                errors.chantMessage = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_CHANT_MESSAGE_MAX_LINES];
              }
            }
            if (showBirthdayInput) {
              const { day, month, year } = values.birthday ?? {};
              if (!day)
                errors['birthday.day'] = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHDAY_REQUIRED];
              if (!month)
                errors['birthday.month'] = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHMONTH_REQUIRED];
              if (!year)
                errors['birthday.year'] = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHYEAR_REQUIRED];
              if (day && month && year) {
                const isValidBirthDay = validateAge({
                  day,
                  month,
                  year,
                });
                if (!isValidBirthDay) {
                  errors['birthday.year'] =
                    'ระบบไม่สามารถให้บริการบุคคลที่อายุต่ำกว่า 13 ปีได้ กรุณาติดต่อ hello@sattha.online เพื่อทำการคืนเงิน';
                }
                const isValidDate = validateDate({
                  day,
                  month,
                  year,
                });
                if (!isValidDate) {
                  errors['birthday.day'] = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHDATE_NOT_VALID];
                }
              }
              if (!values.birthTime) {
                // pass validation
                // errors.birthTime = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_REQUIRED];
              } else {
                try {
                  const { hour, minute, notKnow } = getTimeInput(values.birthTime);
                  if (notKnow) {
                    errors.birthTime = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_NOT_IN_FORMAT];
                  }
                } catch (error) {
                  if (error.message === 'Not Valid Format') {
                    errors.birthTime = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_NOT_IN_FORMAT];
                  } else if (error.message === 'Time not in range') {
                    errors.birthTime =
                      FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_NOT_IN_TIME_FORMAT];
                  } else {
                    errors.birthTime = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_NOT_IN_FORMAT];
                  }
                }
              }
            }

            if (showFacePictureInput) {
              if (!values.facePicture) {
                errors.facePicture = 'กรุณาอัพโหลดรูปหน้าของคุณ';
              } else {
                const parsedValues = JSON.parse(values.facePicture);
                if (!parsedValues || parsedValues.length === 0) {
                  errors.facePicture = 'กรุณาอัพโหลดรูปหน้าของคุณ';
                }
              }
            }
          }

          // If order has shipping bundle
          if (orderMode === ORDER_MODE.SHIPPING) {
            if (!values.shippingName) {
              errors.shippingName = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_SHIPPING_NAME];
            }
            if (!values.shippingAddress) {
              errors.shippingAddress = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_SHIPPING_ADDRESS];
            }
            if (values.shippingPostcode) {
              // const regex = /^\d{5}$/;
              // if (!regex.test(values.shippingPostcode)) {
              //   errors.shippingPostcode = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_SHIPPING_POSTCODE];
              // }
            } else {
              errors.shippingPostcode = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_SHIPPING_POSTCODE];
            }
            if (values.shippingPhoneNo) {
              // const regex =
              //   /^(((\+66|0)(\d{1,2}\-?\d{3}\-?\d{3,4}))|((\+๖๖|๐)([๐-๙]{1,2}\-?[๐-๙]{3}\-?[๐-๙]{3,4})))$/;
              // if (!regex.test(values.shippingPhoneNo)) {
              //   errors.shippingPhoneNo =
              //     FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_SHIPPING_PHONENO_FORMAT];
              // }
            } else {
              errors.shippingPhoneNo = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_SHIPPING_PHONENO];
            }
          }

          const pdpaErrors = validatePDPA(values);
          Object.assign(errors, pdpaErrors);

          console.log('validations', errors);
          return errors;
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => {
          // console.log(values);
          return (
            <form onSubmit={handleSubmit}>
              <HiddenFieldForm />
              <Paper elevation={0} style={{ padding: 16 }}>
                {showPackageSelectionUI && (
                  <>
                    <SelectPackageForm
                      FORM_TEXT={FORM_TEXT}
                      skuMasterCode={skuMasterCode!}
                      enable={enablePackageSelection}
                      availableSkus={availableSkus}
                    />
                    <Box my={3}></Box>
                  </>
                )}
                {!showPackageSelectionUI && <HiddenSkuCodeFieldForm />}
                <Typography variant="h3">{FORM_TEXT[UIFORM_TEXT_FIELDS.HEADER_NAME]}</Typography>
                <ChantForm
                  orderRealId={orderRealId}
                  FORM_TEXT={FORM_TEXT}
                  locationId={locationId}
                  mode={mode}
                  showBirthdayInput={showBirthdayInput}
                  showFacePictureInput={showFacePictureInput}
                />
                <Box my={5}></Box>
                {orderMode === ORDER_MODE.SHIPPING && (
                  <>
                    <Typography variant="h3">
                      {FORM_TEXT[UIFORM_TEXT_FIELDS.HEADER_SHIPPING]}
                    </Typography>
                    <Box my={3}></Box>
                    <ShippingForm FORM_TEXT={FORM_TEXT} />
                    <Box my={5}></Box>
                  </>
                )}
                <AcceptPDPAForm FORM_TEXT={FORM_TEXT} />
                {form.getState().hasValidationErrors && (
                  <>
                    <ErrorBox errors={form.getState().errors} />
                    <Box my={1}></Box>
                  </>
                )}
                <ConfirmationButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="large"
                  stType="gradient"
                  fullWidth
                  disabled={form.getState().hasValidationErrors || isLoading}
                  onClick={handleSubmit}
                  loading={isLoading}
                >
                  {orderMode === ORDER_MODE.FULFILLMENT
                    ? FORM_TEXT[UIFORM_TEXT_FIELDS.CONFIRM_BUTTON]
                    : FORM_TEXT[UIFORM_TEXT_FIELDS.CONFIRM_BUTTON_SHIPPING]}
                </ConfirmationButton>
              </Paper>
            </form>
          );
        }}
      ></Form>
    </>
  );
}
