import { ATTACHMENT_TYPE } from 'Domain/Orders/types';
import React from 'react';
import { QA_TYPE } from '../Containers/types';
import { StoreUploadOrderSuccessProps } from './types';
import FortuneTellingAnswerSummaryCard from 'Components/FortuneTellingAnswerSummaryCard';
import { ImageList, VideoList } from './Components';
import { Box, Typography } from 'Components/Base';

export default function OrderDetailsFortuneTelling(props: StoreUploadOrderSuccessProps) {
  console.log(props);
  const attachmentList = props.orderData.attachments
    ? props.orderData.attachments['store-order-upload']?.attachmentList
    : [];
  const fortuneTellingAnswer = attachmentList?.find(
    (attachment) => attachment.type === ATTACHMENT_TYPE.OBJECT && attachment.name === QA_TYPE
  );
  const textAttachment = attachmentList?.find(
    (attachment) => attachment.type === ATTACHMENT_TYPE.TEXT
  );
  return (
    <>
      <FortuneTellingAnswerSummaryCard
        data={fortuneTellingAnswer?.data}
        additionalText={textAttachment?.contents}
      />
      {attachmentList && (
        <>
          <Box mt={2} />
          <Typography variant="h4">ข้อมูลเพิ่มเติม</Typography>
          <VideoList
            uploadedFiles={attachmentList.filter(
              (attachment) => attachment.type === ATTACHMENT_TYPE.VIDEO
            )}
          />
          <ImageList
            uploadedFiles={attachmentList.filter(
              (attachment) => attachment.type === ATTACHMENT_TYPE.IMAGE
            )}
          />
        </>
      )}
    </>
  );
}
