import React from 'react';
import { useParams } from 'react-router';
import ClientOrderContainer from './ClientOrderContainer';

interface ClientOrderRouterParams {
  orderId: string;
}

export default function ClientOrder() {
  const { orderId: clientOrderId } = useParams<
    keyof ClientOrderRouterParams
  >() as ClientOrderRouterParams;
  return <ClientOrderContainer clientOrderId={clientOrderId} />;
}
