import { Box, Button } from 'Components/Base';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import CheckCircleIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';

import UploadErrorResultTable from './UploadErrorResultTable';
import { Typography } from '@mui/material';
import axios from 'axios';
import BackofficeAPI from 'API/fe-functions/backoffice/BackofficeAPI';

interface DropzoneProps {
  onFilesSelected(files: File[]): void;
}

function MyDropzone(props: DropzoneProps) {
  const { onFilesSelected } = props;
  const onDrop = useCallback(
    (acceptedFiles) => {
      // Do something with the files
      console.log(acceptedFiles);
      onFilesSelected(acceptedFiles);
    },
    [onFilesSelected]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <div>Drop file here</div>
      ) : (
        <Button variant="contained">อัพโหลดไฟล์ XLSX</Button>
      )}
    </div>
  );
}

enum UPLOAD_FILE_STATE {
  INIT,
  FILE_UPLOADING,
  FILE_UPLOADED,
  FILE_SUBMITTING,
  FILE_SUBMITTED,
  FILE_VALIDATION_ERROR,
}

enum FILE_VALIDATION_RESULT {
  PASS,
  PRECHECK_FAIL,
  SUBMIT_FAIL,
}

export default function UploadShippingOrders() {
  const [uploadFileState, setUploadFileState] = useState<UPLOAD_FILE_STATE>(UPLOAD_FILE_STATE.INIT);
  const [dropzoneFiles, setDropzoneFiles] = useState<File[]>([]);

  const handleFilesSelected = async (files) => {
    setDropzoneFiles(files);
    if (files.length > 0) {
      // upload it and get validation response
      const UploadAPI = new BackofficeAPI();
      console.log('will upload files');
      await UploadAPI.shipmentUploadfile(files[0]);
    }
  };

  return (
    <Box display="flex" flexDirection="column">
      <MyDropzone onFilesSelected={handleFilesSelected} />
      <Box my={2}></Box>
      <>
        <Box display="flex" flexDirection="column" alignItems="center">
          <ErrorIcon style={{ color: '#b91600' }} fontSize={'large'} />
          <Typography>มีข้อมูลผิด</Typography>
        </Box>
        <UploadErrorResultTable />
      </>
      <>
        <Box display="flex" flexDirection="column" alignItems="center">
          <CheckCircleIcon style={{ color: '#00B900' }} fontSize={'large'} />
          <Typography>ตรวจข้อมูลผ่าน</Typography>
        </Box>
        <Box my={2}></Box>
        <Button variant="contained" color="primary">
          ยืนยันข้อมูล
        </Button>
      </>
    </Box>
  );
}
