import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import { CHANT_MODE, UIFORM_TEXT_FIELDS } from 'Domain/Sattha/Chant';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { Field, useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { Box, Typography } from 'Components/Base';
import {
  getMTWFansBirthDayDisplay,
  getMTWGenderDisplay,
  getMTWMedicalProblemsDisplay,
  getMTWOptionDisplay,
  getMTWSubSubTopicDisplay,
  getMTWSubTopicDisplay,
  getMTWTemplate,
  mtwAvailableGender,
  mtwAvailableMedicalProblems,
  MTW_REQUEST_TYPES,
  MTW_SHIPMENT_FORM_TYPES,
  MTW_SUBTOPICS,
} from 'Domain/Sku/MTWWallpaper';
import {
  MTWRequestSubTypeInput,
  MTWRequestTypeInput,
  MTWRequestSubSubTypeInput,
  MTWRequestOptionsInput,
  MTWRequestDeviceInput,
  MTWMedicalProbemInput,
} from '../Input/MTWRequestInput';
import CheckBoxGroup from '../Input/CheckBoxGroup';
import {
  availableDays,
  availableMonths,
  availableYears,
  EMAIL_REGEX,
  ENGLISH_NAME_REGEX,
  PHONE_NO_REGEX,
  validateAge,
} from '../utils/utils';
import { getTimeInput } from 'Domain/Sku/FixBadLuck';
import { UserInfoFormDataShipment } from '../Forms/SingleShipmentForm';
import { getBirthTimeDisplay } from 'Domain/Sku/Wallpaper';
import GenderInput from '../Input/GenderInput';

const useStyles = makeStyles((theme) => ({
  hiddenField: {
    display: 'none',
  },
}));

const getText = (value?: any) => {
  if (value === null || value === undefined) return '-';
  return value;
};

export function generateShipmentAdditionalData(values: UserInfoFormDataShipment) {
  const {
    orderName,
    email,
    gender,
    phoneNo,
    birthDay,
    birthMonth,
    birthYear,
    birthTime,
    medicalProblems,
    wallpaperRequestType,
    wallpaperRequestOption,
    wallpaperRequestSubType,
    wallpaperRequestSubSubType,
  } = values;

  const template = getMTWTemplate(wallpaperRequestType! as MTW_REQUEST_TYPES);
  const { hour, minute, notKnow } = birthTime
    ? getTimeInput(birthTime)
    : { hour: 0, minute: 0, notKnow: true };
  const birthTimeText = getBirthTimeDisplay(notKnow ? undefined : { hour: hour!, minute: minute! });
  const plainText = `ชื่อ: ${orderName}
เพศ: ${getMTWGenderDisplay(gender)}
วันเกิด: ${getMTWFansBirthDayDisplay({ day: birthDay!, month: birthMonth!, year: birthYear! })}
เวลาเกิด: ${birthTimeText}
โรคประจำตัว: ${getMTWMedicalProblemsDisplay(medicalProblems)}
ประเภทการสั่งซื้อ: ${template.display}
${template?.selectTopicLabel}: ${getMTWOptionDisplay(template, wallpaperRequestOption!)}
${template?.requestTypeLabel}: ${getMTWSubTopicDisplay(
    template,
    wallpaperRequestSubType!
  )} - ${getMTWSubSubTopicDisplay(
    template,
    wallpaperRequestSubType!,
    wallpaperRequestSubSubType!
  )}`;
  return plainText;
}

export function validations(values: UserInfoFormDataShipment, FORM_TEXT) {
  const template = getMTWTemplate(MTW_REQUEST_TYPES.LUCK_CARD);
  const errors: { [key: string]: string } = {};
  if (!values.orderName) {
    errors.orderName = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_NAME_REQUIRED];
  } else {
    if (!ENGLISH_NAME_REGEX.test(values.orderName)) {
      errors.orderName = 'กรุณากรอกชื่อนามสกุลในการทำการ์ดเป็นภาษาอังกฤษ';
    }
  }
  if (!values.gender) errors.gender = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_GENDER_REQUIRED];
  if (!values.birthDay) errors.birthDay = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHDAY_REQUIRED];
  if (!values.birthMonth)
    errors.birthMonth = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHMONTH_REQUIRED];
  if (!values.birthYear) errors.birthYear = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHYEAR_REQUIRED];
  if (values.birthDay && values.birthMonth && values.birthYear) {
    const isValidBirthDay = validateAge({
      day: values.birthDay,
      month: values.birthMonth,
      year: values.birthYear,
    });
    if (!isValidBirthDay) {
      errors.birthYear =
        'ระบบไม่สามารถให้บริการบุคคลที่อายุต่ำกว่า 13 ปีได้ กรุณาติดต่อ hello@sattha.online เพื่อทำการคืนเงิน';
    }
  }
  if (!values.wallpaperRequestSubType)
    errors.wallpaperRequestSubType = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_WALLPAPER_TOPIC_REQUIRED];
  if (!values.birthTime) {
    // pass validation
  } else {
    try {
      const { hour, minute, notKnow } = getTimeInput(values.birthTime);
      if (notKnow) {
        errors.birthTime = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_NOT_IN_FORMAT];
      }
    } catch (error) {
      if (error.message === 'Not Valid Format') {
        errors.birthTime = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_NOT_IN_FORMAT];
      } else if (error.message === 'Time not in range') {
        errors.birthTime = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_NOT_IN_TIME_FORMAT];
      } else {
        errors.birthTime = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_NOT_IN_FORMAT];
      }
    }
  }
  if (!values.wallpaperRequestOption) {
    errors.wallpaperRequestOption = 'กรุณาเลือก' + template.selectTopicLabel;
  }
  if (!values.wallpaperRequestSubType) {
    errors.wallpaperRequestSubType = 'กรุณาเลือก' + template.requestTypeLabel;
  }
  if (!values.wallpaperRequestSubSubType) {
    errors.wallpaperRequestSubType = 'กรุณาเลือก' + template.requestTypeLabel;
  }
  return errors;
}

export default function MTWShipmentForm({
  FORM_TEXT,
  shipmentFormType,
  values,
}: {
  FORM_TEXT: any;
  shipmentFormType: MTW_SHIPMENT_FORM_TYPES;
  values: UserInfoFormDataShipment;
}) {
  const form = useForm();

  const wallpaperRequestType = MTW_REQUEST_TYPES.LUCK_CARD;
  return (
    <>
      <Box my={1}></Box>
      <Typography variant="info">
        {'* ข้อมูลของท่านจะถูกใช้เพื่อการทำการ์ดเสริมดวงเท่านั้น'}
      </Typography>
      <Box my={3}></Box>
      <FormControl fullWidth required>
        <Field
          name="orderName"
          render={(props) => (
            <>
              <Typography variant="h5">{'ชื่อ-นามสกุล ภาษาอังกฤษ'}</Typography>
              <TextField
                {...props.input}
                label=""
                variant="outlined"
                placeholder="กรอกชื่อ - นามสกุลจริง เป็นภาษาอังกฤษ"
                fullWidth
                InputProps={{
                  startAdornment: <div />,
                }}
                inputProps={{ maxLength: 60 }}
                helperText={
                  props.meta.error && props.meta.touched ? (
                    <FormHelperText error>{props.meta.error}</FormHelperText>
                  ) : null
                }
              />
            </>
          )}
        />
      </FormControl>
      <Box my={2}></Box>
      <FormControl fullWidth required>
        <Field
          name="gender"
          render={(props) => (
            <>
              <Typography variant="h5">{FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_GENDER]}</Typography>
              <GenderInput
                {...props}
                placeholder={FORM_TEXT[UIFORM_TEXT_FIELDS.PLACEHOLDER_GENDER]}
                choices={mtwAvailableGender}
              />
            </>
          )}
        />
      </FormControl>
      <Box my={3}></Box>
      <FormControl fullWidth required>
        <Field
          name="birthDay"
          render={(props) => (
            <>
              <Typography variant="h5">{FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_BIRTHDATE]}</Typography>
              <Select
                {...props.input}
                displayEmpty
                variant="outlined"
                // placeholder="กรอกชื่อ - นามสกุลจริง เพื่อใช้ในการประกอบพิธี"
                fullWidth
              >
                <MenuItem value="" disabled>
                  {FORM_TEXT[UIFORM_TEXT_FIELDS.PLACEHOLDER_BIRTHDATE]}
                </MenuItem>
                {availableDays?.map((data) => (
                  <MenuItem key={data.value} value={data.value}>
                    {data.display}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        />
      </FormControl>
      <Box my={2}></Box>
      <FormControl fullWidth>
        <Field
          name="birthMonth"
          render={(props) => (
            <>
              <Typography variant="h5">{FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_BIRTHMONTH]}</Typography>
              <Select {...props.input} displayEmpty variant="outlined" fullWidth>
                <MenuItem value="" disabled>
                  {FORM_TEXT[UIFORM_TEXT_FIELDS.PLACEHOLDER_BIRTHMONTH]}
                </MenuItem>
                {availableMonths?.map((data) => (
                  <MenuItem key={data.value} value={data.value}>
                    {data.display}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        />
      </FormControl>
      <Box my={2}></Box>
      <FormControl fullWidth>
        <Field
          name="birthYear"
          render={(props) => (
            <>
              <Typography variant="h5">{FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_BIRTHYEAR]}</Typography>
              <Select {...props.input} displayEmpty variant="outlined" fullWidth>
                <MenuItem value="" disabled>
                  {FORM_TEXT[UIFORM_TEXT_FIELDS.PLACEHOLDER_BIRTHYEAR]}
                </MenuItem>
                {availableYears?.map((data) => (
                  <MenuItem key={data.value} value={data.value}>
                    {data.display}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        />
      </FormControl>
      <Box my={2}></Box>
      <FormControl fullWidth>
        <Field
          name="birthTime"
          render={(props) => (
            <>
              <Typography variant="h5">{FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_BIRTHTIME]}</Typography>
              <TextField
                {...props.input}
                label={''}
                variant="outlined"
                placeholder={FORM_TEXT[UIFORM_TEXT_FIELDS.PLACEHOLDER_BIRTHTIME]}
                fullWidth
                InputProps={{
                  startAdornment: <div />,
                }}
                inputProps={{ maxLength: 60 }}
                helperText={
                  props.meta.error && props.meta.touched ? (
                    <FormHelperText error>{props.meta.error}</FormHelperText>
                  ) : null
                }
              />
            </>
          )}
        />
      </FormControl>
      <Box my={2}></Box>
      <FormControl fullWidth>
        <Field
          name="medicalProblems"
          render={(props) => {
            return (
              <>
                <Typography variant="h5">{'คุณมีโรคประจำตัวตามนี้หรือไม่'}</Typography>
                <Typography variant="subtitle2">
                  {'บางโรคจะมีผลกับการที่แม่หมอจัดไพ่ จะได้ปรับหน้าไพ่ได้ดีขึ้น'}
                </Typography>
                <MTWMedicalProbemInput {...props.input} />
              </>
            );
          }}
        />
      </FormControl>
      <Box my={2}></Box>
      <Box my={2}></Box>
      <Typography variant="h4">{'ข้อมูลเรื่องการเสริมดวง'}</Typography>
      <Box my={2}></Box>
      <FormControl fullWidth>
        <Field
          name="wallpaperRequestType"
          render={(props) => (
            <>
              <Typography variant="h5">{'สินค้า'}</Typography>
              <MTWRequestTypeInput {...props.input} />
            </>
          )}
        />
      </FormControl>
      <Box my={2}></Box>
      <FormControl fullWidth>
        <Field
          name="wallpaperRequestOption"
          render={(props) => (
            <>
              <MTWRequestOptionsInput {...props.input} inputRequestType={wallpaperRequestType} />
            </>
          )}
        />
      </FormControl>
      <Box my={2}></Box>
      <FormControl fullWidth>
        <Field
          name="wallpaperRequestSubType"
          render={(props) => (
            <>
              <MTWRequestSubTypeInput
                {...props.input}
                inputRequestType={wallpaperRequestType}
                onChange={(event) => {
                  form.change('wallpaperRequestSubSubType', undefined);
                  form.blur('wallpaperRequestSubSubType');
                  props.input.onChange(event);
                }}
              />
            </>
          )}
        />
      </FormControl>
      <Box my={2}></Box>
      <FormControl fullWidth>
        <Field
          name="wallpaperRequestSubSubType"
          render={(props) => (
            <>
              <MTWRequestSubSubTypeInput
                {...props.input}
                inputRequestType={wallpaperRequestType}
                subTopic={values.wallpaperRequestSubType}
              />
            </>
          )}
        />
      </FormControl>
      <Box my={1}></Box>
    </>
  );
}
