import React, { Fragment, useEffect, useState } from 'react';
import { getPublicUrl } from 'Libs/Firebase/storage';

import { Box, Button, Typography } from 'Components/Base';

import ImageBox from './ImageBox';
import Loading from 'Components/Loading';
import { CHANT_MODE, getChantText, TEXT_LABEL } from 'Domain/Sattha/Chant';
import { ATTACHMENT_TYPE } from 'Libs/Firebase/firestore/Order';
import VideoDownloadBox from './VideoDownloadBox';
import ReactMarkdown from 'Components/MarkdownBox';

const WAIT_IMAGE =
  process.env.REACT_APP_THEME_WAIT_IMAGE || '/assets/orderDetails/uploadImagePlaceholder-line.png';

function PlaceholderBox({ mode, customSubtitle }: { mode: CHANT_MODE; customSubtitle?: string }) {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <img src={WAIT_IMAGE} style={{ maxWidth: 140, height: 'auto' }} alt="" />
      <Box mt={2} />
      <Typography style={{ maxWidth: 240 }} align="center">
        <b>อดใจรออีกนิด!</b> {getChantText(mode)[TEXT_LABEL.UPLOADED_IMAGE_DESCRIPTION]}
      </Typography>
      {customSubtitle && (
        <>
          <Box mt={2} />
          <Typography style={{ maxWidth: 240 }} align="center">
            {customSubtitle}
          </Typography>
        </>
      )}
    </Box>
  );
}

export interface Attachment {
  url: string;
  bucket?: string;
  name?: string;
  ref?: string;
  type: ATTACHMENT_TYPE;
  contents?: string;
  data?: any;
}

interface UploadImagesBoxProps {
  imageList?: Attachment[];
  mode: CHANT_MODE;
  customSubtitle?: string;
  packageDate?: string;
  customerName?: string;
}

const getResizedUrl = (name: string) => {
  const lastDot = name.lastIndexOf('.');
  if (lastDot === -1) return `${name}_1000x1000`;

  const fileName = name.substr(0, lastDot);
  const fileExt = name.substr(lastDot + 1);
  return `${fileName}_1000x1000.${fileExt}`;
};

export function AlternativeImageBox({ imageData }: { imageData: Attachment }) {
  const [resizedUrl, setResizedUrl] = useState<string | null>(null);
  useEffect(() => {
    const getUrl = async (ref, name) => {
      try {
        const url = await getPublicUrl({ ref, file: getResizedUrl(name) });
        setResizedUrl(url);
      } catch (error) {
        console.log(error);
        setResizedUrl(imageData.url);
      }
    };
    if (imageData.ref && imageData.name) {
      getUrl(imageData.ref, imageData.name);
    }
  }, [imageData.ref, imageData.name]);
  if (imageData.ref && imageData.name) {
    if (!resizedUrl) return <Loading />;
    return <ImageBox imageUrl={resizedUrl} downloadUrl={imageData.url} />;
  }
  return <ImageBox imageUrl={imageData.url} />;
}

export function VideoBox({
  videoData,
  name,
  packageDate,
}: {
  videoData: Attachment;
  name?: string;
  packageDate?: string;
}) {
  return <VideoDownloadBox videoUrl={videoData.url} name={name} packageDate={packageDate} />;
}

export default function StoreUploadedImagesBox({
  imageList = [],
  mode,
  customSubtitle,
  packageDate,
  customerName,
}: UploadImagesBoxProps) {
  const uploadedImageList = imageList
    ? imageList.filter((image) => image.type === ATTACHMENT_TYPE.IMAGE)
    : [];
  const uploadedVideoList = imageList
    ? imageList.filter((image) => image.type === ATTACHMENT_TYPE.VIDEO)
    : [];
  const uploadedTextList =
    imageList?.filter(
      (image) => image.type === ATTACHMENT_TYPE.TEXT && image.contents !== undefined
    ) ?? [];

  return (
    <Box>
      <Box mt={2}></Box>
      <Typography variant="h4">{getChantText(mode)[TEXT_LABEL.UPLOADED_IMAGE_TITLE]}</Typography>
      <Box my={3}></Box>
      {imageList.length === 0 && <PlaceholderBox mode={mode} customSubtitle={customSubtitle} />}
      {uploadedVideoList.length > 0 &&
        uploadedVideoList.map((videoData) => (
          <Fragment key={videoData.url}>
            <VideoBox videoData={videoData} packageDate={packageDate} name={customerName} />
            <Box mb={3}></Box>
          </Fragment>
        ))}
      {uploadedImageList.length > 0 &&
        uploadedImageList.map((imageData) => (
          <Fragment key={imageData.url}>
            <AlternativeImageBox imageData={imageData} />
            <Box mb={3}></Box>
          </Fragment>
        ))}
      {uploadedTextList.length > 0 &&
        uploadedTextList.map((textData, i) => (
          <Fragment key={textData.contents + ' ' + i}>
            <Typography variant="h4">
              {getChantText(mode)[TEXT_LABEL.UPLOADED_TEXT_TITLE]}
            </Typography>
            <ReactMarkdown>{textData.contents!}</ReactMarkdown>
            <Box mb={3}></Box>
          </Fragment>
        ))}
    </Box>
  );
}
