import React, { Fragment } from 'react';
import ReactPlayer from 'react-player';
import { useGetStoreOrderUploadSubscriber } from 'Domain/StoreOrderUpload/hooks';
import Loading from 'Components/Loading';
// import StoreUploadedImagesBox from 'Routes/ProviderOrder/Components/OrderDetails/StoreUploadedImagesBox';
import { Box, Button } from 'Components/Base';
import { ButtonGroup, Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ImageBox, {
  IMAGE_BOX_MODE,
} from 'Routes/ClientOrder/Components/OrderDetails/Components/ImageBox';
import ReactMarkdown from 'react-markdown';

type UploadImagesBoxProps = {
  imageList?: { url: string; type: string; contents?: string }[];
};

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'transparent',
    color: 'rgba(0, 0, 0, 0.87)',
    maxHeight: '80vh',
    width: 'auto',
    fontSize: theme.typography.pxToRem(12),
    border: '0px solid transparent',
  },
}))(Tooltip);

function BackofficeStoreUploadedImagesBox({ imageList = [] }: UploadImagesBoxProps) {
  return (
    <Box display="flex" flexDirection="row" overflow="scroll">
      {imageList.length > 0 &&
        imageList.map((image) => (
          <Box key={image.url} p={1}>
            {image.type === 'IMAGE' && (
              <>
                <HtmlTooltip
                  title={
                    <img
                      src={image.url}
                      style={{
                        width: 'auto',
                        maxHeight: '80vh',
                      }}
                      alt={''}
                    />
                  }
                >
                  <img
                    src={image.url}
                    style={{
                      width: 'auto',
                      maxHeight: 300,
                    }}
                    alt={''}
                  />
                </HtmlTooltip>
              </>
            )}
            {image.type === 'VIDEO' && (
              <>
                <ReactPlayer url={image.url} controls />
              </>
            )}
            {image.type === 'TEXT' && (
              <Box
                sx={{
                  minWidth: 100,
                  maxHeight: 200,
                  overflow: 'scroll',
                  maxWidth: 300,
                  border: '1px solid lightgrey',
                }}
                p={1}
              >
                <ReactMarkdown>{image.contents!}</ReactMarkdown>
              </Box>
            )}
          </Box>
        ))}
    </Box>
  );
}

interface ApprovalCardProps {
  id: string;
  orderId: string;
}

export default function BackofficeStoreOrderUploadImages(props: ApprovalCardProps) {
  const { id, orderId } = props;
  const { isExisted, data } = useGetStoreOrderUploadSubscriber(id);
  if (!isExisted) return <Loading />;
  const storeUploadImageList =
    data?.attachmentList.map((at) => ({ url: at.url, type: at.type, contents: at.contents })) || [];
  console.log(data?.attachmentList);
  return (
    <Box>
      <BackofficeStoreUploadedImagesBox imageList={[...storeUploadImageList]} />
    </Box>
  );
}
