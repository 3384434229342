import { Box, Typography } from 'Components/Base';
import { TRACKING_PROVIDERS_REVERSE_MAPPING } from 'Domain/Shipping';
import React from 'react';
import ContentCopy from '@mui/icons-material/Link';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export interface OrderShippingDataProps {
  shippingName: string;
  shippingAddress: string;
  shippingPostcode: string;
  shippingPhoneNo: string;
  trackingCode?: string;
  trackingProvider?: string;
}

const ShippingDetailsBox = (props: OrderShippingDataProps) => (
  <Box>
    <Typography variant="h4">{`ข้อมูลการจัดส่งสินค้า`}</Typography>
    <Box
      style={{ borderRadius: 8, borderColor: 'black', borderWidth: 1, borderStyle: 'solid' }}
      p={2}
      mt={1}
    >
      <Typography>{props.shippingName}</Typography>
      <Typography>{props.shippingAddress + ' ' + props.shippingPostcode}</Typography>
      <Typography>{props.shippingPhoneNo}</Typography>
      {props.trackingCode && (
        <Box mt={1}>
          <Typography>
            {'จัดส่งโดย ' + TRACKING_PROVIDERS_REVERSE_MAPPING[props.trackingProvider!]}
          </Typography>
          <Typography>
            {'เลขที่พัสดุ ' + props.trackingCode}{' '}
            <CopyToClipboard text={props.trackingCode}>
              <span style={{ textDecoration: 'underline' }}>คัดลอก</span>
            </CopyToClipboard>
          </Typography>
        </Box>
      )}
    </Box>
  </Box>
);

export default ShippingDetailsBox;
