import { getDisplayValue } from './utils';
import { WALLPAPER_BIRTHDATE } from './Wallpaper';

export enum HRS_REQUEST_TYPE {
  CHARMING = 'CHARMING',
  TALK = 'TALK',
  SUPPORTER = 'SUPPORTER',
  LUCK = 'LUCK',
  CHARMING_1 = 'CHARMING_1',
  BUSINESS = 'BUSINESS',
  LUCK_1 = 'LUCK_1',
}

export const HRS_REQUEST_DISPLAY: { [key in HRS_REQUEST_TYPE]: string } = {
  [HRS_REQUEST_TYPE.CHARMING]: 'รวยด้วยเสน่ห์',
  [HRS_REQUEST_TYPE.TALK]: 'ปากพารวย',
  [HRS_REQUEST_TYPE.SUPPORTER]: 'รวยด้วยคนอุปถัมภ์',
  [HRS_REQUEST_TYPE.LUCK]: 'รวยด้วยความโชคดี',
  [HRS_REQUEST_TYPE.CHARMING_1]: 'เสริมเสน่หา ความรัก ดึงดูดเพศตรงข้าม',
  [HRS_REQUEST_TYPE.BUSINESS]:
    'เสริมด้านธุรกิจ งานก้าวหน้า สำเร็จดังหวัง มีชัยชนะในการแข่งขัน ผู้ใหญ่อุปถัมภ์เมตตา',
  [HRS_REQUEST_TYPE.LUCK_1]: 'เสริมด้านโชคลาภ ลาภลอย มหาทรัพย์มหาโชค',
};

enum HRS_OCCUPATION {
  NONE = 'NONE',
  O001 = 'O001',
  O002 = 'O002',
  O003 = 'O003',
  O004 = 'O004',
  O005 = 'O005',
  O006 = 'O006',
  O007 = 'O007',
  O008 = 'O008',
  O009 = 'O009',
  O010 = 'O010',
  O011 = 'O011',
  O012 = 'O012',
  O013 = 'O013',
  O014 = 'O014',
  O015 = 'O015',
  O016 = 'O016',
  O999 = 'O999',
}

export const hrsAvailableOccupations = [
  { value: HRS_OCCUPATION.O001, display: '1. ข้าราชการ รัฐวิสาหกิจ' },
  {
    value: HRS_OCCUPATION.O002,
    display: '2. เชลส์ การสื่อสาร ประสานงาน นักการตลาด PR ประชาสัมพันธ์',
  },
  {
    value: HRS_OCCUPATION.O003,
    display: '3. นายหน้าด้านอสังหาริมทรัพย์ โบรกเกอร์ด้านการลงทุน ธุรกิจเครือข่าย',
  },
  { value: HRS_OCCUPATION.O004, display: '4. ขายของ ออนไลน์ ค้าขายทั่วไป' },
  {
    value: HRS_OCCUPATION.O005,
    display: '5. นักลงทุนหุ้น เล่นค่าเงิน คริปโต บิตคอยน์ นักเสี่ยงโชค',
  },
  {
    value: HRS_OCCUPATION.O006,
    display: '6. ศิลปิน ดารา นักร้องนักแสดง อินฟลูฯ YouTuber TikToker นางแบบ นายแบบ',
  },
  {
    value: HRS_OCCUPATION.O007,
    display: '7. พริตตี้ สาวสายเอ็นฯ คนกลางคืน',
  },
  {
    value: HRS_OCCUPATION.O008,
    display: '8. ครีเอทีฟ งานสร้างสรรค์ ไอเดีย',
  },
  { value: HRS_OCCUPATION.O009, display: '9. ธุรกิจความสวยความงาม นักแต่งหน้า เสริมสวย' },
  { value: HRS_OCCUPATION.O010, display: '10. เจ้าของธุรกิจขนาดใหญ่ทุกรูปแบบ' },
  { value: HRS_OCCUPATION.O011, display: '11. พนักงานต้อนรับ แอร์โฮสเตส สจ๊วต' },
  { value: HRS_OCCUPATION.O012, display: '12. แพทย์ พยาบาล งานด้านสุขภาพ' },
  { value: HRS_OCCUPATION.O013, display: '13. นักเรียน นักศึกษา' },
  {
    value: HRS_OCCUPATION.O014,
    display: '14. ไอที โปรแกรมเมอร์ ทุกสาขาที่เกี่ยวกับเทคโนโลยี',
  },
  { value: HRS_OCCUPATION.O015, display: '15. บัญชี การเงิน การธนาคาร' },
  { value: HRS_OCCUPATION.O016, display: '16. ขนส่งทุกประเภท (แกร็บ แมสเซนเจอร์ รับส่งของ)' },
  { value: HRS_OCCUPATION.O999, display: '17. อื่นๆ' },
];

export const hrsAvailableSecondOccupations = [
  { value: HRS_OCCUPATION.NONE, display: 'ไม่มีอาชีพเสริม' },
  ...hrsAvailableOccupations,
];

export const hrsDateOfBirth = [
  {
    display: 'วันจันทร์',
    value: WALLPAPER_BIRTHDATE.MONDAY,
  },
  {
    display: 'วันอังคาร',
    value: WALLPAPER_BIRTHDATE.TUESDAY,
  },
  {
    display: 'วันพุธ',
    value: WALLPAPER_BIRTHDATE.WEDNESDAY,
  },
  {
    display: 'วันพฤหัสฯ',
    value: WALLPAPER_BIRTHDATE.THURSDAY,
  },
  {
    display: 'วันศุกร์',
    value: WALLPAPER_BIRTHDATE.FRIDAY,
  },
  {
    display: 'วันเสาร์',
    value: WALLPAPER_BIRTHDATE.SATURDAY,
  },
  {
    display: 'วันอาทิตย์',
    value: WALLPAPER_BIRTHDATE.SUNDAY,
  },
];

export enum HRS_INCLUDE_SERVICE {
  NONE = 'NONE',
  TWO = 'TWO',
  FOUR = 'FOUR',
}

export const getHRSOccupationDisplay = getDisplayValue(hrsAvailableSecondOccupations);
export const getHRSDateOfBirthDisplay = getDisplayValue(hrsDateOfBirth);
export const getHRSTopicDisplay = (topic: string) =>
  HRS_REQUEST_DISPLAY[topic as HRS_REQUEST_TYPE] ?? 'ข้อมูลไม่ถูกต้อง';
export const getHRSIncludeServiceDisplay = (additionalService?: HRS_INCLUDE_SERVICE) => {
  if (!additionalService || additionalService === HRS_INCLUDE_SERVICE.NONE) return '-';
  switch (additionalService) {
    case HRS_INCLUDE_SERVICE.TWO:
      return '2 บริการเสริม';
    case HRS_INCLUDE_SERVICE.FOUR:
      return '4 บริการเสริม';
    default:
      return 'มีบริการเสริม';
  }
};
