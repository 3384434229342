import React from 'react';
import OrderWaitBox from 'Components/OrderWaitBox';
import {
  ORDER_STATUS,
  ORDER_TYPE,
  SatthaOrder,
  SKU_CHANT_MODE,
} from 'Libs/Firebase/firestore/Order';

interface OrderWaitBoxContainerProps {
  orderInfo: SatthaOrder;
}

const hardcodeGetText = (orderInfo: SatthaOrder): { text1: string; text2?: string } => {
  if (orderInfo.type === ORDER_TYPE.SHIPMENT)
    return {
      text1: `เราได้รับข้อมูลของท่านแล้ว 
    และจะแจ้งเลขการจัดส่ง
    เมื่อดำเนินการส่งเรียบร้อย`,
    };
  if (orderInfo.skuMode === SKU_CHANT_MODE.FORTUNE_TELLING)
    return {
      text1: `อดใจรออีกนิด! คำทำนายจากนักพยากรณ์ที่คุณเลือก
      จะแสดงผลที่นี่ภายใน 7-14 วัน หลังจากวันที่กรอกข้อมูลเสร็จ`,
    };
  return {
    text1: '',
  };
};

export default function OrderWaitBoxContainer(props: OrderWaitBoxContainerProps) {
  const { orderInfo } = props;
  if ([ORDER_STATUS.PAID].includes(orderInfo.status)) {
    return null;
  }
  if (
    [
      ORDER_STATUS.COMPLETED,
      ORDER_STATUS.CLIENT_ACCEPT,
      ORDER_STATUS.MESSAGE_FAIL_DELIVERING,
    ].includes(orderInfo.status)
  ) {
    return null;
  }
  const dataProps = hardcodeGetText(props.orderInfo);
  return <OrderWaitBox {...dataProps} />;
}
