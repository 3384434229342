import React from 'react';
import { Form, Field } from 'react-final-form';
import {
  Paper,
  FormControl,
  TextField,
  FormHelperText,
  Select,
  InputLabel,
  MenuItem,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { Box, Button, Typography } from 'Components/Base';
import { CHANT_MODE, getUserInfoForm, UIFORM_TEXT_FIELDS } from 'Domain/Sattha/Chant';
import { AvailableSkuItem, OrderShippingData } from 'Libs/Firebase/firestore/Order';
import FixBadluckForm, { availableMonths } from '../Components/FixBadLuckForm';
import { getTimeInput } from 'Domain/Sku/FixBadLuck';
import { FIX_BADLUCK_MODE, getFixBadLuckMode } from 'Domain/Orders/utils';
import SelectPackageForm from '../Components/SelectPackageForm';
import ChantForm from '../Components/ChantForm';
import ShippingForm from '../Components/ShippingForm';
import WreathForm from '../Components/WreathForm';
import { HiddenFieldForm, HiddenSkuCodeFieldForm } from '../Components/HiddenFieldForm';
import AcceptPDPAForm, { validatePDPA } from '../Components/AcceptPDPAForm';
import ErrorBox from '../Components/ErrorBox';
import ConfirmationButton from '../Components/ConfirmationButton';

const useStyles = makeStyles((theme) => ({
  hiddenField: {
    display: 'none',
  },
  gradientButton: {
    background: `linear-gradient(314.47deg, #01C1A8 0%, #1DCE59 90.84%)`,
    borderRadius: 2,
    color: 'white',
    width: '100%',
    '&:disabled': {
      color: `#969696`,
      background: '#E6E6E6',
    },
  },
}));

export interface UserInfoFormDataWreath {
  orderId: string;
  mode: CHANT_MODE;
  chantName: string;
  skuCode?: string;
  phoneNo: string;
  wreathLocationPlace: string;
  wreathLocationPlaceSpecific: string;
  wreathLocationProvince: string;
  wreathDeceasedName?: string;
  wreathDisplay?: string;

  acceptTerms: boolean;
  acceptPrivacy: boolean;
}

export interface UserInfoFormInitialData {
  chantName?: string;
  chantMessage?: string;
}

interface CreateOrderFormProps {
  onSubmit?: (formValues: UserInfoFormDataWreath) => void;
  orderId: string;
  locationInstructions?: string;
  locationId: string;
  isLoading: boolean;
  mode: CHANT_MODE;
  initialData?: UserInfoFormInitialData;
  showPackageSelection: boolean;
  enablePackageSelection: boolean;
  skuMasterCode?: string;
  availableSkus?: AvailableSkuItem[];
  wreathProvince: string;
}

enum ORDER_MODE {
  FULFILLMENT = 'FULFILLMENT',
  SHIPPING = 'SHIPPING',
}

export default function UserInfoWreathForm(props: CreateOrderFormProps) {
  const classes = useStyles();

  const {
    orderId,
    onSubmit = () => {
      /** */
    },
    locationId,
    isLoading,
    mode,
    initialData,
    showPackageSelection,
    enablePackageSelection,
    skuMasterCode,
    availableSkus,
    wreathProvince,
  } = props;

  const FORM_TEXT = getUserInfoForm(mode);
  const { chantName, chantMessage } = initialData ?? {};

  const orderMode = ORDER_MODE.FULFILLMENT;

  let newInitialData = {
    orderId,
    mode,
    chantMessage,
    chantName,
    wreathLocationProvince: wreathProvince,
  };

  if (showPackageSelection && skuMasterCode && availableSkus && availableSkus?.length > 0) {
    const skuCode = availableSkus[0].skuCode;
    const newData = { skuCode };
    newInitialData = { ...newInitialData, ...newData };
  }

  const handleSubmit = (values: UserInfoFormDataWreath, ...restArgs) => {
    // console.log('handleSubmit', values);
    const { ...restValue } = values;
    const newValues: UserInfoFormDataWreath = { ...restValue };
    onSubmit(newValues);
  };

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={newInitialData}
        validate={(values) => {
          const errors: { [k: string]: any } = {};
          if (!values.skuCode) {
            errors.skuCode = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_SELECT_PACAKGE];
          }
          if (!values.chantName) {
            errors.chantName = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_NAME_REQUIRED];
          }

          if (values.phoneNo) {
            const regex =
              /^(((\+66|0)(\d{1,2}\-?\d{3}\-?\d{3,4}))|((\+๖๖|๐)([๐-๙]{1,2}\-?[๐-๙]{3}\-?[๐-๙]{3,4})))$/;
            if (!regex.test(values.phoneNo)) {
              errors.phoneNo = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_PHONENO_NOT_VALID];
            }
          } else {
            errors.phoneNo = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_PHONENO_REQUIRED];
          }

          if (!values.wreathLocationPlace) {
            errors.wreathLocationPlace = 'กรุณากรอกสถานที่จัดส่ง (วัด)';
          }

          if (!values.wreathLocationPlaceSpecific) {
            errors.wreathLocationPlaceSpecific = 'กรุณากรอกศาลา';
          }

          const pdpaErrors = validatePDPA(values);
          Object.assign(errors, pdpaErrors);

          // console.log('validations', errors);
          return errors;
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => {
          console.log(values);
          return (
            <form onSubmit={handleSubmit}>
              <HiddenFieldForm />
              <Paper elevation={0} style={{ padding: 16 }}>
                {showPackageSelection && skuMasterCode && (
                  <>
                    <SelectPackageForm
                      FORM_TEXT={FORM_TEXT}
                      skuMasterCode={skuMasterCode}
                      enable={enablePackageSelection}
                      availableSkus={availableSkus}
                      showRelativeDate={true}
                      displayOffsetDays={-1}
                      maxSelections={3}
                    />
                    <Box my={3}></Box>
                  </>
                )}
                <Typography variant="h3">{FORM_TEXT[UIFORM_TEXT_FIELDS.HEADER_NAME]}</Typography>
                <WreathForm FORM_TEXT={FORM_TEXT} locationId={locationId} mode={mode} />
                <Box my={5}></Box>
                {form.getState().hasValidationErrors && (
                  <>
                    <ErrorBox errors={form.getState().errors} />
                    <Box my={1}></Box>
                  </>
                )}
                <AcceptPDPAForm FORM_TEXT={FORM_TEXT} />
                <ConfirmationButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="large"
                  stType="gradient"
                  fullWidth
                  disabled={form.getState().hasValidationErrors || isLoading}
                  onClick={handleSubmit}
                  loading={isLoading}
                >
                  {orderMode === ORDER_MODE.FULFILLMENT
                    ? FORM_TEXT[UIFORM_TEXT_FIELDS.CONFIRM_BUTTON]
                    : FORM_TEXT[UIFORM_TEXT_FIELDS.CONFIRM_BUTTON_SHIPPING]}
                </ConfirmationButton>
              </Paper>
            </form>
          );
        }}
      ></Form>
    </>
  );
}
