import React from 'react';
import { Box, Typography } from 'Components/Base';
import { List } from '@mui/material';

import AiKhai from './Locations/AiKhai';
import TanTai from './Locations/TanJai';
import { CHANT_MODE } from 'Domain/Sattha/Chant';
import Sothon from './Locations/Sothon';
import Default from './Locations/Default';
import LTT from './Locations/LTT';
import HKLongSanInstruction from './Locations/HKLongSan';
import { STORE_LOCATIONS } from '../location';
import GenericChantMessageInstructions from './Locations/GenericChantMessageInstructions';

interface ChantMessageInstructionsProps {
  locationId: string;
  mode: CHANT_MODE;
}

export default function ChantInstructions(props: ChantMessageInstructionsProps) {
  const { locationId, mode } = props;

  switch (locationId.toUpperCase()) {
    case STORE_LOCATIONS.AI_KHAI:
      return <AiKhai mode={mode} />;
    case STORE_LOCATIONS.THEP_THAN_JAI:
      return <TanTai mode={mode} />;
    case STORE_LOCATIONS.SOTHON:
      return <Sothon mode={mode} />;
    case STORE_LOCATIONS.HK_HUNG_HOM:
      return <Default mode={mode} locationTitle={'เจ้าแม่กวนอิม'} />;
    case STORE_LOCATIONS.HK_CHE_KUNG:
      return <Default mode={mode} locationTitle={'เทพเจ้าแชกง'} />;
    case STORE_LOCATIONS.LCN:
      return <Default mode={mode} locationTitle={'เจ้าปู่ศรีสุทโธ'} />;
    case STORE_LOCATIONS.LTT:
      return <LTT mode={mode} />;
    case STORE_LOCATIONS.HK_LONG_SAN:
      return <HKLongSanInstruction mode={mode} />;
    default:
      return <GenericChantMessageInstructions locationId={locationId} mode={mode} />;
  }
}
