import React, { FormEventHandler, Fragment, SyntheticEvent, useState } from 'react';
import { Form, Field } from 'react-final-form';
import {
  Paper,
  FormControl,
  TextField,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Divider,
  FormHelperText,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Alert from '@mui/material/Alert';

import { Box, Button, Typography } from '../../Components/Base';

import ImageUploadBox from 'Containers/ImageUploadBox';
import { UPLOAD_MODE } from 'Containers/ImageUploadBox/ImageUploadBox';
import { SKU_CHANT_MODE } from 'Libs/Firebase/firestore/Order';
import ErrorBox from 'Components/UserInfoForm/Components/ErrorBox';
import { ConfirmationActionUI } from 'Components/StoreOrderForm/StoreOrderForm';

const useStyles = makeStyles((theme) => ({
  hiddenField: {
    display: 'none',
  },
  gradientButton: {
    background: `linear-gradient(314.47deg, #01C1A8 0%, #1DCE59 90.84%)`,
    borderRadius: 2,
    color: 'white',
    width: '100%',
    '&:disabled': {
      color: `#969696`,
      background: '#E6E6E6',
    },
  },
}));

export function HiddenFieldForm() {
  const classes = useStyles();
  return (
    <>
      <FormControl fullWidth className={classes.hiddenField}>
        <Field
          name="orderId"
          render={(props) => (
            <TextField {...props.input} hidden className={classes.hiddenField} fullWidth />
          )}
        />
      </FormControl>
      <FormControl fullWidth className={classes.hiddenField}>
        <Field
          name="orderRefId"
          render={(props) => (
            <TextField {...props.input} hidden className={classes.hiddenField} fullWidth />
          )}
        />
      </FormControl>
      <FormControl fullWidth className={classes.hiddenField}>
        <Field
          name="packageId"
          render={(props) => (
            <TextField {...props.input} hidden className={classes.hiddenField} fullWidth />
          )}
        />
      </FormControl>
    </>
  );
}

interface UploadStorageInterface {
  filename: string;
  fileurl: string;
  ref: string;
}

export interface StoreOrderFortuneTellingFormData {
  orderId: string;
  uploadedImagesData: UploadStorageInterface[];
  uploadedVideoData: UploadStorageInterface[];
  answers: string[];
}

export type StoreOrderFortuneTellingFormProps = {
  onSubmit?: (formValues: StoreOrderFortuneTellingFormData) => void;
  orderRefId?: string;
  orderRealId: string;
  orderId: string;
  storeId: string;
  customerName?: string;
  packageId?: string;
  packageName?: string;
  skuMode: SKU_CHANT_MODE;
  questions: string[];
  mode?: FORM_MODE;
  loading?: boolean;
  error?: any;
  showVideoUploadForm?: boolean;
  showTextFieldForm?: boolean;
  requiredAnswers?: boolean;
  requiredAdditionalInfo?: boolean;
};

export enum FORM_MODE {
  CANNOT_UPLOAD = 'CANNOT_UPLOAD',
  STORE_UPLOAD = 'STORE_UPLOAD',
  STORE_UPLOAD_COMPLETE = 'STORE_UPLOAD_COMPLETE',
  STORE_DONE = 'STORE_DONE',
  STORE_PREPARE = 'STORE_PREPARE',
}

export default function StoreOrderFortuneTellingForm(props: StoreOrderFortuneTellingFormProps) {
  const classes = useStyles();

  const {
    orderRealId,
    orderId,
    orderRefId,
    storeId,
    onSubmit = () => {
      //
    },
    customerName,
    packageId,
    packageName,
    mode = FORM_MODE.STORE_UPLOAD,
    questions,
    loading = false,
    error,
    showVideoUploadForm,
    showTextFieldForm,
    requiredAnswers,
    requiredAdditionalInfo,
  } = props;

  const disableEditing = [
    FORM_MODE.CANNOT_UPLOAD,
    FORM_MODE.STORE_UPLOAD,
    FORM_MODE.STORE_UPLOAD_COMPLETE,
  ].includes(mode);

  const disableSubmitButton =
    loading || [FORM_MODE.CANNOT_UPLOAD, FORM_MODE.STORE_UPLOAD_COMPLETE].includes(mode);

  const handleSubmit = (inValues) => {
    const { uploadedImagesData, uploadedVideoData, ...restValues } = inValues;
    const values = { ...restValues };
    values.uploadedImagesData = JSON.parse(uploadedImagesData);
    values.uploadedVideoData = JSON.parse(uploadedVideoData || '[]');
    onSubmit(values);
  };

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={{ orderId, orderRefId, customerName, packageId, packageName, questions }}
        validateOnBlur
        validate={(values) => {
          const errors: { [k: string]: any } = {};
          if (requiredAnswers) {
            const answers = values.answers;
            if (!answers || answers.length === 0) {
              errors['answers[0]'] = 'กรุณาระบุคำตอบ';
            } else {
              questions.forEach((_, index) => {
                if (!answers[index]) {
                  errors[`answers[${index}]`] = 'กรุณาระบุคำตอบข้อที่ ' + (index + 1);
                }
              });
            }
          }

          if (requiredAdditionalInfo) {
            if (!values.uploadedTextData || values.uploadedTextData?.length === 0) {
              errors['uploadedTextData'] = 'กรุณาใส่คำแนะนำหรือหมายเหตุเพิ่มเติม';
            }
          }

          if (showVideoUploadForm) {
            if (!values.uploadedVideoData) {
              errors['uploadedVideoData'] = 'กรุณาอัพโหลดวิดีโอ';
            } else {
              const value = JSON.parse(values.uploadedVideoData);
              if (value.length === 0) {
                errors['uploadedVideoData'] = 'กรุณาอัพโหลดวิดีโอ';
              }
            }
          }
          console.log('validations', values, errors);
          return errors;
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => {
          console.log(values);
          return (
            <ConfirmationActionUI cancelButtonText="แก้ไขข้อมูล">
              {(doConfirm) => (
                <form onSubmit={doConfirm(handleSubmit)}>
                  <HiddenFieldForm />
                  <Paper elevation={0} style={{ padding: 16 }}>
                    {questions.map((question, index) => (
                      <Fragment key={index}>
                        <Typography variant="h4">{`คำถามข้อที่ ${index + 1}`}</Typography>
                        <Box
                          mt={1}
                          p="1rem"
                          sx={{ border: '1px solid #969696', backgroundColor: '#E0E0E0' }}
                        >
                          <Typography variant="body">{question}</Typography>
                        </Box>
                        <Box mt={1}></Box>
                        <FormControl fullWidth disabled={disableEditing}>
                          <Field
                            name={`answers[${index}]`}
                            render={(props) => {
                              return (
                                <TextField
                                  {...props.input}
                                  label={''}
                                  variant="outlined"
                                  fullWidth
                                  multiline
                                  placeholder={'พิมพ์คำตอบหรือคำแนะนำที่นี่'}
                                  rows={8}
                                  InputProps={{
                                    startAdornment: <div />,
                                  }}
                                  helperText={
                                    props.meta.error &&
                                    props.meta.touched && (
                                      <FormHelperText error>{props.meta.error}</FormHelperText>
                                    )
                                  }
                                />
                              );
                            }}
                          />
                        </FormControl>
                        <Box my={3}></Box>
                      </Fragment>
                    ))}
                    {questions.length > 0 && <Box my={5}></Box>}
                    {showTextFieldForm && (
                      <>
                        <Box my={3}></Box>
                        <FormControl fullWidth>
                          <Field
                            name="uploadedTextData"
                            render={(props) => (
                              <>
                                <Typography variant="h4">
                                  {'คำแนะนำหรือหมายเหตุเพิ่มเติม'}
                                </Typography>
                                <Box my={1}></Box>
                                <TextField
                                  {...props.input}
                                  label={''}
                                  variant="outlined"
                                  fullWidth
                                  multiline
                                  placeholder={'พิมพ์คำแนะนำหรือหมายเหตุเพิ่มเติมที่นี่'}
                                  rows={10}
                                  InputProps={{
                                    startAdornment: <div />,
                                  }}
                                  helperText={
                                    props.meta.error &&
                                    props.meta.touched && (
                                      <FormHelperText error>{props.meta.error}</FormHelperText>
                                    )
                                  }
                                />
                              </>
                            )}
                          />
                        </FormControl>
                        <Box my={5}></Box>
                      </>
                    )}
                    <Typography variant="h4">อัพโหลดไฟล์</Typography>
                    <Field
                      name="uploadedImagesData"
                      render={(props) => (
                        <ImageUploadBox
                          uploadActionText="อัพโหลดรูป"
                          uploadPrefix="store-order-uploads"
                          uploadKey={`store/${storeId}/${orderRealId}`}
                          onUploadFiles={(data) => {
                            form.change('uploadedImagesData', JSON.stringify(data));
                            form.blur('uploadedImagesData');
                          }}
                          disabled={loading}
                          mode={UPLOAD_MODE.IMAGE}
                          multiple
                        />
                      )}
                    />
                    <Box my={5}></Box>
                    {showVideoUploadForm && (
                      <>
                        <Divider />
                        <Box my={3}></Box>
                        <FormControl fullWidth disabled={disableEditing}>
                          <Field
                            name="uploadedVideoData"
                            render={(props) => (
                              <ImageUploadBox
                                uploadActionText="อัพโหลดวิดิโอ"
                                uploadPrefix="store-order-uploads"
                                uploadKey={`store/${storeId}/${orderRealId}`}
                                onUploadFiles={(data) => {
                                  form.change('uploadedVideoData', JSON.stringify(data));
                                  form.blur('uploadedVideoData');
                                }}
                                disabled={loading}
                                mode={UPLOAD_MODE.VIDEO}
                                multiple
                              />
                            )}
                          />
                        </FormControl>
                        <Box my={5}></Box>
                      </>
                    )}
                    {form.getState().hasValidationErrors && (
                      <>
                        <ErrorBox errors={form.getState().errors} />
                        <Box my={1}></Box>
                      </>
                    )}
                    {error && (
                      <>
                        <Alert variant="filled" severity="error">
                          {`เกิดข้อผิดพลาด ${JSON.stringify(error)}`}
                        </Alert>
                        <Box my={3} />
                      </>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      size="large"
                      stType="gradient"
                      fullWidth
                      disabled={form.getState().hasValidationErrors || disableSubmitButton}
                    >
                      {loading ? <CircularProgress /> : 'ส่งงาน'}
                    </Button>
                  </Paper>
                </form>
              )}
            </ConfirmationActionUI>
          );
        }}
      ></Form>
    </>
  );
}
