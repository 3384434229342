import { Button, Container } from 'Components/Base';
import Box from 'Components/Base/Box';
import Loading from 'Components/Loading';
import { useGetChantImageSubscriber } from 'Domain/OrderChantImage/hooks';
import React from 'react';
import ImageBox from '../Components/OrderDetails/Components/ImageBox';

interface OrderChantConfirmProps {
  id: string;
  orderId: string;
  onEditChantMessage?: () => void;
  onConfirmChantMessage?: () => void;
}

export default function OrderChantConfirm(props: OrderChantConfirmProps) {
  const {
    id,
    orderId,
    onEditChantMessage = () => {
      //
    },
    onConfirmChantMessage = () => {
      //
    },
  } = props;
  const { isExisted, data } = useGetChantImageSubscriber(id);
  console.log('chantimage', { isExisted, data });
  const chantImageUrl = data?.url;
  return (
    <Box my={2} sx={{ position: 'relative' }}>
      {!isExisted && !chantImageUrl && <Loading />}
      {isExisted && chantImageUrl && <ImageBox imageUrl={chantImageUrl} hideDownloadButton />}
      <Box display="grid" gridTemplateColumns="1fr 1fr" gap="0px 10%" mt={2}>
        <Button variant="outlined" color="primary" size="large" onClick={onEditChantMessage}>
          แก้ไขข้อความ
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          stType="gradient"
          onClick={onConfirmChantMessage}
          disabled={!chantImageUrl}
        >
          ยืนยันบทสวด
        </Button>
      </Box>
    </Box>
  );
}
