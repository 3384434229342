import { Container, Typography } from 'Components/Base';
import Heading from 'Components/Layout/Heading';
import OrderSummaryTable, { TABLE_VIEW_MODE } from './Components/OrderSummaryTable';
import { useGetOrderSummaryFromSummaryId } from 'Domain/Stores/hooks';
import { Helmet } from 'react-helmet';
import { Drawer, IconButton, List, ListItem, ListItemText, useMediaQuery } from '@mui/material';
import Loading from 'Components/Loading';
import { getChantModeText } from 'Domain/Sattha/Chant';
import { Paper } from '@mui/material';
import { Box } from 'Components/Base';
import { skuSummarySortFn } from 'Domain/Orders/utils';
import { StoreOrderSummary } from 'Domain/Stores/types';
import { canStoreUpload } from 'Domain/Stores/utils';
import { useState } from 'react';
import StoreOrderContainer from 'Routes/StoreOrder/StoreOrderContainer';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import NavigationHeader from 'Components/Layout/NavigationHeader';

export enum SUMMARY_MODE {
  default = 'default',
  print = 'print',
  upload = 'upload',
}

const tableViewModeMapping = {
  [SUMMARY_MODE.default]: TABLE_VIEW_MODE.DEFAULT,
  [SUMMARY_MODE.print]: TABLE_VIEW_MODE.STORE_PRINT,
  [SUMMARY_MODE.upload]: TABLE_VIEW_MODE.UPLOAD,
};

const getUITitle = (ordersummaryId, data) => {
  if (!data) return `รายการออเดอร์`;
  return `รายการออเดอร์ - ${ordersummaryId} - ${data?.store.name}`;
};

const getTotalCost = (data) => {
  const formattedCost = new Intl.NumberFormat('th-TH', {
    style: 'currency',
    currency: 'THB',
  }).format(data?.totalCost || 0);

  return `ยอดที่ต้องโอนให้${data?.store.name} = ${formattedCost}`;
};

function OrderSummaryUploadHeader({ data }: { data: StoreOrderSummary }) {
  const totalOrders = data.orders;
  const waitForUploadOrders = data.orders.filter((order) => canStoreUpload(order.status));
  return (
    <Paper elevation={2}>
      <Box p={1}>
        <List
          dense={true}
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: 0,
          }}
        >
          <ListItem style={{ maxWidth: 200 }}>
            <ListItemText primary={`ออเดอร์ทั้งหมด`} secondary={totalOrders.length} />
          </ListItem>
          <ListItem style={{ maxWidth: 200 }}>
            <ListItemText primary={`ออเดอร์ที่เหลือ`} secondary={waitForUploadOrders.length} />
          </ListItem>
        </List>
      </Box>
    </Paper>
  );
}

function OrderSummaryHeader({ data }: { data: StoreOrderSummary }) {
  const formattedCost = new Intl.NumberFormat('th-TH', {
    style: 'currency',
    currency: 'THB',
  }).format(data?.totalCost || 0);
  const skuSummary = data.skuSummary;
  const datesToPerform = data.batchDate;
  const skuSummaryList = Object.entries(skuSummary);
  skuSummaryList.sort(skuSummarySortFn);
  return (
    <Paper elevation={2}>
      <Box p={1}>
        <List
          dense={true}
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: 0,
          }}
        >
          <ListItem style={{ maxWidth: 200 }}>
            <ListItemText primary={`วันดำเนินการ`} secondary={datesToPerform} />
          </ListItem>
          <ListItem style={{ maxWidth: 200 }}>
            <ListItemText primary={`ยอดเงิน`} secondary={formattedCost} />
          </ListItem>
        </List>
        <List
          dense={true}
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: 0,
            flexWrap: 'wrap',
          }}
        >
          {skuSummaryList.map(([skuCode, skuSummaryData]) => {
            return (
              <ListItem key={skuCode} style={{ maxWidth: 200 }}>
                <ListItemText
                  primary={`${skuSummaryData.sku.name} (${getChantModeText(
                    skuSummaryData.sku.mode
                  )})`}
                  secondary={skuSummaryData.count}
                />
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Paper>
  );
}

function OrderSummaryAgentsHeader({ data }: { data: StoreOrderSummary }) {
  const allAgents = new Map();
  data.orders.forEach((order) => {
    allAgents.set(order.agentId, order.agent);
  });
  const agents = Array.from(allAgents);
  return (
    <>
      <Paper>
        <List
          dense={true}
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: 0,
            flexWrap: 'wrap',
          }}
        >
          {agents.map(([agentId, agentData]) => (
            <ListItem key={agentId} style={{ maxWidth: 200 }}>
              <ListItemText
                primary={
                  <Box display="flex" flexDirection="column">
                    <Typography>{`${agentData?.name}`}</Typography>
                  </Box>
                }
                secondary={
                  <Link
                    to={`/store/${data.storeId}/summary/${data.orderSummaryId}?agentId=${agentId}`}
                    target="_blank"
                  >
                    Link
                  </Link>
                }
              />
            </ListItem>
          ))}
        </List>
      </Paper>
    </>
  );
}

const drawerWidth = 600;

export default function StoreOrderSummaryContainer({
  storeId,
  orderSummaryId,
  agentId,
  mode = SUMMARY_MODE.default,
}: {
  orderSummaryId: string;
  mode?: SUMMARY_MODE;
  storeId: string;
  agentId?: string | null;
}) {
  const { loading, error, data, refetch } = useGetOrderSummaryFromSummaryId(
    storeId,
    orderSummaryId
  );
  const isMdUp = useMediaQuery(`(min-width:${drawerWidth + 50}px)`);
  const navigate = useNavigate();
  const [selectedOrderId, setSelectedOrderId] = useState<string | null>(null);
  const tableViewMode = tableViewModeMapping[mode];
  console.log(mode, tableViewMode, isMdUp);

  const handleDrawerClose = () => {
    setSelectedOrderId(null);
  };

  const handleStoreOrderUploadClick = (orderId) => {
    if (isMdUp) {
      setSelectedOrderId(orderId);
    } else {
      const url = `/store/${storeId}/order/${orderId}`;
      navigate(url);
    }
  };

  const handleUploadSuccess = async () => {
    await refetch();
  };

  const hasAgentId =
    data?.orders.find((order) => order.agentId !== null && order.agentId !== undefined) !==
    undefined;

  const notHasAgentQuery = agentId === null || agentId === undefined;
  const showAgentsHeader = hasAgentId && notHasAgentQuery;
  const hideOrderSummaryHeader = !notHasAgentQuery;
  const disableBreadCrumb = !notHasAgentQuery;

  return (
    <>
      <Helmet>
        <title>{getUITitle(orderSummaryId, data)}</title>
      </Helmet>
      <Container>
        <Box my={3} />
        <NavigationHeader
          title={getUITitle(orderSummaryId, data)}
          breadCrumb={[
            {
              href: disableBreadCrumb ? undefined : `/store/${storeId}/summary`,
              title: 'สรุปออเดอร์',
            },
          ]}
        />
        {loading && !data && <Loading />}
        {data && (
          <>
            {mode !== SUMMARY_MODE.upload && !hideOrderSummaryHeader && (
              <OrderSummaryHeader data={data} />
            )}
            {mode === SUMMARY_MODE.upload && !hideOrderSummaryHeader && (
              <OrderSummaryUploadHeader data={data} />
            )}
            {showAgentsHeader && (
              <>
                <Box my={1} />
                <OrderSummaryAgentsHeader data={data} />
              </>
            )}
            <Box my={1} />
            <OrderSummaryTable
              data={data?.orders}
              showAgentColumn={hasAgentId}
              agentId={agentId}
              viewMode={tableViewModeMapping[mode]}
              onStoreOrderUploadClick={handleStoreOrderUploadClick}
            />
          </>
        )}
        <Drawer
          variant="temporary"
          open={selectedOrderId !== null}
          sx={{
            width: drawerWidth,
            maxWidth: '100vw',
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              maxWidth: '100vw',
              boxSizing: 'border-box',
            },
          }}
          anchor="right"
        >
          <Box>
            <IconButton onClick={handleDrawerClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <StoreOrderContainer
            key={selectedOrderId!}
            orderId={selectedOrderId!}
            storeId={storeId}
            onUploadSuccess={handleUploadSuccess}
          />
        </Drawer>
      </Container>
    </>
  );
}
