import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Modal } from '@mui/material';
import { Container, Button } from 'Components/Base';
import { useGetOrderSummaries, useBackofficeCreateOrderSummary } from 'Domain/Backoffice/hooks';
import Heading from 'Components/Layout/Heading';
import OrderSummariesTable from './Components/OrderSummariesTable';
import CreateOrderSummaryDialog from './Components/CreateOrderSummaryDialog';

export default function BackofficeOrderSummariesRoute() {
  const [open, setOpen] = useState(false);
  const { loading, error, data, refetch } = useGetOrderSummaries();
  const { error: createError, isLoading, createOrderSummary } = useBackofficeCreateOrderSummary();
  const handleCreateOrderSummary = () => {
    setOpen(true);
  };
  const handleSubmit = async (packageDate) => {
    await createOrderSummary(packageDate);
    await refetch();
    setOpen(false);
    if (createError) alert(createError);
  };
  return (
    <>
      <Helmet>
        <title>{`รายการสรุปออเดอร์ทั้งหมด`}</title>
      </Helmet>
      <Container>
        <Heading title={`รายการสรุปออเดอร์ทั้งหมด`} />
        {/* <Button variant="contained" onClick={handleCreateOrderSummary}>
          สร้าง Order Summary
        </Button> */}
        <OrderSummariesTable data={data} refetchData={refetch} />
        <CreateOrderSummaryDialog
          open={open}
          setOpen={setOpen}
          onSubmit={handleSubmit}
          isLoading={isLoading}
        />
      </Container>
    </>
  );
}
