import { Box, Typography } from 'Components/Base';
import BookingInfoBox from 'Components/BookingInfoBox';
import FortuneTellingSummaryCard from 'Components/FortuneTellingSummaryCard';
import { SatthaOrder } from 'Libs/Firebase/firestore/Order';
import React from 'react';

export default function FortuneTellingOrderInfo({ orderInfo }: { orderInfo: SatthaOrder }) {
  const booking = orderInfo.booking;
  return (
    <>
      {booking && (
        <Box py={2}>
          <Typography variant="h3">ลิงก์การดูดวงแบบออนไลน์</Typography>
          <Box my={2}></Box>
          <BookingInfoBox
            meetingUrl={booking.meetingUrl}
            startTime={booking.startTime}
            endTime={booking.endTime}
            showBookingTime
            storeMode
            meetTitle="เริ่มการดูดวง"
          />
        </Box>
      )}
      <FortuneTellingSummaryCard orderInfo={orderInfo} />
    </>
  );
}
