import { Container, Typography } from 'Components/Base';
import Heading from 'Components/Layout/Heading';
import { ORDER_STATUS, SatthaOrder, RefSource } from 'Libs/Firebase/firestore/Order';
import React from 'react';
import { useParams } from 'react-router-dom';
import OrderSummaryTable, { TABLE_VIEW_MODE } from './Components/OrderSummaryTable';
import { useBackofficeGetAllPendingWreathData } from 'Domain/Backoffice/hooks';
import { Helmet } from 'react-helmet';
import { List, ListItem, ListItemText } from '@mui/material';
import { BackofficeOrderSummary } from 'Domain/Backoffice/types';
import Loading from 'Components/Loading';
import { getChantModeText } from 'Domain/Sattha/Chant';
import { Paper } from '@mui/material';
import { Box } from 'Components/Base';
import { skuSummarySortFn } from 'Domain/Orders/utils';

enum SUMMARY_MODE {
  default = 'default',
  print = 'print',
  approval = 'approval',
}
interface BackofficeWreathOrdersRouterParams {
  mode?: SUMMARY_MODE;
}

const tableViewModeMapping = {
  [SUMMARY_MODE.default]: TABLE_VIEW_MODE.DEFAULT,
  [SUMMARY_MODE.print]: TABLE_VIEW_MODE.STORE_PRINT,
  [SUMMARY_MODE.approval]: TABLE_VIEW_MODE.APPROVAL,
};

export default function BackofficeWreathOrdersRoute() {
  const { mode = SUMMARY_MODE.approval } = useParams<
    keyof BackofficeWreathOrdersRouterParams
  >() as BackofficeWreathOrdersRouterParams;
  const { loading, error, data, refetch } = useBackofficeGetAllPendingWreathData();
  const tableViewMode = tableViewModeMapping[mode];
  console.log(mode, tableViewMode);

  return (
    <>
      <Helmet>
        <title>{'ออเดอร์พวงหรีด'}</title>
      </Helmet>
      <Container>
        <Box my={3} />
        <Heading title={'ออเดอร์พวงหรีด'} />
        {loading && !data && <Loading />}
        {data && (
          <>
            <Box my={1} />
            <OrderSummaryTable
              data={data ?? []}
              viewMode={tableViewModeMapping[mode]}
              refetchData={refetch}
            />
          </>
        )}
      </Container>
    </>
  );
}
