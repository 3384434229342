import { SKU_CHANT_MODE } from 'Libs/Firebase/firestore/Order';
import { getTextOfJSDocComment } from 'typescript';

export { SKU_CHANT_MODE as CHANT_MODE };

export function getChantModeText(mode?: SKU_CHANT_MODE) {
  switch (mode) {
    case SKU_CHANT_MODE.VOW:
      return 'แก้บน';
    case SKU_CHANT_MODE.WISH:
      return 'ขอพร';
    case SKU_CHANT_MODE.FIX_BADLUCK:
      return 'แก้ชง';
    case SKU_CHANT_MODE.WALLPAPER:
      return 'วอลเปเปอร์';
    case SKU_CHANT_MODE.FORTUNE_TELLING:
      return 'ดูดวง';
    case SKU_CHANT_MODE.WREATH:
      return 'พวงหรีด';
    case SKU_CHANT_MODE.MERIT:
      return 'ทำบุญ';
    default:
      return mode;
  }
}

export enum UIFORM_TEXT_FIELDS {
  HEADER_NAME,
  HEADER_NAME_ALTERNATIVE,
  LABEL_CHANT_MESSAGE,
  PLACEHOLDER_CHANT_MESSAGE,
  ERROR_NAME_REQUIRED,
  ERROR_CHANT_REQUIRED,
  ERROR_CHANT_MESSAGE_MAX_LINES,
  HEADER_SHIPPING,
  SHIPPING_NAME,
  SHIPPING_ADDRESS,
  SHIPPING_POSTCODE,
  SHIPPING_PHONENO,
  ERROR_SHIPPING_NAME,
  ERROR_SHIPPING_ADDRESS,
  ERROR_SHIPPING_POSTCODE,
  ERROR_SHIPPING_PHONENO,
  ERROR_SHIPPING_PHONENO_FORMAT,
  CONFIRM_BUTTON,
  CONFIRM_BUTTON_SHIPPING,
  SELECT_PACAKGE_HEADER,
  ERROR_SELECT_PACAKGE,
  LABEL_BIRTHDATE,
  LABEL_BIRTHMONTH,
  LABEL_BIRTHTIME,
  LABEL_BIRTHYEAR,
  LABEL_BIRTHTIME_SIGN,
  PLACEHOLDER_BIRTHDATE,
  PLACEHOLDER_BIRTHMONTH,
  PLACEHOLDER_BIRTHTIME,
  PLACEHOLDER_BIRTHYEAR,
  PLACEHOLDER_BIRTHTIME_SIGN,
  ERROR_BIRTHDAY_REQUIRED,
  ERROR_BIRTHDATE_REQUIRED,
  ERROR_BIRTHDATE_NOT_VALID,
  ERROR_BIRTHMONTH_REQUIRED,
  ERROR_BIRTHTIME_REQUIRED,
  ERROR_BIRTHYEAR_REQUIRED,
  ERROR_BIRTHTIME_SIGN_REQUIRED,
  ERROR_BIRTHTIME_NOT_IN_FORMAT,
  ERROR_BIRTHTIME_NOT_IN_TIME_FORMAT,
  ERROR_OCCUPATION_REQUIRED,
  LABEL_NAME,
  LABEL_GENDER,
  PLACEHOLDER_GENDER,
  LABEL_EMAIL,
  PLACEHOLDER_EMAIL,
  LABEL_PHONENO,
  PLACEHOLDER_PHONENO,
  LABEL_OCCUPATION,
  PLACEHOLDER_OCCUPATION,
  ERROR_GENDER_REQUIRED,
  ERROR_WALLPAPER_TOPIC_REQUIRED,
  ERROR_EMAIL_NOT_VALID,
  ERROR_PHONENO_REQUIRED,
  ERROR_PHONENO_NOT_VALID,
  LABEL_LINE_ID,
  LABEL_PHONE_MODEL,
  LABEL_WALLPAPER_COLOR,
  PLACEHOLDER_LINE_ID,
  PLACEHOLDER_PHONE_MODEL,
  PLACEHOLDER_WALLPAPER_COLOR,
  ERROR_LINE_ID_REQUIRED,
  ERROR_PHONE_MODEL_REQUIRED,
  ERROR_WALLPAPER_COLOR_REQUIRED,
  LABEL_FACEPICTURE,
  ERROR_QUESTIONS_REQUIRED,
}

export const UIFORM_TEXT_MAPPING = {
  [SKU_CHANT_MODE.WISH]: {
    [UIFORM_TEXT_FIELDS.SELECT_PACAKGE_HEADER]: 'เลือกรอบวันที่ต้องการทำพิธี',
    [UIFORM_TEXT_FIELDS.ERROR_SELECT_PACAKGE]: 'กรุณาเลือกวันขอพร',
    [UIFORM_TEXT_FIELDS.HEADER_NAME]: 'ข้อมูลผู้ขอพร',
    [UIFORM_TEXT_FIELDS.LABEL_CHANT_MESSAGE]: 'คำขอพร',
    [UIFORM_TEXT_FIELDS.PLACEHOLDER_CHANT_MESSAGE]: 'พิมพ์คำขอพรที่คุณต้องการ',
    [UIFORM_TEXT_FIELDS.ERROR_NAME_REQUIRED]: 'กรุณาใส่ชื่อนามสกุล',
    [UIFORM_TEXT_FIELDS.ERROR_CHANT_REQUIRED]: 'กรุณาใส่คำขอพร',
    [UIFORM_TEXT_FIELDS.ERROR_CHANT_MESSAGE_MAX_LINES]: 'กรุณาใส่คำขอพรไม่เกิน 3 บรรทัด',
    [UIFORM_TEXT_FIELDS.CONFIRM_BUTTON]: 'สร้างบทสวดของคุณ',
    [UIFORM_TEXT_FIELDS.HEADER_SHIPPING]: 'ข้อมูลการจัดส่งสินค้า',
    [UIFORM_TEXT_FIELDS.SHIPPING_NAME]: 'ชื่อ - นามสกุล',
    [UIFORM_TEXT_FIELDS.SHIPPING_ADDRESS]: 'ที่อยู่',
    [UIFORM_TEXT_FIELDS.SHIPPING_POSTCODE]: 'รหัสไปรษณีย์',
    [UIFORM_TEXT_FIELDS.SHIPPING_PHONENO]: 'เบอร์โทรศัพท์',
    [UIFORM_TEXT_FIELDS.ERROR_SHIPPING_NAME]: 'กรุณากรอกชื่อ',
    [UIFORM_TEXT_FIELDS.ERROR_SHIPPING_ADDRESS]: 'กรุณากรอกที่อยู่',
    [UIFORM_TEXT_FIELDS.ERROR_SHIPPING_POSTCODE]: 'กรุณากรอกรหัสไปรษณีย์เป็นตัวเลข 5 หลัก',
    [UIFORM_TEXT_FIELDS.ERROR_SHIPPING_PHONENO]: 'กรุณากรอกเบอร์โทรศัพท์สำหรับการจัดส่ง',
    [UIFORM_TEXT_FIELDS.ERROR_SHIPPING_PHONENO_FORMAT]: 'กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง',
    [UIFORM_TEXT_FIELDS.CONFIRM_BUTTON_SHIPPING]: 'ยืนยันข้อมูลและสร้างบทสวด',
    [UIFORM_TEXT_FIELDS.LABEL_BIRTHDATE]: 'วันที่เกิด',
    [UIFORM_TEXT_FIELDS.LABEL_BIRTHMONTH]: 'เดือนที่เกิด',
    [UIFORM_TEXT_FIELDS.LABEL_BIRTHYEAR]: 'ปีที่เกิด',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHDAY_REQUIRED]: 'กรุณาเลือกวันที่เกิด',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHDATE_REQUIRED]: 'กรุณาเลือกช่วงเวลาเกิด',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHDATE_NOT_VALID]: 'วันเกิดของคุณเป็นวันที่ไม่มีในเดือนนั้น',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHMONTH_REQUIRED]: 'กรุณาเลือกเดือนที่เกิด',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHYEAR_REQUIRED]: 'กรุณาเลือกปีที่เกิด',
    [UIFORM_TEXT_FIELDS.LABEL_BIRTHTIME]: 'เวลาที่เกิด (ชั่วโมง:นาที) - ถ้าไม่รู้ไม่ต้องกรอก',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_NOT_IN_FORMAT]: 'กรุณากรอกเวลาเกิดในรูปแบบตัวเลข 00:00',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_NOT_IN_TIME_FORMAT]:
      'กรุณากรอกเวลาเกิดในช่วง 00:00 ถึง 23:59',
  },
  [SKU_CHANT_MODE.VOW]: {
    [UIFORM_TEXT_FIELDS.SELECT_PACAKGE_HEADER]: 'เลือกรอบวันที่ต้องการทำพิธี',
    [UIFORM_TEXT_FIELDS.ERROR_SELECT_PACAKGE]: 'กรุณาเลือกวันแก้บน',
    [UIFORM_TEXT_FIELDS.HEADER_NAME]: 'ข้อมูลผู้แก้บน',
    [UIFORM_TEXT_FIELDS.LABEL_CHANT_MESSAGE]: 'คำแก้บน',
    [UIFORM_TEXT_FIELDS.PLACEHOLDER_CHANT_MESSAGE]: 'พิมพ์คำแก้บนที่คุณต้องการ',
    [UIFORM_TEXT_FIELDS.ERROR_NAME_REQUIRED]: 'กรุณาใส่ชื่อนามสกุล',
    [UIFORM_TEXT_FIELDS.ERROR_CHANT_REQUIRED]: 'กรุณาใส่คำแก้บน',
    [UIFORM_TEXT_FIELDS.ERROR_CHANT_MESSAGE_MAX_LINES]: 'กรุณาใส่คำแก้บนไม่เกิน 3 บรรทัด',
    [UIFORM_TEXT_FIELDS.CONFIRM_BUTTON]: 'สร้างบทสวดของคุณ',
    [UIFORM_TEXT_FIELDS.HEADER_SHIPPING]: 'ข้อมูลการจัดส่งสินค้า',
    [UIFORM_TEXT_FIELDS.SHIPPING_NAME]: 'ชื่อ - นามสกุล',
    [UIFORM_TEXT_FIELDS.SHIPPING_ADDRESS]: 'ที่อยู่',
    [UIFORM_TEXT_FIELDS.SHIPPING_POSTCODE]: 'รหัสไปรษณีย์',
    [UIFORM_TEXT_FIELDS.SHIPPING_PHONENO]: 'เบอร์โทรศัพท์',
    [UIFORM_TEXT_FIELDS.ERROR_SHIPPING_NAME]: 'กรุณากรอกชื่อ',
    [UIFORM_TEXT_FIELDS.ERROR_SHIPPING_ADDRESS]: 'กรุณากรอกที่อยู่',
    [UIFORM_TEXT_FIELDS.ERROR_SHIPPING_POSTCODE]: 'กรุณากรอกรหัสไปรษณีย์เป็นตัวเลข 5 หลัก',
    [UIFORM_TEXT_FIELDS.ERROR_SHIPPING_PHONENO]: 'กรุณากรอกเบอร์โทรศัพท์',
    [UIFORM_TEXT_FIELDS.ERROR_SHIPPING_PHONENO_FORMAT]: 'กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง',
    [UIFORM_TEXT_FIELDS.CONFIRM_BUTTON_SHIPPING]: 'ยืนยันข้อมูลและสร้างบทสวด',
    [UIFORM_TEXT_FIELDS.LABEL_BIRTHDATE]: 'วันที่เกิด',
    [UIFORM_TEXT_FIELDS.LABEL_BIRTHMONTH]: 'เดือนที่เกิด',
    [UIFORM_TEXT_FIELDS.LABEL_BIRTHYEAR]: 'ปีที่เกิด',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHDAY_REQUIRED]: 'กรุณาเลือกวันที่เกิด',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHDATE_REQUIRED]: 'กรุณาเลือกช่วงเวลาเกิด',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHDATE_NOT_VALID]: 'วันเกิดของคุณเป็นวันที่ไม่มีในเดือนนั้น',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHMONTH_REQUIRED]: 'กรุณาเลือกเดือนที่เกิด',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHYEAR_REQUIRED]: 'กรุณาเลือกปีที่เกิด',
    [UIFORM_TEXT_FIELDS.LABEL_BIRTHTIME]: 'เวลาที่เกิด (ชั่วโมง:นาที) - ถ้าไม่รู้ไม่ต้องกรอก',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_NOT_IN_FORMAT]: 'กรุณากรอกเวลาเกิดในรูปแบบตัวเลข 00:00',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_NOT_IN_TIME_FORMAT]:
      'กรุณากรอกเวลาเกิดในช่วง 00:00 ถึง 23:59',
  },
  [SKU_CHANT_MODE.FIX_BADLUCK]: {
    [UIFORM_TEXT_FIELDS.SELECT_PACAKGE_HEADER]: 'เลือกรอบวันที่ต้องการทำพิธี',
    [UIFORM_TEXT_FIELDS.ERROR_SELECT_PACAKGE]: 'กรุณาเลือกวันแก้ชง',
    [UIFORM_TEXT_FIELDS.HEADER_NAME]: 'ข้อมูลผู้แก้ชง',
    [UIFORM_TEXT_FIELDS.HEADER_NAME_ALTERNATIVE]: 'ข้อมูลผู้สะเดาเคราะห์',
    [UIFORM_TEXT_FIELDS.LABEL_CHANT_MESSAGE]: 'คำแก้ชง',
    [UIFORM_TEXT_FIELDS.PLACEHOLDER_CHANT_MESSAGE]: 'พิมพ์คำแก้ชงที่คุณต้องการ',
    [UIFORM_TEXT_FIELDS.ERROR_NAME_REQUIRED]: 'กรุณาใส่ชื่อนามสกุล',
    [UIFORM_TEXT_FIELDS.ERROR_CHANT_REQUIRED]: 'กรุณาใส่คำแก้ชง',
    [UIFORM_TEXT_FIELDS.ERROR_CHANT_MESSAGE_MAX_LINES]: 'กรุณาใส่คำแก้ชงไม่เกิน 3 บรรทัด',
    [UIFORM_TEXT_FIELDS.CONFIRM_BUTTON]: 'สร้างบทสวดของคุณ',
    [UIFORM_TEXT_FIELDS.HEADER_SHIPPING]: 'ข้อมูลการจัดส่งสินค้า',
    [UIFORM_TEXT_FIELDS.SHIPPING_NAME]: 'ชื่อ - นามสกุล',
    [UIFORM_TEXT_FIELDS.SHIPPING_ADDRESS]: 'ที่อยู่',
    [UIFORM_TEXT_FIELDS.SHIPPING_POSTCODE]: 'รหัสไปรษณีย์',
    [UIFORM_TEXT_FIELDS.SHIPPING_PHONENO]: 'เบอร์โทรศัพท์',
    [UIFORM_TEXT_FIELDS.ERROR_SHIPPING_NAME]: 'กรุณากรอกชื่อ',
    [UIFORM_TEXT_FIELDS.ERROR_SHIPPING_ADDRESS]: 'กรุณากรอกที่อยู่',
    [UIFORM_TEXT_FIELDS.ERROR_SHIPPING_POSTCODE]: 'กรุณากรอกรหัสไปรษณีย์เป็นตัวเลข 5 หลัก',
    [UIFORM_TEXT_FIELDS.ERROR_SHIPPING_PHONENO]: 'กรุณากรอกเบอร์โทรศัพท์',
    [UIFORM_TEXT_FIELDS.ERROR_SHIPPING_PHONENO_FORMAT]: 'กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง',
    [UIFORM_TEXT_FIELDS.CONFIRM_BUTTON_SHIPPING]: 'ยืนยันข้อมูลและสร้างบทสวด',
    [UIFORM_TEXT_FIELDS.LABEL_BIRTHDATE]: 'วันที่เกิด (ถ้าไม่รู้เลือก "ดี")',
    [UIFORM_TEXT_FIELDS.LABEL_BIRTHMONTH]: 'เดือนที่เกิด (ถ้าไม่รู้เลือก "ดี")',
    [UIFORM_TEXT_FIELDS.LABEL_BIRTHYEAR]: 'ปีที่เกิด (ปี 12 นักษัตร)',
    [UIFORM_TEXT_FIELDS.LABEL_BIRTHTIME]: 'เวลาเกิด (ถ้าไม่รู้กรอก "ดี")',
    [UIFORM_TEXT_FIELDS.PLACEHOLDER_BIRTHDATE]: 'เลือกวันที่เกิด',
    [UIFORM_TEXT_FIELDS.PLACEHOLDER_BIRTHMONTH]: 'เลือกเดือนที่เกิด',
    [UIFORM_TEXT_FIELDS.PLACEHOLDER_BIRTHYEAR]: 'เลือกปีพ.ศ.ที่เกิด (ปี 12 นักษัตร)',
    [UIFORM_TEXT_FIELDS.PLACEHOLDER_BIRTHTIME]: 'เช่น เกิดตอนเที่ยงคืน สองนาที กรอกว่า 00:02',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHDATE_REQUIRED]: 'กรุณากรอกวันที่เกิด',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHDATE_NOT_VALID]: 'วันเกิดของคุณเป็นวันที่ไม่มีในเดือนนั้น',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHMONTH_REQUIRED]: 'กรุณากรอกเดือนที่เกิด',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHYEAR_REQUIRED]: 'กรุณาเลือกปีที่เกิด',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_REQUIRED]: 'กรุณากรอกเวลาเกิด',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_NOT_IN_FORMAT]:
      'กรุณากรอกเวลาเกิดในรูปแบบตัวเลข 00:00 หรือคำว่า "ดี"',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_NOT_IN_TIME_FORMAT]:
      'กรุณากรอกเวลาเกิดในช่วง 00:00 ถึง 23:59',
  },
  [SKU_CHANT_MODE.WALLPAPER]: {
    [UIFORM_TEXT_FIELDS.SELECT_PACAKGE_HEADER]: 'รอบการส่งข้อมูลให้ร้าน',
    [UIFORM_TEXT_FIELDS.ERROR_SELECT_PACAKGE]:
      'ไม่มีรอบการส่งข้อมูลให้ร้านในระบบ โปรดติดต่อ hello@sattha.online',
    [UIFORM_TEXT_FIELDS.HEADER_NAME]: 'ข้อมูลทั่วไป',
    [UIFORM_TEXT_FIELDS.LABEL_NAME]: 'ชื่อ - นามสกุล (ภาษาไทย)',
    [UIFORM_TEXT_FIELDS.LABEL_GENDER]: 'เพศ',
    [UIFORM_TEXT_FIELDS.LABEL_PHONENO]: 'เบอร์โทรศัพท์',
    [UIFORM_TEXT_FIELDS.LABEL_EMAIL]: 'อีเมล (ถ้าไม่มีไม่ต้องระบุ)',
    [UIFORM_TEXT_FIELDS.PLACEHOLDER_GENDER]: 'ระบุเพศของคุณ',
    [UIFORM_TEXT_FIELDS.ERROR_NAME_REQUIRED]: 'กรุณาใส่ชื่อนามสกุล',
    [UIFORM_TEXT_FIELDS.CONFIRM_BUTTON]: 'ยืนยันข้อมูล',
    [UIFORM_TEXT_FIELDS.HEADER_SHIPPING]: 'ข้อมูลการจัดส่งสินค้า',
    [UIFORM_TEXT_FIELDS.SHIPPING_NAME]: 'ชื่อ - นามสกุล',
    [UIFORM_TEXT_FIELDS.SHIPPING_ADDRESS]: 'ที่อยู่',
    [UIFORM_TEXT_FIELDS.SHIPPING_POSTCODE]: 'รหัสไปรษณีย์',
    [UIFORM_TEXT_FIELDS.SHIPPING_PHONENO]: 'เบอร์โทรศัพท์',
    [UIFORM_TEXT_FIELDS.ERROR_SHIPPING_NAME]: 'กรุณากรอกชื่อ',
    [UIFORM_TEXT_FIELDS.ERROR_SHIPPING_ADDRESS]: 'กรุณากรอกที่อยู่',
    [UIFORM_TEXT_FIELDS.ERROR_SHIPPING_POSTCODE]: 'กรุณากรอกรหัสไปรษณีย์เป็นตัวเลข 5 หลัก',
    [UIFORM_TEXT_FIELDS.ERROR_SHIPPING_PHONENO]: 'กรุณากรอกเบอร์โทรศัพท์',
    [UIFORM_TEXT_FIELDS.ERROR_SHIPPING_PHONENO_FORMAT]: 'กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง',
    [UIFORM_TEXT_FIELDS.CONFIRM_BUTTON_SHIPPING]: 'ยืนยันข้อมูล',
    [UIFORM_TEXT_FIELDS.LABEL_BIRTHDATE]: 'วันที่เกิด',
    [UIFORM_TEXT_FIELDS.LABEL_BIRTHMONTH]: 'เดือนที่เกิด',
    [UIFORM_TEXT_FIELDS.LABEL_BIRTHYEAR]: 'ปีที่เกิด',
    [UIFORM_TEXT_FIELDS.LABEL_BIRTHTIME]: 'เวลาที่เกิด (ชั่วโมง:นาที) - ถ้าไม่รู้ไม่ต้องกรอก',
    [UIFORM_TEXT_FIELDS.LABEL_BIRTHTIME_SIGN]: 'วันและช่วงเวลาที่เกิด',
    [UIFORM_TEXT_FIELDS.LABEL_OCCUPATION]: 'อาชีพ (กรณีว่างงานให้เลือกอาชีพที่อยากทำ)',
    [UIFORM_TEXT_FIELDS.LABEL_LINE_ID]: 'LINE ID',
    [UIFORM_TEXT_FIELDS.LABEL_PHONE_MODEL]:
      'รุ่นของอุปกรณ์ - ถ้าซื้อแพคเกจที่มีมากกว่า 1 เครื่องต้องใส่ให้ครบ',
    [UIFORM_TEXT_FIELDS.LABEL_WALLPAPER_COLOR]: 'สีพื้นหลังวอลเปเปอร์',
    [UIFORM_TEXT_FIELDS.PLACEHOLDER_BIRTHDATE]: 'เลือกวันที่เกิด',
    [UIFORM_TEXT_FIELDS.PLACEHOLDER_BIRTHMONTH]: 'เลือกเดือนที่เกิด',
    [UIFORM_TEXT_FIELDS.PLACEHOLDER_BIRTHYEAR]: 'เลือกปีพ.ศ.ที่เกิด',
    [UIFORM_TEXT_FIELDS.PLACEHOLDER_BIRTHTIME]: 'เช่น เกิดตอนเที่ยงคืน สองนาที กรอกว่า 00:02',
    [UIFORM_TEXT_FIELDS.PLACEHOLDER_BIRTHTIME_SIGN]: 'เช่น เกิดตอนเที่ยงคืน สองนาที กรอกว่า 00:02',
    [UIFORM_TEXT_FIELDS.PLACEHOLDER_LINE_ID]: 'ระบุ LINE ID',
    [UIFORM_TEXT_FIELDS.PLACEHOLDER_PHONE_MODEL]: 'เช่น iPhone 6S, Galaxy Tab 6, iPad Mini 2',
    [UIFORM_TEXT_FIELDS.PLACEHOLDER_WALLPAPER_COLOR]: 'เลือกสีวอลเปเปอร์เสริมดวง',
    [UIFORM_TEXT_FIELDS.ERROR_PHONENO_REQUIRED]: 'กรุณากรอกเบอร์โทรศัพท์',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHDAY_REQUIRED]: 'กรุณาเลือกวันที่เกิด',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHDATE_REQUIRED]: 'กรุณาเลือกช่วงเวลาเกิด',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHDATE_NOT_VALID]: 'วันเกิดของคุณเป็นวันที่ไม่มีในเดือนนั้น',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHMONTH_REQUIRED]: 'กรุณาเลือกเดือนที่เกิด',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHYEAR_REQUIRED]: 'กรุณาเลือกปีที่เกิด',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_REQUIRED]: 'กรุณากรอกเวลาเกิด',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_SIGN_REQUIRED]: 'กรุณาเลือกเวลาเกิด',
    [UIFORM_TEXT_FIELDS.ERROR_GENDER_REQUIRED]: 'กรุณาเลือกเพศของคุณ',
    [UIFORM_TEXT_FIELDS.ERROR_OCCUPATION_REQUIRED]: 'กรุณาเลือกอาชีพของคุณ',
    [UIFORM_TEXT_FIELDS.ERROR_WALLPAPER_TOPIC_REQUIRED]: 'กรุณาเลือกด้านที่ต้องการเสริมดวง',
    [UIFORM_TEXT_FIELDS.ERROR_EMAIL_NOT_VALID]: 'กรุณากรอกอีเมลให้ถูกรูปแบบ',
    [UIFORM_TEXT_FIELDS.ERROR_LINE_ID_REQUIRED]: 'กรุณากรอก LINE ID',
    [UIFORM_TEXT_FIELDS.ERROR_PHONE_MODEL_REQUIRED]: 'กรุณากรอกรุ่นของอุปกรณ์ของคุณ',
    [UIFORM_TEXT_FIELDS.ERROR_WALLPAPER_COLOR_REQUIRED]: 'กรุณาเลือกสีวอลเปเปอร์',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_NOT_IN_FORMAT]: 'กรุณากรอกเวลาเกิดในรูปแบบตัวเลข 00:00',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_NOT_IN_TIME_FORMAT]:
      'กรุณากรอกเวลาเกิดในช่วง 00:00 ถึง 23:59',
  },
  [SKU_CHANT_MODE.WREATH]: {
    [UIFORM_TEXT_FIELDS.SELECT_PACAKGE_HEADER]: 'เลือกวันที่ต้องการส่งพวงหรีด',
    [UIFORM_TEXT_FIELDS.ERROR_SELECT_PACAKGE]: 'กรุณาเลือกวันส่งพวงหรีด',
    [UIFORM_TEXT_FIELDS.HEADER_NAME]: 'ข้อมูลการจัดส่ง',
    [UIFORM_TEXT_FIELDS.ERROR_NAME_REQUIRED]: 'กรุณาใส่ชื่อนามสกุล',
    [UIFORM_TEXT_FIELDS.LABEL_NAME]: 'ชื่อ - นามสกุลผู้ส่ง',
    [UIFORM_TEXT_FIELDS.LABEL_PHONENO]: 'เบอร์โทรศัพท์ผู้ส่ง',
    [UIFORM_TEXT_FIELDS.PLACEHOLDER_PHONENO]: 'กรอกเบอร์โทรศัพท์ 10 หลักไม่ต้องใส่ขีด',
    [UIFORM_TEXT_FIELDS.CONFIRM_BUTTON]: 'ยืนยันข้อมูล',
    [UIFORM_TEXT_FIELDS.ERROR_PHONENO_REQUIRED]: 'กรุณากรอกเบอร์โทรศัพท์',
    [UIFORM_TEXT_FIELDS.ERROR_PHONENO_NOT_VALID]: 'กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง',
  },
  [SKU_CHANT_MODE.FORTUNE_TELLING]: {
    [UIFORM_TEXT_FIELDS.SELECT_PACAKGE_HEADER]: 'รอบการส่งข้อมูลให้ร้าน',
    [UIFORM_TEXT_FIELDS.ERROR_SELECT_PACAKGE]:
      'ไม่มีรอบการส่งข้อมูลให้ร้านในระบบ โปรดติดต่อ hello@sattha.online',
    [UIFORM_TEXT_FIELDS.HEADER_NAME]: 'ข้อมูลทั่วไป',
    [UIFORM_TEXT_FIELDS.LABEL_NAME]: 'ชื่อ - นามสกุล (ภาษาไทย)',
    [UIFORM_TEXT_FIELDS.LABEL_GENDER]: 'เพศ',
    [UIFORM_TEXT_FIELDS.LABEL_PHONENO]: 'เบอร์โทรศัพท์ (ไม่บังคับ)',
    [UIFORM_TEXT_FIELDS.LABEL_EMAIL]: 'อีเมล (ถ้าไม่มีไม่ต้องระบุ)',
    [UIFORM_TEXT_FIELDS.PLACEHOLDER_GENDER]: 'ระบุเพศของคุณ',
    [UIFORM_TEXT_FIELDS.ERROR_NAME_REQUIRED]: 'กรุณาใส่ชื่อนามสกุล',
    [UIFORM_TEXT_FIELDS.CONFIRM_BUTTON]: 'ยืนยันข้อมูล',
    [UIFORM_TEXT_FIELDS.HEADER_SHIPPING]: 'ข้อมูลการจัดส่งสินค้า',
    [UIFORM_TEXT_FIELDS.SHIPPING_NAME]: 'ชื่อ - นามสกุล',
    [UIFORM_TEXT_FIELDS.SHIPPING_ADDRESS]: 'ที่อยู่',
    [UIFORM_TEXT_FIELDS.SHIPPING_POSTCODE]: 'รหัสไปรษณีย์',
    [UIFORM_TEXT_FIELDS.SHIPPING_PHONENO]: 'เบอร์โทรศัพท์',
    [UIFORM_TEXT_FIELDS.ERROR_SHIPPING_NAME]: 'กรุณากรอกชื่อ',
    [UIFORM_TEXT_FIELDS.ERROR_SHIPPING_ADDRESS]: 'กรุณากรอกที่อยู่',
    [UIFORM_TEXT_FIELDS.ERROR_SHIPPING_POSTCODE]: 'กรุณากรอกรหัสไปรษณีย์เป็นตัวเลข 5 หลัก',
    [UIFORM_TEXT_FIELDS.ERROR_SHIPPING_PHONENO]: 'กรุณากรอกเบอร์โทรศัพท์',
    [UIFORM_TEXT_FIELDS.ERROR_SHIPPING_PHONENO_FORMAT]: 'กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง',
    [UIFORM_TEXT_FIELDS.CONFIRM_BUTTON_SHIPPING]: 'ยืนยันข้อมูล',
    [UIFORM_TEXT_FIELDS.LABEL_BIRTHDATE]: 'วันที่เกิด',
    [UIFORM_TEXT_FIELDS.LABEL_BIRTHMONTH]: 'เดือนที่เกิด',
    [UIFORM_TEXT_FIELDS.LABEL_BIRTHYEAR]: 'ปีที่เกิด',
    [UIFORM_TEXT_FIELDS.LABEL_BIRTHTIME]: 'เวลาที่เกิด ชั่วโมง:นาที (ไม่บังคับ)',
    [UIFORM_TEXT_FIELDS.LABEL_FACEPICTURE]: 'รูปถ่ายหน้าตรง (ไม่บังคับ)',
    [UIFORM_TEXT_FIELDS.PLACEHOLDER_BIRTHDATE]: 'เลือกวันที่เกิด',
    [UIFORM_TEXT_FIELDS.PLACEHOLDER_BIRTHMONTH]: 'เลือกเดือนที่เกิด',
    [UIFORM_TEXT_FIELDS.PLACEHOLDER_BIRTHYEAR]: 'เลือกปีพ.ศ.ที่เกิด',
    [UIFORM_TEXT_FIELDS.PLACEHOLDER_BIRTHTIME]: 'เช่น เกิดบ่ายสองโมงห้านาที กรอกว่า 14:05',
    [UIFORM_TEXT_FIELDS.ERROR_PHONENO_REQUIRED]: 'กรุณากรอกเบอร์โทรศัพท์',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHDAY_REQUIRED]: 'กรุณาเลือกวันที่เกิด',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHDATE_REQUIRED]: 'กรุณาเลือกช่วงเวลาเกิด',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHDATE_NOT_VALID]: 'วันเกิดของคุณเป็นวันที่ไม่มีในเดือนนั้น',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHMONTH_REQUIRED]: 'กรุณาเลือกเดือนที่เกิด',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHYEAR_REQUIRED]: 'กรุณาเลือกปีที่เกิด',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_REQUIRED]: 'กรุณากรอกเวลาเกิด',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_SIGN_REQUIRED]: 'กรุณาเลือกเวลาเกิด',
    [UIFORM_TEXT_FIELDS.ERROR_GENDER_REQUIRED]: 'กรุณาเลือกเพศของคุณ',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_NOT_IN_FORMAT]: 'กรุณากรอกเวลาเกิดในรูปแบบตัวเลข 00:00',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_NOT_IN_TIME_FORMAT]:
      'กรุณากรอกเวลาเกิดในช่วง 00:00 ถึง 23:59',
    [UIFORM_TEXT_FIELDS.ERROR_QUESTIONS_REQUIRED]: 'กรุณากรอกคำถามให้ครบทุกข้อ',
  },
  [SKU_CHANT_MODE.FORTUNE_TELLING_LIVE]: {
    [UIFORM_TEXT_FIELDS.SELECT_PACAKGE_HEADER]: 'รอบการส่งข้อมูลให้ร้าน',
    [UIFORM_TEXT_FIELDS.ERROR_SELECT_PACAKGE]:
      'ไม่มีรอบการส่งข้อมูลให้ร้านในระบบ โปรดติดต่อ hello@sattha.online',
    [UIFORM_TEXT_FIELDS.HEADER_NAME]: 'ข้อมูลทั่วไป',
    [UIFORM_TEXT_FIELDS.LABEL_NAME]: 'ชื่อ - นามสกุล (ภาษาไทย)',
    [UIFORM_TEXT_FIELDS.LABEL_GENDER]: 'เพศ',
    [UIFORM_TEXT_FIELDS.LABEL_PHONENO]: 'เบอร์โทรศัพท์ (ไม่บังคับ)',
    [UIFORM_TEXT_FIELDS.LABEL_EMAIL]: 'อีเมล (ถ้าไม่มีไม่ต้องระบุ)',
    [UIFORM_TEXT_FIELDS.PLACEHOLDER_GENDER]: 'ระบุเพศของคุณ',
    [UIFORM_TEXT_FIELDS.ERROR_NAME_REQUIRED]: 'กรุณาใส่ชื่อนามสกุล',
    [UIFORM_TEXT_FIELDS.CONFIRM_BUTTON]: 'ยืนยันข้อมูล',
    [UIFORM_TEXT_FIELDS.HEADER_SHIPPING]: 'ข้อมูลการจัดส่งสินค้า',
    [UIFORM_TEXT_FIELDS.SHIPPING_NAME]: 'ชื่อ - นามสกุล',
    [UIFORM_TEXT_FIELDS.SHIPPING_ADDRESS]: 'ที่อยู่',
    [UIFORM_TEXT_FIELDS.SHIPPING_POSTCODE]: 'รหัสไปรษณีย์',
    [UIFORM_TEXT_FIELDS.SHIPPING_PHONENO]: 'เบอร์โทรศัพท์',
    [UIFORM_TEXT_FIELDS.ERROR_SHIPPING_NAME]: 'กรุณากรอกชื่อ',
    [UIFORM_TEXT_FIELDS.ERROR_SHIPPING_ADDRESS]: 'กรุณากรอกที่อยู่',
    [UIFORM_TEXT_FIELDS.ERROR_SHIPPING_POSTCODE]: 'กรุณากรอกรหัสไปรษณีย์เป็นตัวเลข 5 หลัก',
    [UIFORM_TEXT_FIELDS.ERROR_SHIPPING_PHONENO]: 'กรุณากรอกเบอร์โทรศัพท์',
    [UIFORM_TEXT_FIELDS.ERROR_SHIPPING_PHONENO_FORMAT]: 'กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง',
    [UIFORM_TEXT_FIELDS.CONFIRM_BUTTON_SHIPPING]: 'ยืนยันข้อมูล',
    [UIFORM_TEXT_FIELDS.LABEL_BIRTHDATE]: 'วันที่เกิด',
    [UIFORM_TEXT_FIELDS.LABEL_BIRTHMONTH]: 'เดือนที่เกิด',
    [UIFORM_TEXT_FIELDS.LABEL_BIRTHYEAR]: 'ปีที่เกิด',
    [UIFORM_TEXT_FIELDS.LABEL_BIRTHTIME]: 'เวลาที่เกิด ชั่วโมง:นาที (ไม่บังคับ)',
    [UIFORM_TEXT_FIELDS.LABEL_FACEPICTURE]: 'รูปถ่ายหน้าตรง (ไม่บังคับ)',
    [UIFORM_TEXT_FIELDS.PLACEHOLDER_BIRTHDATE]: 'เลือกวันที่เกิด',
    [UIFORM_TEXT_FIELDS.PLACEHOLDER_BIRTHMONTH]: 'เลือกเดือนที่เกิด',
    [UIFORM_TEXT_FIELDS.PLACEHOLDER_BIRTHYEAR]: 'เลือกปีพ.ศ.ที่เกิด',
    [UIFORM_TEXT_FIELDS.PLACEHOLDER_BIRTHTIME]: 'เช่น เกิดบ่ายสองโมงห้านาที กรอกว่า 14:05',
    [UIFORM_TEXT_FIELDS.ERROR_PHONENO_REQUIRED]: 'กรุณากรอกเบอร์โทรศัพท์',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHDAY_REQUIRED]: 'กรุณาเลือกวันที่เกิด',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHDATE_REQUIRED]: 'กรุณาเลือกช่วงเวลาเกิด',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHDATE_NOT_VALID]: 'วันเกิดของคุณเป็นวันที่ไม่มีในเดือนนั้น',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHMONTH_REQUIRED]: 'กรุณาเลือกเดือนที่เกิด',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHYEAR_REQUIRED]: 'กรุณาเลือกปีที่เกิด',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_REQUIRED]: 'กรุณากรอกเวลาเกิด',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_SIGN_REQUIRED]: 'กรุณาเลือกเวลาเกิด',
    [UIFORM_TEXT_FIELDS.ERROR_GENDER_REQUIRED]: 'กรุณาเลือกเพศของคุณ',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_NOT_IN_FORMAT]: 'กรุณากรอกเวลาเกิดในรูปแบบตัวเลข 00:00',
    [UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_NOT_IN_TIME_FORMAT]:
      'กรุณากรอกเวลาเกิดในช่วง 00:00 ถึง 23:59',
    [UIFORM_TEXT_FIELDS.ERROR_QUESTIONS_REQUIRED]: 'กรุณากรอกคำถามให้ครบทุกข้อ',
  },
  [SKU_CHANT_MODE.MERIT]: {
    [UIFORM_TEXT_FIELDS.SELECT_PACAKGE_HEADER]: 'เลือกรอบวันที่ต้องการทำพิธี',
    [UIFORM_TEXT_FIELDS.ERROR_SELECT_PACAKGE]: 'กรุณาเลือกรอบการทำพิธี',
    [UIFORM_TEXT_FIELDS.CONFIRM_BUTTON]: 'ยืนยันข้อมูล',
    [UIFORM_TEXT_FIELDS.CONFIRM_BUTTON_SHIPPING]: 'ยืนยันข้อมูล',
    [UIFORM_TEXT_FIELDS.HEADER_SHIPPING]: 'ข้อมูลการจัดส่งสินค้า',
    [UIFORM_TEXT_FIELDS.SHIPPING_NAME]: 'ชื่อ - นามสกุล',
    [UIFORM_TEXT_FIELDS.SHIPPING_ADDRESS]: 'ที่อยู่',
    [UIFORM_TEXT_FIELDS.SHIPPING_POSTCODE]: 'รหัสไปรษณีย์',
    [UIFORM_TEXT_FIELDS.SHIPPING_PHONENO]: 'เบอร์โทรศัพท์',
  },
};

export function getUserInfoForm(mode: SKU_CHANT_MODE = SKU_CHANT_MODE.WISH) {
  const form = UIFORM_TEXT_MAPPING[mode];
  if (form) return form;
  return UIFORM_TEXT_MAPPING[SKU_CHANT_MODE.WISH];
}

export enum TEXT_LABEL {
  CLIENT_USER_INFO_TITLE,
  CLIENT_USER_CARD_TITLE,
  UPLOADED_IMAGE_TITLE,
  UPLOADED_IMAGE_DESCRIPTION,
  UPLOADED_IMAGE_DESCRIPTION_2,
  UPLOADED_TEXT_TITLE,
}

const TEXT = {
  [SKU_CHANT_MODE.WISH]: {
    [TEXT_LABEL.CLIENT_USER_INFO_TITLE]: 'กรอกข้อมูลการขอพร',
    [TEXT_LABEL.CLIENT_USER_CARD_TITLE]: 'การขอพรของคุณ',
    [TEXT_LABEL.UPLOADED_IMAGE_TITLE]: 'หลักฐานการขอพร',
    [TEXT_LABEL.UPLOADED_IMAGE_DESCRIPTION]: 'หลักฐานการขอพรจะแสดง เมื่อร้านค้าดำเนินการเสร็จแล้ว',
    [TEXT_LABEL.UPLOADED_TEXT_TITLE]: 'คำแนะนำ',
  },
  [SKU_CHANT_MODE.VOW]: {
    [TEXT_LABEL.CLIENT_USER_INFO_TITLE]: 'กรอกข้อมูลการแก้บน',
    [TEXT_LABEL.CLIENT_USER_CARD_TITLE]: 'การแก้บนของคุณ',
    [TEXT_LABEL.UPLOADED_IMAGE_TITLE]: 'หลักฐานการแก้บน',
    [TEXT_LABEL.UPLOADED_IMAGE_DESCRIPTION]: 'หลักฐานการแก้บนจะแสดง เมื่อร้านค้าดำเนินการเสร็จแล้ว',
    [TEXT_LABEL.UPLOADED_TEXT_TITLE]: 'คำแนะนำ',
  },
  [SKU_CHANT_MODE.FIX_BADLUCK]: {
    [TEXT_LABEL.CLIENT_USER_INFO_TITLE]: 'กรอกข้อมูลการแก้ชง',
    [TEXT_LABEL.CLIENT_USER_CARD_TITLE]: 'การแก้ชงของคุณ',
    [TEXT_LABEL.UPLOADED_IMAGE_TITLE]: 'หลักฐานการแก้ชง',
    [TEXT_LABEL.UPLOADED_IMAGE_DESCRIPTION]: 'หลักฐานการแก้ชงจะแสดง เมื่อร้านค้าดำเนินการเสร็จแล้ว',
    [TEXT_LABEL.UPLOADED_TEXT_TITLE]: 'คำแนะนำ',
  },
  [SKU_CHANT_MODE.WALLPAPER]: {
    [TEXT_LABEL.CLIENT_USER_INFO_TITLE]: 'กรอกข้อมูลวอลเปเปอร์เสริมดวงของคุณ',
    [TEXT_LABEL.CLIENT_USER_CARD_TITLE]: 'วอลเปเปอร์เสริมดวงของคุณ',
    [TEXT_LABEL.UPLOADED_IMAGE_TITLE]: 'วอลเปเปอร์เสริมดวงของคุณ',
    [TEXT_LABEL.UPLOADED_IMAGE_DESCRIPTION]:
      'วอลเปเปอร์ของคุณอยู่ในคิวแล้ว คุณจะได้รับวอลเปเปอร์ภายใน 3-5 วัน เราจะแจ้งคุณไปเมื่อวอลเปเปอร์ทำเสร็จแล้ว',
    [TEXT_LABEL.UPLOADED_IMAGE_DESCRIPTION_2]:
      'คุณสามารถเข้ามาดูวอลเปเปอร์ได้ที่นี่เมื่อร้านค้าทำเสร็จเรียบร้อยแล้ว',
    [TEXT_LABEL.UPLOADED_TEXT_TITLE]: 'คำแนะนำในการใช้วอลเปเปอร์เสริมดวง',
  },
  [SKU_CHANT_MODE.WREATH]: {
    [TEXT_LABEL.CLIENT_USER_INFO_TITLE]: 'กรอกข้อมูลการจัดส่ง',
    [TEXT_LABEL.CLIENT_USER_CARD_TITLE]: 'รูปหลักฐานการส่งงาน',
    [TEXT_LABEL.UPLOADED_IMAGE_TITLE]: 'รูปหลักฐานการส่งงาน',
    [TEXT_LABEL.UPLOADED_IMAGE_DESCRIPTION]:
      'ภาพหลักฐานการส่งงานจะแสดง เมื่อร้านค้าดำเนินการเสร็จแล้ว',
    [TEXT_LABEL.UPLOADED_TEXT_TITLE]: 'คำแนะนำ',
  },
};

export function getChantText(mode: SKU_CHANT_MODE = SKU_CHANT_MODE.WISH) {
  return TEXT[mode] ?? {};
}
