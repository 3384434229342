import { useState, useEffect } from 'react';

import { getStore } from 'Libs/Firebase/firestore/Store';
import {
  FTLiveBookingListItem,
  StoreData,
  StoreOrderSummary,
  StoreOrderSummaryListItem,
} from './types';
import { SatthaOrder } from 'Libs/Firebase/firestore/Order';
import StoreAPI, {
  StoreUpdateShipmentOrderInput,
  STORE_UPDATE_ORDER_STATUS,
} from 'API/fe-functions/store/StoreAPI';
import { useQuery, useMutation } from 'react-query';

type UseStoreData = {
  loading: boolean;
  data: StoreData | null;
};

type UseStoreOrderData = {
  loading: boolean;
  data: SatthaOrder | null;
  error?: any | null;
  refetch: () => Promise<void>;
};

export function useGetStoreData(storeId?: string): UseStoreData {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<StoreData | null>(null);
  useEffect(() => {
    const loadData = async (id: string) => {
      setLoading(true);
      const data = (await getStore(id)) as StoreData;
      setLoading(false);
      setData(data);
    };
    if (storeId) loadData(storeId);
    return () => {};
  }, [storeId]);

  if (!storeId) return { loading: false, data: null };

  return { loading, data };
}

export const useStoreGetOrderDataFromStoreIdAndOrderId = (
  storeId: string,
  orderId: string
): UseStoreOrderData => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<SatthaOrder | null>(null);
  const [error, setError] = useState<any | null>(null);

  const loadData = async (storeId: string, orderId: string) => {
    try {
      setLoading(true);
      const api = new StoreAPI();
      const data = await api.getOrder(storeId, orderId);
      setLoading(false);
      setData(data);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  const refetch = async () => {
    return loadData(storeId, orderId);
  };

  useEffect(() => {
    loadData(storeId, orderId);
    return () => {};
  }, [storeId, orderId]);
  return { loading, data, error, refetch };
};

export const useStoreUpdateUploadImages = () => {
  const [error, setError] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const submitOrder = async (
    storeId: string,
    orderId: string,
    attachments: any[],
    orderState: STORE_UPDATE_ORDER_STATUS = STORE_UPDATE_ORDER_STATUS.MERCHANT_DELIVERED
  ) => {
    const api = new StoreAPI();
    try {
      setError(null);
      setIsLoading(true);
      const result = await api.submitOrder(storeId, orderId, {
        status: orderState,
        attachmentObjects: attachments,
      });
      setIsLoading(false);
      return result;
    } catch (error) {
      setError(error);
      setIsLoading(false);
      return null;
    }
  };
  return { isLoading, submitOrder, error };
};

export const useStoreReUploadOrder = () => {
  const [error, setError] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const reuploadOrder = async (storeId: string, orderId: string) => {
    const api = new StoreAPI();
    try {
      setError(null);
      setIsLoading(true);
      const result = await api.submitOrder(storeId, orderId, {
        status: STORE_UPDATE_ORDER_STATUS.MERCHANT_DELIVERING,
      });
      setIsLoading(false);
      return result;
    } catch (error) {
      setError(error);
      setIsLoading(false);
      return null;
    }
  };
  return { isLoading, reuploadOrder, error };
};

type UseStoreSummaryOrderData = {
  loading: boolean;
  data: StoreOrderSummary | null;
  error?: any | null;
  refetch: any;
};

type UseStoreSummaryOrderListData = {
  loading: boolean;
  data: StoreOrderSummaryListItem[] | null;
  error?: any | null;
  refetch: any;
};

export const useGetOrderSummaryFromSummaryId = (
  storeId: string,
  orderSummaryId: string
): UseStoreSummaryOrderData => {
  const { isLoading, error, data, refetch } = useQuery<StoreOrderSummary>(
    'storeOrderSummaryId' + orderSummaryId,
    () => {
      const api = new StoreAPI();
      return api.getOrderSummary(storeId, orderSummaryId);
    },
    { refetchOnWindowFocus: false }
  );
  return { loading: isLoading, data: data ?? null, error, refetch };
};

export const useGetOrderSummaryList = (storeId: string): UseStoreSummaryOrderListData => {
  const { isLoading, error, data, refetch } = useQuery<StoreOrderSummaryListItem[]>(
    'storeOrderSummary' + storeId,
    () => {
      const api = new StoreAPI();
      return api.getOrderSummaryList(storeId);
    },
    { refetchOnWindowFocus: false }
  );
  return { loading: isLoading, data: data ?? null, error, refetch };
};

type UseStoreWreathOrdersData = {
  loading: boolean;
  data: SatthaOrder[];
  error?: any | null;
};

export const useGetWreathOrders = (storeId: string): UseStoreWreathOrdersData => {
  const { isLoading, error, data } = useQuery<SatthaOrder[]>(
    'storeWreathOrders' + storeId,
    () => {
      const api = new StoreAPI();
      return api.getWreathOrders(storeId);
    },
    { refetchOnWindowFocus: false }
  );
  return { loading: isLoading, data: data ?? [], error };
};

export const useUpdateShipmentOrder = () => {
  const mutation = useMutation(
    (data: { storeId: string; orderId: string; shipmentData: StoreUpdateShipmentOrderInput }) => {
      const api = new StoreAPI();
      return api.updateShipmentData(data.storeId, data.orderId, data.shipmentData);
    }
  );
  return mutation;
};

type UseFTLiveBookingListData = {
  loading: boolean;
  data: FTLiveBookingListItem[] | null;
  error?: any | null;
  refetch: any;
};

export const useGetFTLiveBookingList = (
  storeId: string,
  agentId?: string
): UseFTLiveBookingListData => {
  const { isLoading, error, data, refetch } = useQuery<FTLiveBookingListItem[]>(
    'storeBookingOrders' + storeId,
    () => {
      const api = new StoreAPI();
      if (!agentId) return api.getFTLiveBookings(storeId);
      return api.getFTLiveBookingsWithAgent(storeId, agentId);
    },
    { refetchOnWindowFocus: false }
  );
  return { loading: isLoading, data: data ?? null, error, refetch };
};
