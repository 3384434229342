import { Divider, FormControl, FormHelperText, MenuItem, Select, TextField } from '@mui/material';
import { CHANT_MODE, UIFORM_TEXT_FIELDS } from 'Domain/Sattha/Chant';
import makeStyles from '@mui/styles/makeStyles';
import React, { Fragment } from 'react';
import { Field, useForm } from 'react-final-form';
import { Box, Typography } from 'Components/Base';
import { getTimeInput } from 'Domain/Sku/FixBadLuck';
import {
  availableDays,
  availableMonths,
  availableYears,
  validateAge,
  validateDate,
} from '../utils/utils';
import GenderInput from '../Input/GenderInput';
import { rmsAvailableGender } from 'Domain/Sku/Wallpaper';
import TextFieldGroup from '../Input/TextFieldGroup';
import { FieldArray } from 'react-final-form-arrays';
import { SkuFortuneTellingData } from 'Libs/Firebase/firestore/Order';
import ImageUploadBox from 'Containers/ImageUploadBox';
import { UPLOAD_MODE } from 'Containers/ImageUploadBox/ImageUploadBox';
import FTMankarinBox, { INPUT_MODE as MANKARIN_INPUT_MODE } from '../Input/FTMankarinBox';
import BirthPlaceInput from '../Input/BirthPlaceInput';

const useStyles = makeStyles((theme) => ({
  hiddenField: {
    display: 'none',
  },
}));

export function validations(
  values: any,
  FORM_TEXT,
  totalQuestions: number,
  {
    requiredPalmPicture,
    requiredBirthPlace,
  }: { requiredPalmPicture?: boolean; requiredBirthPlace?: boolean }
) {
  const errors: { [key: string]: string } = {};
  if (!values.gender) errors.gender = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_GENDER_REQUIRED];

  // Birthday validation
  const { day, month, year } = values.birthday ?? {};
  if (!day) errors['birthday.day'] = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHDAY_REQUIRED];
  if (!month) errors['birthday.month'] = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHMONTH_REQUIRED];
  if (!year) errors['birthday.year'] = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHYEAR_REQUIRED];
  if (day && month && year) {
    const isValidBirthDay = validateAge({
      day,
      month,
      year,
    });
    if (!isValidBirthDay) {
      errors['birthday.year'] =
        'ระบบไม่สามารถให้บริการบุคคลที่อายุต่ำกว่า 13 ปีได้ กรุณาติดต่อ hello@sattha.online เพื่อทำการคืนเงิน';
    }
    const isValidDate = validateDate({
      day,
      month,
      year,
    });
    if (!isValidDate) {
      errors['birthday.day'] = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHDATE_NOT_VALID];
    }
  }

  if (!values.birthTime) {
    // pass validation
    // errors.birthTime = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_REQUIRED];
  } else {
    try {
      const { hour, minute, notKnow } = getTimeInput(values.birthTime);
      if (notKnow) {
        errors.birthTime = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_NOT_IN_FORMAT];
      }
    } catch (error) {
      if (error.message === 'Not Valid Format') {
        errors.birthTime = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_NOT_IN_FORMAT];
      } else if (error.message === 'Time not in range') {
        errors.birthTime = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_NOT_IN_TIME_FORMAT];
      } else {
        errors.birthTime = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_NOT_IN_FORMAT];
      }
    }
  }

  if (requiredPalmPicture) {
    if (!values.palmPicture) {
      errors.palmPicture = 'กรุณาอัพโหลดรูปลายมือ';
    } else {
      const parsedValues = JSON.parse(values.palmPicture);
      if (!parsedValues || parsedValues.length === 0) {
        errors.palmPicture = 'กรุณาอัพโหลดรูปลายมือ';
      }
    }
  }

  if (requiredBirthPlace) {
    if (!values.birthPlace) {
      errors.birthPlace = 'กรุณากรอกจังหวัด/สถานที่เกิด';
    }
  }

  if (totalQuestions > 0) {
    if (!values.questions || values.questions.length < totalQuestions) {
      errors['questions[0]'] = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_QUESTIONS_REQUIRED];
    } else {
      const cleanQuestions = values.questions.filter(
        (q) => q !== null && q !== undefined && q.trim().length > 0
      );
      if (cleanQuestions.length < totalQuestions) {
        errors['questions[0]'] = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_QUESTIONS_REQUIRED];
      }
    }
  }

  return errors;
}

const getQuestionListMap = (totalQuestions = 1) => {
  return Array.from(Array(totalQuestions)).map((_, idx) => ({
    display: 'คำถามข้อที่ ' + (idx + 1),
    key: `questions[${idx}]`,
    field: `questions[${idx}]`,
  }));
};

export default function FortuneTellingHoroForm({
  FORM_TEXT,
  locationId,
  mode,
  totalQuestions,
  additionalInputMode,
  requiredPalmPicture,
  requiredBirthPlace,
  hideFacePicture,
  orderRealId,
}: {
  FORM_TEXT: any;
  locationId: string;
  mode: CHANT_MODE;
  totalQuestions: number;
  requiredPalmPicture?: boolean;
  requiredBirthPlace?: boolean;
  hideFacePicture?: boolean;
  additionalInputMode?: string;
  orderRealId: string;
}) {
  const classes = useStyles();
  const form = useForm();
  return (
    <>
      <Box my={1}></Box>
      <Typography variant="info">{'* ข้อมูลของท่านจะถูกใช้เพื่อการดูดวงเท่านั้น'}</Typography>
      <Box my={3}></Box>
      <FormControl fullWidth required>
        <Field
          name="chantName"
          render={(props) => (
            <>
              <Typography variant="h5">{FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_NAME]}</Typography>
              <TextField
                {...props.input}
                label=""
                variant="outlined"
                placeholder="กรอกชื่อ - นามสกุลจริง"
                fullWidth
                InputProps={{
                  startAdornment: <div />,
                }}
                inputProps={{ maxLength: 60 }}
                helperText={
                  props.meta.error && props.meta.touched ? (
                    <FormHelperText error>{props.meta.error}</FormHelperText>
                  ) : null
                }
              />
            </>
          )}
        />
      </FormControl>
      <Box my={2}></Box>
      <FormControl fullWidth required>
        <Field
          name="phoneNo"
          render={(props) => (
            <>
              <Typography variant="h5">{FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_PHONENO]}</Typography>
              <TextField
                {...props.input}
                label=""
                variant="outlined"
                placeholder={FORM_TEXT[UIFORM_TEXT_FIELDS.PLACEHOLDER_PHONENO]}
                fullWidth
                InputProps={{
                  startAdornment: <div />,
                }}
                inputProps={{ maxLength: 15 }}
                helperText={
                  props.meta.error && props.meta.touched ? (
                    <FormHelperText error>{props.meta.error}</FormHelperText>
                  ) : null
                }
              />
            </>
          )}
        />
      </FormControl>
      <Box my={2}></Box>
      <FormControl fullWidth required>
        <Field
          name="gender"
          render={(props) => (
            <>
              <Typography variant="h5">{FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_GENDER]}</Typography>
              <GenderInput
                {...props}
                placeholder={FORM_TEXT[UIFORM_TEXT_FIELDS.PLACEHOLDER_GENDER]}
                choices={rmsAvailableGender}
              />
            </>
          )}
        />
      </FormControl>
      <Box my={3}></Box>
      <FormControl fullWidth required>
        <Field
          name="birthday.day"
          render={(props) => (
            <>
              <Typography variant="h5">{FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_BIRTHDATE]}</Typography>
              <Select
                {...props.input}
                displayEmpty
                variant="outlined"
                // placeholder="กรอกชื่อ - นามสกุลจริง เพื่อใช้ในการประกอบพิธี"
                fullWidth
              >
                <MenuItem value="" disabled>
                  {FORM_TEXT[UIFORM_TEXT_FIELDS.PLACEHOLDER_BIRTHDATE]}
                </MenuItem>
                {availableDays?.map((data) => (
                  <MenuItem key={data.value} value={data.value}>
                    {data.display}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        />
      </FormControl>
      <Box my={2}></Box>
      <FormControl fullWidth>
        <Field
          name="birthday.month"
          render={(props) => (
            <>
              <Typography variant="h5">{FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_BIRTHMONTH]}</Typography>
              <Select {...props.input} displayEmpty variant="outlined" fullWidth>
                <MenuItem value="" disabled>
                  {FORM_TEXT[UIFORM_TEXT_FIELDS.PLACEHOLDER_BIRTHMONTH]}
                </MenuItem>
                {availableMonths?.map((data) => (
                  <MenuItem key={data.value} value={data.value}>
                    {data.display}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        />
      </FormControl>
      <Box my={2}></Box>
      <FormControl fullWidth>
        <Field
          name="birthday.year"
          render={(props) => (
            <>
              <Typography variant="h5">{FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_BIRTHYEAR]}</Typography>
              <Select {...props.input} displayEmpty variant="outlined" fullWidth>
                <MenuItem value="" disabled>
                  {FORM_TEXT[UIFORM_TEXT_FIELDS.PLACEHOLDER_BIRTHYEAR]}
                </MenuItem>
                {availableYears?.map((data) => (
                  <MenuItem key={data.value} value={data.value}>
                    {data.display}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        />
      </FormControl>
      <Box my={2}></Box>
      <FormControl fullWidth>
        <Field
          name="birthTime"
          render={(props) => (
            <>
              <Typography variant="h5">{FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_BIRTHTIME]}</Typography>
              <TextField
                {...props.input}
                label={''}
                variant="outlined"
                placeholder={FORM_TEXT[UIFORM_TEXT_FIELDS.PLACEHOLDER_BIRTHTIME]}
                fullWidth
                InputProps={{
                  startAdornment: <div />,
                }}
                inputProps={{ maxLength: 60 }}
                helperText={
                  props.meta.error && props.meta.touched ? (
                    <FormHelperText error>{props.meta.error}</FormHelperText>
                  ) : null
                }
              />
            </>
          )}
        />
      </FormControl>
      {requiredBirthPlace && (
        <>
          <Box my={2}></Box>
          <FormControl fullWidth>
            <Field
              name="birthPlace"
              render={(props) => (
                <>
                  <Typography variant="h5">
                    {'สถานที่เกิด (เลือกอื่นๆถ้าเกิดต่างประเทศ)'}
                  </Typography>
                  <BirthPlaceInput
                    {...props.input}
                    label={''}
                    variant="outlined"
                    placeholder={'เลือกจังหวัดที่เกิด หรือ อื่นๆ'}
                    fullWidth
                    InputProps={{
                      startAdornment: <div />,
                    }}
                    inputProps={{ maxLength: 60 }}
                    helperText={
                      props.meta.error && props.meta.touched ? (
                        <FormHelperText error>{props.meta.error}</FormHelperText>
                      ) : null
                    }
                  />
                </>
              )}
            />
          </FormControl>
        </>
      )}
      {hideFacePicture !== true && (
        <>
          <Box my={2}></Box>
          <FormControl fullWidth>
            <Field
              name="facePicture"
              render={(props) => (
                <>
                  <Typography variant="h5">
                    {FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_FACEPICTURE]}
                  </Typography>
                  <Typography variant="info">
                    {'*เพื่อเพิ่มความแม่นยำมากขึ้น ควรจะเป็นรูปที่ถ่ายไม่เกิน 3 เดือน'}
                  </Typography>
                  <Box my={1}></Box>
                  <ImageUploadBox
                    uploadActionText="อัพโหลดรูป"
                    uploadPrefix="client-order-uploads"
                    uploadKey={`client/${locationId}/${orderRealId}`}
                    onUploadFiles={(data) => {
                      form.change('facePicture', JSON.stringify(data));
                      form.blur('facePicture');
                    }}
                    mode={UPLOAD_MODE.IMAGE}
                  />
                </>
              )}
            />
          </FormControl>
        </>
      )}
      {requiredPalmPicture && (
        <>
          <Box my={2}></Box>
          <FormControl fullWidth>
            <Field
              name="palmPicture"
              render={(props) => (
                <>
                  <Typography variant="h5">{'รูปถ่ายลายมือ'}</Typography>
                  <Typography variant="info">
                    {'*กรุณาถ่ายให้เห็นลายมือให้ชัดเจนทั้งสองข้าง'}
                  </Typography>
                  <Box my={1}></Box>
                  <ImageUploadBox
                    uploadActionText="อัพโหลดรูป"
                    uploadPrefix="client-order-uploads"
                    uploadKey={`client/${locationId}/${orderRealId}`}
                    onUploadFiles={(data) => {
                      form.change('palmPicture', JSON.stringify(data));
                      form.blur('palmPicture');
                    }}
                    mode={UPLOAD_MODE.IMAGE}
                    multiple
                  />
                </>
              )}
            />
          </FormControl>
        </>
      )}
      <Box my={2}></Box>
      <Divider />
      <Box my={3}></Box>
      {totalQuestions > 0 && (
        <>
          <Typography variant="h4">{'คำถามที่ต้องการถามนักพยากรณ์'}</Typography>
          {mode === CHANT_MODE.FORTUNE_TELLING && (
            <Typography variant="info">{'* กรุณากรอกให้ครบทุกคำถาม'}</Typography>
          )}
          {mode === CHANT_MODE.FORTUNE_TELLING_LIVE && (
            <>
              <Typography variant="info">{`* กรุณากรอกให้ครบทุกคำถาม`}</Typography>
              <Typography variant="info">
                {`** หากยังไม่มีคำถามสามารถกรอก "-" ในแต่ละข้อและถามคำถามกับนักพยากรณ์ในช่วงเวลาดูดวงได้`}
              </Typography>
              <Box pb={1} />
            </>
          )}
          {getQuestionListMap(totalQuestions).map((question, index) => (
            <Fragment key={question.key}>
              <Typography variant="h5">{question.display}</Typography>
              <Box mt={1}></Box>
              <FormControl fullWidth>
                <Field
                  name={`questions[${index}]`}
                  render={(props) => {
                    return (
                      <TextField
                        {...props.input}
                        label={''}
                        variant="outlined"
                        fullWidth
                        multiline
                        placeholder={'กรอกคำถาม'}
                        InputProps={{
                          startAdornment: <div />,
                        }}
                        inputProps={{ maxLength: 5000 }}
                        helperText={
                          props.meta.error &&
                          props.meta.touched && (
                            <FormHelperText error>{props.meta.error}</FormHelperText>
                          )
                        }
                      />
                    );
                  }}
                />
              </FormControl>
              <Box my={3}></Box>
            </Fragment>
          ))}
        </>
      )}
      {additionalInputMode === MANKARIN_INPUT_MODE && (
        <>
          <Divider />
          <Box my={2}></Box>
          <FTMankarinBox />
        </>
      )}
      <Box my={1}></Box>
    </>
  );
}
