import axios, { AxiosInstance } from 'axios';

import { getHeaders } from '../api';

const API_URL =
  process.env.REACT_APP_CLIENT_API_URL ||
  'https://asia-southeast2-sattha-online-orders.cloudfunctions.net/feOrders/client';

class ClientAuthenAPI {
  private instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      baseURL: API_URL,
    });
  }

  async getCustomTokenFromLINE(accessToken: string): Promise<{ token: string }> {
    const headers = {
      'x-auth-token': accessToken,
    }; // no auth
    const result = await this.instance.post(`/authen/token/line`, null, { headers });
    return result.data;
  }
}

export default ClientAuthenAPI;
