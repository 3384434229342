import { Button, Typography } from 'Components/Base';
import { Link } from 'react-router-dom';
import { ORDER_STATUS, SatthaOrder } from 'Libs/Firebase/firestore/Order';
import MUIDataTable from 'mui-datatables';
import React from 'react';
import { generateStoreUploadURL } from 'Domain/Stores/utils';
import { generateClientMyOrderUrl } from 'Domain/Backoffice/utils';
import QRCode from 'react-qr-code';
import { ButtonGroup, TableCell, TableRow } from '@mui/material';
import { Box } from 'Components/Base';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { useBackofficeApprovalOrder } from 'Domain/Backoffice/hooks';
import { APPROVAL_TYPE } from 'API/fe-functions/backoffice/BackofficeAPI';
import { getOrderSummaryStatusText } from 'Domain/Sattha/Status';

interface OrderSummariesTableProps {
  data?: any[];
  title?: string;
  refetchData?: () => Promise<void>;
}

export default function OrderSummariesTable(props: OrderSummariesTableProps) {
  const {
    title = 'รายการสรุปออเดอร์',
    data = [],
    refetchData = () => {
      /**/
    },
  } = props;

  const columns = [
    {
      name: 'orderSummaryId',
      label: 'Order No.',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'serviceDate',
      label: 'Service Date',
      options: {
        filter: true,
        sort: true,
        sortDirection: 'asc',
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => getOrderSummaryStatusText(value),
      },
    },
    {
      name: 'locationName',
      label: 'Location',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'storeName',
      label: 'Store',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'Actions',
      label: 'Actions',
      options: {
        download: false,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const orderSummary = data[dataIndex];
          return (
            <>
              <Button
                component={Link}
                to={`/backoffice/summary/${orderSummary.orderSummaryId}/approval`}
                variant="contained"
              >
                {'หน้าสรุปออเดอร์'}
              </Button>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    pagination: false,
    selectableRows: 'none',
    elevation: 1,
    setTableProps: () => ({ size: 'small' }),
    responsive: 'standard',
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
  };

  return (
    <MUIDataTable title={title} data={data} columns={columns} options={options} size={'small'} />
  );
}
