import firebase from 'firebase/compat';
import {
  AuthProvider,
  AUTH_STATE,
  UserData,
  LoginWithEmailAndPasswordData,
  LoginWithUsernameAndPasswordData,
  UserAuthStateData,
  AUTH_ERROR_CODE,
  LoginWithCustomTokenData,
} from '../types';

import getFirebaseAuth from 'Libs/Firebase/auth';

const convertToUserData = (firebaseUser: firebase.User): UserData => {
  return {
    id: firebaseUser.uid,
    displayName: firebaseUser.displayName,
  };
};

class FirebaseAuthProvider implements AuthProvider {
  private firebaseAuthAPI: firebase.auth.Auth;
  private user: firebase.User | null = null;
  private authState: AUTH_STATE = AUTH_STATE.INITIALIZING;

  constructor(firebaseAuthAPI: firebase.auth.Auth = getFirebaseAuth()) {
    this.firebaseAuthAPI = firebaseAuthAPI;
  }

  async loginWithEmailAndPassword(loginData: LoginWithEmailAndPasswordData): Promise<any> {
    try {
      const { email, password } = loginData;
      const result = await this.firebaseAuthAPI.signInWithEmailAndPassword(email, password);
      return result;
    } catch (error) {
      console.error(error);
      if (error.code === 'auth/wrong-password') throw new Error(AUTH_ERROR_CODE.INVALID_PASSWORD);
      else throw new Error(AUTH_ERROR_CODE.OTHERS);
    }
  }

  async loginWithCustomToken(loginData: LoginWithCustomTokenData): Promise<any> {
    try {
      const { token } = loginData;
      const result = await this.firebaseAuthAPI.signInWithCustomToken(token);
      return result;
    } catch (error) {
      console.error(error);
      throw new Error(AUTH_ERROR_CODE.OTHERS);
    }
  }

  async loginWithUsername(loginData: LoginWithUsernameAndPasswordData): Promise<any> {
    throw new Error('Not Available');
  }

  async logout(): Promise<void> {
    return this.firebaseAuthAPI.signOut();
  }

  subscribeAuthState(callback: (state: AUTH_STATE, user?: UserData) => void) {
    this.firebaseAuthAPI.onAuthStateChanged((user) => {
      if (user === null) {
        this.authState = AUTH_STATE.NOT_LOGGED_IN;
        callback(AUTH_STATE.NOT_LOGGED_IN);
      } else {
        this.user = user;
        this.authState = AUTH_STATE.LOGGED_IN;
        callback(AUTH_STATE.LOGGED_IN, convertToUserData(user));
      }
    });
  }

  async getUserInfo(): Promise<UserData | null> {
    return Promise.resolve(this.user ? convertToUserData(this.user) : null);
  }

  get getAuthState(): AUTH_STATE {
    return this.authState;
  }

  async initGetUserData(): Promise<UserAuthStateData> {
    return { state: AUTH_STATE.INITIALIZING };
  }
}

export default FirebaseAuthProvider;
