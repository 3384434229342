import React from 'react';
import { Paper, Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box, Typography } from 'Components/Base';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReactMarkdown from 'react-markdown';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { SatthaOrder } from 'Libs/Firebase/firestore/Order';
import { TRACKING_PROVIDERS_REVERSE_MAPPING } from 'Domain/Shipping';

function createData(name: string, value?: any) {
  return { name, value: value ?? 'ไม่ระบุ' };
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
    border: 0,
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.selected,
    border: 0,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: '#E6E6E6',
  borderRadius: 20,
}));

const StyledCell = styled(TableCell)(({ theme }) => ({
  verticalAlign: 'baseline',
}));

function BasicTable({ rows = [] }: { rows: { name: string; value: string }[] }) {
  return (
    <TableContainer component={Card} elevation={0}>
      <Table sx={{ minWidth: 240 }} size={'small'} aria-label="simple table">
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledCell
                component="th"
                scope="row"
                align="right"
                sx={{ fontWeight: 'bold', maxWidth: 150, minWidth: 70, width: 110 }}
              >
                {row.name}
              </StyledCell>
              <StyledCell>{row.value}</StyledCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const HeaderBox = styled(Box)(({ theme }) => ({
  background: `linear-gradient(314.47deg, #01C1A8 0%, #1DCE59 90.84%)`,
}));

const getDataRows = (orderInfo: Partial<SatthaOrder>) => {
  const shippingData = orderInfo.shippingData!;
  const shipmentData = orderInfo.shippingTrackingData;
  return [
    [createData('เลขที่ออเดอร์', orderInfo.refId), createData('สินค้า', orderInfo.skuName)],
    [
      createData('ชื่อ - นามสกุล', shippingData.shippingName),
      createData('ที่อยู่', shippingData.shippingAddress),
      createData('รหัสไปรษณีย์', shippingData.shippingPostcode),
      createData('เบอร์โทรติดต่อ', shippingData.shippingPhoneNo),
      createData(
        'ข้อมูลเพิ่มเติม',
        shippingData.shippingAdditionalData ? (
          <>
            <ReactMarkdown>
              {shippingData.shippingAdditionalData.split('\n').join('  \n')}
            </ReactMarkdown>
          </>
        ) : (
          '-'
        )
      ),
      createData(
        'จัดส่งโดย',
        shipmentData?.trackingProvider
          ? TRACKING_PROVIDERS_REVERSE_MAPPING[shipmentData?.trackingProvider]
          : '-'
      ),
      createData(
        'เลขที่พัสดุ',
        shipmentData?.trackingProvider ? (
          <>
            {shipmentData.trackingCode}{' '}
            <CopyToClipboard text={shipmentData.trackingCode}>
              <span style={{ textDecoration: 'underline' }}>คัดลอก</span>
            </CopyToClipboard>
          </>
        ) : (
          '-'
        )
      ),
    ],
  ];
};

export default function ShipmentSummaryCard({ orderInfo }: { orderInfo: Partial<SatthaOrder> }) {
  const [rows1, rows2] = getDataRows(orderInfo);

  return (
    <StyledCard>
      <HeaderBox py={2} px={2}>
        <Typography variant="h3" color="white">
          สรุปข้อมูล
        </Typography>
      </HeaderBox>
      <Box py={2} px={2}>
        <Typography variant="h4" color="primary">
          ข้อมูลสินค้า
        </Typography>
      </Box>
      <BasicTable rows={rows1} />
      <Box pt={4} pb={2} px={2}>
        <Typography variant="h4" color="primary">
          ข้อมูลการจัดส่ง
        </Typography>
      </Box>
      <BasicTable rows={rows2} />
    </StyledCard>
  );
}
