import firebase from '../firebase';

const firestore = firebase.firestore();

const locationDb = firestore.collection('locations');
const storeDb = firestore.collection('stores');
const packageDb = firestore.collection('packages');

export const getLocation = async (locationId) => {
  console.log('getLocation', locationId);
  const snapshot = await locationDb.doc(locationId).get();
  if (!snapshot.exists) return null;
  return { id: locationId, ...snapshot.data() };
};

export const getStore = async (storeId) => {
  console.log('getStore', storeId);
  const snapshot = await storeDb.doc(storeId).get();
  if (!snapshot.exists) return null;
  return { id: storeId, ...snapshot.data() };
};

export const getPackagesFromStoreId = async (storeId) => {
  console.log('getPackagesFromStoreId', storeId);
  const query = packageDb.where('storeId', '==', storeId);
  const snapshot = await query.get();
  const result = [];
  snapshot.forEach(function (doc) {
    result.push({ id: doc.id, data: doc.data() });
  });
  return result;
};

export const getPackage = async (packageId) => {
  console.log('getPackage', packageId);
  const snapshot = await packageDb.doc(packageId).get();
  if (!snapshot.exists) return null;
  return { id: packageId, ...snapshot.data() };
};

export const getPackageListFromPackageId = async (packageId) => {
  const packageData = await getPackage(packageId);
  if (packageData === undefined || packageData === null) return null;

  const { storeId } = packageData;
  const packageList = await getPackagesFromStoreId(storeId);
  return packageList;
};
