import React from 'react';
import { Paper, Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box, Typography } from 'Components/Base';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import MarkdownBox from 'Components/MarkdownBox';

function createData(name: string, value?: any, hideName?: boolean, hideValue?: boolean, sx?: any) {
  return { name, value: value ?? '-', hideName, hideValue, sx };
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
    border: 0,
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#06C75533',
    border: 0,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: '#E6E6E6',
  borderRadius: 20,
}));

const StyledCell = styled(TableCell)(({ theme }) => ({
  verticalAlign: 'baseline',
}));

function BasicTable({
  rows = [],
}: {
  rows: {
    name: string;
    value: string;
    hideName?: boolean;
    hideValue?: boolean;
    sx?: any;
  }[];
}) {
  return (
    <TableContainer component={Card} elevation={0}>
      <Table sx={{ minWidth: 240 }} size={'small'} aria-label="simple table">
        <TableBody>
          {rows.map((row, index) => (
            <StyledTableRow key={row.name + index} sx={row.sx}>
              {!row.hideName && (
                <StyledCell
                  component="th"
                  colSpan={row.hideValue ? 2 : 1}
                  scope="row"
                  align={row.hideValue ? 'left' : 'right'}
                  sx={{
                    fontWeight: 'bold',
                    maxWidth: 150,
                    minWidth: 70,
                    width: 110,
                    verticalAlign: 'top',
                  }}
                >
                  {row.name}
                </StyledCell>
              )}
              {!row.hideValue && (
                <StyledCell colSpan={row.hideName ? 2 : 1}>{row.value}</StyledCell>
              )}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const HeaderBox = styled(Box)(({ theme }) => ({
  background: `linear-gradient(314.47deg, #01C1A8 0%, #1DCE59 90.84%)`,
}));

interface FortuneTellingAnswerSummaryCardProps {
  data: {
    id: string;
    question: string;
    answer: string;
  }[];
  additionalText?: string;
}

export default function FortuneTellingAnswerSummaryCard({
  data,
  additionalText,
}: FortuneTellingAnswerSummaryCardProps) {
  const rows: any[] = [];
  data?.forEach((qa, index) => {
    rows.push(createData('คำถามที่ ' + (index + 1), qa.question));
    rows.push(createData('', <MarkdownBox>{qa.answer ? qa.answer : '-'}</MarkdownBox>, true));
  });

  const rows2: any[] = [];
  if (additionalText) {
    rows.push(createData('คำแนะนำหรือหมายเหตุเพิ่มเติม', '', false, true));
    rows.push(
      createData('', <MarkdownBox>{additionalText}</MarkdownBox>, true, false, {
        backgroundColor: '#C27FF633 !important',
      })
    );
  }

  return (
    <StyledCard>
      <HeaderBox py={2} px={2}>
        <Typography variant="h3" color="white">
          คำทำนาย
        </Typography>
      </HeaderBox>
      <BasicTable rows={rows} />
      {additionalText && <BasicTable rows={rows2} />}
    </StyledCard>
  );
}
