import React, { Fragment } from 'react';
import { FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { Box, Typography } from 'Components/Base';
import { HRS_REQUEST_DISPLAY } from 'Domain/Sku/HRSWallpaper';

export function HRSRequestTypeInput({
  label,
  value,
  onBlur,
  onChange,
  placeholder,
  ...restProps
}: {
  label?: string;
  value: any;
  onBlur?: any;
  onChange?: any;
  placeholder?: any;
  [key: string]: any;
}) {
  return <Typography>{HRS_REQUEST_DISPLAY[value]}</Typography>;
}
