import { Divider } from '@mui/material';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Box, Container, Typography } from 'Components/Base';
import Heading from 'Components/Layout/Heading';
import React, { useState, useEffect } from 'react';
import BackofficeLoginForm, { UserLoginFormData } from './Components/BackofficeLoginForm';
import Loading from 'Components/Loading';

import { useAuthContext } from 'Domain/auth/hooks';
import { AUTH_ERROR_CODE, AUTH_STATE } from 'Domain/auth/types';

const mapErrorMessage = (authError?: AUTH_ERROR_CODE) => {
  if (!authError) return undefined;
  switch (authError) {
    case AUTH_ERROR_CODE.INVALID_PASSWORD:
      return 'รหัสไม่ถูกต้อง';
    default:
      return 'เกิดข้อผิดพลาด';
  }
};

interface LocationState {
  from: { pathname: string };
}

export default function BackofficeLogin() {
  const location = useLocation();
  const navigate = useNavigate();
  const { loading, authState, loginWithEmailAndPassword, authError } = useAuthContext();

  console.log(loading, authState, authError);

  const handleStoreLogin = (formValues: UserLoginFormData) => {
    const { email, password } = formValues;
    if (loginWithEmailAndPassword) loginWithEmailAndPassword({ email, password });
  };

  const errorMessage = mapErrorMessage(authError);

  useEffect(() => {
    if (authState === AUTH_STATE.LOGGED_IN) {
      navigate((location.state as LocationState)?.from || `/backoffice`);
    }
    return () => {
      //
    };
  }, [authState, location, navigate]);

  return (
    <Container>
      <Heading title="เข้าสู่ระบบ" />
      {loading && <Loading />}
      {!loading && <BackofficeLoginForm onSubmit={handleStoreLogin} errorMessage={errorMessage} />}
    </Container>
  );
}
