import { Alert, AlertTitle, FormHelperText } from '@mui/material';
import React, { Fragment } from 'react';

function checkIsError(errorValue) {
  if (Array.isArray(errorValue)) {
    return errorValue.filter(checkIsError).length !== 0;
  }
  if (typeof errorValue === 'object') return checkIsError(Object.values(errorValue));
  return errorValue !== null && errorValue !== undefined;
}

function getErrorText(errorValue) {
  if (Array.isArray(errorValue))
    return errorValue
      .filter((e) => e !== null && e !== undefined)
      .map(getErrorText)
      .join(', ');
  if (typeof errorValue === 'object') return Object.values(errorValue).map(getErrorText);
  return errorValue;
}

export default function ErrorBox({ errors = {} }: { errors?: { [key: string]: string } }) {
  const filteredValues = Object.entries(errors).filter(([field, errorText]) => errorText);
  if (filteredValues.length === 0) return null;
  return (
    <>
      <Alert severity="error">
        <AlertTitle>มีข้อมูลไม่ถูกต้อง</AlertTitle>
        {filteredValues
          .filter(([field, error]) => checkIsError(error))
          .map(([field, errorText]) => (
            <Fragment key={field}>
              {`- ${getErrorText(errorText)}`}
              <br />
            </Fragment>
          ))}
      </Alert>
    </>
  );
}
