// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from 'firebase/compat/app';
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import 'firebase/compat/analytics';

// Add the Firebase products that you want to use
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';

enum FIREBASE_PROJECT_KEYS {
  PROD_CLIENT_HORO = 'PROD_CLIENT_HORO', // prod-sattha-orders
  PROD_BACKOFFICE = 'PROD_BACKOFFICE', // prod-sattha-orders
  STAGING_CLIENT_HORO = 'STAGING_CLIENT_HORO', // sattha-onine-orders
  STAGING_BACKOFFICE = 'STAGING_BACKOFFICE', // sattha-onine-orders
}

const FIREBASE_PROJECT = process.env.REACT_APP_FIREBASE_APP;

let firebaseConfig: any;

switch (FIREBASE_PROJECT) {
  case FIREBASE_PROJECT_KEYS.PROD_BACKOFFICE:
  case FIREBASE_PROJECT_KEYS.PROD_CLIENT_HORO:
    firebaseConfig = {
      apiKey: 'AIzaSyAmdglWgkaD7cDq985ZR6Stn0lkJZ6A7Bo',
      authDomain: 'prod-sattha-orders.firebaseapp.com',
      projectId: 'prod-sattha-orders',
      storageBucket: 'prod-sattha-orders.appspot.com',
      messagingSenderId: '656687788403',
      appId: '1:656687788403:web:eecb59891cad1e19d15506',
      measurementId: 'G-LE39ZEJC8P',
    };
    break;
  case FIREBASE_PROJECT_KEYS.STAGING_BACKOFFICE:
  case FIREBASE_PROJECT_KEYS.STAGING_CLIENT_HORO:
  default:
    firebaseConfig = {
      apiKey: 'AIzaSyAhLAMt4u6xdgwLRdq6qIrlqUg4s5cjkmY',
      authDomain: 'sattha-online-orders.firebaseapp.com',
      projectId: 'sattha-online-orders',
      storageBucket: 'sattha-online-orders.appspot.com',
      messagingSenderId: '979280553122',
      appId: '1:979280553122:web:1ed5a507322a851314a3fb',
      measurementId: 'G-XKP94JQ0PD',
    };
    break;
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
