import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Modal } from '@mui/material';
import { Container, Button } from 'Components/Base';
import { useGetOrderSummaryList } from 'Domain/Stores/hooks';
import Heading from 'Components/Layout/Heading';
import OrderSummariesTable from './Components/OrderSummariesTable';
import { useParams } from 'react-router-dom';
import Loading from 'Components/Loading';

interface StoreOrderSummaryListRouterParams {
  storeId: string;
}

export default function StoreSummaryListRoute() {
  const { storeId } = useParams<
    keyof StoreOrderSummaryListRouterParams
  >() as StoreOrderSummaryListRouterParams;
  const { loading, error, data, refetch } = useGetOrderSummaryList(storeId);

  console.log(loading, error, data);

  return (
    <>
      <Helmet>
        <title>{`รายการสรุปออเดอร์ทั้งหมด`}</title>
      </Helmet>
      <Container>
        <Heading title={`รายการสรุปออเดอร์ทั้งหมด`} />
        {loading && !data && <Loading />}
        {data && <OrderSummariesTable data={data ?? []} refetchData={refetch} />}
      </Container>
    </>
  );
}
