import Box, { BoxProps } from '@mui/material/Box';

interface ImageBoxProps extends BoxProps {
  src?: string;
  alt?: string;
}

const ImageBox = (props: ImageBoxProps) => <Box component={'img'} {...props} />;

export { ImageBox };

export default Box;
