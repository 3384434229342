import { ButtonGroup, Card, CardContent, CircularProgress, ListSubheader } from '@mui/material';
import { APPROVAL_TYPE } from 'API/fe-functions/backoffice/BackofficeAPI';
import { Box, Button, Typography } from 'Components/Base';
import Heading from 'Components/Layout/Heading';
import PackageInfoForm from 'Components/PackageInfoForm';
import { useBackofficeApprovalOrder } from 'Domain/Backoffice/hooks';
import { ORDER_STATUS, SatthaOrder, SKU_CHANT_MODE } from 'Libs/Firebase/firestore/Order';
import React, { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import UpIcon from '@mui/icons-material/ArrowUpward';
import DownIcon from '@mui/icons-material/ArrowDownward';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import BackofficeStoreOrderUploadImages from '../Components/BackofficeStoreOrderUploadImages';
import WallpaperSummaryCard from 'Components/WallpaperSummaryCard';

const OrderInfoCompact = ({ order }: { order: SatthaOrder }) => (
  <Box>
    <List dense subheader={<ListSubheader>รายละเอียดออเดอร์</ListSubheader>}>
      <ListItem>
        <ListItemText secondary={'เลขการสั่งซื้อ'}>{order.refId}</ListItemText>
        <ListItemText secondary={'ชื่อลูกค้า (ต้องตรงกับในใบขอพร)'}>{order.chantName}</ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText secondary={'ชื่อแพคเกจ'}>{order.skuName}</ListItemText>
      </ListItem>
    </List>
  </Box>
);

const UpdownButton = ({
  onNextOrder,
  onPreviousOrder,
  hasNextOrder,
  hasPreviousOrder,
  loading,
}: {
  onNextOrder: () => void;
  onPreviousOrder: () => void;
  hasNextOrder: boolean;
  hasPreviousOrder: boolean;
  loading?: boolean;
}) => {
  if (loading) return <CircularProgress />;
  return (
    <ButtonGroup variant="text">
      <Button
        startIcon={<UpIcon />}
        onClick={(event) => {
          event.stopPropagation();
          onPreviousOrder();
        }}
        disabled={!hasPreviousOrder}
      />
      <Button
        startIcon={<DownIcon />}
        onClick={(event) => {
          event.stopPropagation();
          onNextOrder();
        }}
        disabled={!hasNextOrder}
      />
    </ButtonGroup>
  );
};

const ApprovalButtons = ({
  order,
  onApproveOrder,
  onRejectOrder,
  showApproveButton = true,
  loading,
  approveText = 'Approve',
  rejectText = 'Reject',
}: {
  order: SatthaOrder;
  onApproveOrder: (order) => void;
  onRejectOrder: (order) => void;
  showApproveButton?: boolean;
  loading?: boolean;
  approveText?: string;
  rejectText?: string;
}) => {
  if (loading) return <CircularProgress />;
  return (
    <ButtonGroup
      variant="outlined"
      disabled={order.status !== ORDER_STATUS.MERCHANT_DELIVERED}
      fullWidth
    >
      {showApproveButton && (
        <Button
          startIcon={<CheckIcon />}
          color="primary"
          onClick={(event) => {
            event.stopPropagation();
            onApproveOrder(order);
          }}
        >
          {approveText}
        </Button>
      )}
      <Button
        startIcon={<ClearIcon />}
        color="secondary"
        onClick={(event) => {
          event.stopPropagation();
          onRejectOrder(order);
        }}
      >
        {rejectText}
      </Button>
    </ButtonGroup>
  );
};

const getCurrentIndex = (orders: SatthaOrder[], currentOrderId) => {
  const currentPosition = orders.findIndex((order) => order.orderId === currentOrderId);
  return currentPosition;
};

const CAN_APPROVE_STAUS = [ORDER_STATUS.MERCHANT_DELIVERED];

const getNextOrder = (orders: SatthaOrder[], currentOrderId) => {
  const currentIndex = getCurrentIndex(orders, currentOrderId);
  if (currentIndex === orders.length) return null;
  let nextOrderIndex = currentIndex + 1;
  let nextOrder = orders[nextOrderIndex];
  while (nextOrder !== undefined && !CAN_APPROVE_STAUS.includes(nextOrder.status)) {
    nextOrderIndex++;
    nextOrder = orders[nextOrderIndex];
  }
  if (!nextOrder) return null;
  return { index: nextOrderIndex, order: nextOrder };
};

const getPrevOrder = (orders: SatthaOrder[], currentOrderId) => {
  const currentIndex = getCurrentIndex(orders, currentOrderId);
  if (currentIndex === 0) return null;
  let nextOrderIndex = currentIndex - 1;
  let nextOrder = orders[nextOrderIndex];
  while (nextOrder !== undefined && !CAN_APPROVE_STAUS.includes(nextOrder.status)) {
    nextOrderIndex--;
    nextOrder = orders[nextOrderIndex];
  }
  if (!nextOrder) return null;
  return { index: nextOrderIndex, order: nextOrder };
};

const canGoNext = (orders: SatthaOrder[], currentOrderId) => {
  const nextOrder = getNextOrder(orders, currentOrderId);
  console.log('nextOrder', nextOrder);
  if (nextOrder === null) return false;
  return true;
};

const canGoPrev = (orders: SatthaOrder[], currentOrderId) => {
  const prevOrder = getPrevOrder(orders, currentOrderId);
  console.log('prevOrder', prevOrder);
  if (prevOrder === null) return false;
  return true;
};

export default function BackofficeApprovalOrderBox({
  initialOrderId,
  orders,
  onOrderApproval = () => {
    //
  },
}: {
  initialOrderId: string;
  orders: SatthaOrder[];
  onOrderApproval?: () => any;
}) {
  const { approveOrder, isLoading, error } = useBackofficeApprovalOrder();
  const [selectedOrderId, setSelectedOrderId] = useState<string | null>(initialOrderId);

  const goNextOrder = (currentOrderId: string) => {
    const nextOrder = getNextOrder(orders, currentOrderId);
    if (nextOrder) setSelectedOrderId(nextOrder.order.orderId);
  };

  const goPrevOrder = (currentOrderId: string) => {
    const prevOrder = getPrevOrder(orders, currentOrderId);
    if (prevOrder) setSelectedOrderId(prevOrder.order.orderId);
  };

  const handleApproveOrder = async (order: SatthaOrder) => {
    await approveOrder(order.orderId, APPROVAL_TYPE.APPROVE);
    onOrderApproval();
    goNextOrder(order.orderId);
  };

  const handleRejectOrder = async (order) => {
    await approveOrder(order.orderId, APPROVAL_TYPE.REJECT);
    onOrderApproval();
    goNextOrder(order.orderId);
  };

  const selectedOrder = orders.find((order) => order.orderId === selectedOrderId);

  if (!selectedOrder)
    return (
      <Box display="flex" flexDirection="column" px={1}>
        <Heading title={'ตรวจงาน'} />
        <Typography>กรุณาเลือกออเดอร์</Typography>
      </Box>
    );

  const isWallpaperOrder = selectedOrder.skuMode === SKU_CHANT_MODE.WALLPAPER;

  return (
    <Box display="flex" flexDirection="column" px={1}>
      <Heading
        title={`ตรวจงาน ${selectedOrder.orderId}`}
        compact
        toolbar={
          <UpdownButton
            onNextOrder={() => {
              goNextOrder(selectedOrder.orderId);
            }}
            onPreviousOrder={() => {
              goPrevOrder(selectedOrder.orderId);
            }}
            hasNextOrder={canGoNext(orders, selectedOrder.orderId)}
            hasPreviousOrder={canGoPrev(orders, selectedOrder.orderId)}
          />
        }
      />
      <ApprovalButtons
        order={selectedOrder}
        onApproveOrder={handleApproveOrder}
        onRejectOrder={handleRejectOrder}
        loading={isLoading}
      />
      <Box my={1} />
      <OrderInfoCompact order={selectedOrder} />
      {isWallpaperOrder && <WallpaperSummaryCard orderInfo={selectedOrder} />}
      <BackofficeStoreOrderUploadImages
        orderId={selectedOrder.orderId}
        id={selectedOrder.id}
        separateByType
        asSideBar
      />
      <Box my={1} />
      <ApprovalButtons
        order={selectedOrder}
        onApproveOrder={handleApproveOrder}
        onRejectOrder={handleRejectOrder}
        loading={isLoading}
      />
      <Box my={3} />
    </Box>
  );
}
