export const TIME_VALIDATION_REGEX = /(^\s*([0-2][0-9]):([0-5][0-9])\s*$)|^\s*(ดี)\s*$/;

type ParsedTimeInput = {
  hour?: number;
  minute?: number;
  notKnow?: boolean;
};

export const getTimeInput = (timeString): ParsedTimeInput => {
  const result = TIME_VALIDATION_REGEX.exec(timeString);
  if (result === null) {
    throw new Error('Not Valid Format:' + timeString);
  } else {
    if (result[4]) {
      return { notKnow: true };
    } else if (result[2] && result[3]) {
      const hour = parseInt(result[2]);
      const minute = parseInt(result[3]);
      if (hour > 23 || hour < 0 || minute > 59 || minute < 0) {
        throw new Error('Time not in range');
      } else {
        return { hour, minute };
      }
    } else {
      throw new Error('Not Valid Format');
    }
  }
};
