import { getDisplayValue } from './utils';

export enum WALLPAPER_INPUT_TYPE {
  HOROWALL = 'HOROWALL',
  RMS = 'RMS',
  MTW = 'MTW',
  HRS = 'HRS',
}

export enum HOROWALL_REQUEST_TYPE {
  WORK = 'WORK',
  LOVE = 'LOVE',
  MONEY = 'MONEY',
  HEALTH = 'HEALTH',
  SELL_WELL = 'SELL_WELL',
  POWER = 'POWER',
  FAMOUS = 'FAMOUS',
  STUDY = 'STUDY',
  LUCKY = 'LUCKY',
  FAMILY = 'FAMILY',
  CHARMING = 'CHARMING',
  FOLLOWER = 'FOLLOWER',
  LAWSUIT = 'LAWSUIT',
}

export const convertSatthaOrderToWallpaperRequestType = (
  requestType?: string
): HOROWALL_REQUEST_TYPE | undefined => {
  if (!requestType) return undefined;
  return requestType as HOROWALL_REQUEST_TYPE;
};

export const HOROWALL_REQUEST_DISPLAY = {
  [HOROWALL_REQUEST_TYPE.WORK]: 'การงาน',
  [HOROWALL_REQUEST_TYPE.LOVE]: 'ความรัก',
  [HOROWALL_REQUEST_TYPE.MONEY]: 'การเงิน',
  [HOROWALL_REQUEST_TYPE.HEALTH]: 'สุขภาพ',
  [HOROWALL_REQUEST_TYPE.SELL_WELL]: 'ค้าขายดี',
  [HOROWALL_REQUEST_TYPE.POWER]: 'อำนาจ บารมี',
  [HOROWALL_REQUEST_TYPE.FAMOUS]: 'มีชื่อเสียง',
  [HOROWALL_REQUEST_TYPE.STUDY]: 'การเรียน',
  [HOROWALL_REQUEST_TYPE.LUCKY]: 'โชคลาภ',
  [HOROWALL_REQUEST_TYPE.FAMILY]: 'ปัญหาครอบครัว',
  [HOROWALL_REQUEST_TYPE.CHARMING]: 'มีเสน่ห์ คนรักคนหลง',
  [HOROWALL_REQUEST_TYPE.FOLLOWER]: 'บริวารดี',
  [HOROWALL_REQUEST_TYPE.LAWSUIT]: 'คดีความ',
};

export const getRequestTypeChoices = (requestType: HOROWALL_REQUEST_TYPE) => {
  const choices = HOROWALL_REQUEST_CHOICES[requestType] ?? [];
  return choices;
};

const HOROWALL_REQUEST_CHOICES = {
  [HOROWALL_REQUEST_TYPE.LOVE]: [
    { value: 'LOVE_1', display: 'เป็นโสด อยากมีคู่ครอง เจอรักแท้ เจอคนที่ใช่' },
    {
      value: 'LOVE_2',
      display: 'คนมีคู่ อยากให้รักยืนยาว ไม่ทะเลาะกัน ไม่นอกใจ มีความหวานให้กันและกัน',
    },
  ],
  [HOROWALL_REQUEST_TYPE.WORK]: [
    {
      value: 'WORK_1',
      display:
        'เลื่อนขั้น เลื่อนตำแหน่ง (ได้รับโอกาสใหม่ๆจากการทำงาน ผลงานโดดเด่น เป็นที่ประจักษร์หรือพร้อมเลื่อนขั้น เลื่อนตำแหน่ง แบบก้าวกระโดด)',
    },
    {
      value: 'WORK_2',
      display:
        'สำเร็จ สมดังหวัง (ไม่ว่าจะทำอะไรก็สำเร็จลุล่วง เป็นดังหวังทุกครั้งไป ทั้งเสนองาน ขายงาน ขายสินค้า ก็ปังแบบหยุดไม่อยู่)',
    },
    {
      value: 'WORK_3',
      display:
        'ราบรื่น ไร้ปัญหา (งานการราบรื่น ไร้ปัญหา แก้ไขปัญหาได้ดีและถูกต้อง ปัญหาที่เจอหนักกลายเป็นเบา ปัญหาเล็กๆ หายไปไม่ต้องพบเจอ)',
    },
    {
      value: 'WORK_4',
      display:
        'เป็นที่รักและที่ต้องการ (รอผลสมัครงานหรือต้องการเป็นที่รักและที่ต้องการทั้งผู้ร่วมงานและลูกค้า มีคนรักใคร่เอ็นดู ทั้งเด็ก และผู้ใหญ่ เจ้านายและลูกน้อง)',
    },
  ],
  [HOROWALL_REQUEST_TYPE.MONEY]: [
    {
      value: 'MONEY_1',
      display: 'เงินทองไหลมาเทมา เงินเหลือใช้จนไม่มีที่เก็บ คิดอะไรก็เป็นเงินเป็นทอง',
    },
  ],
  [HOROWALL_REQUEST_TYPE.HEALTH]: [
    { value: 'HEALTH_1', display: 'สุขภาพดี ไม่เจ็บ ไม่ป่วย โรคร้ายก็ทุเลาลง โรคเบาก็ไม่เจอ' },
  ],
  [HOROWALL_REQUEST_TYPE.SELL_WELL]: [
    {
      value: 'SELL_WELL_1',
      display:
        'มีเท่าไรก็ขายหมดเกลี้ยงไม่มีเหลือทั้ง ค้าขายออนไลน์ หรือมีหน้าร้าน สินค้าอะไรลงขายก็ CF รัวๆ คนทักมาซื้อก็โอนเงินไม่ CC',
    },
  ],
  [HOROWALL_REQUEST_TYPE.POWER]: [
    {
      value: 'POWER_1',
      display: 'มีแต่คนเคารพนับถือเป็นที่รักของทุกคนเป็นหัวหน้าที่ลูกน้องรักลูกน้องเกรงใจ',
    },
  ],
  [HOROWALL_REQUEST_TYPE.FAMOUS]: [
    {
      value: 'FAMOUS_1',
      display: 'เป็นที่รู้จัก โด่งดัง จะทำอะไรก็ปัง ทำอะไรก็เฮง ชื่อเสียงไปไกลกังวาลทั้วทั้งสามโลก',
    },
  ],
  [HOROWALL_REQUEST_TYPE.STUDY]: [
    {
      value: 'STUDY_1',
      display: 'การเรียนดี สอบได้ดั่งใจหวัง อาจารย์เมตตา อ่านหนังสือยังไงก็ออกสอบแบบนั้น',
    },
    { value: 'STUDY_2', display: 'ได้ไปแน่!! เรียนต่อต่างประเทศ' },
  ],
  [HOROWALL_REQUEST_TYPE.LUCKY]: [
    { value: 'LUCKY_1', display: 'มีโชคลาภเข้ามาไม่ขาดสาย จะเล่นหวย รวยเบอร์ บิทคอย ก็ปังไม่หยุด' },
  ],
  [HOROWALL_REQUEST_TYPE.FAMILY]: [
    { value: 'FAMILY_1', display: 'อยากมีลูก มีลูกที่เลี้ยงง่าย ฉลาด เป็นเด็กดี ไม่ดื้อ เชื่อฟัง' },
    {
      value: 'FAMILY_2',
      display: 'ให้พ่อแม่สุขภาพดี ไม่มีทะเลาะเบาะแว้ง ครอบครัวอบอุ่น ไม่มีปัญหา',
    },
  ],
  [HOROWALL_REQUEST_TYPE.CHARMING]: [
    {
      value: 'CHARMING_1',
      display: 'มีเสน่ห์เหลือล้น ดึงดูดทุกสายตาที่จับจ้องคนรักเอ็นดู ขออะไรได้อย่างนั้น',
    },
  ],
  [HOROWALL_REQUEST_TYPE.FOLLOWER]: [
    {
      value: 'FOLLOWER_1',
      display: 'ลูกน้องบริวารไม่สร้างปัญหา นำพาแต่โชคลาภเงินทองโอกาสดีๆมาให้',
    },
  ],
  [HOROWALL_REQUEST_TYPE.LAWSUIT]: [
    {
      value: 'LAWSUIT_1',
      display: 'คดีความคลี่คลายโดยไว ไม่เจอเส้นสาย ผ่อนหนักเป็นเบา',
    },
  ],
};

export enum WALLPAPER_GENDER {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  Q_MALE = 'Q_MALE',
  Q_FEMALE = 'Q_FEMALE',
}

export enum WALLPAPER_BIRTHDATE {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  WEDNESDAY_DAY = 'WEDNESDAY_DAY',
  WEDNESDAY_NIGHT = 'WEDNESDAY_NIGHT',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export const availableGender = [
  // { display: 'หญิง', value: WALLPAPER_GENDER.FEMALE },
  // { display: 'ชาย', value: WALLPAPER_GENDER.MALE },
  { display: 'หญิง (เพศหญิง, ดี้, เลสเบี้ยน)', value: WALLPAPER_GENDER.FEMALE },
  { display: 'ชาย (เพศชาย, Bisexual)', value: WALLPAPER_GENDER.MALE },
  { display: 'ใจอยากเป็นหญิง (สาวประเภทสอง, เกย์ออกหญิง)', value: WALLPAPER_GENDER.Q_FEMALE },
  { display: 'ใจอยากเป็นชาย (ทอม, เกย์ออกชาย)', value: WALLPAPER_GENDER.Q_MALE },
];

export const dateOfBirth = [
  {
    display: 'วันจันทร์ (เกิดจันทร์เวลา 6 โมงเช้า - อังคาร เวลา ตี 5.59 น.) ',
    value: WALLPAPER_BIRTHDATE.MONDAY,
  },
  {
    display: 'วันอังคาร (เกิดอังคารเวลา 6 โมงเช้า - พุธเวลา ตี 5.59 น.)',
    value: WALLPAPER_BIRTHDATE.TUESDAY,
  },
  {
    display: 'วันพุธ-กลางวัน (เกิดพุธเวลา 6 โมงเช้า - พุธเวลา 17.59 น.)',
    value: WALLPAPER_BIRTHDATE.WEDNESDAY_DAY,
  },
  {
    display: 'วันพุธ-กลางคืน (เกิดพุธเวลา 6 โมงเย็น - พฤหัสบดีเวลา ดี 5.59 น.)',
    value: WALLPAPER_BIRTHDATE.WEDNESDAY_NIGHT,
  },
  {
    display: 'วันพฤหัสฯ (เกิดพฤหัสบดีเวลาเวลา 6 โมงเช้า - ศุกร์เวลา ดี 5.59 น.)',
    value: WALLPAPER_BIRTHDATE.THURSDAY,
  },
  {
    display: 'วันศุกร์ (เกิดศุกร์เวลา 6 โมงเช้า - เสาร์เวลา ตี 5.59 น.)',
    value: WALLPAPER_BIRTHDATE.FRIDAY,
  },
  {
    display: 'วันเสาร์ (เกิดเสาร์เวลา 6 โมงเช้า - อาทิตย์เวลา ตี 5.59 น.)',
    value: WALLPAPER_BIRTHDATE.SATURDAY,
  },
  {
    display: 'วันอาทิตย์ (เกิดอาทิตย์เวลา 6 โมงเช้า - จันทร์เวลา ตี 5.59 น.)',
    value: WALLPAPER_BIRTHDATE.SUNDAY,
  },
];

enum WALLPAPER_OCCUPATION {
  O001 = 'O001',
  O002 = 'O002',
  O003 = 'O003',
  O004 = 'O004',
  O005 = 'O005',
  O006 = 'O006',
  O007 = 'O007',
  O008 = 'O008',
  O009 = 'O009',
  O010 = 'O010',
  O011 = 'O011',
  O012 = 'O012',
  O013 = 'O013',
  O014 = 'O014',
  O015 = 'O015',
  O016 = 'O016',
  O017 = 'O017',
  O018 = 'O018',
  O019 = 'O019',
  O020 = 'O020',
  O021 = 'O021',
  O022 = 'O022',
  O023 = 'O023',
}

export const availableOccupations = [
  { value: WALLPAPER_OCCUPATION.O001, display: '1. งานราชการ รัฐวิสาหกิจ' },
  {
    value: WALLPAPER_OCCUPATION.O002,
    display: '2. งานเซลล์ การสื่อสาร ประสานงาน การใช้ภาษา การตลาด งานบุคคล',
  },
  {
    value: WALLPAPER_OCCUPATION.O003,
    display: '3. ครู ที่ปรึกษา อาจารย์ ติวเตอร์ ล่าม การศึกษาความรู้',
  },
  { value: WALLPAPER_OCCUPATION.O004, display: '4. หมอ พยาบาล งานการแพทย์ งานด้านสุขภาพ' },
  {
    value: WALLPAPER_OCCUPATION.O005,
    display: '5. ศิลปิน ดารา นักร้อง นักแสดง อินฟลู blogger งานสื่อ นักข่าว วงการบันเทิง',
  },
  { value: WALLPAPER_OCCUPATION.O006, display: '6. งานเกี่ยวกับบัญชี การเงิน ธนาคาร' },
  {
    value: WALLPAPER_OCCUPATION.O007,
    display: '7. ธุรกิจกลุ่มอสังหาริมทรัพย์ ซื้อขายบ้าน ที่ดิน บริการที่พัก โรงแรม งานก่อสร้าง',
  },
  {
    value: WALLPAPER_OCCUPATION.O008,
    display:
      '8. งานออกแบบ กราฟริคดีไซน์ งานเบื้องหลัง Production House ถ่ายภาพงสรรค์ ไอเดีย แฟชั่น',
  },
  { value: WALLPAPER_OCCUPATION.O009, display: '9. งานครีเอทีฟ สร้างสรรค์ ไอเดีย แฟชั่น' },
  { value: WALLPAPER_OCCUPATION.O010, display: '10. งานที่เกี่ยวกับการแข่งขัน' },
  { value: WALLPAPER_OCCUPATION.O011, display: '11. ค้าขาย พ่อค้า แม่ค้า ขายของออนไลน์' },
  { value: WALLPAPER_OCCUPATION.O012, display: '12. ธุรกิจการขนส่ง' },
  { value: WALLPAPER_OCCUPATION.O013, display: '13. ธุรกิจอาหาร' },
  {
    value: WALLPAPER_OCCUPATION.O014,
    display: '14. งานด้านเทคโนโลยี โปรแกรมเมอร์ นวัตกรรม เครื่องยนต์ พลังงาน สิ่งแวดล้อม',
  },
  { value: WALLPAPER_OCCUPATION.O015, display: '15. งานด้านกฎหมาย' },
  { value: WALLPAPER_OCCUPATION.O016, display: '16. งานด้านความสวยความงาม' },
  { value: WALLPAPER_OCCUPATION.O017, display: '17. พนักงานโรงงาน และโรงงานอุตสาหกรรม' },
  { value: WALLPAPER_OCCUPATION.O018, display: '18. ธุรกิจเกี่ยวกับสัตว์' },
  { value: WALLPAPER_OCCUPATION.O019, display: '19. งานบริการ งานรับจ้างทั่วไป' },
  { value: WALLPAPER_OCCUPATION.O020, display: '20. นักลงทุน เล่นหุ้น เทรดหุ้น คริปโต บิทคอย' },
  { value: WALLPAPER_OCCUPATION.O021, display: '21. นักเรียน นักศึกษา' },
  { value: WALLPAPER_OCCUPATION.O022, display: '22. งานที่เกี่ยวกับการเกษตร' },
  { value: WALLPAPER_OCCUPATION.O023, display: '23. อยู่บ้าน ดูแลครอบครัว' },
];

export const availableMonths = [
  { display: 'มกราคม', value: 1, maxDays: 31 },
  { display: 'กุมภาพันธ์', value: 2, maxDays: 29 },
  { display: 'มีนาคม', value: 3, maxDays: 31 },
  { display: 'เมษายน', value: 4, maxDays: 30 },
  { display: 'พฤษภาคม', value: 5, maxDays: 31 },
  { display: 'มิถุนายน', value: 6, maxDays: 30 },
  { display: 'กรกฎาคม', value: 7, maxDays: 31 },
  { display: 'สิงหาคม', value: 8, maxDays: 31 },
  { display: 'กันยายน', value: 9, maxDays: 30 },
  { display: 'ตุลาคม', value: 10, maxDays: 31 },
  { display: 'พฤศจิกายน', value: 11, maxDays: 30 },
  { display: 'ธันวาคม', value: 12, maxDays: 31 },
];

export const getGenderDisplay = getDisplayValue(availableGender);
export const getMonthDisplay = getDisplayValue(availableMonths);
export const getOccupationDisplay = getDisplayValue(availableOccupations);
export const getDateOfBirthDisplay = getDisplayValue(dateOfBirth);
export const getTopicDisplay = (topic: string) =>
  HOROWALL_REQUEST_DISPLAY[topic as HOROWALL_REQUEST_TYPE] ?? 'ข้อมูลไม่ถูกต้อง';
export const getSubTopicDisplay = (topic: string, subTopic: string) => {
  const availableSubTopics = getRequestTypeChoices(topic as HOROWALL_REQUEST_TYPE);
  return getDisplayValue(availableSubTopics)(subTopic);
};
export const getYearDisplay = (year: number) => {
  if (year === undefined) return '-';
  return `${year} / ${year - 543}`;
};
export const getHorowallIncludeServiceDisplay = (includeService?: boolean) => {
  if (includeService) return '5 บริการเสริม';
  return '-';
};

// RMS Wallpaper
export enum RMS_REQUEST_TYPE {
  MONEY = 'MONEY',
  WORK = 'WORK',
  LOVE = 'LOVE',
  HEALTH = 'HEALTH',
  POWER = 'POWER',
  SOCIAL = 'SOCIAL',
  MONEY_1 = 'MONEY_1',
  SELL_WELL = 'SELL_WELL',
  WORK_POSITION = 'WORK_POSITION',
}

export const RMS_REQUEST_DISPLAY: { [key in RMS_REQUEST_TYPE]: string } = {
  [RMS_REQUEST_TYPE.MONEY]: 'โชคลาภ การเงิน',
  [RMS_REQUEST_TYPE.WORK]: 'การงาน',
  [RMS_REQUEST_TYPE.LOVE]: 'ความรัก',
  [RMS_REQUEST_TYPE.HEALTH]: 'สุขภาพ และการเรียน',
  [RMS_REQUEST_TYPE.POWER]: 'อำนาจ บารมี',
  [RMS_REQUEST_TYPE.SOCIAL]: 'เครือข่าย และการเจรจา',
  [RMS_REQUEST_TYPE.MONEY_1]: 'เสริมดวงโชคลาภการเงิน',
  [RMS_REQUEST_TYPE.SELL_WELL]: 'การค้ารุ่งเรือง ค้าขายดี',
  [RMS_REQUEST_TYPE.WORK_POSITION]: 'เลื่อนยศ เลื่อนตำแหน่ง',
};

export const rmsAvailableGender = [
  { display: 'หญิง', value: WALLPAPER_GENDER.FEMALE },
  { display: 'ชาย', value: WALLPAPER_GENDER.MALE },
];

export enum RMS_WALLPAPER_COLOR {
  NONE = 'NONE',
  RED = 'RED',
  BLACK = 'BLACK',
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
  CUSTOM = 'CUSTOM',
}

export const rmsAllWallpaperColor = [
  { display: '-', value: RMS_WALLPAPER_COLOR.NONE },
  { display: 'สีแดง', value: RMS_WALLPAPER_COLOR.RED },
  { display: 'สีดำ', value: RMS_WALLPAPER_COLOR.BLACK },
  { display: 'สีเขียว', value: RMS_WALLPAPER_COLOR.GREEN },
  { display: 'สีเหลือง', value: RMS_WALLPAPER_COLOR.YELLOW },
  { display: 'สีเสริมดวงโชคลาภการเงิน ตามพื้นฐานดวงชะตา', value: RMS_WALLPAPER_COLOR.CUSTOM },
];

export const rmsAvailableWallpaperColor = rmsAllWallpaperColor;

export const rmsAvailableMonths = [
  { display: '01', value: 1, maxDays: 31 },
  { display: '02', value: 2, maxDays: 29 },
  { display: '03', value: 3, maxDays: 31 },
  { display: '04', value: 4, maxDays: 30 },
  { display: '05', value: 5, maxDays: 31 },
  { display: '06', value: 6, maxDays: 30 },
  { display: '07', value: 7, maxDays: 31 },
  { display: '08', value: 8, maxDays: 31 },
  { display: '09', value: 9, maxDays: 30 },
  { display: '10', value: 10, maxDays: 31 },
  { display: '11', value: 11, maxDays: 30 },
  { display: '12', value: 12, maxDays: 31 },
];

export const getRMSTopicDisplay = (topic: string) =>
  RMS_REQUEST_DISPLAY[topic as RMS_REQUEST_TYPE] ?? 'ข้อมูลไม่ถูกต้อง';
export const getRMSGenderDisplay = getDisplayValue(rmsAvailableGender);
export const getRMSWallpaperColorDisplay = getDisplayValue(rmsAllWallpaperColor);
export const getBirthTimeDisplay = (birthTime?: { hour: number; minute: number }) => {
  if (birthTime === undefined || birthTime === null) return 'ไม่ทราบ';
  return birthTime.hour + ':' + String(birthTime.minute).padStart(2, '0');
};
export const getRMSBirthHourPrintDisplay = (birthTime?: { hour: number; minute: number }) => {
  if (birthTime === undefined || birthTime === null) return '';
  return String(birthTime.hour).padStart(2, '0');
};
export const getRMSBirthMinutePrintDisplay = (birthTime?: { hour: number; minute: number }) => {
  if (birthTime === undefined || birthTime === null) return '';
  return String(birthTime.minute).padStart(2, '0');
};
export const getRMSBirthtimeUnknownDisplay = (birthTime?: { hour: number; minute: number }) => {
  if (birthTime === undefined || birthTime === null) return 'ไม่ทราบ';
  return '';
};
export const getRMSMonthPrintDisplay = getDisplayValue(rmsAvailableMonths);
