import { Alert, Box } from '@mui/material';
import { Button, Typography } from 'Components/Base';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

const DEFAULT_TIMEZONE = 'Asia/Bangkok';

dayjs.extend(utc);
dayjs.extend(timezone);

const getDisplayTimeText = (startTimeIn: Dayjs, endTimeIn: Dayjs) => {
  const startTime = dayjs(startTimeIn).tz(DEFAULT_TIMEZONE);
  const endTime = dayjs(endTimeIn).tz(DEFAULT_TIMEZONE);
  return (
    startTime.locale('th').format('DD MMM') +
    ' ' +
    startTime.locale('th').format('HH:mm') +
    '-' +
    endTime.locale('th').format('HH:mm')
  );
};

interface BookingInfoBoxProps {
  meetingUrl?: string;
  startTime?: Date;
  endTime?: Date;
  // UI Props
  meetTitle?: string;
  showBookingTime?: boolean;
  storeMode?: boolean;
}

export default function BookingInfoBox({
  meetingUrl,
  meetTitle = 'คลิกเพื่อดูดวงกับนักพยากรณ์',
  startTime,
  endTime,
  showBookingTime,
  storeMode,
}: BookingInfoBoxProps) {
  return (
    <Box display="flex" flexDirection={'column'} alignItems="center">
      {!storeMode && (
        <Typography>
          {
            'คลิกลิงก์ด้านล่างเมื่อถึงวันและเวลาที่คุณเลือกไว้ หากช้าเกินกว่า 15 นาที ถือว่าท่านทำการสละสิทธิ์นี้ทันที หากติดปัญหาให้ติดต่อที่เบอร์ 096-410-6520'
          }
        </Typography>
      )}
      {storeMode && (
        <>
          <Typography sx={{ textAlign: 'center' }}>
            {`คลิกลิงก์ด้านล่างเมื่อถึงวันและเวลาที่นัด
          หากลูกค้าไม่เข้ามาใน LINE Meet ตามเวลาที่นัดหมาย
          กรุณาติดต่อแอดมิน`}
          </Typography>
          <Box pt={1}></Box>
          <Typography>กรุณากดปุ่มส่งงาน เมื่อทำการดูดวงเสร็จ</Typography>
          <Box pt={1}></Box>
        </>
      )}
      {showBookingTime && startTime && endTime && (
        <Typography sx={{ width: '100%' }} variant="subtitle">
          {'เวลาที่จอง: ' +
            getDisplayTimeText(dayjs(startTime), dayjs(endTime)) +
            ' (ตามเวลาประเทศไทย)'}
        </Typography>
      )}
      <Box py={1} />
      {meetingUrl && (
        <Button
          component="a"
          href={meetingUrl}
          variant="contained"
          color="primary"
          size="large"
          stType="gradient"
          fullWidth
          target="_blank"
        >
          {meetTitle}
        </Button>
      )}
      {!meetingUrl && (
        <Button component="a" href={meetingUrl} variant="contained" size="large" fullWidth disabled>
          {meetTitle}
        </Button>
      )}
    </Box>
  );
}
