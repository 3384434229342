import React from 'react';

import StoreOrderForm, { FORM_MODE } from 'Components/StoreOrderForm';
import Container from 'Components/Base/Container';
import { Box, Typography } from 'Components/Base';
import { Divider } from '@mui/material';
import Heading from 'Components/Layout/Heading';
import {
  useStoreGetOrderDataFromStoreIdAndOrderId,
  useStoreReUploadOrder,
  useStoreUpdateUploadImages,
} from 'Domain/Stores/hooks';
import { ORDER_STATUS, SatthaOrder, SKU_CHANT_MODE } from 'Libs/Firebase/firestore/Order';
import Loading from 'Components/Loading';
import OrderDetails from './OrderDetails';
import { CompletedBox } from './OrderDetails/Components';
import StoreOrderInfo from './StoreOrderInfo';
import StoreUploadOrder, {
  getOrderUploadFormMode,
  ORDER_UPLOAD_FORM_MODE,
} from './Containers/StoreUploadOrder';
import PackageInfoForm from 'Components/PackageInfoForm';

interface StoreOrderRouterParams {
  storeId: string;
  orderId: string;
}

export enum UPLOAD_STATUS {
  UNKNOWN,
  CANNOT_UPLOAD,
  NOT_UPLOADED,
  UPLOADED_NOT_SUBMITTED,
  UPLOADED_AND_SUBMITTED,
  UPLOADED_AND_COMPLETED,
}

const getFormMode = (order: SatthaOrder | null) => {
  if (!order) return FORM_MODE.CANNOT_UPLOAD;
  const { status } = order;

  switch (status) {
    case ORDER_STATUS.MERCHANT_ACCEPT:
    case ORDER_STATUS.MERCHANT_DELIVERING:
    case ORDER_STATUS.MERCHANT_PREPARING:
      return FORM_MODE.STORE_UPLOAD;

    case ORDER_STATUS.MERCHANT_DELIVERED:
      return FORM_MODE.STORE_UPLOAD_COMPLETE;

    case ORDER_STATUS.CLIENT_ACCEPT:
    case ORDER_STATUS.COMPLETED:
      return FORM_MODE.STORE_DONE;

    case ORDER_STATUS.CLIENT_FILLED_INFO: {
      if ([SKU_CHANT_MODE.WREATH, SKU_CHANT_MODE.FORTUNE_TELLING_LIVE].includes(order.skuMode))
        return FORM_MODE.STORE_PREPARE;
      else return FORM_MODE.CANNOT_UPLOAD;
    }
    case ORDER_STATUS.PAID:
      if ([SKU_CHANT_MODE.FORTUNE_TELLING_LIVE].includes(order.skuMode))
        return FORM_MODE.STORE_PREPARE;
      else return FORM_MODE.CANNOT_UPLOAD;
    default:
      return FORM_MODE.CANNOT_UPLOAD;
  }
};

const getUploadActionStatus = (order: SatthaOrder | null) => {
  if (!order) return UPLOAD_STATUS.UNKNOWN;
  const { status } = order;

  switch (status) {
    case ORDER_STATUS.MERCHANT_ACCEPT:
    case ORDER_STATUS.MERCHANT_PREPARING:
      return UPLOAD_STATUS.NOT_UPLOADED;

    case ORDER_STATUS.MERCHANT_DELIVERING:
      return UPLOAD_STATUS.UPLOADED_NOT_SUBMITTED;

    case ORDER_STATUS.MERCHANT_DELIVERED:
      return UPLOAD_STATUS.UPLOADED_AND_SUBMITTED;

    case ORDER_STATUS.CLIENT_ACCEPT:
    case ORDER_STATUS.COMPLETED:
    case ORDER_STATUS.MESSAGE_FAIL_DELIVERING:
      return UPLOAD_STATUS.UPLOADED_AND_COMPLETED;

    case ORDER_STATUS.CLIENT_FILLED_INFO: {
      if ([SKU_CHANT_MODE.WREATH, SKU_CHANT_MODE.FORTUNE_TELLING_LIVE].includes(order.skuMode))
        return UPLOAD_STATUS.NOT_UPLOADED;
      else return UPLOAD_STATUS.CANNOT_UPLOAD;
    }
    case ORDER_STATUS.PAID:
      if ([SKU_CHANT_MODE.FORTUNE_TELLING_LIVE].includes(order.skuMode))
        return UPLOAD_STATUS.NOT_UPLOADED;
      else return UPLOAD_STATUS.CANNOT_UPLOAD;
    default:
      return UPLOAD_STATUS.CANNOT_UPLOAD;
  }
};

export default function StoreOrderContainer({
  orderId,
  storeId,
  onUploadSuccess,
}: {
  orderId: string;
  storeId: string;
  onUploadSuccess?: () => void;
}) {
  const {
    loading,
    data: orderData,
    refetch,
  } = useStoreGetOrderDataFromStoreIdAndOrderId(storeId, orderId);
  const { reuploadOrder, isLoading, error: submitError } = useStoreReUploadOrder();
  console.log(loading, orderData);
  const mode = getFormMode(orderData);
  const uploadActionMode = getUploadActionStatus(orderData);
  console.log(mode, orderData?.status);

  const handleReUploadOrder = async () => {
    await reuploadOrder(storeId, orderId);
    await refetch();
  };

  if (loading)
    return (
      <Container maxWidth="md">
        <Heading title="อัพโหลดหลักฐาน" />
        {loading && <Loading />}
      </Container>
    );

  if (!orderData)
    return (
      <Container maxWidth="md">
        <Heading title="อัพโหลดหลักฐาน" />
        <Box my={2}></Box>
        {!orderData && <Typography variant="h6">ไม่พบรายการออเดอร์</Typography>}
        <Box my={2}></Box>
        <Typography variant="footer">
          หากพบปัญหาการใช้งานสามารถแจ้ง LINE{' '}
          <a href="https://line.me/R/ti/p/@sattha.online">@sattha.online</a>{' '}
          พร้อมแจ้งเลขที่ออเดอร์​ที่มีปัญหา{''}
          <a href="/logout">ออกจากระบบ</a>
        </Typography>
      </Container>
    );

  const showOrderInfo = [
    FORM_MODE.STORE_PREPARE,
    FORM_MODE.STORE_UPLOAD,
    FORM_MODE.STORE_UPLOAD_COMPLETE,
  ].includes(mode);

  const showCompletedBox = [
    UPLOAD_STATUS.UPLOADED_AND_SUBMITTED,
    UPLOAD_STATUS.UPLOADED_AND_COMPLETED,
  ].includes(uploadActionMode);

  const showPendingBox = [UPLOAD_STATUS.UPLOADED_NOT_SUBMITTED].includes(uploadActionMode);

  const canResendOrder =
    [UPLOAD_STATUS.UPLOADED_AND_SUBMITTED, UPLOAD_STATUS.UPLOADED_NOT_SUBMITTED].includes(
      uploadActionMode
    ) && getOrderUploadFormMode(orderData) === ORDER_UPLOAD_FORM_MODE.FULLFILLMENT;

  return (
    <Container maxWidth={'sm'}>
      {showCompletedBox && (
        <>
          <Box my={3} />
          <CompletedBox canResendOrder={canResendOrder} onResendOrder={handleReUploadOrder} />
          <Box my={3} />
        </>
      )}
      {showOrderInfo && (
        <>
          <Box my={1}></Box>
          <Heading title="ข้อมูลออเดอร์" />
          <PackageInfoForm orderInfo={orderData} hidePrice />
          <Box my={2}></Box>
          <StoreOrderInfo orderInfo={orderData} skuMode={orderData.skuMode} />
          <Box my={4}></Box>
        </>
      )}
      {mode === FORM_MODE.CANNOT_UPLOAD && (
        <>
          <Heading title="อัพโหลดหลักฐาน" />
          <Typography>ออเดอร์ยังไม่มีสิทธิ์ในการอัพโหลด</Typography>
        </>
      )}
      {mode === FORM_MODE.STORE_UPLOAD_COMPLETE && (
        <OrderDetails mode={mode} orderData={orderData} storeId={storeId} orderId={orderId} />
      )}
      {[FORM_MODE.STORE_UPLOAD, FORM_MODE.STORE_PREPARE].includes(mode) && (
        <StoreUploadOrder
          key={orderData.updatedAt?.toString() ?? 'key'}
          mode={mode}
          orderData={orderData}
          storeId={storeId}
          orderId={orderId}
          onUploadSuccess={async () => {
            await refetch();
            if (onUploadSuccess) onUploadSuccess();
          }}
        />
      )}
      {
        <Typography variant="footer">
          หากพบปัญหาการใช้งานสามารถแจ้ง LINE{' '}
          <a href="https://line.me/R/ti/p/@sattha.online">@sattha.online</a>{' '}
          พร้อมแจ้งเลขที่ออเดอร์​ที่มีปัญหา <a href="/logout">ออกจากระบบ</a>
        </Typography>
      }
    </Container>
  );
}
