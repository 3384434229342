import { FormControl, FormHelperText, MenuItem, Select, TextField } from '@mui/material';
import { CHANT_MODE, UIFORM_TEXT_FIELDS } from 'Domain/Sattha/Chant';
import React from 'react';
import { Field } from 'react-final-form';
import { Box, Typography } from 'Components/Base';
import ChineseZodiacYearInput from '../Input/ChineseZodiacYearInput';
import ChantMessageInstructions from 'Components/Locations/ChantMessageInstructions';
import { FIX_BADLUCK_MODE } from 'Domain/Orders/utils';

const availableDays = Array.from(Array(31)).map((_, i) => ({ display: `${i + 1}`, value: i + 1 }));
availableDays.push({ display: 'ดี', value: 0 });

export const availableMonths = [
  { display: 'มกราคม', value: 1, maxDays: 31 },
  { display: 'กุมภาพันธ์', value: 2, maxDays: 29 },
  { display: 'มีนาคม', value: 3, maxDays: 31 },
  { display: 'เมษายน', value: 4, maxDays: 30 },
  { display: 'พฤษภาคม', value: 5, maxDays: 31 },
  { display: 'มิถุนายน', value: 6, maxDays: 30 },
  { display: 'กรกฎาคม', value: 7, maxDays: 31 },
  { display: 'สิงหาคม', value: 8, maxDays: 31 },
  { display: 'กันยายน', value: 9, maxDays: 30 },
  { display: 'ตุลาคม', value: 10, maxDays: 31 },
  { display: 'พฤศจิกายน', value: 11, maxDays: 30 },
  { display: 'ธันวาคม', value: 12, maxDays: 31 },
  { display: 'ดี', value: 0 },
];

export default function FixBadluckForm({
  FORM_TEXT,
  locationId,
  mode,
  fixBadLuckMode,
}: {
  FORM_TEXT: any;
  locationId: string;
  mode: CHANT_MODE;
  fixBadLuckMode: FIX_BADLUCK_MODE;
}) {
  return (
    <>
      <Box my={1}></Box>
      {fixBadLuckMode === FIX_BADLUCK_MODE.ZODIAC_REQUIRED && (
        <>
          <Typography variant="info">{'* ข้อมูลของท่านจะถูกใช้เพื่อการแก้ชงเท่านั้น'}</Typography>
          <Typography variant="info">
            {'* หากท่านอายุต่ำกว่า 13 ปีเราจะไม่ขอเก็บข้อมูลอายุด้วยนโยบายความเป็นส่วนตัวของข้อมูล'}
          </Typography>
        </>
      )}
      {fixBadLuckMode === FIX_BADLUCK_MODE.NAME_REQUIRED && (
        <>
          <Typography variant="info">
            {'* ข้อมูลของท่านจะถูกใช้เพื่อการสะเดาะเคราะห์เท่านั้น'}
          </Typography>
        </>
      )}
      <Box my={3}></Box>
      <FormControl fullWidth>
        <Field
          name="chantName"
          render={(props) => (
            <>
              <Typography variant="h5">{`ชื่อ - นามสกุล`}</Typography>
              <TextField
                {...props.input}
                label=""
                variant="outlined"
                placeholder="กรอกชื่อ - นามสกุลจริง เพื่อใช้ในการประกอบพิธี"
                fullWidth
                InputProps={{
                  startAdornment: <div />,
                }}
                inputProps={{ maxLength: 60 }}
                helperText={
                  props.meta.error && props.meta.touched ? (
                    <FormHelperText error>{props.meta.error}</FormHelperText>
                  ) : (
                    'Tips: กรุณากรอกชื่อจริงเพื่อใช้ในการทำพิธี'
                  )
                }
              />
            </>
          )}
        />
      </FormControl>
      {fixBadLuckMode === FIX_BADLUCK_MODE.ZODIAC_REQUIRED && (
        <>
          <Box my={3}></Box>
          <FormControl fullWidth>
            <Field
              name="birthDate"
              render={(props) => (
                <>
                  <Typography variant="h5">
                    {FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_BIRTHDATE]}
                  </Typography>
                  <Select
                    {...props.input}
                    displayEmpty
                    variant="outlined"
                    placeholder="กรอกชื่อ - นามสกุลจริง เพื่อใช้ในการประกอบพิธี"
                    fullWidth
                  >
                    <MenuItem value="" disabled>
                      {FORM_TEXT[UIFORM_TEXT_FIELDS.PLACEHOLDER_BIRTHDATE]}
                    </MenuItem>
                    {availableDays?.map((data) => (
                      <MenuItem key={data.value} value={data.value}>
                        {data.display}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            />
          </FormControl>
          <Box my={2}></Box>
          <FormControl fullWidth>
            <Field
              name="birthMonth"
              render={(props) => (
                <>
                  <Typography variant="h5">
                    {FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_BIRTHMONTH]}
                  </Typography>
                  <Select {...props.input} displayEmpty variant="outlined" fullWidth>
                    <MenuItem value="" disabled>
                      {FORM_TEXT[UIFORM_TEXT_FIELDS.PLACEHOLDER_BIRTHMONTH]}
                    </MenuItem>
                    {availableMonths?.map((data) => (
                      <MenuItem key={data.value} value={data.value}>
                        {data.display}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            />
          </FormControl>
          <Box my={2}></Box>
          <FormControl fullWidth>
            <Field
              name="birthYear"
              render={(props) => (
                <>
                  <Typography variant="h5">
                    {FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_BIRTHYEAR]}
                  </Typography>
                  <ChineseZodiacYearInput
                    {...props.input}
                    fullWidth
                    placeholder={FORM_TEXT[UIFORM_TEXT_FIELDS.PLACEHOLDER_BIRTHYEAR]}
                  />
                </>
              )}
            />
          </FormControl>
          <Box my={2}></Box>
          <FormControl fullWidth>
            <Field
              name="birthTime"
              render={(props) => (
                <>
                  <Typography variant="h5">
                    {FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_BIRTHTIME]}
                  </Typography>
                  <TextField
                    {...props.input}
                    label={''}
                    variant="outlined"
                    placeholder={FORM_TEXT[UIFORM_TEXT_FIELDS.PLACEHOLDER_BIRTHTIME]}
                    fullWidth
                    InputProps={{
                      startAdornment: <div />,
                    }}
                    inputProps={{ maxLength: 60 }}
                    helperText={
                      props.meta.error && props.meta.touched ? (
                        <FormHelperText error>{props.meta.error}</FormHelperText>
                      ) : null
                    }
                  />
                </>
              )}
            />
          </FormControl>
        </>
      )}
      <Box my={1}></Box>
      <Box my={5}></Box>
      <ChantMessageInstructions locationId={locationId} mode={mode} />
    </>
  );
}
