import React, { FormEventHandler, SyntheticEvent, useState } from 'react';
import { Form, Field } from 'react-final-form';
import {
  Paper,
  FormControl,
  TextField,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Divider,
  FormHelperText,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Alert from '@mui/material/Alert';

import { Box, Button, Typography } from '../../Components/Base';

import ImageUploadBox from 'Containers/ImageUploadBox';
import { convertFromStorageObject, UPLOAD_MODE } from 'Containers/ImageUploadBox/ImageUploadBox';
import { Attachment, SKU_CHANT_MODE } from 'Libs/Firebase/firestore/Order';
import ErrorBox from 'Components/UserInfoForm/Components/ErrorBox';
import { StorageObject } from 'Domain/Orders/types';

const useStyles = makeStyles((theme) => ({
  hiddenField: {
    display: 'none',
  },
  gradientButton: {
    background: `linear-gradient(314.47deg, #01C1A8 0%, #1DCE59 90.84%)`,
    borderRadius: 2,
    color: 'white',
    width: '100%',
    '&:disabled': {
      color: `#969696`,
      background: '#E6E6E6',
    },
  },
}));

export function HiddenFieldForm() {
  const classes = useStyles();
  return (
    <>
      <FormControl fullWidth className={classes.hiddenField}>
        <Field
          name="orderId"
          render={(props) => (
            <TextField {...props.input} hidden className={classes.hiddenField} fullWidth />
          )}
        />
      </FormControl>
      <FormControl fullWidth className={classes.hiddenField}>
        <Field
          name="orderRefId"
          render={(props) => (
            <TextField {...props.input} hidden className={classes.hiddenField} fullWidth />
          )}
        />
      </FormControl>
      <FormControl fullWidth className={classes.hiddenField}>
        <Field
          name="packageId"
          render={(props) => (
            <TextField {...props.input} hidden className={classes.hiddenField} fullWidth />
          )}
        />
      </FormControl>
    </>
  );
}

interface UploadStorageInterface {
  filename: string;
  fileurl: string;
  ref: string;
}

export interface StoreOrderFormData {
  orderId: string;
  uploadedImagesData: UploadStorageInterface[];
  uploadedVideoData: UploadStorageInterface[];
}

export type StoreOrderFormProps = {
  onSubmit?: (formValues: StoreOrderFormData) => void;
  onSave?: (formValues: StoreOrderFormData) => void;
  orderRefId?: string;
  orderRealId: string;
  orderId: string;
  storeId: string;
  customerName?: string;
  packageId?: string;
  packageName?: string;
  skuMode: SKU_CHANT_MODE;
  mode?: FORM_MODE;
  loading?: boolean;
  error?: any;
  attachments?: {
    ['chant-images']?: Attachment;
    ['store-order-upload']?: {
      attachmentList: Attachment[];
    };
  };
};

export enum FORM_MODE {
  CANNOT_UPLOAD = 'CANNOT_UPLOAD',
  STORE_UPLOAD = 'STORE_UPLOAD',
  STORE_UPLOAD_COMPLETE = 'STORE_UPLOAD_COMPLETE',
  STORE_DONE = 'STORE_DONE',
  STORE_PREPARE = 'STORE_PREPARE',
}

export function ConfirmationActionUI(props: {
  confirmationTitle?: string;
  confirmationText?: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  children: (doConfirm: (callback: any) => (e: any) => any) => React.ReactNode;
}) {
  const {
    confirmationTitle = 'ยืนยันการส่งออเดอร์',
    confirmationText = 'โปรดตรวจสอบรูปหลักฐานให้ตรงตามชื่อผู้ทำพิธีก่อนส่งออเดอร์',
    cancelButtonText = 'ตรวจสอบอีกครั้ง',
    confirmButtonText = 'ส่งออเดอร์',
  } = props;
  const [open, setOpen] = useState(false);
  const [callback, setCallback] = useState<any>({
    c: () => {
      /** */
    },
  });
  const handleClose = () => setOpen(false);
  const handleConfirmation = (callback) => (event) => {
    event.preventDefault();
    event = {
      ...event,
      target: { ...event.target, value: event.target.value },
    };

    setCallback({ c: () => callback(event) });
    setOpen(true);
  };
  const handleConfirm = () => {
    setOpen(false);
    if (callback?.c) callback.c();
  };
  return (
    <>
      {props.children(handleConfirmation)}
      <Dialog open={open} fullWidth maxWidth={'sm'}>
        <DialogTitle>{confirmationTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{confirmationText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{cancelButtonText}</Button>
          <Button
            onClick={handleConfirm}
            variant="contained"
            color="primary"
            type="submit"
            size="large"
          >
            {confirmButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const parseAttachments = (attachments?: {
  ['chant-images']?: Attachment;
  ['store-order-upload']?: {
    attachmentList: Attachment[];
  };
}) => {
  if (!attachments || !attachments['store-order-upload'])
    return {
      uploadedImagesData: undefined,
      uploadedVideoData: undefined,
      uploadedTextData: undefined,
    };
  const storeOrderUploads = attachments['store-order-upload']?.attachmentList ?? [];
  console.log(storeOrderUploads);
  const imageData = storeOrderUploads
    .filter((u) => u.type === 'IMAGE')
    .map((u) => convertFromStorageObject(u as StorageObject));
  const videoData = storeOrderUploads
    .filter((u) => u.type === 'VIDEO')
    .map((u) => convertFromStorageObject(u as StorageObject));
  return {
    uploadedImagesData: JSON.stringify(imageData),
    uploadedVideoData: JSON.stringify(videoData),
    uploadedTextData: undefined,
  };
};

export default function StoreOrderForm(props: StoreOrderFormProps) {
  const classes = useStyles();

  const {
    orderRealId,
    orderId,
    orderRefId,
    storeId,
    onSubmit = () => {
      //
    },
    onSave = () => {
      //
    },
    customerName,
    packageId,
    packageName,
    skuMode,
    mode = FORM_MODE.STORE_UPLOAD,
    attachments,
    loading = false,
    error,
  } = props;

  const disableEditing = [
    FORM_MODE.CANNOT_UPLOAD,
    FORM_MODE.STORE_UPLOAD,
    FORM_MODE.STORE_UPLOAD_COMPLETE,
  ].includes(mode);

  const disableSubmitButton =
    loading || [FORM_MODE.CANNOT_UPLOAD, FORM_MODE.STORE_UPLOAD_COMPLETE].includes(mode);

  const handleSubmit = (inValues) => {
    const { uploadedImagesData, uploadedVideoData, ...restValues } = inValues;
    const values = { ...restValues };
    values.uploadedImagesData = JSON.parse(uploadedImagesData);
    values.uploadedVideoData = JSON.parse(uploadedVideoData || '[]');
    onSubmit(values);
  };

  const handleSave = (inValues) => {
    const { uploadedImagesData, uploadedVideoData, ...restValues } = inValues;
    const values = { ...restValues };
    values.uploadedImagesData = JSON.parse(uploadedImagesData);
    values.uploadedVideoData = JSON.parse(uploadedVideoData || '[]');
    onSave(values);
  };

  const showTextFieldForm = [SKU_CHANT_MODE.WALLPAPER].includes(skuMode);
  const showVideoUploadForm = [
    SKU_CHANT_MODE.WISH,
    SKU_CHANT_MODE.VOW,
    SKU_CHANT_MODE.FIX_BADLUCK,
    SKU_CHANT_MODE.MERIT,
  ].includes(skuMode);

  const { uploadedImagesData, uploadedVideoData, uploadedTextData } = parseAttachments(attachments);

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          orderId,
          orderRefId,
          customerName,
          packageId,
          packageName,
          uploadedImagesData,
          uploadedVideoData,
          uploadedTextData,
        }}
        validateOnBlur
        validate={(values) => {
          const errors: { [k: string]: any } = {};
          if (!values.uploadedImagesData) {
            errors['uploadedImagesData'] = 'กรุณาอัพโหลดรูป';
          } else {
            const value = JSON.parse(values.uploadedImagesData);
            if (value.length === 0) {
              errors['uploadedImagesData'] = 'กรุณาอัพโหลดรูป';
            }
          }
          console.log('validations', values, errors);
          return errors;
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => {
          console.log(values);
          return (
            <ConfirmationActionUI>
              {(doConfirm) => (
                <form onSubmit={doConfirm(handleSubmit)}>
                  <HiddenFieldForm />
                  <Paper elevation={0} style={{ padding: 16 }}>
                    <FormControl fullWidth disabled={disableEditing}>
                      <Field
                        name="orderId"
                        render={(props) => (
                          <TextField
                            {...props.input}
                            label="Order NO."
                            variant="outlined"
                            fullWidth
                            InputProps={{
                              startAdornment: <div />,
                            }}
                            inputProps={{
                              disabled: disableEditing,
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <Box my={3}></Box>
                    <FormControl fullWidth disabled={disableEditing}>
                      <Field
                        name="customerName"
                        render={(props) => (
                          <TextField
                            {...props.input}
                            label="ชื่อลูกค้า"
                            variant="outlined"
                            fullWidth
                            InputProps={{
                              startAdornment: <div />,
                            }}
                            inputProps={{
                              disabled: disableEditing,
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <Box my={3}></Box>
                    <FormControl fullWidth disabled={disableEditing}>
                      <Field
                        name="packageName"
                        render={(props) => (
                          <TextField
                            {...props.input}
                            label="แพคเกจ"
                            variant="outlined"
                            fullWidth
                            InputProps={{
                              startAdornment: <div />,
                            }}
                            inputProps={{
                              disabled: disableEditing,
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <Box my={3}></Box>
                    <FormControl fullWidth disabled={disableEditing}>
                      <Field
                        name="uploadedImagesData"
                        render={(props) => {
                          console.log(props.input.value);
                          return (
                            <ImageUploadBox
                              value={props.input.value}
                              uploadActionText="อัพโหลดรูปการดำเนินการ"
                              uploadPrefix="store-order-uploads"
                              uploadKey={`store/${storeId}/${orderRealId}`}
                              onUploadFiles={(data) => {
                                form.change('uploadedImagesData', JSON.stringify(data));
                                form.blur('uploadedImagesData');
                              }}
                              disabled={loading}
                              mode={UPLOAD_MODE.IMAGE}
                              multiple
                            />
                          );
                        }}
                      />
                    </FormControl>
                    <Box my={5}></Box>
                    {showVideoUploadForm && (
                      <>
                        <Divider />
                        <Box my={3}></Box>
                        <FormControl fullWidth disabled={disableEditing}>
                          <Field
                            name="uploadedVideoData"
                            render={(props) => (
                              <ImageUploadBox
                                value={props.input.value}
                                uploadActionText="อัพโหลดวิดิโอการดำเนินการ (สำหรับบางแพคเกจเท่านั้น)"
                                uploadPrefix="store-order-uploads"
                                uploadKey={`store/${storeId}/${orderRealId}`}
                                onUploadFiles={(data) => {
                                  form.change('uploadedVideoData', JSON.stringify(data));
                                  form.blur('uploadedVideoData');
                                }}
                                disabled={loading}
                                mode={UPLOAD_MODE.VIDEO}
                              />
                            )}
                          />
                        </FormControl>
                        <Box my={5}></Box>
                      </>
                    )}
                    {showTextFieldForm && (
                      <>
                        <Box my={3}></Box>
                        <FormControl fullWidth>
                          <Field
                            name="uploadedTextData"
                            render={(props) => (
                              <TextField
                                {...props.input}
                                label={'ข้อความการดำเนินการ'}
                                variant="outlined"
                                fullWidth
                                multiline
                                placeholder={'ใส่ข้อมูลเพิ่มเติม ตามรายละเอียดของแพคเกจ'}
                                rows={10}
                                InputProps={{
                                  startAdornment: <div />,
                                }}
                                inputProps={{ maxLength: 5000 }}
                                helperText={
                                  props.meta.error &&
                                  props.meta.touched && (
                                    <FormHelperText error>{props.meta.error}</FormHelperText>
                                  )
                                }
                              />
                            )}
                          />
                        </FormControl>
                        <Box my={5}></Box>
                      </>
                    )}
                    {form.getState().hasValidationErrors && (
                      <>
                        <ErrorBox errors={form.getState().errors} />
                        <Box my={1}></Box>
                      </>
                    )}
                    {error && (
                      <>
                        <Alert variant="filled" severity="error">
                          {`เกิดข้อผิดพลาด ${JSON.stringify(error)}`}
                        </Alert>
                        <Box my={3} />
                      </>
                    )}
                    {loading && (
                      <Box display={'flex'} width="100%" justifyContent={'center'}>
                        <CircularProgress />
                      </Box>
                    )}
                    {!loading && (
                      <>
                        <Button
                          type="button"
                          size="large"
                          fullWidth
                          disabled={form.getState().hasValidationErrors || disableSubmitButton}
                          onClick={() => {
                            console.log('onclick');
                            handleSave(values);
                          }}
                        >
                          {'บันทึก'}
                        </Button>
                        <Box mt={2} />
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          size="large"
                          stType="gradient"
                          fullWidth
                          disabled={form.getState().hasValidationErrors || disableSubmitButton}
                        >
                          {'ส่งงาน'}
                        </Button>
                      </>
                    )}
                  </Paper>
                </form>
              )}
            </ConfirmationActionUI>
          );
        }}
      ></Form>
    </>
  );
}
