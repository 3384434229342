import { Container, Typography } from 'Components/Base';
import Heading from 'Components/Layout/Heading';
import { ORDER_STATUS, SatthaOrder, RefSource } from 'Libs/Firebase/firestore/Order';
import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import StoreWreathOrdersTable, { TABLE_VIEW_MODE } from './Components/StoreWreathOrdersTable';
import { useGetWreathOrders } from 'Domain/Stores/hooks';
import { Helmet } from 'react-helmet';
import { List, ListItem, ListItemText } from '@mui/material';
import Loading from 'Components/Loading';
import { getChantModeText } from 'Domain/Sattha/Chant';
import { Paper } from '@mui/material';
import { Box } from 'Components/Base';
import { skuSummarySortFn } from 'Domain/Orders/utils';
import { StoreOrderSummary } from 'Domain/Stores/types';
import { canStoreUpload } from 'Domain/Stores/utils';

enum SUMMARY_MODE {
  default = 'default',
  print = 'print',
  upload = 'upload',
}
interface StoreOrderSummaryRouterParams {
  mode?: SUMMARY_MODE;
  storeId: string;
}

const tableViewModeMapping = {
  [SUMMARY_MODE.default]: TABLE_VIEW_MODE.DEFAULT,
  [SUMMARY_MODE.print]: TABLE_VIEW_MODE.STORE_PRINT,
  [SUMMARY_MODE.upload]: TABLE_VIEW_MODE.UPLOAD,
};

function OrderSummaryUploadHeader({ orders }: { orders: SatthaOrder[] }) {
  const totalOrders = orders;
  const waitForUploadOrders = orders.filter((order) => canStoreUpload(order.status));
  return (
    <Paper elevation={2}>
      <Box p={1}>
        <List
          dense={true}
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: 0,
          }}
        >
          <ListItem style={{ maxWidth: 200 }}>
            <ListItemText primary={`ออเดอร์ทั้งหมด`} secondary={totalOrders.length} />
          </ListItem>
          <ListItem style={{ maxWidth: 200 }}>
            <ListItemText primary={`ออเดอร์ที่เหลือ`} secondary={waitForUploadOrders.length} />
          </ListItem>
        </List>
      </Box>
    </Paper>
  );
}

export default function StoreOrderSummaryRoute() {
  const { storeId, mode = SUMMARY_MODE.upload } = useParams<
    keyof StoreOrderSummaryRouterParams
  >() as StoreOrderSummaryRouterParams;
  const { loading, error, data } = useGetWreathOrders(storeId);
  const tableViewMode = tableViewModeMapping[mode];
  console.log(mode, tableViewMode);

  return (
    <>
      <Helmet>
        <title>{'ออเดอร์พวงหรีดของร้าน'}</title>
      </Helmet>
      <Container>
        <Box my={3} />
        <Heading title={'ออเดอร์พวงหรีดของร้าน'} />
        {loading && !data && <Loading />}
        {data && (
          <>
            <OrderSummaryUploadHeader orders={data} />
            <Box my={1} />
            <StoreWreathOrdersTable data={data ?? []} viewMode={tableViewModeMapping[mode]} />
          </>
        )}
      </Container>
    </>
  );
}
