import { CHANT_MODE } from 'Domain/Sattha/Chant';

export interface LocationProps {
  mode: CHANT_MODE;
  locationTitle?: string;
}

export function getTitle(mode: CHANT_MODE, locationName: string = '') {
  switch (mode) {
    case CHANT_MODE.VOW:
      return `เคล็ดลับการกรอกคำแก้บน`;
    case CHANT_MODE.WISH:
      return `เคล็ดลับการกรอกคำขอพร${locationName}ให้สำเร็จ`;
    case CHANT_MODE.FIX_BADLUCK:
      return `เคล็ดลับการกรอกคำแก้ชง`;
    default:
      return 'เคล็ดลับการกรอกข้อมูล';
  }
}
