import React from 'react';
import { Form, Field } from 'react-final-form';
import { Box, FormHelperText, TextField } from '@mui/material';
import { Button, Typography } from 'Components/Base';
import {} from 'react-router-dom';
import Container from 'Components/Base/Container';
import Loading from 'Components/Loading';
import { Helmet } from 'react-helmet';
import { useRedeemCode } from './hooks';
import {
  RedeemRedeemCodeInvalidInputResponse,
  RedeemRedeemCodeSuccessResponse,
  RedemptionCodeStatus,
} from './types';

const getErrorText = (error: RedemptionCodeStatus | string) => {
  switch (error) {
    case RedemptionCodeStatus.AVAILABLE:
      return 'คุณสามารถใช้โค้ดนี้ได้';
    case RedemptionCodeStatus.NOT_FOUND:
      return 'ไม่พบโค้ดนี้ กรุณาติดต่อแอดมินผ่านทาง hello@sattha.online';
    case RedemptionCodeStatus.EXPIRED:
      return 'โค้ดหมดอายุแล้ว';
    case RedemptionCodeStatus.USED:
      return 'โค้ดนี้ถูกใช้งานไปแล้ว';
    case RedemptionCodeStatus.INVALID:
      return 'มีบางอย่างผิดพลาด กรุณาติดต่อแอดมินผ่านทาง hello@sattha.online';
    default:
      return error;
  }
};

export default function ClientRedeem() {
  const [redemptionStatus, setRedemptionStatus] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [redeemCode] = useRedeemCode();

  const onSubmit = async (values) => {
    // Implement your redemption logic here
    // console.log(values);
    setErrorMessage('');
    const result = await redeemCode({
      variables: { input: { redemptionCode: values.redemptionCode.trim().toUpperCase() } },
    });
    // console.log(result.data);
    const redeemCodeResp = result.data?.redeemRedeemCode;
    if (!redeemCodeResp) {
      setErrorMessage('มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง');
      return;
    } else if ('code' in redeemCodeResp) {
      const redeptionError = result.data as unknown as RedeemRedeemCodeInvalidInputResponse;
      setErrorMessage(redeptionError.redeemRedeemCode.additionalData.status);
    } else if ('status' in redeemCodeResp) {
      // success
      const redemption = result.data as RedeemRedeemCodeSuccessResponse;
      const orderIds = redemption.redeemRedeemCode.data!.orderIds;
      if (orderIds.length === 1) {
        // set url to /myorder/:orderId
        window.location.href = `/myorder/${orderIds[0]}`;
      } else {
        // TODO: show aviailable order list
      }
    } else {
      console.log(result);
    }
  };

  return (
    <>
      <Helmet>
        <title>Redeem Code</title>
      </Helmet>
      <Container maxWidth={'sm'}>
        <Box mt={2} />
        <Typography variant="h3">Redeem Code</Typography>
        <Box mt={2} />
        <Typography variant="title">กรอกโค้ดเพื่อสร้างออเดอร์ของคุณ</Typography>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="redemptionCode"
                render={({ input, meta }) => (
                  <div>
                    <TextField
                      {...input}
                      type="text"
                      placeholder="Redemption Code"
                      fullWidth
                      disabled={submitting}
                      helperText={
                        errorMessage && (
                          <FormHelperText error>{getErrorText(errorMessage)}</FormHelperText>
                        )
                      }
                    />
                    {meta.touched && meta.error && <span>{meta.error}</span>}
                  </div>
                )}
              />
              <Box mt={2} />
              <Button
                type="submit"
                disabled={submitting || !values.redemptionCode}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
              >
                Redeem Code
              </Button>
            </form>
          )}
        />
      </Container>
    </>
  );
}
