import firebase from './firebase';

const storage = firebase.storage();

export async function getPublicUrl({
  bucket,
  ref,
  file,
}: {
  bucket?: string;
  ref: string;
  file: string;
}) {
  const refStorage = storage.ref(ref + '/' + file);
  const url = await refStorage.getDownloadURL();
  return url;
}

export default storage;
