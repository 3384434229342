import React from 'react';
import { Box, Typography } from 'Components/Base';
import { List } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { ChantLocationProps } from '../../utils';
import { CHANT_MODE, getChantModeText } from 'Domain/Sattha/Chant';
import Chants from '../../../location';

export default function GenericChantInstructions({ mode, locationId }: ChantLocationProps) {
  const performInstructionData = Chants[locationId]?.performInstructions;
  if (!performInstructionData) return null;
  const markdownText = performInstructionData[mode];

  if (!markdownText) return null;
  return (
    <Box>
      <ReactMarkdown>{markdownText}</ReactMarkdown>
    </Box>
  );
}
