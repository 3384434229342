import { Button, Typography } from 'Components/Base';
import { Link } from 'react-router-dom';
import { ORDER_STATUS, SatthaOrder } from 'Libs/Firebase/firestore/Order';
import MUIDataTable from 'mui-datatables';
import React from 'react';
import {
  canStoreUpload,
  canStoreViewDelivered,
  generateStoreUploadURL,
  STORE_CAN_UPLOAD_STATUS_LIST,
} from 'Domain/Stores/utils';
import { generateClientMyOrderUrl } from 'Domain/Backoffice/utils';
import QRCode from 'react-qr-code';
import { ButtonGroup, TableCell, TableRow } from '@mui/material';
import { Box } from 'Components/Base';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { useBackofficeApprovalOrder } from 'Domain/Backoffice/hooks';
import { APPROVAL_TYPE } from 'API/fe-functions/backoffice/BackofficeAPI';
import { getStoreOrderStatusText } from 'Domain/Sattha/Status';
import { getChantModeText } from 'Domain/Sattha/Chant';
import { StoreOrder } from 'Domain/Stores/types';

export enum TABLE_VIEW_MODE {
  STORE_PRINT,
  UPLOAD,
  DEFAULT,
}
interface OrderSummaryTableProps {
  data?: StoreOrder[];
  title?: string;
  viewMode?: TABLE_VIEW_MODE;
  agentId?: string | null;
  showAgentColumn?: boolean;
  onStoreOrderUploadClick: (orderId: string) => void;
}

const getOrderStatusColor = (status: ORDER_STATUS) => {
  switch (status) {
    case ORDER_STATUS.CLIENT_ACCEPT:
    case ORDER_STATUS.COMPLETED:
      return 'green';
    case ORDER_STATUS.MERCHANT_ACCEPT:
    case ORDER_STATUS.MERCHANT_PREPARING:
    case ORDER_STATUS.MERCHANT_DELIVERING:
      return 'blue';
    default:
      return 'grey';
  }
};

export default function StoreOrderSummaryTable(props: OrderSummaryTableProps) {
  const {
    title = 'รายการออเดอร์',
    data = [],
    agentId,
    showAgentColumn,
    viewMode = TABLE_VIEW_MODE.DEFAULT,
    onStoreOrderUploadClick,
  } = props;
  const { approveOrder, isLoading, error } = useBackofficeApprovalOrder();
  const enhancedData = data
    .filter((orderData) => {
      if (!agentId) return true;
      return orderData.agentId === agentId;
    })
    .map((orderData) => ({
      ...orderData,
      clientUrl: generateClientMyOrderUrl(orderData.orderId),
      storeUploadUrl: generateStoreUploadURL(orderData.storeId, orderData.orderId),
    }));

  const columns = [
    {
      name: 'orderId',
      label: 'Order No.',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        filterList: [TABLE_VIEW_MODE.UPLOAD].includes(viewMode)
          ? [...STORE_CAN_UPLOAD_STATUS_LIST]
          : [],
        sort: true,
        display: [TABLE_VIEW_MODE.DEFAULT, TABLE_VIEW_MODE.UPLOAD].includes(viewMode),
        customBodyRender: (value) => (
          <Typography style={{ color: getOrderStatusColor(value) }}>
            {getStoreOrderStatusText(value)}
          </Typography>
        ),
      },
    },
    {
      name: 'refId',
      label: 'Ref Order No.',
      options: {
        filter: true,
        sort: true,
        display: [TABLE_VIEW_MODE.DEFAULT, TABLE_VIEW_MODE.STORE_PRINT].includes(viewMode),
      },
    },
    {
      name: 'agent.name',
      label: 'แม่หมอ',
      options: {
        filter: true,
        sort: true,
        display: showAgentColumn,
      },
    },
    {
      name: 'chantName',
      label: 'ชื่อ',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'skuCode',
      label: 'รหัสแพคเกจ',
      options: {
        filter: true,
        sort: false,
        display: [
          TABLE_VIEW_MODE.DEFAULT,
          TABLE_VIEW_MODE.UPLOAD,
          TABLE_VIEW_MODE.STORE_PRINT,
        ].includes(viewMode),
      },
    },
    {
      name: 'skuName',
      label: 'แพคเกจ',
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const order = enhancedData[dataIndex];
          const type = getChantModeText(order.skuMode);
          const skuName = order.skuName;
          return `${skuName} (${type})`;
        },
      },
    },
    {
      name: 'QR',
      label: 'QR',
      options: {
        download: false,
        display: [TABLE_VIEW_MODE.STORE_PRINT].includes(viewMode),
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const order = enhancedData[dataIndex];
          const url = generateStoreUploadURL(order.storeId, order.orderId);
          return <QRCode value={url} level="L" size={64} />;
        },
      },
    },
    {
      name: 'storeUploadUrl',
      label: 'Store URL',
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
      },
    },
    {
      name: 'Actions',
      label: 'Actions',
      options: {
        download: false,
        display: [TABLE_VIEW_MODE.DEFAULT, TABLE_VIEW_MODE.UPLOAD].includes(viewMode),
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const order = enhancedData[dataIndex];
          console.log(order.orderId, order.status);
          return (
            <>
              {(canStoreUpload(order.status) || canStoreViewDelivered(order.status)) && (
                <Button
                  variant="contained"
                  color={canStoreUpload(order.status) ? 'primary' : 'secondary'}
                  disabled={!(canStoreUpload(order.status) || canStoreViewDelivered(order.status))}
                  onClick={() => {
                    onStoreOrderUploadClick(order.orderId);
                  }}
                >
                  {canStoreUpload(order.status) && 'ส่งหลักฐาน'}
                  {canStoreViewDelivered(order.status) && 'ดูหลักฐาน'}
                </Button>
              )}
            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    pagination: false,
    selectableRows: 'none',
    elevation: 1,
    setTableProps: () => ({ size: 'small' }),
    // responsive: 'standard',
    print: false,
    download: true,
    viewColumns: false,
    enableNestedDataAccess: '.',
  };

  return (
    <MUIDataTable
      title={title}
      data={enhancedData}
      columns={columns}
      options={options}
      size={'small'}
    />
  );
}
