import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getHeaders } from 'API/fe-functions/api';
import getConfig from 'Libs/Config/utils';
const config = getConfig();

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_CLIENT_GRAPHQL_API_URL,
});

const authLink = setContext(async (_, { headers }) => {
  const authHeaders = config.enableClientAuth ? await getHeaders() : {};
  return {
    headers: {
      ...headers,
      ...authHeaders,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
