import { Breadcrumbs, Divider, Link } from '@mui/material';
import { Box, Typography } from 'Components/Base';
import { Fragment } from 'react';

type NavigationHeaderProps = {
  title: string;
  compact?: boolean;
  toolbar?: any;
  breadCrumb?: { href?: string; title: string }[];
};

function Crumb({ href, title }: { href?: string; title: string }) {
  if (href)
    return (
      <Link underline="hover" color="inherit" href={href}>
        <Typography variant="h4" color="primary">
          {title}
        </Typography>
      </Link>
    );

  return (
    <Typography variant="h4" color="black">
      {title}
    </Typography>
  );
}

export default function NavigationHeader({
  title,
  compact,
  toolbar,
  breadCrumb,
}: NavigationHeaderProps) {
  return (
    <>
      <Box my={1} />
      <Box display="flex" justifyContent={'space-between'}>
        <Breadcrumbs aria-label="breadcrumb">
          {breadCrumb &&
            breadCrumb.map(({ href, title }) => (
              <Crumb key={href + title} href={href} title={title} />
            ))}
          <Typography variant="h4" color="black">
            {title}
          </Typography>
        </Breadcrumbs>
        {toolbar && toolbar}
      </Box>
      <Box my={1} />
      <Divider />
      {!compact && <Box my={3} />}
      {compact && <Box my={1} />}
    </>
  );
}
