import React from 'react';
import { useParams } from 'react-router-dom';
import StoreFortuneTellingLiveOrdersContainer from './StoreFortuneTellingLiveOrdersContainer';

interface StoreFTLOrderRouterParams {
  storeId: string;
  agentId?: string;
}

export default function StoreFortuneTellingLiveOrders() {
  const { agentId, storeId } = useParams<
    keyof StoreFTLOrderRouterParams
  >() as StoreFTLOrderRouterParams;

  return <StoreFortuneTellingLiveOrdersContainer storeId={storeId} agentId={agentId} />;
}
