import React from 'react';
import { Box, Typography } from 'Components/Base';
import { List } from '@mui/material';
import { LocationProps } from '../utils';
import { CHANT_MODE, getChantModeText } from 'Domain/Sattha/Chant';

/**
 * "วิธีขอพรเทพหวังต้าเซียนจากบ้าน
อุปกรณ์ที่ต้องใช้ • ธูป 3 ดอก (หากไม่มีก็ไม่เป็นไร) 
บทสวดข้างบน (กดบันทึกเก็บไว้อ่านได้เลย!) 

ก่อนถึงเวลาที่ร้านค้าจะดำเนินการขอพร 
• จุดธูปให้พร้อม • ตั้งจิตอธิษฐานให้มั่น แล้วกล่าวบทสวด, ชื่อ, เรื่องที่อยากขอพร/บนบาน พร้อมทั้งบอกของที่ต้องการนำมาแก้บน (ถ้ามี) หากพรสมปรารถนา เช่น เมื่อข้าพเจ้าสำเร็จแล้วจะมาถวายชุดขอคู่ทันใจ เป็นต้น"
 * @param param0 
 * @returns 
 */

export default function HKWongTaiSin({ mode }: LocationProps) {
  if (mode === CHANT_MODE.WISH) {
    return (
      <Box>
        <Typography variant="h4">{`วิธีขอพรเทพหวังต้าเซียนจากบ้าน`}</Typography>
        <Box mt={1}></Box>
        <b>อุปกรณ์ที่ต้องใช้</b>
        <ul>
          <li>ธูป 3 ดอก (หากไม่มีก็ไม่เป็นไร)</li>
          <li>บทสวดข้างบน (กดบันทึกเก็บไว้อ่านได้เลย!)</li>
        </ul>
        <b>ก่อนถึงเวลาที่ร้านค้าจะดำเนินการขอพร</b>
        <ul>
          <li>จุดธูปให้พร้อม</li>
          <li>
            ตั้งจิตอธิษฐานให้มั่น แล้วกล่าวบทสวด, ชื่อ, เรื่องที่อยากขอพร/บนบาน
            พร้อมทั้งบอกของที่ต้องการนำมาแก้บน (ถ้ามี) หากพรสมปรารถนา
          </li>
        </ul>
        เช่น เมื่อข้าพเจ้าสำเร็จแล้วจะมาถวายชุดขอคู่ทันใจ เป็นต้น
      </Box>
    );
  }
  return null;
}
