import { Box, Typography } from 'Components/Base';

export default function OrderWaitBox({ text1, text2 }: { text1: string; text2?: string }) {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <img
        src={'/assets/orderDetails/uploadImagePlaceholder-line.png'}
        style={{ maxWidth: 140, height: 'auto' }}
        alt=""
      />
      <Box mt={2} />
      <Typography style={{ maxWidth: 240 }} align="center">
        {text1}
      </Typography>
      {text2 && (
        <>
          <Box mt={2} />
          <Typography style={{ maxWidth: 240 }} align="center">
            {text2}
          </Typography>
        </>
      )}
    </Box>
  );
}
