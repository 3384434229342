import { Calendar } from 'react-big-calendar';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import dayjsLocalizer from './dayjsLocalizer';
import localeData from 'dayjs/plugin/localeData';
import minMax from 'dayjs/plugin/minMax';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useCallback, useMemo } from 'react';
import { Typography, Box, Button } from 'Components/Base';
import { Chip, Paper } from '@mui/material';
import FaceIcon from '@mui/icons-material/Face';
import withStyles from '@mui/styles/withStyles';

dayjs.locale('th');
dayjs.extend(isSameOrBefore);
dayjs.extend(localeData);
dayjs.extend(minMax);
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
const localizer = dayjsLocalizer(dayjs);

const COLORS = [
  '#f94144',
  '#f3722c',
  '#f8961e',
  '#F9844A',
  '#F9C74F',
  '#90BE6D',
  '#43AA8B',
  '#4D908E',
  '#577590',
  '#277DA1',
];

function hashCode(str) {
  // java String#hashCode
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return Math.abs(hash);
}

const getEventColor = (code: string) => {
  const hashValue = hashCode(code);
  return COLORS[hashValue % COLORS.length];
};

export interface CalendarResource {
  id: string;
  title: string;
}

export enum CALENDAR_EVENT_STATUS {
  AVAILABLE,
  BOOKED,
}

export interface CalendarEvent {
  id: string;
  title: string;
  start: Date;
  end: Date;
  allDay?: boolean;
  resource?: CalendarResource;
  desc?: string;
  url?: string;
  status: CALENDAR_EVENT_STATUS;
}

function Event({ event }: { event: CalendarEvent }) {
  return (
    <Box>
      {isBooked(event.status) && '✅ '}
      {event.resource && `${event.resource.title}`}
      {event.title && <strong>{` ${event.title}`}</strong>}
    </Box>
  );
}

const isBooked = (status: CALENDAR_EVENT_STATUS) => status === CALENDAR_EVENT_STATUS.BOOKED;

function EventAgenda({ event }: { event: CalendarEvent }) {
  return (
    <Box display="flex" flexDirection={'row'}>
      <Box alignItems={'flex-start'}>
        <Box display="flex" flexDirection={'row'}>
          {isBooked(event.status) && (
            <>
              <Chip size={'small'} variant={'filled'} label="จองแล้ว" color="success" />
              <Box ml={1} />
            </>
          )}
          {event.resource && (
            <Chip
              icon={<FaceIcon />}
              sx={{
                borderColor:
                  event.status === CALENDAR_EVENT_STATUS.BOOKED
                    ? getEventColor(event.resource.id)
                    : undefined,
              }}
              label={event.resource.title}
              size={'small'}
              variant={'outlined'}
            />
          )}
          <Box ml={1} />
          <Typography variant="body2">{event.title}</Typography>
          {event.url && (
            <Box pl={1}>
              <a href={event.url} target="_blank" rel="noreferrer">
                <Typography>ดูรายละเอียด</Typography>
              </a>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default function EventCalendar({ events }: { events: CalendarEvent[] }) {
  const { components } = useMemo(
    () => ({
      components: {
        agenda: {
          event: EventAgenda,
        },
        event: Event,
      },
    }),
    []
  );
  const { events: calendarEvents } = useMemo(() => ({ events }), [events]);
  const eventPropGetter = useCallback(
    (event, start, end, isSelected) => ({
      style: {
        backgroundColor: getEventColor(event.resource?.id),
      },
    }),
    []
  );
  return (
    <Box
      sx={{
        '& .rbc-agenda-table tr': { backgroundColor: 'white !important ' },
        '& .rbc-toolbar': {
          position: 'sticky',
          top: 0,
          backgroundColor: 'white',
          zIndex: 1,
        },
      }}
    >
      <Calendar
        components={components}
        localizer={localizer}
        events={calendarEvents}
        startAccessor="start"
        endAccessor="end"
        style={{ minHeight: '80vh' }}
        defaultView="agenda"
        eventPropGetter={eventPropGetter}
        step={15}
        showMultiDayTimes
      />
    </Box>
  );
}
