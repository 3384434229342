import React, { FormEventHandler, SyntheticEvent, useState } from 'react';
import { Form, Field } from 'react-final-form';
import {
  Paper,
  FormControl,
  TextField,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Divider,
  FormHelperText,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Alert from '@mui/material/Alert';

import { Box, Button, Typography } from '../../Components/Base';

import { SKU_CHANT_MODE } from 'Libs/Firebase/firestore/Order';
import { ConfirmationActionUI } from 'Components/StoreOrderForm/StoreOrderForm';
import { TRACKING_PROVIDERS } from 'Domain/Shipping';
import ErrorBox from 'Components/UserInfoForm/Components/ErrorBox';

const useStyles = makeStyles((theme) => ({
  hiddenField: {
    display: 'none',
  },
  gradientButton: {
    background: `linear-gradient(314.47deg, #01C1A8 0%, #1DCE59 90.84%)`,
    borderRadius: 2,
    color: 'white',
    width: '100%',
    '&:disabled': {
      color: `#969696`,
      background: '#E6E6E6',
    },
  },
}));

export function HiddenFieldForm() {
  const classes = useStyles();
  return (
    <>
      <FormControl fullWidth className={classes.hiddenField}>
        <Field
          name="orderId"
          render={(props) => (
            <TextField {...props.input} hidden className={classes.hiddenField} fullWidth />
          )}
        />
      </FormControl>
      <FormControl fullWidth className={classes.hiddenField}>
        <Field
          name="orderRefId"
          render={(props) => (
            <TextField {...props.input} hidden className={classes.hiddenField} fullWidth />
          )}
        />
      </FormControl>
      <FormControl fullWidth className={classes.hiddenField}>
        <Field
          name="packageId"
          render={(props) => (
            <TextField {...props.input} hidden className={classes.hiddenField} fullWidth />
          )}
        />
      </FormControl>
    </>
  );
}

interface UploadStorageInterface {
  filename: string;
  fileurl: string;
  ref: string;
}

export interface StoreOrderFormData {
  orderId: string;
  uploadedImagesData: UploadStorageInterface[];
  uploadedVideoData: UploadStorageInterface[];
}

type StoreOrderFormProps = {
  onSubmit?: (formValues: StoreOrderFormData) => void;
  orderRefId?: string;
  orderRealId: string;
  orderId: string;
  storeId: string;
  customerName?: string;
  packageId?: string;
  packageName?: string;
  skuMode: SKU_CHANT_MODE;
  mode?: FORM_MODE;
  loading?: boolean;
  error?: any;
};

export enum FORM_MODE {
  CANNOT_UPLOAD = 'CANNOT_UPLOAD',
  STORE_UPLOAD = 'STORE_UPLOAD',
  STORE_UPLOAD_COMPLETE = 'STORE_UPLOAD_COMPLETE',
  STORE_DONE = 'STORE_DONE',
  STORE_PREPARE = 'STORE_PREPARE',
}

export default function StoreShipmentOrderForm(props: StoreOrderFormProps) {
  const classes = useStyles();

  const {
    orderRealId,
    orderId,
    orderRefId,
    storeId,
    onSubmit = () => {
      //
    },
    customerName,
    packageId,
    packageName,
    skuMode,
    mode = FORM_MODE.STORE_UPLOAD,
    loading = false,
    error,
  } = props;

  const disableEditing = [
    FORM_MODE.CANNOT_UPLOAD,
    FORM_MODE.STORE_UPLOAD,
    FORM_MODE.STORE_UPLOAD_COMPLETE,
  ].includes(mode);

  const disableSubmitButton =
    loading || [FORM_MODE.CANNOT_UPLOAD, FORM_MODE.STORE_UPLOAD_COMPLETE].includes(mode);

  const handleSubmit = (inValues) => {
    onSubmit(inValues);
  };

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={{ orderId, orderRefId, customerName, packageId, packageName }}
        validateOnBlur
        validate={(values) => {
          const errors: { [k: string]: any } = {};
          if (!values.trackingProvider) errors.trackingProvider = 'กรุณาเลือกบริษัทขนส่ง';
          if (!values.trackingCode) errors.trackingCode = 'กรุณากรอกเลขที่ติดตามพัสดุ';
          console.log('validations', values, errors);
          return errors;
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => {
          console.log(values);
          return (
            <ConfirmationActionUI
              cancelButtonText="แก้ไขข้อมูล"
              confirmationText="กรุณาตรวจสอบเลขที่การส่งของว่าตรงกับออเดอร์"
            >
              {(doConfirm) => (
                <form onSubmit={doConfirm(handleSubmit)}>
                  <HiddenFieldForm />
                  <Paper elevation={0} style={{ padding: 16 }}>
                    <FormControl fullWidth>
                      <Field
                        name="trackingProvider"
                        render={(props) => (
                          <Select {...props.input} displayEmpty variant="outlined" fullWidth>
                            <MenuItem value="" disabled>
                              {'เลือกบริษัทขนส่ง'}
                            </MenuItem>
                            {TRACKING_PROVIDERS.map((data) => (
                              <MenuItem key={data.value} value={data.value}>
                                {data.name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                    <Box my={3}></Box>
                    <FormControl fullWidth>
                      <Field
                        name="trackingCode"
                        render={(props) => (
                          <TextField
                            {...props.input}
                            label="เลขติดตามพัสดุ"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    </FormControl>
                    <Box my={3}></Box>
                    <Box my={5}></Box>
                    {form.getState().hasValidationErrors && (
                      <>
                        <ErrorBox errors={form.getState().errors} />
                        <Box my={1}></Box>
                      </>
                    )}
                    {error && (
                      <>
                        <Alert variant="filled" severity="error" sx={{ overflow: 'auto' }}>
                          {`เกิดข้อผิดพลาด ${JSON.stringify(error)}`}
                        </Alert>
                        <Box my={3} />
                      </>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      size="large"
                      stType="gradient"
                      fullWidth
                      disabled={form.getState().hasValidationErrors || disableSubmitButton}
                    >
                      {loading ? <CircularProgress /> : 'ส่งงาน'}
                    </Button>
                  </Paper>
                </form>
              )}
            </ConfirmationActionUI>
          );
        }}
      ></Form>
    </>
  );
}
