import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Modal } from '@mui/material';
import { Container, Button } from 'Components/Base';
import { useGetOrderSummaries, useBackofficeCreateOrderSummary } from 'Domain/Backoffice/hooks';
import Heading from 'Components/Layout/Heading';
import UploadShippingOrders from 'Domain/Shipping/UploadShippingOrders';

export default function BackofficeShipmentUploadRoute() {
  return (
    <>
      <Helmet>
        <title>{`อัพโหลดเลขที่ส่งของ`}</title>
      </Helmet>
      <Container>
        <Heading title={`อัพโหลดเลขที่ส่งของ`} />
        <UploadShippingOrders />
      </Container>
    </>
  );
}
