import React from 'react';
import { Field } from 'react-final-form';
import { FormControl, Select, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import 'dayjs/locale/th';

import { AvailableSkuItem } from 'Libs/Firebase/firestore/Order';
import { Typography } from 'Components/Base';
import { UIFORM_TEXT_FIELDS } from 'Domain/Sattha/Chant';

dayjs.extend(isToday);
dayjs.extend(isTomorrow);

const getDisplayDateWithRelativeDate = (serviceDateStr: string, offsetDays: number = 0) => {
  const day = dayjs(serviceDateStr, 'YYYYMMDD').add(offsetDays, 'day').locale('th');
  let formattedDate = day.format('D MMM');

  if (day.isToday()) {
    formattedDate = `${formattedDate} (วันนี้)`;
  }

  if (day.isTomorrow()) {
    formattedDate = `${formattedDate} (พรุ่งนี้)`;
  }

  return formattedDate;
};

const getDisplayDate = (serviceDateStr: string, offsetDays: number = 0) => {
  const day = dayjs(serviceDateStr, 'YYYYMMDD').add(offsetDays, 'day').locale('th');
  return day.format('D MMM');
};

export default function SelectPackageForm({
  name = 'skuCode',
  FORM_TEXT,
  enable,
  skuMasterCode,
  availableSkus = [],
  showRelativeDate = false,
  displayOffsetDays = 0,
  maxSelections,
}: {
  name?: string;
  FORM_TEXT: any;
  enable: boolean;
  skuMasterCode: string;
  availableSkus?: AvailableSkuItem[];
  showRelativeDate?: boolean;
  displayOffsetDays?: number;
  maxSelections?: number;
}) {
  return (
    <>
      <FormControl fullWidth>
        <Field
          name={name}
          render={(props) => (
            <>
              <Typography variant="h5">
                {FORM_TEXT[UIFORM_TEXT_FIELDS.SELECT_PACAKGE_HEADER]}
              </Typography>
              <Select
                {...props.input}
                displayEmpty
                variant="outlined"
                //label={FORM_TEXT[UIFORM_TEXT_FIELDS.SELECT_PACAKGE_HEADER]}
                fullWidth
              >
                {availableSkus?.length === 0 && (
                  <option value="" disabled>
                    ไม่มีวันดำเนินการในขณะนี้
                  </option>
                )}
                {availableSkus
                  ?.filter((_, idx) =>
                    maxSelections !== null && maxSelections !== undefined
                      ? idx < maxSelections
                      : true
                  )
                  .map((sku) => (
                    <MenuItem key={sku.skuCode} value={sku.skuCode}>
                      {showRelativeDate
                        ? getDisplayDateWithRelativeDate(sku.serviceDate, displayOffsetDays)
                        : getDisplayDate(sku.serviceDate, displayOffsetDays)}
                    </MenuItem>
                  ))}
              </Select>
            </>
          )}
        />
      </FormControl>
    </>
  );
}
