import { Alert } from '@mui/material';
import { Box, Button, Container, Typography } from 'Components/Base';
import { ORDER_STATUS, SatthaOrder } from 'Libs/Firebase/firestore/Order';

const NotifyLateOrder = ({ order }: { order: SatthaOrder }) => {
  return (
    <>
      <Box>
        <Alert severity="warning">
          {`ทาง${order.locationName}มีการปิดเนื่องจากสถานการณ์โควิดแพร่ระบาดจึงทำให้ไม่สามารถทำการขอพร แก้บน หรือแก้ชง ตามวันที่ท่านเลือกได้`}
          <br />
          หากทางวัดเปิดอีกครั้งเราจะดำเนินการให้เร็วที่สุด
          <br />
          <br />
          หรือท่านมีความประสงค์จะติดต่อเปลี่ยนแปลงสถานที่หรือยกเลิกคำสั่งซื้อ กรุณาส่ง
          อีเมล์พร้อมระบุเลขที่สั่งซื้อมาที่ hello@sattha.online
        </Alert>
      </Box>
    </>
  );
};

export const shouldShowNotifyOrderLateAlert = (order: SatthaOrder) => {
  return false;
  // if (
  //   [ORDER_STATUS.CLIENT_FILLED_INFO, ORDER_STATUS.PAID, ORDER_STATUS.MERCHANT_PREPARING].includes(
  //     order.status
  //   )
  // ) {
  //   if (['HK-WONG-TAI-SIN'].includes(order.locationId)) {
  //     return true;
  //   }
  // }
  // return false;
};

export default NotifyLateOrder;
