import React from 'react';
import { Box, Typography } from 'Components/Base';
import { List } from '@mui/material';

import AiKhai from './Locations/AiKhai';
import TanTai from './Locations/TanJai';
import { CHANT_MODE } from 'Domain/Sattha/Chant';
import Sothon from './Locations/Sothon';
import Demo from './Locations/Demo';
import HKHungHom from './Locations/HKHungHom';
import HKCheKung from './Locations/HKCheKung';
import LCN from './Locations/LCN';
import HKWongTaiSin from './Locations/HKWongTaiSin';
import LLM from './Locations/LLM';
import LPP from './Locations/LPP';
import LSC from './Locations/LSC';
import LSR from './Locations/LSR';
import LBC from './Locations/LBC';
import LCL from './Locations/LCL';
import LTT from './Locations/LTT';
import HKLongSan from './Locations/HKLongSan';
import LSR04 from './Locations/LSR04';
import LTM04 from './Locations/LTM04';
import { STORE_LOCATIONS } from '../location';
import GenericChantInstructions from './Locations/GenericChantInstructions';

interface ChantInstructionsProps {
  locationId: string;
  mode: CHANT_MODE;
}

export default function ChantInstructions(props: ChantInstructionsProps) {
  const { locationId, mode } = props;

  // switch case for every STORE_LOCATIONS
  switch (locationId) {
    case STORE_LOCATIONS.AI_KHAI:
      return <AiKhai mode={mode} />;
    case STORE_LOCATIONS.THEP_THAN_JAI:
      return <TanTai mode={mode} />;
    case STORE_LOCATIONS.SOTHON:
      return <Sothon mode={mode} />;
    case STORE_LOCATIONS.HK_CHE_KUNG:
      return <HKCheKung mode={mode} />;
    case STORE_LOCATIONS.HK_HUNG_HOM:
      return <HKHungHom mode={mode} />;
    case STORE_LOCATIONS.LCN:
      return <LCN mode={mode} />;
    case STORE_LOCATIONS.HK_WONG_TAI_SIN:
      return <HKWongTaiSin mode={mode} />;
    case STORE_LOCATIONS.HK_LONG_SAN:
      return <HKLongSan mode={mode} />;
    case STORE_LOCATIONS.LLM:
      return <LLM mode={mode} />;
    case STORE_LOCATIONS.SLM:
      return <LLM mode={mode} />;
    case STORE_LOCATIONS.LPP:
      return <LPP mode={mode} />;
    case STORE_LOCATIONS.LSC:
      return <LSC mode={mode} />;
    case STORE_LOCATIONS.LSR:
      return <LSR mode={mode} />;
    case STORE_LOCATIONS.LBC:
      return <LBC mode={mode} />;
    case STORE_LOCATIONS.LCL:
      return <LCL mode={mode} />;
    case STORE_LOCATIONS.LTT:
      return <LTT mode={mode} />;
    case STORE_LOCATIONS.LSR04:
      return <LSR04 mode={mode} />;
    case STORE_LOCATIONS.LTM04:
      return <LTM04 mode={mode} />;
    case STORE_LOCATIONS.DEMO:
      return <Demo mode={mode} />;
    default:
      return <GenericChantInstructions locationId={locationId} mode={mode} />;
  }
}
