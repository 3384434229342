export enum FORM_GENERATOR_INPUT_TYPE {
  SECTION,
  HEADING,
  TEXT_AREA,
  CHANT_NAME_MULTIPLE,
}

interface FormGeneratorBlockBase {
  type: FORM_GENERATOR_INPUT_TYPE;
}

export interface FormGeneratorBlockHeading extends FormGeneratorBlockBase {
  title: string;
}

export interface FormGeneratorBlockSection extends FormGeneratorBlockBase {
  label: string;
}

interface FormGeneratorInputBase extends FormGeneratorBlockBase {
  name: string;
  placeholder?: string;
  label: string;
  tips?: string;
}

export interface FormGeneratorInputTypeTextArea extends FormGeneratorInputBase {
  maxLength?: number;
  rows?: number;
}

export interface FormGeneratorInputTypeChantNameMultiple extends FormGeneratorInputBase {
  allowAnonymous?: boolean;
  allowMultiple?: boolean;
  anonymousLabel?: string;
  multipleLabel?: string;
  multipleMax?: number;
}

export type FormGeneratorInput =
  | FormGeneratorBlockHeading
  | FormGeneratorBlockSection
  | FormGeneratorInputTypeTextArea
  | FormGeneratorInputTypeChantNameMultiple;

export interface FormGeneratorProps {
  data: FormGeneratorInput[];
}
