import { StorageObject } from 'Domain/Orders/types';
import { RMS_WALLPAPER_COLOR } from 'Domain/Sku/Wallpaper';
import firebase from '../firebase';
import { HRS_INCLUDE_SERVICE } from 'Domain/Sku/HRSWallpaper';

const firestore = firebase.firestore();

const orderDb = firestore.collection('orders');

export enum RefSource {
  LINE_HORO_MY_SHOP = 'LINE_HORO_MY_SHOP',
  LINE_SATTHA_OA = 'LINE_SATTHA_OA',
}

export enum StoreProvider {
  HORO = 'HORO',
}

export enum ORDER_SUMMARY_STATUS {
  NEW = 'NEW',
  ORDER_READY = 'ORDER_READY',
  SENT_TO_STORE = 'SENT_TO_STORE',
  MERCHANT_DELIVERING = 'MERCHANT_DELIVERING',
  MERCHANT_DELIVERED = 'MERCHANT_DELIVERED',
  COMPLETED = 'COMPLETED',
  ARCHIVED = 'ARCHIVED',
}

export enum ORDER_STATUS {
  CREATED = 'CREATED',
  PENDING = 'PENDING',
  PAYMENT_UPLOADED = 'PAYMENT_UPLOADED',
  PAID = 'PAID',
  CLIENT_FILLED_INFO = 'CLIENT_FILLED_INFO',
  CANCELLED = 'CANCELLED',
  MERCHANT_ACCEPT = 'MERCHANT_ACCEPT',
  MERCHANT_PREPARING = 'MERCHANT_PREPARING',
  MERCHANT_DELIVERING = 'MERCHANT_DELIVERING',
  MERCHANT_DELIVERED = 'MERCHANT_DELIVERED',
  CLIENT_ACCEPT = 'CLIENT_ACCEPT',
  COMPLETED = 'COMPLETED',
  MESSAGE_FAIL_DELIVERING = 'MESSAGE_FAIL_DELIVERING',
  FOLLOW_UP = 'FOLLOW_UP',
  WAIT_FOR_FOLLOW_UP = 'WAIT_FOR_FOLLOW_UP',
}

export interface Attachment {
  name: string;
  url: string;
  type: ATTACHMENT_TYPE;
  contents?: string;
  data?: any;
}

export enum SKU_CHANT_MODE {
  VOW = 'VOW',
  WISH = 'WISH',
  FIX_BADLUCK = 'FIX_BADLUCK',
  WALLPAPER = 'WALLPAPER',
  WREATH = 'WREATH',
  FORTUNE_TELLING = 'FORTUNE_TELLING',
  FORTUNE_TELLING_LIVE = 'FORTUNE_TELLING_LIVE',
  MERIT = 'MERIT',
}

// Should sync between client and server
export enum ORDER_TYPE {
  // Default is Fulfillment
  FULFILLMENT = 'FULFILLMENT',
  SHIPMENT = 'SHIPMENT',
}
export interface BundleItem {
  orderId: string;
  type: ORDER_TYPE;
  skuCode: string;
  skuMode: SKU_CHANT_MODE;
  status: ORDER_STATUS;
  packageDate: string;
}
export interface OrderBundleInfo {
  haveShippingOrder: boolean;
  shippingOrder: SatthaOrder;
  bundleItems: BundleItem[];
}

export interface OrderShippingData {
  shippingName: string;
  shippingAddress: string;
  shippingPostcode: string;
  shippingPhoneNo: string;
  shippingAdditionalData?: string;
}

export interface OrderShippingTrackingData {
  trackingCode: string;
  trackingProvider: string;
}

export interface AvailableSkuItem {
  skuCode: string;
  packageDate: string;
  serviceDate: string;
}

export interface SkuWallpaperData {
  wallpaperRequestType: string;
  horowallIncludeServices?: boolean;
  mtwIncludeServices?: boolean;
  inputTemplate: string;
  wallpaperColor?: RMS_WALLPAPER_COLOR;
  mtwDeviceType?: string;
  hrsIncludeServices?: HRS_INCLUDE_SERVICE;
}

export interface SkuFortuneTellingData {
  horoRequestType: string;
  questions: number;
  answerWithVideo?: boolean;
  requiredFacePicture?: boolean;
  requiredPalmPicture?: boolean;
  requiredBirthPlace?: boolean;
  hideFacePicture?: boolean;
  additionalInputMode?: string;
}

export interface SkuWreathData {
  wreathLocation: string;
}

export interface SkuShipmentData {
  inputTemplate: string;
}

export interface SkuWishVowData {
  requiredBirthday?: boolean;
  requiredFacePicture?: boolean;
}

export interface SkuMeritData {
  inputTemplate: string;
}

export type SkuData =
  | SkuWallpaperData
  | SkuWreathData
  | SkuShipmentData
  | SkuWishVowData
  | SkuFortuneTellingData
  | SkuMeritData;

export interface OrderWallpaperData {
  email: string;
  phoneNo: string;
  gender: string;
  birthDay: number;
  birthDate: string;
  birthMonth: number;
  birthYear: number;
  occupation: string;
  topic: string;
  subTopic: string;
  // RMS
  wallpaperColor: string;
  lineId: string;
  birthTime: { hour: number; minute: number };
  phoneModel: string; // MTW & RMS
  // MTW
  medicalProblems?: string[];
  fansBirthDay?: {
    day: number;
    month: number;
    year: number;
  };
  subSubTopic: string;
  option: string;
  // HRS
  secondaryOccupation?: string;
}

export interface OrderFortuneTellingData {
  phoneNo?: string;
  birthday: {
    day: number;
    month: number;
    year: number;
  };
  birthTime?: {
    hour: number;
    minute: number;
  };
  birthPlace?: string;
  gender: string;
  faceImage?: StorageObject;
  palmImage?: StorageObject[];
  questions: string[];
  occupation?: string;
  additionalInfo?: any;
}

export interface OrderWreathData {
  phoneNo: string;
  locationPlace: string;
  locationPlaceSpecific: string;
  locationProvince: string;
  deceasedName?: string;
  displayMessage?: String;
}

export interface OrderMeritData {
  chantNames: string[];
  chantAnonymous: boolean;
}

export interface OrderBookingInfo {
  agentId: string;
  bookingCode: string;
  startTime: Date;
  endTime: Date;
  meetingUrl?: string;
  status: string;
}

export enum ORDER_SKU_MASTER_TYPE {
  SKUMASTER = 'SKUMASTER',
  SKUCODE = 'SKUCODE',
}

export interface SatthaOrder {
  id: string;
  orderId: string;
  type: ORDER_TYPE;
  status: ORDER_STATUS;
  updatedAt: Date;

  orderSummaryId?: string;

  storeId: string;
  storeName: string;

  skuMasterCode?: string;
  skuCode?: string;
  skuName: string;
  skuDate: Date;
  skuImageUrl: string;
  skuMode: SKU_CHANT_MODE;
  skuData?: SkuData;
  skuMasterType: ORDER_SKU_MASTER_TYPE;
  packageDate?: string;
  serviceDate?: string;
  agentId?: string;

  // only show when order is paid, and no sku selected
  availableSkus?: AvailableSkuItem[];

  locationId: string;
  locationName: string;
  locationDisplayInfo?: string;

  // Reference from provider
  refSource?: RefSource;
  refId?: string;
  refSKUCode?: string;

  chantName?: string;
  chantMessage?: string;
  chantConfirmed: boolean;

  locationInstructionsTitle: string;
  locationInstructions: string;
  locationHolyThingImageUrl?: string;

  paymentAmount?: number;

  attachments?: {
    ['chant-images']?: Attachment;
    ['store-order-upload']?: {
      attachmentList: Attachment[];
    };
  };

  // Bundle
  bundleInfo?: OrderBundleInfo;

  // Shipment Data
  shippingData?: OrderShippingData;
  shippingTrackingData?: OrderShippingTrackingData;

  // Wallpaper
  wallpaperData?: OrderWallpaperData;

  // Fortune Telling
  fortuneTellingData?: OrderFortuneTellingData;

  // Wreath
  wreathData?: OrderWreathData;

  // Merit
  meritData?: OrderMeritData;

  // Booking Info
  booking?: OrderBookingInfo;

  // Chant
  birthday?: { day: number; month: number; year: number };
  faceImage?: StorageObject;
}

export interface SatthaStoreOrder {
  id: string;
  orderId: string;
  status: ORDER_STATUS;

  skuCode: string;
  skuName: string;

  chantName?: string;
}

export enum ATTACHMENT_TYPE {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  TEXT = 'TEXT',
  OBJECT = 'OBJECT',
}
export interface OrderAttachmentDocumentData {
  url: string;
  type: ATTACHMENT_TYPE;
  bucket?: string;
  name?: string;
  ref?: string;
  contents?: string;
  data?: any;
}

export interface ChantImageDocumentData extends OrderAttachmentDocumentData {}

export interface StoreOrderUploadDocumentData {
  attachmentList: OrderAttachmentDocumentData[];
}

function mapProviderToRefSource(provider: StoreProvider): RefSource | null {
  switch (provider) {
    case StoreProvider.HORO:
      return RefSource.LINE_HORO_MY_SHOP;
    default:
      return null;
  }
}

type SatthaOrderReturnValue = SatthaOrder | null;

export const getOrderFromId = async (orderId: string): Promise<SatthaOrderReturnValue> => {
  console.log('getOrderFromId', orderId);
  const snapshot = await orderDb.doc(orderId).get();
  if (!snapshot.exists) return null;
  return { id: orderId, ...snapshot.data() } as SatthaOrder;
};

export const getOrderFromProvider = async (
  provider: string = '',
  refId: string
): Promise<SatthaOrderReturnValue> => {
  const refSource = mapProviderToRefSource(
    StoreProvider[provider.toUpperCase() as keyof typeof StoreProvider]
  );
  console.log('getOrderFromProvider', provider, refSource, refId);
  if (refSource === null) return null;
  const results = await orderDb
    .where('refId', '==', refId)
    .where('refSource', '==', refSource)
    .get();
  if (results.docs.length === 0 || results.docs.length > 1) return null;
  const data = results.docs[0];
  return { id: data.id, ...data.data() } as SatthaOrder;
};

export const getChantImageDocument = (documentId) =>
  orderDb.doc(`${documentId}/attachments/chant-image`);

export const getStoreOrderUploadDocument = (documentId) =>
  orderDb.doc(`${documentId}/attachments/store-order-upload`);
