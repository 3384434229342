import React from 'react';
import { Form } from 'react-final-form';
import { Paper, FormHelperText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import arrayMutators from 'final-form-arrays';

import { Box, Button, Typography } from 'Components/Base';
import { CHANT_MODE, getUserInfoForm, UIFORM_TEXT_FIELDS } from 'Domain/Sattha/Chant';
import {
  AvailableSkuItem,
  OrderShippingData,
  SkuWallpaperData,
} from 'Libs/Firebase/firestore/Order';
import ShippingForm from '../Components/ShippingForm';
import HorowallWallpaperForm, {
  validations as horowallValidations,
} from '../Components/HorowallWallpaperForm';
import {
  HOROWALL_REQUEST_TYPE,
  RMS_REQUEST_TYPE,
  WALLPAPER_INPUT_TYPE,
} from 'Domain/Sku/Wallpaper';
import { MTW_DEVICE_TYPES, MTW_REQUEST_TYPES } from 'Domain/Sku/MTWWallpaper';
import { HiddenFieldForm, HiddenSkuCodeFieldForm } from '../Components/HiddenFieldForm';
import ReactMarkdown from 'react-markdown';
import RMSWallpaperForm, { validations as rmsValidations } from '../Components/RMSWallpaperForm';
import MTWWallpaperForm, {
  validations as mtwValidations,
  getInitialData as mtwGetInitialData,
} from '../Components/MTWWallpaperForm';
import { shippingValidations } from '../utils/validations';
import AcceptPDPAForm, { validatePDPA } from '../Components/AcceptPDPAForm';
import ErrorBox from '../Components/ErrorBox';
import ConfirmationButton from '../Components/ConfirmationButton';
import HRSWallpaperForm, { validations as hrsValidations } from '../Components/HRSWallpaperForm';
import { HRS_REQUEST_TYPE } from 'Domain/Sku/HRSWallpaper';

const useStyles = makeStyles((theme) => ({
  hiddenField: {
    display: 'none',
  },
  gradientButton: {
    background: `linear-gradient(314.47deg, #01C1A8 0%, #1DCE59 90.84%)`,
    borderRadius: 2,
    color: 'white',
    width: '100%',
    '&:disabled': {
      color: `#969696`,
      background: '#E6E6E6',
    },
  },
}));

export interface UserInfoFormData {
  orderId: string;
  mode: CHANT_MODE;
  chantName: string;
  chantMessage: string;
  shippingName?: string;
  shippingAddress?: string;
  shippingPhoneNo?: string;
  shippingPostcode?: string;
  skuCode?: string;
  birthDate?: number;
  birthMonth?: number;
  birthTime?: string;
  birthYear?: string;
}

export interface UserInfoFormDataWallpaper {
  orderId: string;
  mode: CHANT_MODE;
  chantName: string;
  shippingName?: string;
  shippingAddress?: string;
  shippingPhoneNo?: string;
  shippingPostcode?: string;
  skuCode?: string;
  email?: string;
  gender: string;
  phoneNo: string;
  birthDay: number;
  birthDate: string;
  birthMonth: number;
  birthYear: number;
  occupation: string;
  wallpaperRequestType: string;
  wallpaperRequestSubType: string;
  acceptTerms: boolean;
  acceptPrivacy: boolean;

  // RMS
  birthTime?: string;
  lineId?: string;
  phoneModel?: string;
  wallpaperColor?: string;

  // MTW
  medicalProblems?: string[];
  fansBirthDay?: {
    day: number;
    month: number;
    year: number;
  };
  wallpaperRequestSubSubType?: string;
  wallpaperRequestOption?: string;

  // HRS
  secondaryOccupation?: string;
}

export interface UserInfoFormInitialData {
  chantName?: string;
  chantMessage?: string;
}

const HorowallIncludedServices = () => {
  return (
    <>
      <Box mt={3} />
      <Typography variant="h4">ข้อมูลบริการเสริมที่ได้รับ</Typography>
      <ReactMarkdown>{`
1. ค้นหาฤกษ์เปลี่ยนวอลเปเปอร์ ที่แม่นยำ เหมาะกับคุณ เพื่อให้เรื่องที่ ต้องการสำเร็จได้รวดเร็ว และถูกต้องยิ่งขึ้น

2. คำอธิษฐานที่ใช้ขอเน้นเฉพาะเรื่องที่คุณต้องการ เพื่อกระตุ้นดึงดูดพลังงานดีๆ ช่วยเสริมดวงให้ดีขึ้นไปอีก

3. นอกจากเปลี่ยนวอลเปเปอร์แล้วเรื่องที่คุณต้องการก็มาพร้อมการทำบุญว่าต้องไหว้หรือสักการะอะไรเพื่อกระตุ้นให้สำเร็จโดยเร็ว

4. ถ้าคุณไม่มั่นใจในพื้นดวงที่แท้จริงของคุณสามารถเลือกให้เราค้นหาพื้นดวง ราศีและธาตุให้ได้

5. เรื่องที่คุณเลือกปังไวขึ้น ถ้าได้เลขไปใช้ตั้งชื่อไลน์ ช่วยดึงดูดให้เรื่องที่เน้นมาเร็วมาไว เพราะเห็น จากไลน์ในการแชตได้ทันที
      `}</ReactMarkdown>
    </>
  );
};

const MTWIncludedServices = () => {
  return (
    <>
      <Box mt={3} />
      <Typography variant="h4">ข้อมูลบริการเสริมที่ได้รับ</Typography>
      <ReactMarkdown>{`
1. ใส่รูปพื้นดวงจักรราศีของตัวเอง เพื่อช่วยให้วอลเปเปอร์เสริมพลังกับตัวเรามากขึ้น

2. บริการหาฤกษ์มงคลในการเริ่มใช้วอลเปเปอร์
      `}</ReactMarkdown>
    </>
  );
};

interface CreateWallpaperOrderFormProps {
  onSubmit?: (formValues: UserInfoFormDataWallpaper) => void;
  orderId: string;
  locationInstructions?: string;
  locationId: string;
  isLoading: boolean;
  mode: CHANT_MODE;
  initialData?: UserInfoFormInitialData;
  haveShipping?: boolean;
  shippingData?: OrderShippingData;
  showPackageSelection: boolean;
  enablePackageSelection: boolean;
  skuMasterCode?: string;
  availableSkus?: AvailableSkuItem[];
  wallpaperRequestType?:
    | HOROWALL_REQUEST_TYPE
    | RMS_REQUEST_TYPE
    | MTW_REQUEST_TYPES
    | HRS_REQUEST_TYPE;
  horowallShowIncludedServices?: boolean;
  wallpaperInputTemplate: string;
  skuData: SkuWallpaperData;
}

enum ORDER_MODE {
  FULFILLMENT = 'FULFILLMENT',
  SHIPPING = 'SHIPPING',
}

export default function UserInfoWallpaperForm(props: CreateWallpaperOrderFormProps) {
  const classes = useStyles();

  const {
    orderId,
    onSubmit = () => {
      /** */
    },
    locationId,
    isLoading,
    mode,
    initialData,
    haveShipping,
    shippingData,
    showPackageSelection,
    enablePackageSelection,
    skuMasterCode,
    availableSkus,
    wallpaperRequestType,
    horowallShowIncludedServices,
    wallpaperInputTemplate,
    skuData,
  } = props;

  const FORM_TEXT = getUserInfoForm(mode);
  const { chantName, chantMessage } = initialData ?? {};

  const orderMode = haveShipping ? ORDER_MODE.SHIPPING : ORDER_MODE.FULFILLMENT;

  let newInitialData: any = { orderId, mode, chantMessage, chantName, wallpaperRequestType };

  if (shippingData) {
    newInitialData = { ...newInitialData, ...shippingData };
  }

  if (showPackageSelection && skuMasterCode && availableSkus && availableSkus?.length > 0) {
    const skuCode = availableSkus[0].skuCode;
    const newData = { skuCode };
    newInitialData = { ...newInitialData, ...newData };
  }

  if (skuData.wallpaperColor) {
    newInitialData = { ...newInitialData, wallpaperColor: skuData.wallpaperColor };
  }

  if (wallpaperInputTemplate === WALLPAPER_INPUT_TYPE.MTW) {
    const mtwInitialData = mtwGetInitialData(skuData, newInitialData);
    newInitialData = { ...newInitialData, ...mtwInitialData };
  }

  const handleSubmit = (values: UserInfoFormDataWallpaper, ...restArgs) => {
    // console.log('handleSubmit', values);
    const { ...restValue } = values;
    const newValues: UserInfoFormDataWallpaper = { ...restValue };
    onSubmit(newValues);
  };

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={newInitialData}
        mutators={{
          // potentially other mutators could be merged here
          ...arrayMutators,
        }}
        validate={(values) => {
          const errors: { [k: string]: any } = {};
          if (!values.skuCode) {
            errors.skuCode = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_SELECT_PACAKGE];
          }
          if (!values.chantName) {
            errors.chantName = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_NAME_REQUIRED];
          }

          // Checking by mode
          if (
            [CHANT_MODE.WALLPAPER].includes(mode) &&
            wallpaperInputTemplate === WALLPAPER_INPUT_TYPE.HOROWALL
          ) {
            const horowallErrors = horowallValidations(values, FORM_TEXT);
            Object.assign(errors, horowallErrors);
          }

          if (
            [CHANT_MODE.WALLPAPER].includes(mode) &&
            wallpaperInputTemplate === WALLPAPER_INPUT_TYPE.RMS
          ) {
            const rmsErrors = rmsValidations(values, FORM_TEXT);
            Object.assign(errors, rmsErrors);
          }

          if (
            [CHANT_MODE.WALLPAPER].includes(mode) &&
            wallpaperInputTemplate === WALLPAPER_INPUT_TYPE.MTW
          ) {
            const mtwErrors = mtwValidations(values, FORM_TEXT);
            Object.assign(errors, mtwErrors);
          }

          if (
            [CHANT_MODE.WALLPAPER].includes(mode) &&
            wallpaperInputTemplate === WALLPAPER_INPUT_TYPE.HRS
          ) {
            const mtwErrors = hrsValidations(values, FORM_TEXT);
            Object.assign(errors, mtwErrors);
          }

          // If order has shipping bundle
          if (orderMode === ORDER_MODE.SHIPPING) {
            const shippingErrors = shippingValidations(values, FORM_TEXT);
            Object.assign(errors, shippingErrors);
          }

          const pdpaErrors = validatePDPA(values);
          Object.assign(errors, pdpaErrors);

          console.log('validations', errors);
          return errors;
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => {
          console.log(values);
          return (
            <form onSubmit={handleSubmit}>
              <HiddenFieldForm />
              <Paper elevation={0} style={{ padding: 16 }}>
                <HiddenSkuCodeFieldForm />
                <Typography variant="h3">{FORM_TEXT[UIFORM_TEXT_FIELDS.HEADER_NAME]}</Typography>
                {wallpaperInputTemplate === WALLPAPER_INPUT_TYPE.HOROWALL && (
                  <HorowallWallpaperForm
                    FORM_TEXT={FORM_TEXT}
                    locationId={locationId}
                    mode={mode}
                    wallpaperRequestType={wallpaperRequestType! as HOROWALL_REQUEST_TYPE}
                  />
                )}
                {wallpaperInputTemplate === WALLPAPER_INPUT_TYPE.RMS && (
                  <RMSWallpaperForm
                    FORM_TEXT={FORM_TEXT}
                    locationId={locationId}
                    mode={mode}
                    wallpaperRequestType={wallpaperRequestType! as RMS_REQUEST_TYPE}
                    forceWallpaperColor={skuData.wallpaperColor}
                  />
                )}
                {wallpaperInputTemplate === WALLPAPER_INPUT_TYPE.MTW && (
                  <MTWWallpaperForm
                    FORM_TEXT={FORM_TEXT}
                    wallpaperRequestType={wallpaperRequestType! as MTW_REQUEST_TYPES}
                    values={values}
                    forceDeviceType={skuData.mtwDeviceType as MTW_DEVICE_TYPES}
                  />
                )}
                {wallpaperInputTemplate === WALLPAPER_INPUT_TYPE.HRS && (
                  <HRSWallpaperForm
                    FORM_TEXT={FORM_TEXT}
                    locationId={locationId}
                    mode={mode}
                    wallpaperRequestType={wallpaperRequestType! as HRS_REQUEST_TYPE}
                  />
                )}
                {horowallShowIncludedServices && <HorowallIncludedServices />}
                {skuData?.mtwIncludeServices && <MTWIncludedServices />}
                <Box my={5}></Box>
                {orderMode === ORDER_MODE.SHIPPING && (
                  <>
                    <Typography variant="h3">
                      {FORM_TEXT[UIFORM_TEXT_FIELDS.HEADER_SHIPPING]}
                    </Typography>
                    <Box my={3}></Box>
                    <ShippingForm FORM_TEXT={FORM_TEXT} />
                    <Box my={5}></Box>
                  </>
                )}
                <AcceptPDPAForm FORM_TEXT={FORM_TEXT} />
                {form.getState().hasValidationErrors && (
                  <>
                    <ErrorBox errors={form.getState().errors} />
                    <Box my={1}></Box>
                  </>
                )}
                <ConfirmationButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="large"
                  stType="gradient"
                  fullWidth
                  onClick={handleSubmit}
                  disabled={form.getState().hasValidationErrors || isLoading}
                  loading={isLoading}
                >
                  {orderMode === ORDER_MODE.FULFILLMENT
                    ? FORM_TEXT[UIFORM_TEXT_FIELDS.CONFIRM_BUTTON]
                    : FORM_TEXT[UIFORM_TEXT_FIELDS.CONFIRM_BUTTON_SHIPPING]}
                </ConfirmationButton>
              </Paper>
            </form>
          );
        }}
      ></Form>
    </>
  );
}
