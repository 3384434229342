import { createTheme, adaptV4Theme } from '@mui/material/styles';

const PRIMARY_COLOR = process.env.REACT_APP_THEME_PRIMARY_COLOR || '#00B900';

const theme = createTheme(
  adaptV4Theme({
    typography: {
      fontFamily: [
        'Anuphan',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      h1: {
        fontWeight: 'bold',
        fontSize: 48,
      },
      h2: {
        fontWeight: 'bold',
        fontSize: 36,
      },
      h3: {
        fontWeight: 'bold',
        fontSize: 24,
      },
      h4: {
        fontWeight: 'bold',
        fontSize: 20,
        lineHeight: 1.5,
      },
      h5: {
        fontWeight: 'bold',
        fontSize: 15,
      },
      h6: {
        fontWeight: 'bold',
        fontSize: 13,
      },
      body1: {
        fontSize: 15,
        lineHeight: 1.5,
      },
      body2: {
        fontSize: 15,
      },
      fontSize: 15,
    },
    palette: {
      primary: { main: PRIMARY_COLOR },
    },
    overrides: {
      MuiTableCell: {
        root: {
          fontSize: 15,
        },
      },
      MuiTableHead: {
        root: {
          fontSize: 15,
          fontWeight: 'bold',
        },
      },
      MuiRadio: {
        root: {
          color: PRIMARY_COLOR,
        },
        colorSecondary: {
          '&$checked': {
            color: PRIMARY_COLOR,
          },
        },
      },
    },
  })
);

export default theme;
