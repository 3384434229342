import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(0),
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: 'white',
    borderBottom: '1px solid #E5E5E5',
  },
}));

const logoMaxHeight = parseInt(process.env.REACT_APP_LOGO_MAX_HEIGHT || '40', 10);

const useLogoStyles = makeStyles((theme) => ({
  logo: {
    minHeight: 27,
    maxHeight: logoMaxHeight,
  },
}));

const logoPath = process.env.REACT_APP_LOGO_PATH || '/assets/LINE-LOGO.svg';

const Logo = () => {
  const classes = useLogoStyles();
  return <img src={logoPath} className={classes.logo} alt="header logo"></img>;
};

export default function ApplicationBar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar} elevation={0}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            size="large"
          >
            <Logo />
          </IconButton>
        </Toolbar>
      </AppBar>
    </div>
  );
}
