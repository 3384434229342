import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { WALLPAPER_GENDER } from 'Domain/Sku/Wallpaper';
import { useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { PrivateDataDialog } from '../Components/ConfirmationButton/ConfirmationButton';

interface GenderInputProps extends FieldRenderProps<any, HTMLElement, any> {
  placeholder: string;
  choices: {
    display: string;
    value: WALLPAPER_GENDER;
  }[];
}

enum CONFIRMATION_STATE {
  NONE = 'NONE',
  OPEN = 'OPEN',
}

export default function GenderInput(props: GenderInputProps) {
  const [confirmationState, setConfirmationState] = useState(CONFIRMATION_STATE.NONE);
  const [eventState, setEventState] = useState<SelectChangeEvent<any> | null>(null);
  const handleConfirm = () => {
    setConfirmationState(CONFIRMATION_STATE.NONE);
    props.input.onChange(eventState);
  };
  const handleCancel = () => {
    setConfirmationState(CONFIRMATION_STATE.NONE);
    props.input.onChange(undefined);
  };
  const handleInputChanged = (e: SelectChangeEvent<any>) => {
    if ([WALLPAPER_GENDER.Q_FEMALE, WALLPAPER_GENDER.Q_MALE].includes(e.target.value)) {
      setConfirmationState(CONFIRMATION_STATE.OPEN);
      setEventState(e);
    } else {
      props.input.onChange(e);
    }
  };
  return (
    <>
      <PrivateDataDialog
        open={confirmationState === CONFIRMATION_STATE.OPEN}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
      <Select
        {...props.input}
        displayEmpty
        variant="outlined"
        fullWidth
        onChange={handleInputChanged}
      >
        <MenuItem value="" disabled>
          {props.placeholder}
        </MenuItem>
        {props.choices.map((data) => (
          <MenuItem key={data.value} value={data.value}>
            {data.display}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}
