import React from 'react';
import dayjs from 'dayjs';
import { Paper } from '@mui/material';

import { OrderInfo } from 'Domain/Orders/types';
import { Box, Typography } from 'Components/Base';
import { SatthaOrder } from 'Libs/Firebase/firestore/Order';

type PackageInfoFormProps = {
  orderInfo: SatthaOrder;
  hidePrice?: boolean;
};

export default function PackageInfoForm(props: PackageInfoFormProps) {
  const { orderInfo, hidePrice } = props;
  const {
    skuName,
    locationName,
    skuDate = new Date(),
    refId,
    paymentAmount = 0.0,
    skuImageUrl,
    packageDate,
    serviceDate,
  } = orderInfo;

  const formattedPrice = new Intl.NumberFormat('th-TH', {
    style: 'currency',
    currency: 'THB',
  }).format(paymentAmount);

  let dateStr: string = 'ยังไม่ได้เลือกวัน';
  if (serviceDate) dateStr = 'วันที่: ' + dayjs(serviceDate, 'YYYY-MM-DD').format('DD/MM/YYYY');
  else if (packageDate) dateStr = 'วันที่: ' + packageDate;

  return (
    <Paper elevation={0}>
      <Box display="flex" flexDirection="row" mx={2}>
        <Box width={78} height={78}>
          <img
            style={{
              width: '100%',
              height: '100%',
            }}
            src={skuImageUrl}
            alt="package name"
          ></img>
        </Box>
        <Box display="flex" flexDirection="column" ml={2}>
          <Typography variant="h4">{skuName}</Typography>
          <Box mt={1}></Box>
          <Typography variant="label">{locationName}</Typography>
          <Typography variant="label">{dateStr}</Typography>
          <Typography variant="label">Order Number: {refId}</Typography>
          {!hidePrice && (
            <>
              <Box my={1}></Box>
              <Typography variant="title">{formattedPrice}</Typography>
            </>
          )}
        </Box>
      </Box>
    </Paper>
  );
}
