import { ORDER_STATUS } from 'Libs/Firebase/firestore/Order';

const PUBLIC_URL =
  process.env.REACT_APP_STORE_PUBLIC_URL ||
  process.env.PUBLIC_URL ||
  'https://orders.sattha.online';

export const generateStoreUploadURL = (storeId: string, orderId: string) =>
  `${PUBLIC_URL}/store/${storeId}/order/${orderId}`;

export const STORE_CAN_UPLOAD_STATUS_LIST = [
  ORDER_STATUS.MERCHANT_ACCEPT,
  ORDER_STATUS.MERCHANT_DELIVERING,
  ORDER_STATUS.MERCHANT_PREPARING,
];

export const STORE_CAN_VIEW_DELIVERED_STATUS_LIST = [ORDER_STATUS.MERCHANT_DELIVERED];

export const STORE_CAN_VIEW_STATUS_LIST = [ORDER_STATUS.CLIENT_FILLED_INFO];

export const canStoreUpload = (status) => STORE_CAN_UPLOAD_STATUS_LIST.includes(status);
export const canStoreView = (status) => STORE_CAN_VIEW_STATUS_LIST.includes(status);
export const canStoreViewDelivered = (status) =>
  STORE_CAN_VIEW_DELIVERED_STATUS_LIST.includes(status);
