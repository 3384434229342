import {
  getStoreOrderUploadDocument,
  StoreOrderUploadDocumentData,
} from 'Libs/Firebase/firestore/Order';
import { useEffect, useState } from 'react';

/**
 * Retrieve Store's Upload Image via Firestore's onSnapshot
 * @param orderDocumentId
 * @returns
 */
export const useGetStoreOrderUploadSubscriber = (orderDocumentId: string) => {
  const [isExisted, setIsExisted] = useState(false);
  const [data, setData] = useState<StoreOrderUploadDocumentData | null>(null);
  useEffect(() => {
    const unsubscribe = getStoreOrderUploadDocument(orderDocumentId).onSnapshot((update) => {
      if (update.exists) {
        const chantImageData = update.data() as StoreOrderUploadDocumentData;
        setData(chantImageData);
        setIsExisted(true);
      }
    });
    return () => {
      unsubscribe();
    };
  }, [orderDocumentId]);

  return { isExisted, data };
};
