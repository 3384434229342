import React from 'react';
import { Form, Field } from 'react-final-form';
import { FormControl, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  hiddenField: {
    display: 'none',
  },
}));

function HiddenTextFieldInput({ name }: { name: string }) {
  const classes = useStyles();
  return (
    <FormControl fullWidth className={classes.hiddenField}>
      <Field
        name={name}
        render={(props) => (
          <TextField {...props.input} hidden className={classes.hiddenField} fullWidth />
        )}
      />
    </FormControl>
  );
}

export function HiddenFieldForm() {
  const classes = useStyles();
  return (
    <>
      <FormControl fullWidth className={classes.hiddenField}>
        <Field
          name="orderId"
          render={(props) => (
            <TextField {...props.input} hidden className={classes.hiddenField} fullWidth />
          )}
        />
      </FormControl>
      <FormControl fullWidth className={classes.hiddenField}>
        <Field
          name="mode"
          render={(props) => (
            <TextField {...props.input} hidden className={classes.hiddenField} fullWidth />
          )}
        />
      </FormControl>
    </>
  );
}

export function HiddenSkuCodeFieldForm() {
  const classes = useStyles();
  return (
    <>
      <HiddenTextFieldInput name="skuCode" />
    </>
  );
}
