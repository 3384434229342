import React from 'react';
import { Form, Field } from 'react-final-form';
import { Paper, FormControl, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { Box, Button, Typography } from 'Components/Base';

const useStyles = makeStyles((theme) => ({
  hiddenField: {
    display: 'none',
  },
  gradientButton: {
    background: `linear-gradient(314.47deg, #01C1A8 0%, #1DCE59 90.84%)`,
    borderRadius: 2,
    color: 'white',
    width: '100%',
    '&:disabled': {
      color: `#969696`,
      background: '#E6E6E6',
    },
  },
}));

export function HiddenFieldForm() {
  const classes = useStyles();
  return (
    <>
      <FormControl fullWidth className={classes.hiddenField}>
        <Field
          name="email"
          render={(props) => (
            <TextField {...props.input} hidden className={classes.hiddenField} fullWidth />
          )}
        />
      </FormControl>
    </>
  );
}

const PASSWORD_LENGTH = 6;

export interface UserLoginFormData {
  email: string;
  name: string;
  password: string;
}

type StoreLoginFormProps = {
  onSubmit?: (formValues: UserLoginFormData) => void;
  email: string;
  name?: string;
  errorMessage?: string | undefined;
  loading?: boolean;
};

export default function StoreLoginForm(props: StoreLoginFormProps) {
  const classes = useStyles();

  const {
    onSubmit = (values: UserLoginFormData) => {
      /** */
    },
    email,
    name,
    errorMessage,
    loading,
  } = props;

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={{ email, name }}
        validate={(values) => {
          const errors: { [k: string]: any } = {};
          if (!values.password) {
            errors.password = 'กรุณาใส่รหัสผ่าน';
          }
          // if (values.password && values.password.length !== PASSWORD_LENGTH) {
          //   errors.password = `กรุณาใส่รหัสผ่านให้ครบ ${PASSWORD_LENGTH} หลัก`;
          // }
          console.log('validations', errors);
          return errors;
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => {
          console.log(values);
          return (
            <form onSubmit={handleSubmit}>
              <HiddenFieldForm />
              <Paper elevation={0} style={{ padding: 16 }}>
                <FormControl fullWidth>
                  <Field
                    name="name"
                    render={(props) => (
                      <TextField
                        {...props.input}
                        label="ชื่อร้านค้า"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          startAdornment: <div />,
                        }}
                        inputProps={{
                          disabled: true,
                        }}
                      />
                    )}
                  />
                </FormControl>
                <Box my={3}></Box>
                <FormControl fullWidth>
                  <Field
                    name="password"
                    render={(props) => (
                      <TextField
                        {...props.input}
                        label="Pin Code"
                        variant="outlined"
                        fullWidth
                        placeholder={`กรอกรหัสที่ได้รับจากทางศรัทธา.online`}
                        InputProps={{
                          startAdornment: <div />,
                        }}
                        inputProps={{ maxLength: 20 }}
                      />
                    )}
                  />
                </FormControl>
                <Box my={5}></Box>
                {errorMessage && (
                  <>
                    <Typography>{errorMessage}</Typography>
                    <Box my={5}></Box>
                  </>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="large"
                  stType="gradient"
                  fullWidth
                  disabled={form.getState().hasValidationErrors || loading}
                >
                  เข้าสู่ระบบ
                </Button>
              </Paper>
            </form>
          );
        }}
      ></Form>
    </>
  );
}
