import React from 'react';
import { Box, Typography } from 'Components/Base';
import { List } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { LocationProps } from '../utils';
import { CHANT_MODE, getChantModeText } from 'Domain/Sattha/Chant';

const WISH_TEXT = `### เคล็ดลับการกรอกคำขอพรเทพเจ้าจันทรา วัดหลงซานให้สำเร็จ 

ระบุชื่อและนามสกุลของคุณ และ คู่ของคุณหรือคนที่คุณหมายปองชื่อ นามสกุลอะไร ที่อยู่คือที่ไหนให้ชัดเจน ให้ขอพรด้วยใจแน่วแน่ แนะนำว่าให้ขอทีละเรื่อง และบอกกล่าวให้ชัดเจนไม่คลุมเครือ สิ่งที่สำคัญคือ คำขอพรหรือเรื่องที่ต้องการขอพรจะต้องเป็นเรื่องที่ถูกต้องตามศีลธรรมเท่านั้น จึงจะมีโอกาสสมหวัง`;

const VOW_TEXT = ``;

export default function HKLongSanInstruction({ mode }: LocationProps) {
  if (mode === CHANT_MODE.WISH) {
    return (
      <Box>
        <ReactMarkdown>{WISH_TEXT}</ReactMarkdown>
      </Box>
    );
  }
  return (
    <Box>
      <ReactMarkdown>{VOW_TEXT}</ReactMarkdown>
    </Box>
  );
}
