import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import { CHANT_MODE, UIFORM_TEXT_FIELDS } from 'Domain/Sattha/Chant';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { Field, useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { Box, Typography } from 'Components/Base';
import {
  getMTWTemplate,
  mtwAvailableGender,
  mtwAvailableMedicalProblems,
  MTW_DEVICE_TYPES,
  MTW_REQUEST_TYPES,
  MTW_SUBTOPICS,
} from 'Domain/Sku/MTWWallpaper';
import {
  MTWRequestSubTypeInput,
  MTWRequestTypeInput,
  MTWRequestSubSubTypeInput,
  MTWRequestOptionsInput,
  MTWRequestDeviceInput,
  MTWMedicalProbemInput,
} from '../Input/MTWRequestInput';
import CheckBoxGroup from '../Input/CheckBoxGroup';
import {
  availableDays,
  availableMonths,
  availableYears,
  EMAIL_REGEX,
  PHONE_NO_REGEX,
  validateAge,
  validateDate,
} from '../utils/utils';
import { getTimeInput } from 'Domain/Sku/FixBadLuck';
import { UserInfoFormDataWallpaper } from '../Forms/WallpaperForm';
import { SkuWallpaperData } from 'Libs/Firebase/firestore/Order';
import GenderInput from '../Input/GenderInput';

const useStyles = makeStyles((theme) => ({
  hiddenField: {
    display: 'none',
  },
}));

export function getInitialData(
  skuData: SkuWallpaperData,
  initialValues: Partial<UserInfoFormDataWallpaper>
) {
  let additionalInitialValues: Partial<UserInfoFormDataWallpaper> = {};
  const wallpaperRequestType = skuData.wallpaperRequestType as unknown as MTW_REQUEST_TYPES;
  const template = getMTWTemplate(wallpaperRequestType);

  // Set phoneModel
  if (skuData.mtwDeviceType) {
    // if forced, then force it
    additionalInitialValues.phoneModel = skuData.mtwDeviceType;
  } else if (template.availableDeviceType.length > 0) {
    // if options, use first one
    additionalInitialValues.phoneModel = template.availableDeviceType[0];
  } else {
    // if no options, use default, which is phone
    additionalInitialValues.phoneModel = MTW_DEVICE_TYPES.PHONE;
  }

  if (template.availableTopics.length === 1) {
    additionalInitialValues.wallpaperRequestOption = template.availableTopics[0].value;
  }
  return additionalInitialValues;
}

const shouldShowPhoneModel = (wallpaperRequestType: MTW_REQUEST_TYPES) => {
  return [MTW_REQUEST_TYPES.ARCANA_TAROT, MTW_REQUEST_TYPES.ARCANA_TAROT_DESKTOP].includes(
    wallpaperRequestType
  );
};

export function validations(values: UserInfoFormDataWallpaper, FORM_TEXT) {
  const template = getMTWTemplate(values.wallpaperRequestType as MTW_REQUEST_TYPES);
  const errors: { [key: string]: string } = {};
  if (!values.gender) errors.gender = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_GENDER_REQUIRED];
  if (!values.phoneNo) {
    errors.phoneNo = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_SHIPPING_PHONENO];
  } else {
    // if (!PHONE_NO_REGEX.test(values.phoneNo)) {
    //   errors.phoneNo = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_SHIPPING_PHONENO_FORMAT];
    // }
  }
  if (values.email) {
    if (!EMAIL_REGEX.test(values.email)) {
      errors.email = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_EMAIL_NOT_VALID];
    }
  }
  if (!values.birthDay) errors.birthDay = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHDAY_REQUIRED];
  if (!values.birthMonth)
    errors.birthMonth = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHMONTH_REQUIRED];
  if (!values.birthYear) errors.birthYear = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHYEAR_REQUIRED];
  if (values.birthDay && values.birthMonth && values.birthYear) {
    const isValidBirthDay = validateAge({
      day: values.birthDay,
      month: values.birthMonth,
      year: values.birthYear,
    });
    if (!isValidBirthDay) {
      errors.birthYear =
        'ระบบไม่สามารถให้บริการบุคคลที่อายุต่ำกว่า 13 ปีได้ กรุณาติดต่อ hello@sattha.online เพื่อทำการคืนเงิน';
    }
    const isValidDate = validateDate({
      day: values.birthDay,
      month: values.birthMonth,
      year: values.birthYear,
    });
    if (!isValidDate) {
      errors.birthDay = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHDATE_NOT_VALID];
    }
  }
  if (!values.wallpaperRequestSubType)
    errors.wallpaperRequestSubType = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_WALLPAPER_TOPIC_REQUIRED];
  if (!values.birthTime) {
    // pass validation
  } else {
    try {
      const { hour, minute, notKnow } = getTimeInput(values.birthTime);
      if (notKnow) {
        errors.birthTime = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_NOT_IN_FORMAT];
      }
    } catch (error) {
      if (error.message === 'Not Valid Format') {
        errors.birthTime = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_NOT_IN_FORMAT];
      } else if (error.message === 'Time not in range') {
        errors.birthTime = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_NOT_IN_TIME_FORMAT];
      } else {
        errors.birthTime = FORM_TEXT[UIFORM_TEXT_FIELDS.ERROR_BIRTHTIME_NOT_IN_FORMAT];
      }
    }
  }
  if (!values.wallpaperRequestOption) {
    errors.wallpaperRequestOption = 'กรุณาเลือก' + template.selectTopicLabel;
  }
  if (!values.wallpaperRequestSubType) {
    errors.wallpaperRequestSubType = 'กรุณาเลือก' + template.requestTypeLabel;
  }
  if (!values.wallpaperRequestSubSubType) {
    errors.wallpaperRequestSubType = 'กรุณาเลือก' + template.requestTypeLabel;
  }
  if (values.wallpaperRequestSubType === MTW_SUBTOPICS.FANS) {
    if (!values.fansBirthDay) {
      errors['fansBirthDay.day'] = 'กรุณากรอกวันเกิดติ่งของคุณ';
      errors['fansBirthDay.month'] = 'กรุณากรอกเดือนเกิดติ่งของคุณ';
      errors['fansBirthDay.year'] = 'กรุณากรอกปีเกิดติ่งของคุณ';
    } else {
      if (!values.fansBirthDay.day) {
        errors['fansBirthDay.day'] = 'กรุณากรอกวันเกิดติ่งของคุณ';
      }
      if (!values.fansBirthDay.month) {
        errors['fansBirthDay.month'] = 'กรุณากรอกเดือนเกิดติ่งของคุณ';
      }
      if (!values.fansBirthDay.year) {
        errors['fansBirthDay.year'] = 'กรุณากรอกปีเกิดติ่งของคุณ';
      }
    }
  }
  if (shouldShowPhoneModel(values.wallpaperRequestType as MTW_REQUEST_TYPES)) {
    if (!values.phoneModel) {
      errors.phoneModel = 'กรุณาเลือกอุปกรณ์ที่จะใช้วอลเปเปอร์';
    }
  }
  return errors;
}

export default function MTWWallpaperForm({
  FORM_TEXT,
  wallpaperRequestType,
  values,
  forceDeviceType,
}: {
  FORM_TEXT: any;
  wallpaperRequestType: MTW_REQUEST_TYPES;
  values: UserInfoFormDataWallpaper;
  forceDeviceType?: MTW_DEVICE_TYPES;
}) {
  const form = useForm();
  return (
    <>
      <Box my={1}></Box>
      <Typography variant="info">
        {'* ข้อมูลของท่านจะถูกใช้เพื่อการทำวอลเปเปอร์เท่านั้น'}
      </Typography>
      <Box my={3}></Box>
      <FormControl fullWidth required>
        <Field
          name="chantName"
          render={(props) => (
            <>
              <Typography variant="h5">{FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_NAME]}</Typography>
              <TextField
                {...props.input}
                label=""
                variant="outlined"
                placeholder="กรอกชื่อ - นามสกุลจริง"
                fullWidth
                InputProps={{
                  startAdornment: <div />,
                }}
                inputProps={{ maxLength: 60 }}
                helperText={
                  props.meta.error && props.meta.touched ? (
                    <FormHelperText error>{props.meta.error}</FormHelperText>
                  ) : null
                }
              />
            </>
          )}
        />
      </FormControl>
      <Box my={2}></Box>
      <FormControl fullWidth required>
        <Field
          name="gender"
          render={(props) => (
            <>
              <Typography variant="h5">{FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_GENDER]}</Typography>
              <GenderInput
                {...props}
                placeholder={FORM_TEXT[UIFORM_TEXT_FIELDS.PLACEHOLDER_GENDER]}
                choices={mtwAvailableGender}
              />
            </>
          )}
        />
      </FormControl>
      <Box my={2}></Box>
      <FormControl fullWidth required>
        <Field
          name="phoneNo"
          render={(props) => (
            <>
              <Typography variant="h5">{FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_PHONENO]}</Typography>
              <TextField
                {...props.input}
                label=""
                variant="outlined"
                placeholder={FORM_TEXT[UIFORM_TEXT_FIELDS.PLACEHOLDER_PHONENO]}
                fullWidth
                InputProps={{
                  startAdornment: <div />,
                }}
                inputProps={{ maxLength: 15 }}
                helperText={
                  props.meta.error && props.meta.touched ? (
                    <FormHelperText error>{props.meta.error}</FormHelperText>
                  ) : null
                }
              />
            </>
          )}
        />
      </FormControl>
      <Box my={2}></Box>
      <FormControl fullWidth>
        <Field
          name="email"
          render={(props) => (
            <>
              <Typography variant="h5">{FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_EMAIL]}</Typography>
              <TextField
                {...props.input}
                label=""
                variant="outlined"
                placeholder={FORM_TEXT[UIFORM_TEXT_FIELDS.PLACEHOLDER_EMAIL]}
                fullWidth
                InputProps={{
                  startAdornment: <div />,
                }}
                inputProps={{ maxLength: 60 }}
                helperText={
                  props.meta.error && props.meta.touched ? (
                    <FormHelperText error>{props.meta.error}</FormHelperText>
                  ) : null
                }
              />
            </>
          )}
        />
      </FormControl>
      <Box my={3}></Box>
      <FormControl fullWidth required>
        <Field
          name="birthDay"
          render={(props) => (
            <>
              <Typography variant="h5">{FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_BIRTHDATE]}</Typography>
              <Select
                {...props.input}
                displayEmpty
                variant="outlined"
                // placeholder="กรอกชื่อ - นามสกุลจริง เพื่อใช้ในการประกอบพิธี"
                fullWidth
              >
                <MenuItem value="" disabled>
                  {FORM_TEXT[UIFORM_TEXT_FIELDS.PLACEHOLDER_BIRTHDATE]}
                </MenuItem>
                {availableDays?.map((data) => (
                  <MenuItem key={data.value} value={data.value}>
                    {data.display}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        />
      </FormControl>
      <Box my={2}></Box>
      <FormControl fullWidth>
        <Field
          name="birthMonth"
          render={(props) => (
            <>
              <Typography variant="h5">{FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_BIRTHMONTH]}</Typography>
              <Select {...props.input} displayEmpty variant="outlined" fullWidth>
                <MenuItem value="" disabled>
                  {FORM_TEXT[UIFORM_TEXT_FIELDS.PLACEHOLDER_BIRTHMONTH]}
                </MenuItem>
                {availableMonths?.map((data) => (
                  <MenuItem key={data.value} value={data.value}>
                    {data.display}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        />
      </FormControl>
      <Box my={2}></Box>
      <FormControl fullWidth>
        <Field
          name="birthYear"
          render={(props) => (
            <>
              <Typography variant="h5">{FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_BIRTHYEAR]}</Typography>
              <Select {...props.input} displayEmpty variant="outlined" fullWidth>
                <MenuItem value="" disabled>
                  {FORM_TEXT[UIFORM_TEXT_FIELDS.PLACEHOLDER_BIRTHYEAR]}
                </MenuItem>
                {availableYears?.map((data) => (
                  <MenuItem key={data.value} value={data.value}>
                    {data.display}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        />
      </FormControl>
      <Box my={2}></Box>
      <FormControl fullWidth>
        <Field
          name="birthTime"
          render={(props) => (
            <>
              <Typography variant="h5">{FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_BIRTHTIME]}</Typography>
              <TextField
                {...props.input}
                label={''}
                variant="outlined"
                placeholder={FORM_TEXT[UIFORM_TEXT_FIELDS.PLACEHOLDER_BIRTHTIME]}
                fullWidth
                InputProps={{
                  startAdornment: <div />,
                }}
                inputProps={{ maxLength: 60 }}
                helperText={
                  props.meta.error && props.meta.touched ? (
                    <FormHelperText error>{props.meta.error}</FormHelperText>
                  ) : null
                }
              />
            </>
          )}
        />
      </FormControl>
      {shouldShowPhoneModel(wallpaperRequestType) && (
        <>
          <Box my={2}></Box>
          <FormControl fullWidth>
            <Field
              name="phoneModel"
              render={(props) => (
                <>
                  <Typography variant="h5">{'อุปกรณ์ที่จะใช้วอลเปเปอร์'}</Typography>
                  <MTWRequestDeviceInput
                    {...props.input}
                    inputRequestType={wallpaperRequestType}
                    forceDeviceType={forceDeviceType}
                  />
                </>
              )}
            />
          </FormControl>
        </>
      )}
      <Box my={2}></Box>
      <FormControl fullWidth>
        <Field
          name="medicalProblems"
          render={(props) => {
            return (
              <>
                <Typography variant="h5">{'คุณมีโรคประจำตัวตามนี้หรือไม่'}</Typography>
                <Typography variant="subtitle2">
                  {'บางโรคจะมีผลกับการที่แม่หมอจัดไพ่ จะได้ปรับหน้าไพ่ได้ดีขึ้น'}
                </Typography>
                <MTWMedicalProbemInput {...props.input} />
              </>
            );
          }}
        />
      </FormControl>
      <Box my={2}></Box>
      <Box my={2}></Box>
      <Typography variant="h4">{'ข้อมูลเรื่องการเสริมดวง'}</Typography>
      <Box my={2}></Box>
      <FormControl fullWidth>
        <Field
          name="wallpaperRequestType"
          render={(props) => (
            <>
              <Typography variant="h5">{'ชุดวอลเปเปอร์'}</Typography>
              <MTWRequestTypeInput {...props.input} />
            </>
          )}
        />
      </FormControl>
      <Box my={2}></Box>
      <FormControl fullWidth>
        <Field
          name="wallpaperRequestOption"
          render={(props) => (
            <>
              <MTWRequestOptionsInput
                {...props.input}
                inputRequestType={wallpaperRequestType}
                onChange={(event) => {
                  const template = getMTWTemplate(
                    values.wallpaperRequestType as unknown as MTW_REQUEST_TYPES
                  );
                  const topic = template.availableTopics.find(
                    (v) => v.value === event.target.value
                  );
                  if (topic?.forceSelection) {
                    form.change('wallpaperRequestSubType', topic?.forceSelection);
                    form.change('wallpaperRequestSubSubType', undefined);
                    form.blur('wallpaperRequestSubSubType');
                    form.blur('wallpaperRequestSubType');
                  }
                  props.input.onChange(event);
                }}
              />
            </>
          )}
        />
      </FormControl>
      <Box my={2}></Box>
      <FormControl fullWidth>
        <Field
          name="wallpaperRequestSubType"
          render={(props) => (
            <>
              <MTWRequestSubTypeInput
                {...props.input}
                inputRequestType={wallpaperRequestType}
                wallpaperRequestOption={values.wallpaperRequestOption}
                onChange={(event) => {
                  form.change('wallpaperRequestSubSubType', undefined);
                  form.blur('wallpaperRequestSubSubType');
                  props.input.onChange(event);
                }}
              />
            </>
          )}
        />
      </FormControl>
      <Box my={2}></Box>
      <FormControl fullWidth>
        <Field
          name="wallpaperRequestSubSubType"
          render={(props) => (
            <>
              <MTWRequestSubSubTypeInput
                {...props.input}
                inputRequestType={wallpaperRequestType}
                subTopic={values.wallpaperRequestSubType}
              />
            </>
          )}
        />
      </FormControl>
      {values.wallpaperRequestSubType === MTW_SUBTOPICS.FANS && (
        <>
          <Box my={2}></Box>
          <Typography variant="h4">{'วันเกิดของคนที่คุณติ่ง'}</Typography>
          <Box my={2}></Box>
          <FormControl fullWidth required>
            <Field
              name="fansBirthDay.day"
              render={(props) => (
                <>
                  <Typography variant="h5">
                    {FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_BIRTHDATE]}
                  </Typography>
                  <Select
                    {...props.input}
                    displayEmpty
                    variant="outlined"
                    // placeholder="กรอกชื่อ - นามสกุลจริง เพื่อใช้ในการประกอบพิธี"
                    fullWidth
                  >
                    <MenuItem value="" disabled>
                      {FORM_TEXT[UIFORM_TEXT_FIELDS.PLACEHOLDER_BIRTHDATE]}
                    </MenuItem>
                    {availableDays?.map((data) => (
                      <MenuItem key={data.value} value={data.value}>
                        {data.display}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            />
          </FormControl>
          <Box my={2}></Box>
          <FormControl fullWidth>
            <Field
              name="fansBirthDay.month"
              render={(props) => (
                <>
                  <Typography variant="h5">
                    {FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_BIRTHMONTH]}
                  </Typography>
                  <Select {...props.input} displayEmpty variant="outlined" fullWidth>
                    <MenuItem value="" disabled>
                      {FORM_TEXT[UIFORM_TEXT_FIELDS.PLACEHOLDER_BIRTHMONTH]}
                    </MenuItem>
                    {availableMonths?.map((data) => (
                      <MenuItem key={data.value} value={data.value}>
                        {data.display}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            />
          </FormControl>
          <Box my={2}></Box>
          <FormControl fullWidth>
            <Field
              name="fansBirthDay.year"
              render={(props) => (
                <>
                  <Typography variant="h5">
                    {FORM_TEXT[UIFORM_TEXT_FIELDS.LABEL_BIRTHYEAR]}
                  </Typography>
                  <Select {...props.input} displayEmpty variant="outlined" fullWidth>
                    <MenuItem value="" disabled>
                      {FORM_TEXT[UIFORM_TEXT_FIELDS.PLACEHOLDER_BIRTHYEAR]}
                    </MenuItem>
                    {availableYears?.map((data) => (
                      <MenuItem key={data.value} value={data.value}>
                        {data.display}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            />
          </FormControl>
          <Box my={2}></Box>
        </>
      )}
      <Box my={1}></Box>
    </>
  );
}
