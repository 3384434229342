import { useState, useEffect } from 'react';
import { useQuery, useMutation, UseQueryResult } from 'react-query';
import { useMutation as useApolloMutation } from '@apollo/client';

import ClientAPI from 'API/fe-functions/client/ClientAPI';

import {
  getOrderFromProvider,
  getOrderFromId,
  SatthaOrder,
} from '../../Libs/Firebase/firestore/Order';
import useApi from 'API/fe-functions/useApi';
import {
  updateFixBadLuckOrderMutation,
  updateFortuneTellingOrderMutation,
  updateHRSWallpaperOrderMutation,
  updateMeritOrderMutation,
  updateMTWWallpaperOrderMutation,
  updateRMSWallpaperOrderMutation,
  updateSingleShipmentOrderMutation,
  updateWallpaperOrderMutation,
  updateWishVowOrderMutation,
  updateWreathOrderMutation,
} from './query';
import {
  UpdateFixBadLuckOrderInput,
  UpdateFortuneTellingOrderInput,
  UpdateHRSWallpaperOrderInput,
  UpdateMeritOrderInput,
  UpdateMTWWallpaperOrderInput,
  UpdateRMSWallpaperOrderInput,
  UpdateShipmentOrderInput,
  UpdateWallpaperOrderInput,
  UpdateWishVowOrderInput,
  UpdateWreathOrderInput,
} from './types';

interface GetOrderData {
  loading: boolean;
  data: SatthaOrder | null;
  error?: any | null;
}

interface GetOrderDataWithRefetch extends GetOrderData {
  refetch: (options?: { throwOnError: boolean; cancelRefetch: boolean }) => Promise<UseQueryResult>;
}

export const useClientGetOrderData = (orderId: string): GetOrderDataWithRefetch => {
  const { isLoading, error, data, refetch } = useQuery<SatthaOrder>(
    'orderIdData' + orderId,
    () => {
      const clientApi = new ClientAPI();
      return clientApi.getOrder(orderId);
    },
    { refetchOnWindowFocus: true, retry: 5 }
  );
  return { loading: isLoading, data: data ?? null, error, refetch };
};

export const useClientGetOrderDataFromProvider = (
  provider: string,
  orderId: string
): GetOrderData => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<SatthaOrder | null>(null);
  const [error, setError] = useState<any | null>(null);
  useEffect(() => {
    const loadData = async (provider: string, orderId: string) => {
      try {
        setLoading(true);
        const clientApi = new ClientAPI();
        const data = await clientApi.getOrderFromProvider(provider, orderId);
        setLoading(false);
        setData(data);
      } catch (error) {
        setLoading(false);
        setError(error);
      }
    };
    loadData(provider, orderId);
    return () => {
      //
    };
  }, [provider, orderId]);
  return { loading, data, error };
};

/**
 * @deprecated Use useClientUpdateChantMessage instead
 * @returns Update Function and Result
 */
export const useClientUpdateOrderData = () => {
  const [error, setError] = useState<any | null>(null);
  const updateOrderData = async (orderId: string, chantName: string, chantMessage: string) => {
    const clientApi = new ClientAPI();
    try {
      await clientApi.updateOrder(orderId, { chantName, chantMessage });
    } catch (error) {
      setError(error);
    }
  };
  return { updateOrderData, error };
};

type ShippingInfoInput = {
  shippingName: string;
  shippingAddress: string;
  shippingPhoneNo: string;
  shippingPostcode: string;
};

interface UpdateChangMessageVariables {
  orderId: string;
  chantName: string;
  chantMessage: string;
  shippingInfo?: ShippingInfoInput;
  skuCode?: string;
}

export const useClientUpdateChantMessage = (orderId: string) => {
  const mutation = useMutation<SatthaOrder, any, UpdateChangMessageVariables>(
    ({ orderId, chantName, chantMessage, shippingInfo, skuCode }) => {
      const clientApi = new ClientAPI();
      return clientApi.updateOrder(orderId, { chantName, chantMessage, shippingInfo, skuCode });
    },
    {
      mutationKey: 'orderId' + orderId,
    }
  );
  return {
    updateChantMessage: mutation.mutateAsync,
    order: mutation.data,
    loading: mutation.isLoading,
    error: mutation.error,
  };
};

export const useClientConfirmChantMessage = (orderId: string) => {
  const mutation = useMutation<SatthaOrder>(
    () => {
      const clientApi = new ClientAPI();
      return clientApi.confirmChantOrder(orderId);
    },
    {
      mutationKey: 'orderId' + orderId,
    }
  );
  return {
    confirmChantMessage: mutation.mutateAsync,
    order: mutation.data,
    loading: mutation.isLoading,
    error: mutation.error,
  };
};

export function useUpdateWishVowOrder() {
  return useApolloMutation<any, { orderId: string; input: UpdateWishVowOrderInput }>(
    updateWishVowOrderMutation
  );
}

export function useUpdateFixBadLuckOrder() {
  return useApolloMutation<any, { orderId: string; input: UpdateFixBadLuckOrderInput }>(
    updateFixBadLuckOrderMutation
  );
}

export function useUpdateWallpaperOrder() {
  return useApolloMutation<any, { orderId: string; input: UpdateWallpaperOrderInput }>(
    updateWallpaperOrderMutation
  );
}

export function useUpdateRMSWallpaperOrder() {
  return useApolloMutation<any, { orderId: string; input: UpdateRMSWallpaperOrderInput }>(
    updateRMSWallpaperOrderMutation
  );
}

export function useUpdateMTWWallpaperOrder() {
  return useApolloMutation<any, { orderId: string; input: UpdateMTWWallpaperOrderInput }>(
    updateMTWWallpaperOrderMutation
  );
}

export function useUpdateHRSWallpaperOrder() {
  return useApolloMutation<any, { orderId: string; input: UpdateHRSWallpaperOrderInput }>(
    updateHRSWallpaperOrderMutation
  );
}

export function useUpdateFortuneTellingOrder() {
  return useApolloMutation<any, { orderId: string; input: UpdateFortuneTellingOrderInput }>(
    updateFortuneTellingOrderMutation
  );
}

export function useUpdateWreathOrder() {
  return useApolloMutation<any, { orderId: string; input: UpdateWreathOrderInput }>(
    updateWreathOrderMutation
  );
}

export function useUpdateSingleShipmentOrder() {
  return useApolloMutation<any, { orderId: string; input: UpdateShipmentOrderInput }>(
    updateSingleShipmentOrderMutation
  );
}

export function useUpdateMeritOrder() {
  return useApolloMutation<any, { orderId: string; input: UpdateMeritOrderInput }>(
    updateMeritOrderMutation
  );
}
