import React from 'react';
import { Divider } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';

import { Box, Typography } from 'Components/Base';

import ImageBox from './ImageBox';

type ChantImageBoxProps = {
  imageUrl: string;
};

export default function ChantImageBox({ imageUrl }: ChantImageBoxProps) {
  return (
    <Box>
      <Box mt={2}></Box>
      <Typography variant="h4">บทสวดของคุณ</Typography>
      <Box my={3}></Box>
      <ImageBox imageUrl={imageUrl} />
    </Box>
  );
}
