import Heading from 'Components/Layout/Heading';
import StoreOrderFortuneTellingForm from 'Components/StoreOrderFortuneTellingForm';
import { ATTACHMENT_TYPE, UploadStorageObject } from 'Domain/Orders/types';
import { useStoreUpdateUploadImages } from 'Domain/Stores/hooks';
import { SkuFortuneTellingData, SKU_CHANT_MODE } from 'Libs/Firebase/firestore/Order';
import React from 'react';
import { QA_TYPE, StoreUploadOrderProps } from './types';

export default function StoreFortuneTellingOrder(props: StoreUploadOrderProps) {
  const { storeId, orderId, onUploadSuccess, orderData, mode } = props;
  const { submitOrder, isLoading, error: submitError } = useStoreUpdateUploadImages();

  const handleSubmit = async (formValues) => {
    console.log('hs', formValues);
    const { uploadedImagesData, uploadedVideoData, uploadedTextData, answers, questions } =
      formValues;
    const imageObjectList: UploadStorageObject[] = uploadedImagesData.map((data) => ({
      url: data.fileurl,
      name: data.filename,
      ref: data.ref,
      bucket: data.bucket,
      type: ATTACHMENT_TYPE.IMAGE,
    }));
    const videoObjectList: UploadStorageObject[] = uploadedVideoData.map((data) => ({
      url: data.fileurl,
      name: data.filename,
      ref: data.ref,
      bucket: data.bucket,
      type: ATTACHMENT_TYPE.VIDEO,
    }));
    const bucketObjectList: UploadStorageObject[] = [...imageObjectList, ...videoObjectList];
    if (uploadedTextData) {
      bucketObjectList.push({
        url: '',
        name: 'Text Content',
        type: ATTACHMENT_TYPE.TEXT,
        ref: '',
        bucket: '',
        contents: uploadedTextData,
      });
    }
    const qaList = questions.map((question, index) => ({
      id: index,
      question: question,
      answer: answers ? answers[index] ?? null : null,
    }));
    bucketObjectList.push({
      url: '',
      name: QA_TYPE,
      type: ATTACHMENT_TYPE.OBJECT,
      ref: '',
      bucket: '',
      data: qaList,
    });
    console.log(bucketObjectList);
    const result = await submitOrder(storeId, orderId, bucketObjectList);
    console.log(result);
    // Have result means success
    if (result) onUploadSuccess();
  };

  const skuData = orderData.skuData as SkuFortuneTellingData;
  const notRequiredAnswer =
    skuData.answerWithVideo ||
    orderData.skuMode === SKU_CHANT_MODE.FORTUNE_TELLING_LIVE ||
    skuData.questions === 0;
  const requiredAdditionalInfo =
    orderData.skuMode === SKU_CHANT_MODE.FORTUNE_TELLING && skuData.questions === 0;

  return (
    <>
      <Heading title="คำถามที่ต้องการถามนักพยากรณ์" />
      <StoreOrderFortuneTellingForm
        {...orderData}
        mode={mode}
        customerName={orderData.chantName}
        packageName={orderData.skuName}
        packageId={orderData.skuCode}
        skuMode={orderData.skuMode}
        orderRealId={orderData.id}
        questions={orderData.fortuneTellingData?.questions ?? []}
        onSubmit={handleSubmit}
        loading={isLoading}
        error={submitError?.response?.data}
        showVideoUploadForm={skuData.answerWithVideo}
        requiredAnswers={!notRequiredAnswer}
        requiredAdditionalInfo={requiredAdditionalInfo}
        showTextFieldForm
      />
    </>
  );
}
