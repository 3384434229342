import React from 'react';
import { Paper, Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box, Typography } from 'Components/Base';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { SatthaOrder, SkuFortuneTellingData } from 'Libs/Firebase/firestore/Order';
import {
  getMonthDisplay,
  getYearDisplay,
  getRMSGenderDisplay,
  getBirthTimeDisplay,
} from 'Domain/Sku/Wallpaper';
import { ImageBox } from 'Components/Base/Box';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { getMankarinDesignTopicDisplay, getMankarinNeedsDisplay } from 'Domain/Sku/FortuneTelling';

const DEFAULT_TIMEZONE = 'Asia/Bangkok';

dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault(DEFAULT_TIMEZONE);

function createData(name: string, value?: any, hideName?: boolean, hideValue?: boolean, sx?: any) {
  return { name, value: value ?? '-', hideName, hideValue, sx };
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
    border: 0,
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.selected,
    border: 0,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: '#E6E6E6',
  borderRadius: 20,
}));

const StyledCell = styled(TableCell)(({ theme }) => ({
  verticalAlign: 'baseline',
}));

function BasicTable({
  rows = [],
}: {
  rows: {
    name: string;
    value: string;
    hideName?: boolean;
    hideValue?: boolean;
    sx?: any;
  }[];
}) {
  return (
    <TableContainer component={Card} elevation={0}>
      <Table sx={{ minWidth: 240 }} size={'small'} aria-label="simple table">
        <TableBody>
          {rows.map((row, index) => (
            <StyledTableRow key={row.name + index} sx={row.sx}>
              {!row.hideName && (
                <StyledCell
                  component="th"
                  colSpan={row.hideValue ? 2 : 1}
                  scope="row"
                  align={row.hideValue ? 'left' : 'right'}
                  sx={{
                    fontWeight: 'bold',
                    maxWidth: 150,
                    minWidth: 70,
                    width: 110,
                    verticalAlign: 'top',
                  }}
                >
                  {row.name}
                </StyledCell>
              )}
              {!row.hideValue && (
                <StyledCell colSpan={row.hideName ? 2 : 1}>{row.value}</StyledCell>
              )}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const HeaderBox = styled(Box)(({ theme }) => ({
  background: `linear-gradient(314.47deg, #01C1A8 0%, #1DCE59 90.84%)`,
}));

const getDataRows = (orderInfo: Partial<SatthaOrder>) => {
  const fortuneTellingData = orderInfo.fortuneTellingData!;
  const rows = [
    [
      createData('ชื่อ - นามสกุล', orderInfo.chantName),
      createData('เบอร์โทรศัพท์', fortuneTellingData?.phoneNo),
      createData('เพศ', getRMSGenderDisplay(fortuneTellingData?.gender)),
      createData('วันที่เกิด', fortuneTellingData?.birthday.day),
      createData('เดือนที่เกิด', getMonthDisplay(fortuneTellingData?.birthday.month)),
      createData('ปีที่เกิด', getYearDisplay(fortuneTellingData?.birthday.year)),
      createData('เวลาที่เกิด', getBirthTimeDisplay(fortuneTellingData?.birthTime)),
      createData(
        'รูปถ่ายหน้าตรง',
        fortuneTellingData?.faceImage ? (
          <ImageBox
            src={fortuneTellingData?.faceImage?.url}
            sx={{ maxWidth: 160, width: '100%' }}
            onClick={() => {
              window.open(fortuneTellingData?.faceImage?.url, '_blank');
            }}
          />
        ) : (
          '-'
        )
      ),
    ],
    [],
  ];

  const skuData = orderInfo?.skuData as SkuFortuneTellingData;

  if (skuData?.requiredBirthPlace) {
    const birthPlaceValue = createData('สถานที่เกิด', fortuneTellingData?.birthPlace ?? '-');
    rows[0].splice(7, 0, birthPlaceValue);
  }

  if (skuData?.requiredPalmPicture) {
    const palmImage = fortuneTellingData?.palmImage;
    let palmImageData: any = '-';
    if (palmImage)
      palmImageData = palmImage.map((p) => (
        <ImageBox
          key={p.url}
          src={p.url}
          sx={{ maxWidth: 160, width: '100%' }}
          onClick={() => {
            window.open(fortuneTellingData?.faceImage?.url, '_blank');
          }}
        />
      ));
    rows[0].push(createData('รูปลายมือ', palmImageData));
  }

  if (fortuneTellingData?.questions && fortuneTellingData?.questions.length > 0) {
    fortuneTellingData?.questions?.forEach((question, index) => {
      rows[1].push(createData('คำถาม ' + (index + 1), question));
    });
  } else {
    rows[1].push(createData('ไม่มีคำถาม', ''));
  }

  return rows;
};

function getBookingDataRow(orderInfo: Partial<SatthaOrder>) {
  const row: any[] = [];
  const booking = orderInfo.booking;
  if (!booking) return row;

  const startTime = dayjs(booking.startTime).tz(DEFAULT_TIMEZONE);
  const endTime = dayjs(booking.endTime).tz(DEFAULT_TIMEZONE);
  row.push(createData('วันที่', startTime.locale('th').format('D MMM')));
  row.push(
    createData(
      'เวลา',
      `${startTime.locale('th').format('HH:mm')}-${endTime
        .locale('th')
        .format('HH:mm')} (ตามเวลาประเทศไทย)`
    )
  );
  return row;
}

const getDesignRows = (orderInfo: Partial<SatthaOrder>) => {
  const additionalFtData = orderInfo.fortuneTellingData?.additionalInfo;
  const rows: any[] = [];
  if (additionalFtData) {
    switch (additionalFtData.inputMode) {
      case 'MANKARIN':
        rows.push(createData('อาชีพ', orderInfo.fortuneTellingData?.occupation));
        rows.push(
          createData(
            'เรื่องที่ให้ออกแบบ 1',
            <Typography variant="h5" color="primary">
              เรื่องที่ให้ออกแบบ 1
            </Typography>,
            true,
            false
          )
        );
        rows.push(
          createData('เรื่อง', getMankarinDesignTopicDisplay(additionalFtData.design[0]?.topic))
        );
        rows.push(
          createData('เสริมดวง', getMankarinNeedsDisplay(additionalFtData.design[0]?.enhancements))
        );
        rows.push(createData('ข้อมูลเพิ่มเติม', additionalFtData.design[0]?.comment));
        rows.push(
          createData(
            'เรื่องที่ให้ออกแบบ 2',
            <Typography variant="h5" color="primary">
              เรื่องที่ให้ออกแบบ 2
            </Typography>,
            true,
            false
          )
        );
        rows.push(
          createData('เรื่อง', getMankarinDesignTopicDisplay(additionalFtData.design[1]?.topic))
        );
        rows.push(
          createData('เสริมดวง', getMankarinNeedsDisplay(additionalFtData.design[1]?.enhancements))
        );
        rows.push(createData('ข้อมูลเพิ่มเติม', additionalFtData.design[1]?.comment));
        break;

      default:
        break;
    }
  }
  return rows;
};

export default function FortuneTellingSummaryCard({
  orderInfo,
  hideBookingTime,
}: {
  orderInfo: Partial<SatthaOrder>;
  hideBookingTime?: boolean;
}) {
  const [rows1, rows2] = getDataRows(orderInfo);
  const additionalDataRows = getDesignRows(orderInfo);

  return (
    <StyledCard>
      <HeaderBox py={2} px={2}>
        <Typography variant="h3" color="white">
          สรุปข้อมูล
        </Typography>
      </HeaderBox>
      {orderInfo.booking && !hideBookingTime && (
        <>
          <Box py={2} px={2}>
            <Typography variant="h4" color="primary">
              ข้อมูลการจอง
            </Typography>
          </Box>
          <BasicTable rows={getBookingDataRow(orderInfo)} />
        </>
      )}
      <Box py={2} px={2}>
        <Typography variant="h4" color="primary">
          ข้อมูลทั่วไป
        </Typography>
      </Box>
      <BasicTable rows={rows1} />
      <Box pt={4} pb={2} px={2}>
        <Typography variant="h4" color="primary">
          คำถามที่ต้องการถามนักพยากรณ์
        </Typography>
      </Box>
      <BasicTable rows={rows2} />
      {additionalDataRows.length > 0 && (
        <>
          <Box pt={4} pb={2} px={2}>
            <Typography variant="h4" color="primary">
              ข้อมูลเพิ่มเติม
            </Typography>
          </Box>
          <BasicTable rows={additionalDataRows} />
        </>
      )}
    </StyledCard>
  );
}
