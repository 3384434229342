import { Box, Button, Container, Typography } from 'Components/Base';

import ShippingDetailsBox, { OrderShippingDataProps } from './ShippingDetailsBox';
import WallpaperCampaign from 'Containers/WallpaperCampaign';
import OrderWaitBox from 'Containers/OrderWaitBox';
import FortuneTellingSummaryCard from 'Components/FortuneTellingSummaryCard';
import { Footer, OrderDetailsProps } from '../OrderDetails';
import { ATTACHMENT_TYPE } from 'Libs/Firebase/firestore/Order';
import { QA_TYPE } from 'Routes/StoreOrder/Containers/types';
import FortuneTellingAnswerSummaryCard from 'Components/FortuneTellingAnswerSummaryCard';
import { Fragment } from 'react';
import { AlternativeImageBox, VideoBox } from './StoreUploadedImagesBox';
import PackageInfoForm from 'Components/PackageInfoForm';
import BookingInfoBox from 'Components/BookingInfoBox';

export default function OrderDetailsFortuneTelling(props: OrderDetailsProps) {
  const {
    isChantImageLoading = true,
    chantImageUrl,
    storeUploadImageList = [],
    showFeedbackButton,
    locationId,
    mode,
    shippingData,
    shippingTrackingData,
    haveShippingData,
    orderInfo,
  } = props;
  const fortuneTellingAnswer = storeUploadImageList.find(
    (attachment) => attachment.type === ATTACHMENT_TYPE.OBJECT && attachment.name === QA_TYPE
  );
  const textAttachment = storeUploadImageList?.find(
    (attachment) => attachment.type === ATTACHMENT_TYPE.TEXT
  );
  const uploadedImageList = storeUploadImageList.filter(
    (image) => image.type === ATTACHMENT_TYPE.IMAGE
  );
  const uploadedVideoList = storeUploadImageList.filter(
    (image) => image.type === ATTACHMENT_TYPE.VIDEO
  );

  const booking = orderInfo.booking;

  return (
    <Box>
      <Container maxWidth={'sm'} disableGutters={false}>
        <Box pt={2} />
        <PackageInfoForm orderInfo={orderInfo} />
        {booking && (
          <Box pt={2}>
            <Typography variant="h4">ลิงก์การดูดวงแบบออนไลน์</Typography>
            <Box my={2}></Box>
            <BookingInfoBox
              meetingUrl={booking.meetingUrl}
              startTime={booking.startTime}
              endTime={booking.endTime}
            />
          </Box>
        )}
        <Box pt={2} />
        <Typography variant="h4">คำทำนายของคุณ</Typography>
        <WallpaperCampaign orderId={orderInfo.orderId} />
        <Box pt={1} />
        <OrderWaitBox orderInfo={orderInfo} />
        {fortuneTellingAnswer && (
          <>
            <Box pt={3} />
            {uploadedVideoList.length > 0 &&
              uploadedVideoList.map((videoData) => (
                <Fragment key={videoData.url}>
                  <VideoBox
                    videoData={videoData}
                    packageDate={orderInfo.packageDate}
                    name={orderInfo.chantName}
                  />
                  <Box mb={3}></Box>
                </Fragment>
              ))}
            <FortuneTellingAnswerSummaryCard
              data={fortuneTellingAnswer?.data}
              additionalText={textAttachment?.contents}
            />
            <Box mb={3}></Box>
            {uploadedImageList.length > 0 &&
              uploadedImageList.map((imageData) => (
                <Fragment key={imageData.url}>
                  <AlternativeImageBox imageData={imageData} />
                  <Box mb={3}></Box>
                </Fragment>
              ))}
          </>
        )}
        <Box mt={4} mb={4} />
        <FortuneTellingSummaryCard orderInfo={orderInfo} />
        {haveShippingData && (
          <>
            <Box my={2}></Box>
            <ShippingDetailsBox {...shippingData!} {...shippingTrackingData} />
          </>
        )}
        <Box my={5}></Box>
        <Footer showFeedbackButton={showFeedbackButton} mode={mode} orderId={orderInfo.orderId} />
      </Container>
    </Box>
  );
}
