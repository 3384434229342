import { gql } from '@apollo/client';

export const updateWishVowOrderMutation = gql`
  mutation updateWishVowOrder($orderId: String!, $input: UpdateWishVowOrderInput!) {
    updateWishVowOrder(orderId: $orderId, input: $input) {
      ... on UpdateWishVowOrderSuccessResponse {
        order {
          orderId
        }
      }
      ... on UpdateWishVowOrderInvalidInputResponse {
        message
        code
      }
    }
  }
`;

export const updateFixBadLuckOrderMutation = gql`
  mutation updateFixBadLuckOrder($orderId: String!, $input: UpdateFixBadLuckOrderInput!) {
    updateFixBadLuckOrder(orderId: $orderId, input: $input) {
      ... on UpdateFixBadLuckOrderSuccessResponse {
        order {
          orderId
        }
      }
      ... on UpdateFixBadLuckOrderInvalidInputResponse {
        message
        code
      }
    }
  }
`;

export const updateWallpaperOrderMutation = gql`
  mutation updateWallpaperOrder($orderId: String!, $input: UpdateWallpaperOrderInput!) {
    updateWallpaperOrder(orderId: $orderId, input: $input) {
      ... on UpdateWallpaperOrderSuccessResponse {
        order {
          orderId
        }
      }
      ... on UpdateWallpaperOrderInvalidInputResponse {
        message
        code
      }
    }
  }
`;

export const updateRMSWallpaperOrderMutation = gql`
  mutation updateRMSWallpaperOrder($orderId: String!, $input: UpdateRMSWallpaperOrderInput!) {
    updateRMSWallpaperOrder(orderId: $orderId, input: $input) {
      ... on UpdateWallpaperOrderSuccessResponse {
        order {
          orderId
        }
      }
      ... on UpdateWallpaperOrderInvalidInputResponse {
        message
        code
      }
    }
  }
`;

export const updateMTWWallpaperOrderMutation = gql`
  mutation updateMTWWallpaperOrder($orderId: String!, $input: UpdateMTWWallpaperOrderInput!) {
    updateMTWWallpaperOrder(orderId: $orderId, input: $input) {
      ... on UpdateWallpaperOrderSuccessResponse {
        order {
          orderId
        }
      }
      ... on UpdateWallpaperOrderInvalidInputResponse {
        message
        code
      }
    }
  }
`;

export const updateHRSWallpaperOrderMutation = gql`
  mutation updateHRSWallpaperOrder($orderId: String!, $input: UpdateHRSWallpaperOrderInput!) {
    updateHRSWallpaperOrder(orderId: $orderId, input: $input) {
      ... on UpdateWallpaperOrderSuccessResponse {
        order {
          orderId
        }
      }
      ... on UpdateWallpaperOrderInvalidInputResponse {
        message
        code
      }
    }
  }
`;

export const updateFortuneTellingOrderMutation = gql`
  mutation updateFortuneTellingOrder($orderId: String!, $input: UpdateFortuneTellingOrderInput!) {
    updateFortuneTellingOrder(orderId: $orderId, input: $input) {
      ... on UpdateFortuneTellingOrderSuccessResponse {
        order {
          orderId
        }
      }
      ... on UpdateFortuneTellingOrderInvalidInputResponse {
        message
        code
      }
    }
  }
`;

export const updateWreathOrderMutation = gql`
  mutation updateWreathOrder($orderId: String!, $input: UpdateWreathOrderInput!) {
    updateWreathOrder(orderId: $orderId, input: $input) {
      ... on UpdateWreathOrderSuccessResponse {
        order {
          orderId
        }
      }
      ... on UpdateWreathOrderInvalidInputResponse {
        message
        code
      }
    }
  }
`;

export const updateSingleShipmentOrderMutation = gql`
  mutation updateShipmentOrder($orderId: String!, $input: UpdateShipmentOrderInput!) {
    updateShipmentOrder(orderId: $orderId, input: $input) {
      ... on UpdateShipmentOrderSuccessResponse {
        order {
          orderId
        }
      }
      ... on UpdateShipmentOrderInvalidInputResponse {
        message
        code
      }
    }
  }
`;

export const updateMeritOrderMutation = gql`
  mutation updateMeritOrder($orderId: String!, $input: UpdateMeritOrderInput!) {
    updateMeritOrder(orderId: $orderId, input: $input) {
      ... on UpdateMeritOrderSuccessResponse {
        order {
          orderId
        }
      }
      ... on UpdateMeritOrderInvalidInputResponse {
        message
        code
      }
    }
  }
`;
