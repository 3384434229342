import { Button, Typography } from 'Components/Base';
import { Link } from 'react-router-dom';
import { ORDER_STATUS, SatthaOrder } from 'Libs/Firebase/firestore/Order';
import MUIDataTable from 'mui-datatables';
import React from 'react';
import { generateStoreUploadURL } from 'Domain/Stores/utils';
import { generateClientMyOrderUrl } from 'Domain/Backoffice/utils';
import QRCode from 'react-qr-code';
import { ButtonGroup, TableCell, TableRow } from '@mui/material';
import { Box } from 'Components/Base';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { useBackofficeApprovalOrder } from 'Domain/Backoffice/hooks';
import { APPROVAL_TYPE } from 'API/fe-functions/backoffice/BackofficeAPI';

interface UploadErrorResultTableProps {
  data?: any[];
  title?: string;
  refetchData?: () => Promise<void>;
}

export default function UploadErrorResultTable(props: UploadErrorResultTableProps) {
  const {
    title = 'ข้อมูลที่ผิดพลาด',
    data = [],
    refetchData = () => {
      /**/
    },
  } = props;

  const columns = [
    {
      name: 'orderSummaryId',
      label: 'Order No.',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'batchDate',
      label: 'Batch Date',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'locationName',
      label: 'Location',
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    pagination: false,
    selectableRows: 'none',
    elevation: 1,
    setTableProps: () => ({ size: 'small' }),
    responsive: 'standard',
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
  };

  return (
    <MUIDataTable title={title} data={data} columns={columns} options={options} size={'small'} />
  );
}
