import React from 'react';
import { Box, Typography } from 'Components/Base';
import { List } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { LocationProps } from '../utils';
import { CHANT_MODE, getChantModeText } from 'Domain/Sattha/Chant';

const WISH_TEXT = ``;

const VOW_TEXT = ``;

const FIX_BADLUCK_TEXT = `### วิธีแก้ชงกับเทพไท่ส่วยเอี๊ย วัดเล่งเน่ยยี่จากบ้าน

**อุปกรณ์ที่ต้องใช้**
* ธูป 3 ดอก (แนะนำว่าควรมี)
* บทสวดข้างบน (กดบันทึกเก็บไว้อ่านได้เลย!)

**ก่อนถึงเวลาที่ร้านค้าจะดำเนินการแก้ชง**
* จุดธูปให้พร้อม
* ตั้งจิตอธิษฐานให้มั่นต่อรูปเทพไท่ส่วยเอี๊ยที่เราเตรียมไว้ให้ แล้วกล่าวบทสวด, ชื่อของผู้แก้ชงให้ชัดเจน 
* ปักธูปลงกระถาง เป็นอันเสร็จพิธี"
`;

export default function LCL({ mode }: LocationProps) {
  if (mode === CHANT_MODE.WISH) {
    return (
      <Box>
        <ReactMarkdown>{WISH_TEXT}</ReactMarkdown>
      </Box>
    );
  }
  if (mode === CHANT_MODE.VOW) {
    return (
      <Box>
        <ReactMarkdown>{VOW_TEXT}</ReactMarkdown>
      </Box>
    );
  }
  if (mode === CHANT_MODE.FIX_BADLUCK) {
    return (
      <Box>
        <ReactMarkdown>{FIX_BADLUCK_TEXT}</ReactMarkdown>
      </Box>
    );
  }
  return null;
}
